import React, {Fragment,useState,useRef,Suspense} from 'react';
import { useEffect } from 'react';

import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

//per modifica o eliminazione:
import FormGridFormRowSchedaAssegnata from '../SchedaAllenamentoMAS/FormGridFormRow';

import Clessidra from '../Clessidra.js';
import Cestino from "../../immagini/cestino.png";
import Interrogativo from '../../immagini/interrogativo-bianco.png';
import Modale from '../Modale.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record

const STATO_SCEGLI_ISCRITTO = 8;
const STATO_VAI_IN_MODIFICA_ASSEGNAZIONE = 9;
const STATO_VAI_IN_ELIMINA_ASSEGNAZIONE = 10;
const STATO_PREPARA_PDF= 11;
const STATO_VAI_IN_DETTAGLI = 12;

function TablesAssegnazioniSchede(props) {
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    var today = new Date();

    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    //const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno+'T00:00:00';
    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno;
    const dataIncrementata4Settimane = calcolaDataIncrementata(dataOggi);

    //giro di ricerca persona:
    const [statoTendinaIscritto,setStatoTendinaIscritto]=useState(false);
    const [risultatoServerDatiPersona,setRisultatoServerDatiPersona]=useState('');
    const [insPersonaEdi,setInsPersonaEdi]=useState('');
    const [insIdPersonaEdi,setInsIdPersonaEdi]=useState('');
    const [flagModaleInfoPopolamento, setFlagModaleInfoPopolamento] = React.useState(false);

    //giro di ricerca delle schede attualmente assegnate alla persona selezionata:
    const [arrayIdScheda,setArrayIdScheda]=useState(['']);
    const [arrayNomeScheda,setArrayNomeScheda]=useState(['']);
    const [arrayPeriodo,setArrayPeriodo]=useState(['']);
    const [arrayDataDa,setArrayDataDa]=useState(['']);
    const [arrayDataA,setArrayDataA]=useState(['']);
    const [arrayNote,setArrayNote]=useState(['']);

    const [flagAttivaModaleInserisciDescrizione, setFlagAttivaModaleInserisciDescrizione] = useState(0);
    
    //per memorizzare le note ed il periodo della scheda eventualmente selezionata in tendina:
    const [arrayNoteScheda,setArrayNoteScheda]=useState(['']);
    const [arrayPeriodoScheda,setArrayPeriodoScheda]=useState(['']);
    const [arrayIdTutteSchede,setArrayIdTutteSchede]=useState(['']);
    
    //per nuova scheda:
    const [insSchedaEdi,setInsSchedaEdi]=useState('');
    const [assegnaNuovaScheda,setAssegnaNuovaScheda]=useState(0);
    const [risultatoServerDatiTuttiEsercizi,setRisultatoServerDatiTuttiEsercizi]=useState('');
    const [risultatoServerDatiTutteClassiEsercizi,setRisultatoServerDatiTutteClassiEsercizi]=useState('');
    const [statoTendinaEsercizi,setStatoTendinaEsercizi]=useState('');
    const [statoTendinaClassiEsercizi,setStatoTendinaClassiEsercizi]=useState(false); 
    const [risultatoServerDatiScheda,setRisultatoServerDatiScheda]=useState('');
    const [risultatoServerDatiTuttiEserciziLimitato,setRisultatoServerDatiTuttiEserciziLimitato]=useState([]);
    const [statoTendinaSchedeAllenamento,setStatoTendinaSchedeAllenamento]=useState('');
    const dataDa   = useRef();
    const dataA   = useRef();
    const [dataADefault,setDataADefault]=useState('');
    const [dataDaDefault,setDataDaDefault]=useState('');
    const [descrizioneDefault,setDescrizioneDefault]=useState('');
    const [noteDefault,setNoteDefault]=useState('');
    const [periodoDefault,setPeriodoDefault]=useState('');
    const [defaultScheda,setDefaultScheda]=useState('');
    const serie   = useRef([]);
    const ripetizioni   = useRef([]);
    const recupero   = useRef([]);
    const descrizione   = useRef([]);
    const note   = useRef([]);
    const [aggiungiEsercizio,setAggiungiEsercizio]=useState(0);
    const [termineRicercaDatiEserciziSingolaScheda,setTermineRicercaDatiEserciziSingolaScheda]=useState(0);

    //se scelgo una scheda tra quelle già presenti, i risultati vengono messi qui:
    const [arrayEserciziScheda,setArrayEserciziScheda]=useState([]);
    const [arraySerieScheda,setArraySerieScheda]=useState([]);
    const [arrayRipetizioniScheda,setArrayRipetizioniScheda]=useState([]);
    const [arrayRecuperoTraSerieScheda,setArrayRecuperoTraSerieScheda]=useState([]);
    const [arrayNoteEsercizioScheda,setArrayNoteEsercizioScheda]=useState([]);
    const [arrayClasseScheda,setArrayClasseScheda]=useState([]);
    const [arraySpiegazioneScheda,setArraySpiegazioneScheda]=useState([]);
    const [arrayFotoScheda,setArrayFotoScheda]=useState([]);
    const [arrayTypeFotoScheda,setArrayTypeFotoScheda]=useState([]);

    const [arraySezioneScheda,setArraySezioneScheda]=useState([]);
    const [arraySezioniPossibili,setArraySezioniPossibili]=useState(["R","A","B","C","D","E"]);
    const [arrayNumeroScheda,setArrayNumeroScheda]=useState([]);
    const [arrayNumeriPossibili,setArrayNumeriPossibili]=useState([1,2,3,4,5,6,7,8,9]);
    const [arraySedutaScheda,setArraySedutaScheda]=useState([]);
    const [arraySedutePossibili,setArraySedutePossibili]=useState([1,2,3,4,5,6,7]);
    
    //per tenere traccia se la scheda selezionata è stata modificata o meno:
    //const [modificataSchedaStandard,setModificataSchedaStandard]=useState(0);//DISABILITATO PER ORA

    const [ritornaPaginaIniziale,setRitornaPaginaIniziale]=useState(0);

    const [arrayCognomi,setArrayCognomi]=useState([]);
    const [arrayNomi,setArrayNomi]=useState([]);
    const [cognome,setCognome]=useState('');
    const [nome,setNome]=useState('');

    //per modificare o eliminare una scheda:
    const [idSchedaModificare,setIdSchedaModificare]=useState(0);
    const [idSchedaEliminare,setIdSchedaEliminare]=useState(0);  
    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    //per il vincolo tra le date:
    const [mantieniVincolo, setMantieniVincolo] = useState(true);

    //per modali:


    useEffect(() => {
        //console.log("entro in useeffect iniziale")
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaIscritto(false);
        setStatoTendinaEsercizi(false);
        setStatoTendinaSchedeAllenamento(false);
        setDataADefault(dataIncrementata4Settimane);
        setDataDaDefault(dataOggi);
        ricercaDatiPersona();
        setAggiungiEsercizio(0);
        setIdSchedaModificare(0);
        setIdSchedaEliminare(0);
        setTermineRicercaDatiEserciziSingolaScheda(0);
    },[]);

    useEffect(() => {
        //relativo alla ricerca dell'iscritto
        //console.log("entro in useeffect di statoTendinaIscritto ")
        if(termineRicercaDatiEserciziSingolaScheda === 1){
            inserimentoNuovaScheda();
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[termineRicercaDatiEserciziSingolaScheda]);


    useEffect(() => {
        //relativo alla ricerca dell'iscritto
        //console.log("entro in useeffect di statoTendinaIscritto ")
        if(statoTendinaIscritto === true){
            setStatoPagina(STATO_SCEGLI_ISCRITTO);
        }
    },[statoTendinaIscritto]);

    useEffect(() => {
        //relativo alla ricerca dati per nuova assegnazione
        //console.log("entro in useeffect di statoTendinaEsercizi,statoTendinaSchedeAllenamento ")
        if(statoTendinaEsercizi === true && statoTendinaSchedeAllenamento === true  && statoTendinaClassiEsercizi === true){
            for (let i=0; i<arrayClasseScheda.length ; i++) {
                callbackSetValoreTendEditabileClassiEsercizi(arrayClasseScheda[i],i,0);   
            }
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaEsercizi,statoTendinaSchedeAllenamento,statoTendinaClassiEsercizi]);

    /*DISABILITATO
    useEffect(() => {
        //console.log("entro in useeffect di modificataSchedaStandard ")
        if(modificataSchedaStandard === 1){
            //console.log("DaSTD")
            //controllo se il nome scheda standard + iscritto è già stato usato e cmq restituisco il numero progressivo da aggiungere al nome suggerito:
            setStatoPagina(STATO_RICERCA_DATI);
            if(descrizione.current) ricercaSchedePersonalizzateStessoNome();
        }
    },[modificataSchedaStandard]);
    */

    useEffect(() => {
        if(ritornaPaginaIniziale===1){
            resettoVarDiStatoPerPaginaIniziale();
        }    
    },[ritornaPaginaIniziale]);

    useEffect(() => {
        //ogni volta che cambi persona fai vedere le sue schede per cui le ricerco:
        if(statoTendinaIscritto === true && insIdPersonaEdi!==''){
            resettoVarDiStatoPerPaginaIniziale();
        }
    },[insIdPersonaEdi]);

    useEffect(() => {
        //console.log("aggiungiEsercizio")
        //aggiungo un sercizio alla scheda:
        let ese = [...arrayEserciziScheda];//popolo l'array locale con i dati dell'array di stato
        ese.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayEserciziScheda(ese);//aggiorno nuovamente la var di stato

        //NE AGGIUNGO ANCHE LE CARATTERISTICHE:
        let serie = [...arraySerieScheda];//popolo l'array locale con i dati dell'array di stato
        serie.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArraySerieScheda(serie);//aggiorno nuovamente la var di stato
        
        let rip = [...arrayRipetizioniScheda];//popolo l'array locale con i dati dell'array di stato
        rip.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRipetizioniScheda(rip);//aggiorno nuovamente la var di stato
                 
        let rec = [...arrayRecuperoTraSerieScheda];//popolo l'array locale con i dati dell'array di stato
        rec.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRecuperoTraSerieScheda(rec);//aggiorno nuovamente la var di stato
                
        let note = [...arrayNoteEsercizioScheda];//popolo l'array locale con i dati dell'array di stato
        note.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayNoteEsercizioScheda(note);//aggiorno nuovamente la var di stato
        
        let spi = [...arraySpiegazioneScheda];//popolo l'array locale con i dati dell'array di stato
        spi.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArraySpiegazioneScheda(spi);
        
        let foto = [...arrayFotoScheda];//popolo l'array locale con i dati dell'array di stato
        foto.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayFotoScheda(foto);
        
        //let nFoto = [...arrayNomeFotoScheda];//popolo l'array locale con i dati dell'array di stato
        //nFoto.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        //setArrayNomeFotoScheda(nFoto);
        
        let tFoto = [...arrayTypeFotoScheda];//popolo l'array locale con i dati dell'array di stato
        tFoto.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayTypeFotoScheda(tFoto);
                    
        let cla = [...arrayClasseScheda];//popolo l'array locale con i dati dell'array di stato
        cla.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayClasseScheda(cla);

        let sez = [...arraySezioneScheda];//popolo l'array locale con i dati dell'array di stato
        sez.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArraySezioneScheda(sez);

        let num = [...arrayNumeroScheda];//popolo l'array locale con i dati dell'array di stato
        num.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayNumeroScheda(num);

        let sed = [...arraySedutaScheda];//popolo l'array locale con i dati dell'array di stato
        sed.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArraySedutaScheda(sed);

    },[aggiungiEsercizio]);

    function tornaElenco() {
        setFlagModaleInfoPopolamento(false);
      }

    function tornaScheda(){
        setFlagAttivaModaleInserisciDescrizione(0);
    }

    function resettoVarDiStatoPerPaginaIniziale(){
        setStatoPagina(STATO_RICERCA_DATI);
        //azzero le var di stato:
        setRitornaPaginaIniziale(0);

        //setModificataSchedaStandard(0);
        setInsSchedaEdi('');
        setAssegnaNuovaScheda(0);
        setRisultatoServerDatiTuttiEsercizi('');
        setStatoTendinaEsercizi('');
        setRisultatoServerDatiScheda('');
        setStatoTendinaSchedeAllenamento('');
        setDataADefault(dataIncrementata4Settimane);
        setDataDaDefault(dataOggi);
        setDescrizioneDefault('');
        setNoteDefault('');
        setDefaultScheda('');
        setAggiungiEsercizio(0);
        //azzero le schede trovate per la persona precedente:
        setArrayIdScheda(['']);
        setArrayNomeScheda(['']);
        setArrayPeriodo(['']);
        setArrayDataDa(['']);
        setArrayDataA(['']);
        setArrayNote(['']);
        //ed azzero i dati relativi ad eventuali schede che stavo compilando:
        setArrayEserciziScheda([]);
        setArraySerieScheda([]);
        setArrayRipetizioniScheda([]);
        setArrayRecuperoTraSerieScheda([]);
        setArrayNoteEsercizioScheda([]);
        setArrayClasseScheda([]);
        setArraySpiegazioneScheda([]);
        setArrayFotoScheda([]);
        setArrayTypeFotoScheda([]);
        ricercaSchedePersona(insIdPersonaEdi);
        setFlagAttivaModaleInserisciDescrizione(0);
    }

    function callbackSetValoreTendEditabileClassiEsercizi(valore,index,res){
        //quando scelgo una classe di esercizi, la tendina degli esercizi si limita ai soli esercizi appartenetei alla classe prescelta:
        //per cui in posizione index passo da un array con tutti gli elementi (risultatoServerDatiTuttiEsercizi) ad uno con solo gli esercizi di una data classe
        // ovvero risultatoServerDatiTuttiEserciziLimitato[index]:
        //setModificataSchedaStandard(1);
        let dati = [];
        let elementi = risultatoServerDatiTuttiEsercizi.length;
        let indice = 0;

        if(elementi === 0 || valore === '') {
            for (let i = 0; i < elementi; i++) {
                dati.splice(indice,1,risultatoServerDatiTuttiEsercizi[i]);//);
                indice = indice +1;
            }
        }else{
            for (let i = 0; i < elementi; i++) {
                //console.log("*"+risultatoServerDatiTuttiEsercizi[i].CLASSE+"-"+valore)
                //controllo se la classe è pari a valore enel caso inserisco l'esercizio nell'array 'limitato':
                if(risultatoServerDatiTuttiEsercizi[i].CLASSE===valore){
                    dati.splice(indice,1,risultatoServerDatiTuttiEsercizi[i]);//);
                    indice = indice +1;
                }
            }
        }
 
        risultatoServerDatiTuttiEserciziLimitato[index] = [...dati];
        
        if(res===1){
            let datiese = [...arrayEserciziScheda];//popolo l'array locale con i dati dell'array di stato
            datiese.splice(index,1,'');//);
            setArrayEserciziScheda(datiese);//aggiorno nuovamente la var di stato
            //inoltre devo andare a cancellare tutti i dati eventualmente già presenti per il record in esame:
            
            aggiornaSerie(index,'');
            aggiornaRipetizioni(index,'');
            aggiornaRecupero(index,'');
            aggiornaNote(index,'');
            aggiornaSpiegazione(index,'');
            aggiornaFoto(index,'');
            //aggiornaNomeFoto(index,'');
            aggiornaTypeFoto(index,'');
            aggiornaClasse(index,valore,0);//l'unica cosa che metto è la classe
            //aggiornaSezione(index,'');
            //aggiornaNumero(index,'');
        }
    }

    

    function ricercaDatiTutteClassiEsercizi() {
        //ricerco tutte le calssi di esercizi disponibili che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoClassiEsercizi",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiTutteClassiEsercizi(valoreDiRitorno.risultatoRitorno);
                //setArrayClassiEsercizi(valoreDiRitorno.risultatoRitorno);         
                setStatoTendinaClassiEsercizi(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiPersona() {
        //ricerco tra gli iscritti che mi servono per popolare la tendina delle persone che possono essere assegnatari:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoPersone",ridotto:1,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiPersona(valoreDiRitorno.risultatoRitorno);
                let arrLocaleCognomi=[];
                let arrLocaleNomi=[];
                for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                    arrLocaleCognomi[valoreDiRitorno.risultatoRitorno[i].ID]=valoreDiRitorno.risultatoRitorno[i].COGNOME;
                    arrLocaleNomi[valoreDiRitorno.risultatoRitorno[i].ID]=valoreDiRitorno.risultatoRitorno[i].NOME;
                }
                if (valoreDiRitorno.risultatoRitorno.length === 0) {
                    arrLocaleCognomi[0]="";
                    arrLocaleNomi[0]=""
                }
                setArrayCognomi(arrLocaleCognomi);
                setArrayNomi(arrLocaleNomi);
                setStatoTendinaIscritto(true);
                }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );
    }
    function callbacksetValoriPersona(valore,posizioneTendina,valoreNonVisibile){
        if(valoreNonVisibile !== undefined &&  valoreNonVisibile !== '' && valoreNonVisibile != '-1'){
            setInsPersonaEdi(valore);
            setInsIdPersonaEdi(valoreNonVisibile);
            setCognome(arrayCognomi[valoreNonVisibile]);
            setNome(arrayNomi[valoreNonVisibile]);
        }
    }

    function ricercaSchedePersona(idPersona){
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"schedeAllPersoneRecuperaDatiMAS",idPersona:idPersona,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    let arrLocale1=[];
                    let arrLocale2=[];
                    let arrLocale3=[];
                    let arrLocale4=[];
                    let arrLocale5=[];
                    let arrLocale6=[];
                  
                    for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                        arrLocale1[i]=valoreDiRitorno.risultatoRitorno[i].ID;
                        setArrayIdScheda(arrLocale1);
                        arrLocale2[i]=valoreDiRitorno.risultatoRitorno[i].NOMESCHEDA;
                        setArrayNomeScheda(arrLocale2);
                        arrLocale3[i]=valoreDiRitorno.risultatoRitorno[i].DATA_DA;
                        setArrayDataDa(arrLocale3);
                        arrLocale4[i]=valoreDiRitorno.risultatoRitorno[i].DATA_A;
                        setArrayDataA(arrLocale4);
                        arrLocale5[i]=valoreDiRitorno.risultatoRitorno[i].NOTE_SCHEDA;
                        setArrayNote(arrLocale5);
                        arrLocale6[i]=valoreDiRitorno.risultatoRitorno[i].PERIODO;
                        setArrayPeriodo(arrLocale6);
                    } 
                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    // Funzione per calcolare la data incrementata di 4 settimane rispetto alla data odierna fornita come argomento:
    function calcolaDataIncrementata(dataString) {
        // Converti la stringa in un oggetto Date
        const data = new Date(dataString);
    
        // Aggiungi 4 settimane (28 giorni)
        data.setDate(data.getDate() + 28);
    
        // Formatta di nuovo in yyyy-mm-gg
        const mese = String(data.getMonth() + 1).padStart(2, "0");
        const giorno = String(data.getDate()).padStart(2, "0");
        return `${data.getFullYear()}-${mese}-${giorno}`;
    }
    // Gestione modifica della data di inizio
    const handleDataInizioChange = (e) => {
        const nuovaDataInizio = e.target.value;
        setDataDaDefault(nuovaDataInizio);

        if (mantieniVincolo) {
        setDataADefault(calcolaDataConOffset(nuovaDataInizio, 28)); // Aggiorna dataFine
        }
    };
    // Gestione modifica della data di fine
    const handleDataFineChange = (e) => {
        const nuovaDataFine = e.target.value;
        setDataADefault(nuovaDataFine);

        if (mantieniVincolo) {
        setDataDaDefault(calcolaDataConOffset(nuovaDataFine, -28)); // Aggiorna dataInizio
        }
    };
    // Gestione del checkbox
    const handleCheckboxChange = () => {
        setMantieniVincolo((prev) => !prev);
    };

    // Funzione per calcolare una data aggiungendo giorni
    const calcolaDataConOffset = (data, giorni) => {
        const nuovaData = new Date(data);
        nuovaData.setDate(nuovaData.getDate() + giorni);
        const mese = String(nuovaData.getMonth() + 1).padStart(2, "0");
        const giorno = String(nuovaData.getDate()).padStart(2, "0");
        return `${nuovaData.getFullYear()}-${mese}-${giorno}`;
    };

    function inserimentoNuovaSchedaPrimoStep(){
        setAssegnaNuovaScheda(1);
        ricercaDatiTuttiEsercizi();//serve per popolare la tendina relativa agli esercizi
        ricercaDatiTutteClassiEsercizi();
        ricercaSchedeAllenamento();//serve per popolare la tendina relativa alle schede già presenti evantulmente da prendere ad esempio
    }

    function inserimentoNuovaScheda(){
        setAssegnaNuovaScheda(2);
        //ricercaDatiTuttiEsercizi();//serve per popolare la tendina relativa agli esercizi
        //ricercaDatiTutteClassiEsercizi();
        //ricercaSchedeAllenamento();//serve per popolare la tendina relativa alle schede già presenti evantulmente da prendere ad esempio
    }
    function ricercaDatiTuttiEsercizi() {
        //ricerco tutti gli esercizi disponibili che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoEsercizi",elencoCompleto:'1',recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiTuttiEsercizi(valoreDiRitorno.risultatoRitorno);       
                setStatoTendinaEsercizi(true);
                }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaSchedeAllenamento() {
        //ricerco tra le schede allenamento che mi servono per popolare la tendina:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoSchedeAllenamentoMAS",aggiungiVuota:1,standard:1,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiScheda(valoreDiRitorno.risultatoRitorno);
                
                let arrLocale1=[];
                let arrLocale2=[];
                let arrLocale3=[];
                for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                    arrLocale1[valoreDiRitorno.risultatoRitorno[i].ID]=valoreDiRitorno.risultatoRitorno[i].NOTE_SCHEDA;
                    arrLocale2[i]=valoreDiRitorno.risultatoRitorno[i].ID;
                    arrLocale3[valoreDiRitorno.risultatoRitorno[i].ID]=valoreDiRitorno.risultatoRitorno[i].PERIODO;
                    setArrayNoteScheda(arrLocale1);
                    setArrayIdTutteSchede(arrLocale2);
                    setArrayPeriodoScheda(arrLocale3);
                }
                setStatoTendinaSchedeAllenamento(true);
                }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function callbacksetValoriScheda(valore,posizioneTendina,valoreNonVisibile){
        if(insSchedaEdi !== valore && arrayIdTutteSchede.includes(valoreNonVisibile)){
            setTermineRicercaDatiEserciziSingolaScheda(0);
            setRisultatoServerDatiTuttiEserciziLimitato([]);
            setInsSchedaEdi(valore);
            setDefaultScheda(valore);
            if (valore === 'Scheda vuota') setDescrizioneDefault();
            else setDescrizioneDefault(valore);
            setNoteDefault(arrayNoteScheda[valoreNonVisibile]);
            setPeriodoDefault(arrayPeriodoScheda[valoreNonVisibile]);
            //selezionata una tra le schede già presenti, devo vedere quali sono gli esercizi e tutte le sue caratteristiche:
            setStatoPagina("STATO_RICERCA_DATI");
            ricercaDatiEserciziSingolaScheda(valoreNonVisibile);
            
        }
    }
    function ricercaDatiEserciziSingolaScheda(idScheda) {
        //ricerco i valori di default da mettere nelle tendine (si tratta dei valori già attualmente assegnati alla scheda)
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              op:"elencoEserciziSchedaMAS",
              visioneFoto:1,
              id:idScheda,
              emak: props.emak,
              sessionId:props.sessionId})
          };
      
          fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    //console.log("Recuperati dati dal backend");
                    let arrLocaleEsercizi=[];
                    let arrLocaleSerie=[];
                    let arrLocaleRipetizioni=[];
                    let arrLocaleRecupero=[];
                    let arrLocaleNote=[];
                    let arrLocaleClasse=[];
                    let arrLocaleSpiegazione=[];
                    let arrLocaleFoto=[];
                    let arrLocaleTypeFoto=[];
                    let arrLocaleSezione=[];
                    let arrLocaleNumero=[];
                    let arrLocaleSeduta=[];

                    for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                        arrLocaleEsercizi[i]=valoreDiRitorno.risultatoRitorno[i].ESERCIZIO;
                        arrLocaleSerie[i]=valoreDiRitorno.risultatoRitorno[i].SERIE;
                        arrLocaleRipetizioni[i]=valoreDiRitorno.risultatoRitorno[i].RIPETIZIONI;
                        arrLocaleRecupero[i]=valoreDiRitorno.risultatoRitorno[i].RECUPERO_TRA_SERIE;
                        arrLocaleNote[i]=valoreDiRitorno.risultatoRitorno[i].NOTE_ESERCIZIO;
                        arrLocaleClasse[i]=valoreDiRitorno.risultatoRitorno[i].CLASSE;
                        arrLocaleSpiegazione[i]=valoreDiRitorno.risultatoRitorno[i].SPIEGAZIONE;
                        arrLocaleFoto[i]=valoreDiRitorno.risultatoRitorno[i].FILE;
                        arrLocaleTypeFoto[i]=valoreDiRitorno.risultatoRitorno[i].TYPE;
                        arrLocaleSezione[i]=valoreDiRitorno.risultatoRitorno[i].SEZIONE;
                        arrLocaleNumero[i]=Number(valoreDiRitorno.risultatoRitorno[i].NUMERO);
                        arrLocaleSeduta[i]=Number(valoreDiRitorno.risultatoRitorno[i].SEDUTA);
                    }
                    if (valoreDiRitorno.risultatoRitorno.length === 0) {
                        //console.log("Nessun esercizio, ne aggiungo uno vuoto");
                        arrLocaleEsercizi[0]="";    
                        arrLocaleSerie[0]="";
                        arrLocaleRipetizioni[0]="";
                        arrLocaleRecupero[0]="";
                        arrLocaleNote[0]="";
                        arrLocaleClasse[0]="";
                        arrLocaleSpiegazione[0]="";
                        arrLocaleFoto[0]="";
                        arrLocaleTypeFoto[0]="";
                        arrLocaleSezione[0]="";
                        arrLocaleNumero[0]="";
                        arrLocaleSeduta[0]="";
                    }  
       
                    setArrayEserciziScheda(arrLocaleEsercizi);
                    setArraySerieScheda(arrLocaleSerie);
                    setArrayRipetizioniScheda(arrLocaleRipetizioni);
                    setArrayRecuperoTraSerieScheda(arrLocaleRecupero);
                    setArrayNoteEsercizioScheda(arrLocaleNote);
                    setArrayClasseScheda(arrLocaleClasse);
                    setArraySpiegazioneScheda(arrLocaleSpiegazione);
                    setArrayFotoScheda(arrLocaleFoto);
                    setArrayTypeFotoScheda(arrLocaleTypeFoto);
                    //setModificataSchedaStandard(0);
                    setTermineRicercaDatiEserciziSingolaScheda(1);
                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    setArraySezioneScheda(arrLocaleSezione);
                    setArrayNumeroScheda(arrLocaleNumero);
                    setArraySedutaScheda(arrLocaleSeduta);
                }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaSchedePersonalizzateStessoNome(){
        //ricerco se esistono e quante sono le schede personalizzate per la stessa persona con lo stesso nome e cmq restituisco il numero progressivo da mettere per lo stesso nome:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              op:"ricercaSchedeAllenamentoStessoNome",
              descrizione:descrizione.current.value,
              persona:insPersonaEdi,
              emak: props.emak,
              sessionId:props.sessionId})
          };

          fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {
                setLoading(false);
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                  alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                if(insSchedaEdi !== ""){
                    if(valoreDiRitorno.risultatoRitorno[0].NUMERORECORD=== '-1'){
                        setDescrizioneDefault(descrizioneDefault);
                    }
                    if(valoreDiRitorno.risultatoRitorno[0].NUMERORECORD!== '0' && valoreDiRitorno.risultatoRitorno[0].NUMERORECORD!== '-1'){
                        setDescrizioneDefault(descrizioneDefault+" di "+insPersonaEdi+"_"+valoreDiRitorno.risultatoRitorno[0].NUMERORECORD);
                    }   
                    if(valoreDiRitorno.risultatoRitorno[0].NUMERORECORD=== '0'){
                        setDescrizioneDefault(descrizioneDefault+" di "+insPersonaEdi);
                    }
                }
              setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
              }
          },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
    }
    function callbackSetValoreTendEditabileEserciziScheda(valore,index,valoreNonVisibile){
  
        if (index === undefined) return;
        let dati = [...arrayEserciziScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,valore);//);
        setArrayEserciziScheda(dati);//aggiorno nuovamente la var di stato

        //setModificataSchedaStandard(1);
        if(valore === ''){
            //azzero solo i valori relativi alle caratteristiche dell'esercizio mantenendone la classe:
            aggiornaSerie(index,'');
            aggiornaRipetizioni(index,'');
            aggiornaRecupero(index,'');
            aggiornaNote(index,'');
            aggiornaSpiegazione(index,'');
            aggiornaFoto(index,'');
            //aggiornaNomeFoto(index,'');
            aggiornaTypeFoto(index,'');
            //aggiornaSezione(index,'');
            //aggiornaNumero(index,'');
            //aggiornaSeduta(index,'');
        }else{
            for (let i = 0; i < risultatoServerDatiTuttiEsercizi.length; i++) {
                if(risultatoServerDatiTuttiEsercizi[i].DESCRIZIONE===valore){
                    aggiornaSerie(index,risultatoServerDatiTuttiEsercizi[i].SERIE_STD);
                    aggiornaRipetizioni(index,risultatoServerDatiTuttiEsercizi[i].RIPETIZIONI_STD);
                    aggiornaRecupero(index,risultatoServerDatiTuttiEsercizi[i].RECUPERO_TRA_SERIE_STD);
                    aggiornaNote(index,risultatoServerDatiTuttiEsercizi[i].NOTE_ESERCIZIO_STD);
                    if(arrayClasseScheda[index]!==risultatoServerDatiTuttiEsercizi[i].CLASSE) aggiornaClasse(index,"",1); //
                    aggiornaSpiegazione(index,risultatoServerDatiTuttiEsercizi[i].SPIEGAZIONE);
                    aggiornaFoto(index,risultatoServerDatiTuttiEsercizi[i].FILE);
                    // aggiornaNomeFoto(index,risultatoServerDatiTuttiEsercizi[i].FOTO);
                    aggiornaTypeFoto(index,risultatoServerDatiTuttiEsercizi[i].TYPE);
                    //aggiornaSezione(index,risultatoServerDatiTuttiEsercizi[i].SEZIONE);
                    //aggiornaNumero(index,Number(risultatoServerDatiTuttiEsercizi[i].NUMERO));
                    //aggiornaSeduta(index,Number(risultatoServerDatiTuttiEsercizi[i].SEDUTA));
                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                }
            }
        }

        {/*


        faccio richiesta dati per esercizio scelto:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"esercizioRecuperaDati",
                id:valoreNonVisibile,
                emak: props.emak,
                sessionId:props.sessionId
            })
        };
        setStatoPagina(STATO_RICERCA_DATI);

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) setStatoPagina(STATO_TORNA_LOGIN);
                if(valoreDiRitorno.risultatoOperazione===1){
                    //memorizzo i valori da mettere come default per esercizio in esame:
                    aggiornaSerie(index,valoreDiRitorno.risultatoRitorno[0].SERIE_STD);
                    aggiornaRipetizioni(index,valoreDiRitorno.risultatoRitorno[0].RIPETIZIONI_STD);
                    aggiornaRecupero(index,valoreDiRitorno.risultatoRitorno[0].RECUPERO_TRA_SERIE_STD);
                    aggiornaNote(index,valoreDiRitorno.risultatoRitorno[0].NOTE_ESERCIZIO_STD);
                    aggiornaClasse(index,valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE_CLASSE);
                    aggiornaSpiegazione(index,valoreDiRitorno.risultatoRitorno[0].SPIEGAZIONE);
                    aggiornaFoto(index,valoreDiRitorno.risultatoRitorno[0].FILE);
                    aggiornaTypeFoto(index,valoreDiRitorno.risultatoRitorno[0].TYPE);
                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );*/}
    }
    function spostaEsercizio(posizione,spostamento){

        const toIndex = parseInt(posizione)+spostamento;

        if(toIndex>=0){
            const fromIndex = parseInt(posizione); 

            /*let risCla = [...risultatoServerDatiTutteClassiEsercizi];
            const elementRisCla = risCla.splice(fromIndex, 1)[0];
            risCla.splice(toIndex, 0, elementRisCla);
            setRisultatoServerDatiTutteClassiEsercizi(risCla);//aggiorno nuovamente la var di stato*/

            let ris = [...risultatoServerDatiTuttiEserciziLimitato];
            const elementRis = ris.splice(fromIndex, 1)[0];
            ris.splice(toIndex, 0, elementRis);
            setRisultatoServerDatiTuttiEserciziLimitato(ris);//aggiorno nuovamente la var di stato

            let ese = [...arrayEserciziScheda];//popolo l'array locale con i dati dell'array di stato
            const elementEse = ese.splice(fromIndex, 1)[0];
            ese.splice(toIndex, 0, elementEse);
            setArrayEserciziScheda(ese);//aggiorno nuovamente la var di stato
            
            let ser = [...arraySerieScheda];//popolo l'array locale con i dati dell'array di stato
            const elementSer = ser.splice(fromIndex, 1)[0];
            ser.splice(toIndex, 0, elementSer);
            setArraySerieScheda(ser);//aggiorno nuovamente la var di stato

            let rip = [...arrayRipetizioniScheda];//popolo l'array locale con i dati dell'array di stato
            const elementRip = rip.splice(fromIndex, 1)[0];
            rip.splice(toIndex, 0, elementRip);
            setArrayRipetizioniScheda(rip);//aggiorno nuovamente la var di stato

            let rec = [...arrayRecuperoTraSerieScheda];//popolo l'array locale con i dati dell'array di stato
            const elementRec = rec.splice(fromIndex, 1)[0];
            rec.splice(toIndex, 0, elementRec);
            setArrayRecuperoTraSerieScheda(rec);//aggiorno nuovamente la var di stato

            let note = [...arrayNoteEsercizioScheda];//popolo l'array locale con i dati dell'array di stato
            const elementNote = note.splice(fromIndex, 1)[0];
            note.splice(toIndex, 0, elementNote);
            setArrayNoteEsercizioScheda(note);//aggiorno nuovamente la var di stato

            let cla = [...arrayClasseScheda];//popolo l'array locale con i dati dell'array di stato
            const elementCla = cla.splice(fromIndex, 1)[0];
            cla.splice(toIndex, 0, elementCla);
            setArrayClasseScheda(cla);//aggiorno nuovamente la var di stato

            let spi = [...arraySpiegazioneScheda];//popolo l'array locale con i dati dell'array di stato
            const elementSpi = spi.splice(fromIndex, 1)[0];
            spi.splice(toIndex, 0, elementSpi);
            setArraySpiegazioneScheda(spi);//aggiorno nuovamente la var di stato

            let fot= [...arrayFotoScheda];//popolo l'array locale con i dati dell'array di stato
            const elementFot = fot.splice(fromIndex, 1)[0];
            fot.splice(toIndex, 0, elementFot);
            setArrayFotoScheda(fot);//aggiorno nuovamente la var di stato

            let typefot= [...arrayTypeFotoScheda];//popolo l'array locale con i dati dell'array di stato
            const elementTypeFot = typefot.splice(fromIndex, 1)[0];
            typefot.splice(toIndex, 0, elementTypeFot);
            setArrayTypeFotoScheda(typefot);//aggiorno nuovamente la var di stato

        }
    }

    function eliminaEsercizio(posizione) {
        let risCla = [...risultatoServerDatiTutteClassiEsercizi];
        risCla.splice(posizione, 1);//cancello l'elemento desiderato
        setRisultatoServerDatiTutteClassiEsercizi(risCla);//aggiorno nuovamente la var di stato
     
        let ris = [...risultatoServerDatiTuttiEserciziLimitato];
        ris.splice(posizione, 1);//cancello l'elemento desiderato
        setRisultatoServerDatiTuttiEserciziLimitato(ris);//aggiorno nuovamente la var di stato

        let ese = [...arrayEserciziScheda];//popolo l'array locale con i dati dell'array di stato
        ese.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayEserciziScheda(ese);//aggiorno nuovamente la var di stato
        
        //la stessa cosa è da fare per tutti gli array legati all'esercizio
        let ser = [...arraySerieScheda];//popolo l'array locale con i dati dell'array di stato
        ser.splice(posizione, 1);//cancello l'elemento desiderato
        setArraySerieScheda(ser);//aggiorno nuovamente la var di stato

        let rip = [...arrayRipetizioniScheda];//popolo l'array locale con i dati dell'array di stato
        rip.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRipetizioniScheda(rip);//aggiorno nuovamente la var di stato

        let rec = [...arrayRecuperoTraSerieScheda];//popolo l'array locale con i dati dell'array di stato
        rec.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRecuperoTraSerieScheda(rec);//aggiorno nuovamente la var di stato

        let note = [...arrayNoteEsercizioScheda];//popolo l'array locale con i dati dell'array di stato
        note.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayNoteEsercizioScheda(note);//aggiorno nuovamente la var di stato

        let cla = [...arrayClasseScheda];//popolo l'array locale con i dati dell'array di stato
        cla.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayClasseScheda(cla);//aggiorno nuovamente la var di stato

        let spi = [...arraySpiegazioneScheda];//popolo l'array locale con i dati dell'array di stato
        spi.splice(posizione, 1);//cancello l'elemento desiderato
        setArraySpiegazioneScheda(spi);//aggiorno nuovamente la var di stato

        let fot= [...arrayFotoScheda];//popolo l'array locale con i dati dell'array di stato
        fot.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayFotoScheda(fot);//aggiorno nuovamente la var di stato

        let typefot= [...arrayTypeFotoScheda];//popolo l'array locale con i dati dell'array di stato
        typefot.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayTypeFotoScheda(typefot);//aggiorno nuovamente la var di stato

        let sez= [...arraySezioneScheda];//popolo l'array locale con i dati dell'array di stato
        sez.splice(posizione, 1);//cancello l'elemento desiderato
        setArraySezioneScheda(sez);//aggiorno nuovamente la var di stato

        let num= [...arrayNumeroScheda];//popolo l'array locale con i dati dell'array di stato
        num.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayNumeroScheda(num);//aggiorno nuovamente la var di stato

        let sed= [...arraySedutaScheda];//popolo l'array locale con i dati dell'array di stato
        sed.splice(posizione, 1);//cancello l'elemento desiderato
        setArraySedutaScheda(sed);//aggiorno nuovamente la var di stato
        //setModificataSchedaStandard(1);
    }

    function aggiornaSerie(index,dato){
        let dati = [...arraySerieScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArraySerieScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRipetizioni(index,dato){
        let dati = [...arrayRipetizioniScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayRipetizioniScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRecupero(index,dato){
        let dati = [...arrayRecuperoTraSerieScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayRecuperoTraSerieScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaNote(index,dato){
        let dati = [...arrayNoteEsercizioScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayNoteEsercizioScheda(dati);//aggiorno nuovamente la var di stato
    } 
    function aggiornaClasse(index,dato){
        let dati = [...arrayClasseScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayClasseScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaSpiegazione(index,dato){
        let dati = [...arraySpiegazioneScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArraySpiegazioneScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaFoto(index,dato){
        let dati = [...arrayFotoScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayFotoScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaTypeFoto(index,dato){
        let dati = [...arrayTypeFotoScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayTypeFotoScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaSezione(index,dato){
        let dati = [...arraySezioneScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArraySezioneScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaNumero(index,dato){
        let dati = [...arrayNumeroScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayNumeroScheda(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaSeduta(index,dato){
        let dati = [...arraySedutaScheda];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArraySedutaScheda(dati);//aggiorno nuovamente la var di stato
    }

    function callbackSetVariazioneCampi(variazioneCampi){
        setVariazioneCampi(variazioneCampi);
    }
    function handleCambiamentoMod(event) {
        setVariazioneCampi(1);
    }

    function callbackTermineModificaSchedaAssegnazione() {
        setRitornaPaginaIniziale(1);
    }

    function callbackTermineEliminaSchedaAssegnazione() {
        setRitornaPaginaIniziale(1);
    }

    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;


        let presenteScheda = -1;
        if(insSchedaEdi !== ''){
            presenteScheda = 0;
            //controllo se la scheda allenamento non è presente nell'elenco attuale:
            for (let i=0; i<risultatoServerDatiScheda.length ; i++) {
                if(risultatoServerDatiScheda[i]["DESCRIZIONE"] === insSchedaEdi){
                    presenteScheda=1;
                    break;
                } 
            }
            if(presenteScheda === 0){
                setStatoPagina(STATO_MEMORIZZAZIONE);
                controlli = 1;
            } 
        }
        //controllo se il nome della scheda non è vuoto
        if(descrizione.current.value === ''){
            setFlagAttivaModaleInserisciDescrizione(1);
            controlli = 1;
        }
        if(props.ope!=='cancella' && bypassaControlli===0){
            //controllo lunghezza campo text:
           
            if(note.current.value.length>1000){
                controlli = 1;
                alert("Il campo Note contiene troppi caratteri (massimo accettato 1000)");
                setDisabilitaBottoni(false);
            }
        }
        //controllo che per ogni esercizio esplicitato ci sia seduta, sezione e numero indicati:
        for (let i = 0; i < arrayEserciziScheda.length; i++) {
            const esercizio = arrayEserciziScheda[i];
            const sezione = arraySezioneScheda[i];
            const numero = arrayNumeroScheda[i];
            const seduta = arraySedutaScheda[i];
        
            // Verifica che tutti i campi non siano vuoti o undefined
            if (esercizio !== undefined && esercizio !== "" &&
              (sezione === undefined || sezione === "" || numero === undefined || numero === "" || seduta === undefined || seduta === "")
            ) {
                controlli = 1;
                let recordBaco = i+1;
                alert("Anomalia: i campi sezione, numero e seduta devono essere sempre valorizzati");
                setDisabilitaBottoni(false);
            }
          }

            
        if(controlli===0){ 
                // parametri per inviare i dati al server:
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope !=='cancella' ? 
                        JSON.stringify({op:"schedeAllPersoneIns",
                        persona:insPersonaEdi,
                        idPersona:insIdPersonaEdi,
                        dataDa:dataDa.current.value,
                        dataA:dataA.current.value,
                        idElemento:props.id,
                        sezioneEserciziScheda:arraySezioneScheda,
                        numeroEserciziScheda:arrayNumeroScheda,
                        sedutaEserciziScheda:arraySedutaScheda,
                        descrizione:descrizione.current.value,
                        eserciziScheda:arrayEserciziScheda,
                        serieEserciziScheda:arraySerieScheda,
                        ripetizioniEserciziScheda:arrayRipetizioniScheda,
                        recuperoEserciziScheda:arrayRecuperoTraSerieScheda,
                        noteEserciziScheda:arrayNoteEsercizioScheda,
                        note:note.current.value,
                        periodo:periodoDefault,
                        emak: props.emak,
                        sessionId:props.sessionId})
                        : 
                        JSON.stringify({op:"schedeAllPersoneCanc",
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                    };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) setRitornaPaginaIniziale(1);
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            //altrimenti vado in errore generico
                            if(!valoreDiRitorno.mess) setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        } 
                    },
                    (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
        }
    }

    return (
        <Fragment>
          
          <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
                {/*<div>
                    <AppSidebar/>
    </div>*/}
             
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1AssSchedaAll {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_VAI_IN_MODIFICA_ASSEGNAZIONE ? 
                    <div>
                    <Suspense fallback={
                    <div className="loader-container">
                    <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda assegnata in corso...</h6></div>
                    </div>
                    }>
                    <div className="app-main__outer">
                        <FormGridFormRowSchedaAssegnata 
                                    {...props}
                                    ope={"modifica"} 
                                    tipo={"assegnata"} 
                                    persona={insPersonaEdi}
                                    cognome={cognome}
                                    nome={nome}
                                    callbackTermineModifica={callbackTermineModificaSchedaAssegnazione} 
                                    sessionId={props.sessionId} 
                                    id={idSchedaModificare} 
                                    >
                        </FormGridFormRowSchedaAssegnata>
                    </div>
                    </Suspense>
                    </div>
                :""}

                {statoPagina === STATO_VAI_IN_DETTAGLI ? 
                    <div>
                    <Suspense fallback={
                    <div className="loader-container">
                    <div className="loader-container-inner"><h6 className="mt-5">Caricamento dettagli scheda assegnata in corso...</h6></div>
                    </div>
                    }>
                    <div className="app-main__outer">
                        <FormGridFormRowSchedaAssegnata 
                                    {...props}
                                    ope={"dettagli"} 
                                    tipo={"assegnata"} 
                                    persona={insPersonaEdi}
                                    cognome={cognome}
                                    nome={nome}
                                    callbackTermineModifica={callbackTermineModificaSchedaAssegnazione} 
                                    sessionId={props.sessionId} 
                                    id={idSchedaModificare} 
                                    >
                        </FormGridFormRowSchedaAssegnata>
                    </div>
                    </Suspense>
                    </div>
                :""}

                {statoPagina === STATO_PREPARA_PDF ? 
                    <div>
                    <Suspense fallback={
                    <div className="loader-container">
                    <div className="loader-container-inner"><h6 className="mt-5">Preparazione pdf scheda assegnata in corso...</h6></div>
                    </div>
                    }>
                    <div className="app-main__outer">
                        <FormGridFormRowSchedaAssegnata 
                                    {...props}
                                    ope={"modifica"} 
                                    stato={"preparaPdf"}
                                    tipo={"assegnata"} 
                                    persona={insPersonaEdi}
                                    cognome={cognome}
                                    nome={nome}
                                    callbackTermineModifica={callbackTermineModificaSchedaAssegnazione} 
                                    sessionId={props.sessionId} 
                                    id={idSchedaModificare} 
                                    >
                        </FormGridFormRowSchedaAssegnata>
                    </div>
                    </Suspense>
                    </div>
                :""}
                

                {statoPagina === STATO_VAI_IN_ELIMINA_ASSEGNAZIONE ? 
                    <div>
                    <Suspense fallback={
                    <div className="loader-container">
                    <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda assegnata in corso...</h6></div>
                    </div>
                    }>
                    <div className="app-main__outer">
                    <FormGridFormRowSchedaAssegnata 
                                    {...props}
                                    ope={"cancella"} 
                                    tipo={"assegnata"} 
                                    persona={insPersonaEdi}
                                    cognome={cognome}
                                    nome={nome}
                                    callbackTermineModifica={callbackTermineEliminaSchedaAssegnazione} 
                                    sessionId={props.sessionId} 
                                    id={idSchedaEliminare} 
                                    >
                        </FormGridFormRowSchedaAssegnata>
                    </div>
                    </Suspense>
                    </div>
                :""}

                {statoPagina === STATO_SCEGLI_ISCRITTO || statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                    <div className="app-main__outer">
                        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                            <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                               
                                    {statoPagina === STATO_SCEGLI_ISCRITTO ? 
                                    <>
                                    {flagModaleInfoPopolamento=== true ?
                                            <Modale 
                                                larghezza = "lg"
                                                titolo="Informativa assegnazione schede allenamento"
                                                flagErrore={false}
                                                contenuto={<div>
                                                Questo ambiente consente di creare, modificare ed eliminare schede di allenamento personalizzate, assegnandole ai singoli iscritti. È possibile partire da una scheda predefinita già caricata nel sistema (Allenamenti - Schede Allenamento Predefinite) e adattarla alle esigenze specifiche, oppure creare una nuova scheda partendo da zero.
                                                <br></br>
                                                <br></br><br></br>
                                                <p style={{ textAlign: 'left' }}>
                                                Funzionalità principali:<br></br><br></br>
                                                - Selezione dell’iscritto: dopo aver selezionato un iscritto, si accede a un’area in cui sono visualizzate eventuali schede già assegnategli in precedenza ed eventulmente modificabili.
                                                Per assegnare una nuova scheda, è disponibile il pulsante Assegna nuova scheda.<br></br><br></br>
                                                - Creazione e personalizzazione della scheda: durante l’assegnazione, sono disponibili diverse opzioni:<br></br>
                                                &nbsp;* Scelta della scheda di base: è possibile partire da una scheda predefinita o da una scheda vuota.<br></br>
                                                &nbsp;* Selezione degli esercizi: gli esercizi possono essere scelti direttamente dalla tendina Esercizio, che elenca tutti quelli caricati a sistema. In alternativa, si può filtrare per Classificazione (es. Riscaldamento, Forza, Massa, ecc.) e successivamente selezionare l’esercizio desiderato dalla tendina, che mostrerà solo quelli appartenenti alla classificazione scelta.<br></br>
                                                &nbsp;* Modifica e gestione degli esercizi: è possibile aggiungere, rimuovere e riordinare gli esercizi nella scheda. Per ogni esercizio, è possibile personalizzare i parametri caratteristici, come numero di serie, ripetizioni, tempi di recupero, e così via.
                                                &nbsp;* Esportazione della scheda: la scheda può essere esportata in formato PDF per facilitarne la stampa o la condivisione.
                                                </p>
                                                <br></br>
                                                Una volta assegnata, la scheda sarà visibile all’iscritto, che potrà consultarla comodamente sul proprio dispositivo, visualizzando gli esercizi uno per uno.
                                                </div>}
                                                bottoni={[
                                                    {
                                                        "etichetta":"OK",
                                                        "tipo":"primary",
                                                        callback:() => {tornaElenco()}
                                                    }    
                                                ]}
                                            />
                                        :""}

                                    <CardTitle>Selezionare l'iscritto {/*<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoPopolamento(true)}/>*/}
                                    </CardTitle> 
                                    </>
                                    :""}
                                    {statoPagina === STATO_RICERCA_DATI_CORRETTA ? <CardTitle>Schede Allenamento di </CardTitle> :""}
                                    
                                    {flagAttivaModaleInserisciDescrizione === 1 ?
                                        <Modale 
                                            titolo="Richiesta nome della scheda"
                                            flagErrore={true}
                                            contenuto={<div>Non è stato indicato il nome della scheda, che è necessario</div>}
                                            bottoni={[
                                                {
                                                    "etichetta":"OK",
                                                    "tipo":"primary",
                                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                                }    
                                            ]}
                                        />                    
                                    :""}

                                    <Row form> 
                                        <Col md={12}>    
                                            <FormGroup>
                                            <TendinaEditabile
                                                //titolo={"Iscritto*"}
                                                nomeCampo="COGNOME"
                                                nomeCampo2="NOME"
                                                nomeCampoParentesi="NUMERO_TESSERA"
                                                nomeCampoNonVisibile="ID"
                                                valoreDefault={insPersonaEdi}
                                                arrayValori={risultatoServerDatiPersona}
                                                bloccoInserimento = "1"
                                                callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                                callbackSetValoreTendEditabile={callbacksetValoriPersona} 
                                            ></TendinaEditabile>
                                            </FormGroup> 
                                        </Col>  
                                    </Row> 

                            

                                    {assegnaNuovaScheda === 1 ? 
                                        <Row>
                                        {risultatoServerDatiScheda.length>0 ?
                                            <Col md={12}>
                                                <Label>Seleziona la scheda da assegnare a {insPersonaEdi}</Label>
                                                <TendinaEditabile
                                                    //titolo={"Schede di Allenamento Standard"}
                                                    nomeCampo="DESCRIZIONE"
                                                    nomeCampoNonVisibile="ID"
                                                    valoreDefault={defaultScheda}
                                                    arrayValori={risultatoServerDatiScheda}
                                                    bloccoInserimento="1"
                                                    callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                                    callbackSetValoreTendEditabile={callbacksetValoriScheda} 
                                                ></TendinaEditabile>
                                            </Col>
                                            :<Col md={12}>Attualmente non sono presenti nel database Schede di Allenamento Standard da prendere come riferimento</Col>}
                                        </Row>
                                    :""}


                                    {statoPagina === STATO_RICERCA_DATI_CORRETTA && assegnaNuovaScheda === 0 ?
                                        <>
                                        {arrayIdScheda[0]!=='' ?
                                            <Table bordered className="mb-0"><tbody>
                                                <tr><td colSpan={8}><div className='schedaSottotitolo'>Schede attualmente assegnate:</div></td></tr>
                                                <tr><td></td><td>Nome della Scheda</td><td>Data Scheda</td><td>Da Eseguire dal Giorno</td><td>Da Eseguire fino al Giorno</td><td>Note</td><td></td><td></td></tr>
                                                {arrayIdScheda.map((elemento,index) => <tr key={index}>
                                                    <td><img onClick={(e) => {handleCambiamentoMod(e);
                                                    setStatoPagina(STATO_VAI_IN_ELIMINA_ASSEGNAZIONE);setIdSchedaEliminare(arrayIdScheda[index])}} src={Cestino}/></td>
                                                    <td>{arrayNomeScheda[index]}</td>
                                                    <td><TrasformaDateAUmano data={arrayPeriodo[index]}></TrasformaDateAUmano></td>
                                                    <td><TrasformaDateAUmano data={arrayDataDa[index]}></TrasformaDateAUmano></td>
                                                    <td><TrasformaDateAUmano data={arrayDataA[index]}></TrasformaDateAUmano></td>
                                                    <td>{arrayNote[index]}</td>
                                                    <td><center><Button color="success" className="mt-2" onClick={(e) => {
                                                    setStatoPagina(STATO_VAI_IN_DETTAGLI);setIdSchedaModificare(arrayIdScheda[index])}} >Dettagli</Button></center></td>
                                                    {/*<td><center><Button color="primary" className="mt-2" onClick={(e) => {
                                                    setStatoPagina(STATO_VAI_IN_MODIFICA_ASSEGNAZIONE);setIdSchedaModificare(arrayIdScheda[index])}} >Modifica</Button></center></td> 
                                                    */}
                                                    <td><center><Button color="success" className="mt-2" onClick={(e) => {
                                                    setStatoPagina(STATO_PREPARA_PDF);setIdSchedaModificare(arrayIdScheda[index])}} >Esporta in formato PDF</Button></center></td> 
                                                    
                                                    </tr>)
                                                }
                                            </tbody></Table>
                                        :"All'iscritto non risulta assegnata alcuna scheda."}
                                        </>
                                    :""}

                                    


                                    {assegnaNuovaScheda === 2 ? 
                                        <>
                                        <div className='schedaSottotitolo'>Nuova scheda da assegnare</div> 
                                    
                                        <Row form>
                                            <Col md={5}>
                                                <FormGroup>
                                                    <Label for="dataDa">Scheda da eseguire dal giorno</Label>
                                                    <Input maxLength={10} type="date" name="dataDa" id="dataDa"
                                                        innerRef={dataDa} 
                                                        placeholder=""
                                                        value={dataDaDefault}
                                                        onChange={handleDataInizioChange}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setDataDaDefault(dataDa.current.value)}}/> 
                                                </FormGroup>
                                            </Col>
                                            <Col md={5}>
                                                <FormGroup>
                                                    <Label for="dataA">al giorno</Label>
                                                    <Input maxLength={10} type="date" name="dataA" id="dataA"
                                                        innerRef={dataA} 
                                                        placeholder=""
                                                        value={dataADefault}
                                                        onChange={handleDataFineChange}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setDataADefault(dataA.current.value)}}/> 
                                                 </FormGroup>
                                            </Col>

                                            <Col md={2}>
                                            <FormGroup>
                                            <label>
                                                <input
                                                type="checkbox"
                                                checked={mantieniVincolo}
                                                onChange={handleCheckboxChange}
                                                />
                                                Mantieni 4 settimane tra la data di inizio esecuzione e la data di fine esecuzione
                                            </label>
                                            </FormGroup>
                                            </Col>
                                        </Row>
                                    
                                        <Row form> 
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="descrizione">Nome della Scheda*</Label>
                                                    <Input maxLength={200} type="text" name="descrizione" id="descrizione" defaultValue={descrizioneDefault} innerRef={descrizione} 
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value)}}/> 
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    
                                        <Row form> 
                                            <Col md={12}>

                                            {arrayEserciziScheda !== undefined && arrayEserciziScheda.length>0 ?
                                                <>  
                                                    <table>
                                                    <thead>
                                                        <tr>
                                                            <td></td>
                                                            <td style={{paddingLeft:2,paddingRight:2}}></td>
                                                            <td style={{width: '5%',paddingRight:2}}>Seduta</td>
                                                            <td style={{width: '5%',paddingRight:2}}>Sezione</td>
                                                            <td style={{width: '5%',paddingRight:2}}>Numero</td>
                                                            <td style={{width: '10%',paddingRight:2}}>Classificazione</td>
                                                            <td style={{width: '25%',paddingRight:2}}>Esercizio</td>
                                                            <td style={{width: '7%',paddingRight:2}}>N. serie</td>
                                                            <td style={{width: '7%',paddingRight:2}}>Ripetizioni</td>
                                                            <td style={{width: '10%',paddingRight:2}}>Recupero tra serie</td>
                                                            <td style={{width: '16%',paddingRight:2}}>Note esercizio</td>
                                                            <td></td>
                                                        </tr>
                                                    </thead>

                                                    {arrayEserciziScheda !== undefined && arrayEserciziScheda.length>0 ?
                                                        arrayEserciziScheda.map((elemento,index) => <tr key={index}>

                                                            <td><img onClick={(e) => { handleCambiamentoMod(1); eliminaEsercizio(index)}} src={Cestino}/></td>
                                                            <td style={{paddingLeft:2,paddingRight:2,textAlign:'right'}}>{index+1}</td>
                                                        
                                                        
                                                        {/*SEDUTA*/}
                                                        <td>
                                                            <select                   
                                                                className="form-control"  
                                                                value={arraySedutaScheda[index]} 
                                                                onInput={(e)=>{
                                                                callbackSetVariazioneCampi(1);
                                                                aggiornaSeduta(index,e.target.value);}}>
                                                                <option value=''></option>
                                                                {arraySedutePossibili.map((elementoP,indexP) =>
                                                                arraySedutaScheda[index] === arraySedutePossibili[indexP] ? 
                                                                    <option key={indexP} value={arraySedutePossibili[indexP]} 
                                                                        selected>{arraySedutePossibili[indexP]}</option> : 
                                                                    <option key={indexP} value={arraySedutePossibili[indexP]}>
                                                                        {arraySedutePossibili[indexP]}</option>
                                                                )};
                                                            </select>
                                                              
                                                        </td>
                                                        {/*SEZIONE*/}
                                                        <td>
                                                            <select                   
                                                                className="form-control"  
                                                                value={arraySezioneScheda[index]} 
                                                                onInput={(e)=>{
                                                                callbackSetVariazioneCampi(1);
                                                                aggiornaSezione(index,e.target.value);}}>
                                                                <option value=''></option>
                                                                {arraySezioniPossibili.map((elementoP,indexP) =>
                                                                arraySezioneScheda[index] === arraySezioniPossibili[indexP] ? 
                                                                    <option key={indexP} value={arraySezioniPossibili[indexP]} 
                                                                        selected>{arraySezioniPossibili[indexP]}</option> : 
                                                                    <option key={indexP} value={arraySezioniPossibili[indexP]}>
                                                                        {arraySezioniPossibili[indexP]}</option>
                                                                )};
                                                            </select>
                                                            
                                                          
                                                            {props.ope === "cancella" ? 
                                                                <Label>: {arraySezioneScheda[index]}</Label> 
                                                            : ""}
                                                        </td>
                                                        {/*NUMERO*/}
                                                        <td>
                                                            <select                   
                                                                className="form-control"  
                                                                value={arrayNumeroScheda[index]} 
                                                                onInput={(e)=>{
                                                                callbackSetVariazioneCampi(1);
                                                                aggiornaNumero(index,e.target.value);}}>
                                                                <option value=''></option>
                                                                {arrayNumeriPossibili.map((elementoP,indexP) =>
                                                                arrayNumeroScheda[index] === arrayNumeriPossibili[indexP] ? 
                                                                    <option key={indexP} value={arrayNumeriPossibili[indexP]} 
                                                                        selected>{arrayNumeriPossibili[indexP]}</option> : 
                                                                    <option key={indexP} value={arrayNumeriPossibili[indexP]}>
                                                                        {arrayNumeriPossibili[indexP]}</option>
                                                                )};
                                                            </select>
                                                            
                                                          
                                                            {props.ope === "cancella" ? 
                                                                <Label>: {arrayNumeroScheda[index]}</Label> 
                                                            : ""}
                                                        </td>
                                                        <td>
                                                            <select                   
                                                                className="form-control"  
                                                                onInput={(e)=>{
                                                                callbackSetVariazioneCampi(1);
                                                                callbackSetValoreTendEditabileClassiEsercizi(e.target.value,index,1);}}>
                                                                <option value=''></option>
                                                                {risultatoServerDatiTutteClassiEsercizi.map((elementoP,indexP) =>
                                                                    arrayClasseScheda[index] === risultatoServerDatiTutteClassiEsercizi[indexP]['DESCRIZIONE'] ? 
                                                                    <option key={indexP} value={risultatoServerDatiTutteClassiEsercizi[indexP]['DESCRIZIONE']} selected>{risultatoServerDatiTutteClassiEsercizi[indexP]['DESCRIZIONE']}</option> : 
                                                                    <option key={indexP} value={risultatoServerDatiTutteClassiEsercizi[indexP]['DESCRIZIONE']}>{risultatoServerDatiTutteClassiEsercizi[indexP]['DESCRIZIONE']}</option>
                                                                )};
                                                            </select>
                                                        </td>

                                                        <td>
                                                            {risultatoServerDatiTuttiEserciziLimitato[index] ?
                                            
                                                                <select                   
                                                                    className="form-control"  
                                                                    onInput={(e)=>{
                                                                    callbackSetVariazioneCampi(1);
                                                                    callbackSetValoreTendEditabileEserciziScheda(e.target.value,index,);}}>
                                                                    <option value=''></option>
                                                                    {risultatoServerDatiTuttiEserciziLimitato[index].map((elementoPossibile,indexPossibile) =>
                                                                    arrayEserciziScheda[index] === risultatoServerDatiTuttiEserciziLimitato[index][indexPossibile]['DESCRIZIONE'] ? 
                                                                    <option key={indexPossibile} value={risultatoServerDatiTuttiEserciziLimitato[index][indexPossibile]['DESCRIZIONE']} selected>{risultatoServerDatiTuttiEserciziLimitato[index][indexPossibile]['DESCRIZIONE']}</option> : 
                                                                    <option key={indexPossibile} value={risultatoServerDatiTuttiEserciziLimitato[index][indexPossibile]['DESCRIZIONE']}>{risultatoServerDatiTuttiEserciziLimitato[index][indexPossibile]['DESCRIZIONE']}</option>
                                                                    )};
                                                                </select>
                                                                :
                                                        
                                                                <select                   
                                                                        className="form-control"  
                                                                        onInput={(e)=>{
                                                                        callbackSetVariazioneCampi(1);
                                                                        callbackSetValoreTendEditabileEserciziScheda(e.target.value,index);}}>
                                                                    <option value=''></option>
                                                                    {risultatoServerDatiTuttiEsercizi.map((elementoPossibile,indexPossibile) =>

                                                                        arrayEserciziScheda[index] === risultatoServerDatiTuttiEsercizi[indexPossibile]['DESCRIZIONE'] ? 
                                                                        <option key={indexPossibile} value={risultatoServerDatiTuttiEsercizi[indexPossibile]['DESCRIZIONE']} selected>{risultatoServerDatiTuttiEsercizi[indexPossibile]['DESCRIZIONE']}</option> : 
                                                                        <option key={indexPossibile} value={risultatoServerDatiTuttiEsercizi[indexPossibile]['DESCRIZIONE']}>{risultatoServerDatiTuttiEsercizi[indexPossibile]['DESCRIZIONE']}</option>
                                                                    )};
                                                                </select>
                                                            }

                                                        </td>
                                                        <td>
                                                            <Input maxLength={11} type="text" name="serie[index]" id="serie[index]" value={arraySerieScheda[index]} innerRef={serie[index]} 
                                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);
                                                                /*setModificataSchedaStandard(1);*/
                                                                aggiornaSerie(index,e.target.value)}}/> 
                                                        </td>

                                                        <td style={{display: 'table-cell'}}>
                                                            <Input maxLength={11} type="text" name="ripetizioni[index]" id="ripetizioni[index]" Value={arrayRipetizioniScheda[index]} innerRef={ripetizioni[index]} 
                                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);
                                                                /*setModificataSchedaStandard(1);*/
                                                                aggiornaRipetizioni(index,e.target.value)}}/> 
                                                        </td>

                                                        <td style={{display: 'table-cell'}}>
                                                            <Input maxLength={200} type="text" name="recupero[index]" id="recupero[index]" Value={arrayRecuperoTraSerieScheda[index]} innerRef={recupero[index]} 
                                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);
                                                                /*setModificataSchedaStandard(1);*/
                                                                aggiornaRecupero(index,e.target.value)}}/>                                   
                                                        </td>

                                                        <td style={{display: 'table-cell'}}>
                                                            <Input maxLength={200} type="text"  value={arrayNoteEsercizioScheda[index]} 
                                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);
                                                                /*setModificataSchedaStandard(1);*/
                                                                aggiornaNote(index,e.target.value)}}/>
                                                        </td>
                                                    
                                                        <td style={{paddingLeft:2}}><center><Button color="primary" style={{marginBottom : 5}} onClick={() => {
                                                            handleCambiamentoMod(1);
                                                            spostaEsercizio(index,-1)}}>Su</Button></center></td> 
                                                    
                                                        <td style={{paddingLeft:2}}><center><Button color="primary" style={{marginBottom : 5}} onClick={() => {
                                                            handleCambiamentoMod(1);
                                                            spostaEsercizio(index,1)}}>Giù</Button></center></td> 
                                                        
                                                        </tr>
                                                        )      
                                                
                                                    :""}

                                                    </table>
                                                
                                                    <Button color="primary" className="mt-3 mb-3" onClick={() => {
                                                    handleCambiamentoMod(1);
                                                    //setModificataSchedaStandard(1);
                                                    setAggiungiEsercizio(aggiungiEsercizio+1)}} 
                                                    >Aggiungi Esercizio</Button>
                                                </>
                                                :""}
                                            </Col>
                                        </Row>
                                    
                                        <Row form> 
                                            <Col md={12}>                       
                                            <Label for="note">Note aggiuntive della Scheda</Label>
                                            <Input maxLength={200} type="textarea" name="note" id="note" defaultValue={noteDefault} innerRef={note} 
                                                   placeholder="" onInput={(e)=>{handleCambiamentoMod(e);
                                                   /*setModificataSchedaStandard(1);*/
                                                   setNoteDefault(note.current.value)}}/>            
                                            </Col>
                                        </Row>

                                        
                                        </>
                                    :""}
                                
                                </div>
                                {statoPagina === STATO_RICERCA_DATI_CORRETTA && assegnaNuovaScheda === 0 ?
                                    <div className="schedaSpazioInferiore">
                                        <Row form><Col md={12}><Button color="success" className="mt-2" onClick={() => {setStatoPagina("STATO_RICERCA_DATI");inserimentoNuovaSchedaPrimoStep()}}>Assegna nuova scheda</Button></Col></Row>
                                        </div>
                                :""}
                                {assegnaNuovaScheda === 2 ? 
                                    <div className="schedaSpazioInferiore">
                                        <Button color="success" className="mt-2 bottoneMargineADestra" onClick={() => fetchOperazioneServer()}>Conferma assegnazione</Button>
                                        <Button color="primary" className="mt-2 bottoneMargineADestra" onClick={() => {setRitornaPaginaIniziale(1)}}>Annulla assegnazione</Button>
                                        </div>
                                :""}
                            </div>
                    {/*<AppFooter/>*/}
                    </div>

                    
                :""}
            </div>
       </Fragment>    
    );   
}
export default TablesAssegnazioniSchede;





