import React from 'react';
import { useState, useEffect } from 'react';

const Tendina2 = ({
  style,
  className,
  options,
  defaultValue,
  onSelect,
}) => {
  const [selectedValue, setSelectedValue] = useState('');
  
  // Aggiungi il valore fittizio se defaultValue è " " e non è nell'elenco delle opzioni
  const enhancedOptions = defaultValue === " " && !options.includes(" ") 
    ? [" ", ...options] 
    : options;

  useEffect(() => {
    setSelectedValue(defaultValue);  // Imposta lo stato con il valore di defaultValue
  }, [defaultValue]);

  const handleChange = (event) => {
    const selectedIndex = event.target.selectedIndex - (defaultValue === " " && !options.includes(" ") ? 1 : 0);  // Se c'è il valore fittizio, decrementa l'indice di 1
    setSelectedValue(event.target.value);  // Impostiamo il valore selezionato
    onSelect(selectedIndex);  // Restituiamo l'indice, -1 per l'opzione fittizia
  };

  return (
    <div>
      <select         
        value={selectedValue}         
        onChange={handleChange}
        style={style}
        className={className}
      >
        {enhancedOptions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Tendina2;