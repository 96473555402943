import React, { useState, useEffect } from 'react';
import { convertiDataComputer2Umano, oggiData } from './funzioni.js'
import Clessidra from '../Pagine/Clessidra'
import stile from './stile.module.css';

function Scadenze(props) {
    const PAGINE = {
        "caricamentoDati": 1,
        "elencoScadenze": 2,
    };
    var oggi = oggiData(); // AAAA-MM-GG
    const [pagina, setPagina] = useState(PAGINE.caricamentoDati);
    const [dovutoAbbonamenti, setDovutoAbbonamenti] = useState(0);
    const [dovutoAssociative, setDovutoAssociative] = useState(0);
    const [elencoProssimeSpese, setElencoProssimeSpese] = useState([]); // json
    const [flagAbbonamenti,setFlagAbbonamenti] = useState(false);
    const [flagAssociative, setFlagAssociative] = useState(false)
    const [flagProssimeSpese, setFlagProssimeSpese] = useState(false)
    const [dataFineCertMedico, setDataFineCertMedico] = useState("");
    const [dataFineDocIdentita, setDataFineDocIdentita] = useState("");
    const [dataFineDocPrivacy, setDataFineDocPrivacy] = useState("");

    const [richiestoCertMedico, setRichiestoCertMedico] = useState("");
    const [richiestoDocIdentita, setRichiestoDocIdentita] = useState("");
    const [richiestoDocPrivacy, setRichiestoDocPrivacy] = useState("");

    function scaricaElencoScadenze() {
        setPagina(PAGINE.caricamentoDati);
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "elencoScadenze", emak: props.emak, sessionId: props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    if (valore_di_ritorno.dovutoAbbonamenti!=undefined) {
                        setFlagAbbonamenti(true);
                        setDovutoAbbonamenti(valore_di_ritorno.dovutoAbbonamenti);
                    }
                    if (valore_di_ritorno.dovutoAssociative!=undefined) {
                        setFlagAssociative(true);
                        setDovutoAssociative(valore_di_ritorno.dovutoAssociative);
                    }
                    if (valore_di_ritorno.prossimeSpese!=undefined) {
                        setFlagProssimeSpese(true);
                        setElencoProssimeSpese(valore_di_ritorno.prossimeSpese);
                    }
                    setDataFineCertMedico(valore_di_ritorno.dataFineCertMedico);
                    setDataFineDocIdentita(valore_di_ritorno.dataFineDocIdentita);
                    setDataFineDocPrivacy(valore_di_ritorno.dataFineDocPrivacy);

                    setRichiestoCertMedico(valore_di_ritorno.richiestoCertMedico);
                    setRichiestoDocIdentita(valore_di_ritorno.richiestoDocIdentita);
                    setRichiestoDocPrivacy(valore_di_ritorno.richiestoDocPrivacy);

                    setPagina(PAGINE.elencoScadenze);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );                
        setPagina(PAGINE.caricamentoDati);         
    }

    useEffect(() => {
        scaricaElencoScadenze();
    }, [])
    
    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            props.callback(props.PAGINECLIENTI.home);
        } 
    }, [props.flagRichiestaBack])  

    if (pagina === PAGINE.caricamentoDati) {
        return (<Clessidra loading={true} />)
    }

    if (pagina === PAGINE.elencoScadenze) {
        return (
            <div className={stile.scrollView}>
                <div className={stile.scadenzeTestoNomePersona}>
                    Scadenze di {props.identitaSelezionata.cognome} {props.identitaSelezionata.nome}
                </div>
        
                {richiestoCertMedico == 1 ?
                    <div className={stile.scadenzeElencoContenitoreElemento}>
                        <div className={stile.scadenzeElencoTestoTitolo}>Certificato medico</div>
                        {dataFineCertMedico === "" ? (
                            <div className={stile.scadenzeElencoTestoAllarme}>NON PRESENTATO</div>
                        ) : dataFineCertMedico < oggi ? (
                            <div className={stile.scadenzeElencoTestoAllarme}>
                                SCADUTO {convertiDataComputer2Umano(dataFineCertMedico)}
                            </div>
                        ) : (
                            <div className={stile.scadenzeElencoTestoNormale}>
                                scade {convertiDataComputer2Umano(dataFineCertMedico)}
                            </div>
                        )}
                    </div>
                :""}
        
                {richiestoDocIdentita == 1 ?
                    <div className={stile.scadenzeElencoContenitoreElemento}>
                        <div className={stile.scadenzeElencoTestoTitolo}>Documento di identità</div>
                        {dataFineDocIdentita === "" ? (
                            <div className={stile.scadenzeElencoTestoAllarme}>NON PRESENTATO</div>
                        ) : dataFineDocIdentita < oggi ? (
                            <div className={stile.scadenzeElencoTestoAllarme}>
                                SCADUTO {convertiDataComputer2Umano(dataFineDocIdentita)}
                            </div>
                        ) : (
                            <div className={stile.scadenzeElencoTestoNormale}>
                                valido fino a {convertiDataComputer2Umano(dataFineDocIdentita)}
                            </div>
                        )}
                    </div>
                :""}

                {richiestoDocPrivacy == 1 ?
                    <div className={stile.scadenzeElencoContenitoreElemento}>
                        <div className={stile.scadenzeElencoTestoTitolo}>Documento Privacy</div>
                        {dataFineDocPrivacy === "" ? (
                            <div className={stile.scadenzeElencoTestoAllarme}>NON PRESENTATO</div>
                        ) : dataFineDocPrivacy < oggi ? (
                            <div className={stile.scadenzeElencoTestoAllarme}>
                                SCADUTO {convertiDataComputer2Umano(dataFineDocPrivacy)}
                            </div>
                        ) : (
                            <div className={stile.scadenzeElencoTestoNormale}>
                                valido fino a {convertiDataComputer2Umano(dataFineDocPrivacy)}
                            </div>
                        )}
                    </div>
                :""}
        

                {flagAbbonamenti ? (
                    <div className={stile.scadenzeElencoContenitoreElemento}>
                        <div className={stile.scadenzeElencoTestoTitolo}>Abbonamenti</div>
                        {dovutoAbbonamenti === 0 ? (
                            <div className={stile.scadenzeElencoTestoNormale}>Nessun arretrato</div>
                        ) : dovutoAbbonamenti > 0 ? (
                            <div className={stile.scadenzeElencoTestoAllarme}>
                                {dovutoAbbonamenti} EURO DI ARRETRATI
                            </div>
                        ) : (
                            <div className={stile.scadenzeElencoTestoNormale}>
                                {-dovutoAbbonamenti} EURO DI CREDITI
                            </div>
                        )}
                    </div>
                ) : null}
        
                {flagAssociative ? (
                    <div className={stile.scadenzeElencoContenitoreElemento}>
                        <div className={stile.scadenzeElencoTestoTitolo}>Quote associative</div>
                        {dovutoAssociative === 0 ? (
                            <div className={stile.scadenzeElencoTestoNormale}>Nessun arretrato</div>
                        ) : dovutoAssociative > 0 ? (
                            <div className={stile.scadenzeElencoTestoAllarme}>
                                {dovutoAssociative} EURO DI ARRETRATI
                            </div>
                        ) : (
                            <div className={stile.scadenzeElencoTestoNormale}>
                                {-dovutoAssociative} EURO DI CREDITI
                            </div>
                        )}
                    </div>
                ) : null}
        
                {flagProssimeSpese ? (
                    <div className={stile.scadenzeElencoContenitoreElemento}>
                        <div className={stile.scadenzeElencoTestoTitolo}>
                            Pagamenti nei prossimi 30 giorni
                        </div>
                        {elencoProssimeSpese.map((x, i) => (
                            <div key={i}>
                                <div className={stile.scadenzeElencoTestoNormale}>
                                    {convertiDataComputer2Umano(x.DATA)}: {x.IMPORTO} EURO <br />
                                    per {x.OPERAZIONE}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        );
        
    }

    return (
        <div>Errore pagina non trovata (Scadenze)</div>
    )
}

export default Scadenze;
