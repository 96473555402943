import React, { useState, useEffect } from 'react';
import SelezionaData from './SelezionaData';
import { convertiDataComputer2Umano } from './funzioni.js'
import Clessidra from '../Pagine/Clessidra'
import stile from './stile.module.css';

function News(props) {
    const PAGINE = {
        "caricamentoDati": 1,
        "elenco": 2,
        "modifica": 3,
        "inserisci": 4,
    };
    const [pagina, setPagina] = useState(PAGINE.caricamentoDati);
    const [elencoNews, setElencoNews] = useState([]);
    const [idNews, setIdNews] = useState(-1);
    const [titolo, setTitolo] = useState("");
    const [testo, setTesto] = useState("");
    const [dataEliminazione, setDataEliminazione] = useState("");
    const [elementoSelezionato, setElementoSelezionato] = useState(-1);

    function scaricaElencoNews() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "elencoNews", emak: props.emak, sessionId: props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    setElencoNews(valore_di_ritorno.risultatoRitorno);
                    setPagina(PAGINE.elenco);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function insModNews() {
        if (titolo=="") {
            window.alert("Indica il titolo della news"); 
            return;
        }
        if (testo=="") {
            window.alert("Indica il testo della news"); 
            return;
        }
        if (dataEliminazione=="") {
            window.alert("Indica la data di eliminazione della news"); 
            return;
        }
        setPagina(PAGINE.caricamentoDati);
        const adessoDate = new Date();
        const oggiString = adessoDate.getFullYear()+"-"+(adessoDate.getMonth()+1)+"-"+adessoDate.getDate()

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: pagina===PAGINE.modifica ? "newsMod" : "newsIns", 
                idElemento: pagina===PAGINE.modifica ? idNews : 0,                
                titolo: titolo,
                testo: testo,
                dataEliminazione: dataEliminazione,
                emak: props.emak, 
                sessionId: props.sessionId
            })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        if (valore_di_ritorno.mess !== "") {
                            window.alert(valore_di_ritorno.mess); 
                        } else {
                            window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        }
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    var e=[...elencoNews];
                    if (pagina === PAGINE.modifica) {
                        e[elementoSelezionato].TITOLO=titolo;
                        e[elementoSelezionato].TESTO=testo;
                        e[elementoSelezionato].DATA_ELIMINAZIONE=dataEliminazione;
                        //console.log(dataEliminazione);
                        e[elementoSelezionato].DATA_INS_MOD=oggiString;
                    } else {
                        var elem=[];
                        elem.ID=valore_di_ritorno.id;
                        elem.TITOLO=titolo;
                        elem.TESTO=testo;
                        elem.DATA_ELIMINAZIONE=dataEliminazione;
                        elem.DATA_INS_MOD=oggiString;
                        e.unshift(elem); // inserisce elem in testa all'array e
                    }
                    setElencoNews(e);
                    setPagina(PAGINE.elenco);                    
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function cancNews(id, indiceInElencoNews) {
        //console.log("Richiesta cancellazione news id="+id+" e posizione in array="+indiceInElencoNews);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "newsCanc", 
                idElemento: id,
                emak: props.emak, 
                sessionId: props.sessionId
            })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) {
                        if (valore_di_ritorno.mess !== "") {
                            window.alert(valore_di_ritorno.mess); 
                        } else {
                            window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        }
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    var e=[...elencoNews];
                    e.splice(indiceInElencoNews, 1);
                    setElencoNews(e);
                    setPagina(PAGINE.elenco);                     
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {            
            props.setFlagRichiestaBack(false);
            switch (pagina) {
                case PAGINE.elenco: props.callback(props.PAGINECLIENTI.home); break;
                case PAGINE.modifica: setPagina(PAGINE.elenco); break;
                case PAGINE.inserisci: setPagina(PAGINE.elenco); break;
                default: props.callback(props.PAGINECLIENTI.home); break;
            }
        } 
    }, [props.flagRichiestaBack])  

    useEffect(() => {
        scaricaElencoNews()
    },[]);

    if (pagina === PAGINE.caricamentoDati) {
        return (<Clessidra loading={true} />)
    }

    if (pagina === PAGINE.inserisci || pagina === PAGINE.modifica) {
        return (
            <div style={{ overflowY: 'auto', padding:10 }}>
    
                <div>
    
                    <div className={stile.eventiModificaTestoTitoli} style={{paddingBottom:5}}>
                        {pagina === PAGINE.modifica ? "Modifica news" : "Inserisci nuova news"}
                    </div>
    
                    <div className={stile.eventiModificaTestoTitoli}>Titolo*</div>
                    <div style={{display: 'flex', flexDirection:'row'}}>
                        <input value={titolo} onChange={(v) => setTitolo(v.target.value)} maxLength={200} style={{flex:1}} className={stile.eventiModificaInputTesto}></input>
                        <div onClick={() => setTitolo("")}>
                            <img
                                style={{marginLeft:5}} className={stile.iconaPiccolaQuadrata}
                                src={require('./x.png')}
                            />
                        </div>
                    </div>                            
    
                    <div className={stile.eventiModificaTestoTitoli}>Testo*</div>
                    <div style={{display: 'flex', flexDirection:'row'}}>
                        <textarea value={testo} onChange={(v) => setTesto(v.target.value)} rows={10} maxLength={10000} style={{flex:1, textAlignVertical:'top'}} className={stile.eventiModificaInputTesto}></textarea>
                        <div onClick={() => setTesto("")}>
                            <img
                                style={{marginLeft:5}} className={stile.iconaPiccolaQuadrata}
                                src={require('./x.png')}
                            />
                        </div>
                    </div>
    
                    <div className={stile.eventiModificaTestoTitoli}>Data di eliminazione automatica (dato necessario):</div>
                    <SelezionaData data={dataEliminazione} setData={setDataEliminazione} titolo="Data di eliminazione"/>
                </div>
    
                <div style={{display:'flex', flexDirection:'row', marginTop:10}}>
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreRosso}`} 
                        onClick={() => { setPagina(PAGINE.elenco) }}
                    >
                        <div className={stile.spazioIconaPiccola}>
                            <img
                                className={stile.iconaPiccolaQuadrata}
                                src={require('./annulla.png')}
                            />
                        </div>
                        <div className={stile.bottoneTestoNormale}>ANNULLA</div>
                    </div>
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreVerde}`} 
                        onClick={() => { insModNews() }}
                    >
                        <div className={stile.spazioIconaPiccola}>
                            <img
                                className={stile.iconaPiccolaQuadrata}
                                src={require('./modifica.png')}
                            />
                        </div>
                        <div className={stile.bottoneTestoNormale}>MEMORIZZA</div>
                    </div>
                </div>
    
            </div>
    
        )
    }    
    
    if (pagina === PAGINE.elenco) {
        return (        
            <div style={{ overflowY: 'scroll' }}>
                <div style={{display:"flex", flexDirection: "row", paddingLeft:10, paddingRight:10, paddingBottom:10, paddingTop:5, marginBottom:5, backgroundColor:"#E0E0E0"}}>
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}  
                        onClick={() => { 
                            setTitolo("");
                            setTesto("");
                            setDataEliminazione("");
                            setPagina(PAGINE.inserisci);
                        }}
                    >
                        <div className={stile.spazioIconaPiccola}>
                            <img
                                className={stile.iconaPiccolaQuadrata}
                                src={require('./stella.png')}
                            />
                        </div>
                        <div className={stile.bottoneTestoNormale}>Inserisci nuova</div>
                    </div>
                </div>
    
                {elencoNews.map((x, i) =>
                    <div key={i} className={x.NUOVA === 1 ? stile.elencoSchedeContenitoreElementoEvidenziato : elementoSelezionato === i ? stile.elencoSchedeContenitoreElementoSelezionato : stile.elencoSchedeContenitoreElemento}>
                        <div                             
                            style={{flex:1}}
                            onClick={() => { 
                                setElementoSelezionato(i);
                            }}
                        >
                            <div className={stile.elencoSchedeNome}>
                                {x.TITOLO}
                            </div>
                            <div className={stile.elencoSchedeTesto}>
                                {x.TESTO.length < 100 || elementoSelezionato === i ? x.TESTO : x.TESTO.substr(0, 100) + "...[continua]"}
                            </div>
                            <div className={stile.elencoSchedeDateCreazione}>
                                aggiornata al {convertiDataComputer2Umano(x.DATA_INS_MOD, "")}
                            </div>
                        </div>
    
                        { (props.identitaSelezionata.flag_staff && x.FLAG_AMMINISTRAZIONE !== 1) || (props.identitaSelezionata.flag_amministrazione) ?
                        <div 
                            style={{width:50, alignItems:"center"}}
                            onClick={() => {
                                setElementoSelezionato(i);
                                setIdNews(x.ID);
                                setTitolo(x.TITOLO);
                                setTesto(x.TESTO);
                                setDataEliminazione(x.DATA_ELIMINAZIONE);
                                setPagina(PAGINE.modifica);
                            }}
                        >
                            <img
                                className={stile.iconaPiccolaQuadrata}
                                src={require('./modifica.png')}
                            />
                        </div>
                        : 
                        <div 
                            style={{width:50, alignItems:"center"}}
                            onClick={() => {
                                window.alert("Impossibile modificare la news " + x.TITOLO + ": può essere modificata solo da chi l'ha inserita o da un amministratore");
                            }}
                        >
                            <img
                                className={stile.iconaPiccolaQuadrata}
                                src={require('./modifica-rossa.png')}
                            />
                        </div>
                        }
    
                        { (props.identitaSelezionata.flag_staff && x.FLAG_AMMINISTRAZIONE !== 1) || (props.identitaSelezionata.flag_amministrazione) ?
                        <div 
                            style={{width:50, alignItems:"center"}}
                            onClick={() => { 
                                if (window.confirm("Confermi eliminazione della news? " + x.TITOLO)) cancNews(x.ID,i)
                            }}
                        >
                            <img
                                className={stile.iconaPiccolaQuadrata}
                                src={require('./xgrigia-nobordo.png')}
                            />
                        </div>
                        : 
                        <div 
                            style={{width:50, alignItems:"center"}}
                            onClick={() => { 
                                window.alert("Impossibile eliminare la news " + x.TITOLO + ": può essere modificata solo da chi l'ha inserita o da un amministratore");
                            }}
                        >
                            <img
                                className={stile.iconaPiccolaQuadrata}
                                src={require('./xrossa-nobordo.png')}
                            />
                        </div>
                        }
                    </div>
                )}
            </div>
        )
    }
    
    
    
    return (
        <div>Errore pagina non trovata (News)</div>
    )

}

export default News;
