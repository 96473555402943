import React from 'react';
import stile from './stile.module.css';

function IconaMenu(props) {
    return (        
        <div class={stile.spazioIconaMenuEsterno} style={{maxWidth:200, cursor:'pointer'}}>
            <div onClick={() => props.callback()} class={stile.spazioIconaMenuInterno}>
                <div class={stile.spazioIconaMenuInterno}>
                    <img
                        class={stile.iconaMenuHome}
                        src={props.immagine}
                        style={{ objectFit: 'contain' }}
                    />
                </div>
                <div class={props.evidenzia ? stile.testoIconaMenuHome2 : stile.testoIconaMenuHome}>
                    {props.testo}
                </div>
            </div>
        </div>
    );
}

export default IconaMenu;