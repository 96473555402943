import React, { useState, useEffect } from 'react';
import Clessidra from '../Pagine/Clessidra'
import stile from './stile.module.css';

function EventiInputRisultati(props) {
    const opzioniVisualizzazione = ["tutti i convocati","solo quelli che hanno aderito","solo i presenti"];
    const [opzioneVisualizzazione, setOpzioneVisualizzazione] = useState(opzioniVisualizzazione[0]);
    const [arrayRisultatiTemporanei, setArrayRisultatiTemporanei] = useState([]);

    function aggiornaRisultati() {    
        var arrayIdPersone=[];
        for (var i=0; i<props.personeConvocateNonStaff.length; i++) {
            arrayIdPersone[i]=props.personeConvocateNonStaff[i].ID_PERSONA;
        }                
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "eventiUpdateRisultati",
                idEvento: props.idEvento, 
                idPersone: arrayIdPersone,
                risultati: arrayRisultatiTemporanei,
                emak: props.emak, 
                sessionId: props.sessionId
            })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback('login'); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback("");
                        return; 
                    }
                    props.callback("");
                },
                (error) => {
                    props.callback('login');
                    return;
                }
            );
    }

    useEffect(() => {
        var r=[];
        for (var i=0; i<props.personeConvocateNonStaff.length; i++) {
            r[i]=props.personeConvocateNonStaff[i].RISULTATI;
        }
        setArrayRisultatiTemporanei(r);
    },[])

    return (
        <>
            <div style={{ overflowY: 'scroll' }}>
                <div className={stile.eventiModificaTestoTitoli} style={{ paddingTop: 5 }}>
                    Visualizza:
                </div>
                <div className={stile.genericaDropdown} style={{ marginBottom: 5 }}>
                    <select 
                        className={stile.genericaDropdownTesto} 
                        onChange={(e) => { setOpzioneVisualizzazione(opzioniVisualizzazione[e.target.value]); }}
                        value={opzioneVisualizzazione}
                    >
                        {opzioniVisualizzazione.map((opzione, index) => (
                            <option key={index} value={index}>{opzione}</option>
                        ))}
                    </select>
                </div>
    
                <div className={stile.eventiConvocatiScrollView} style={{ overflowY: 'scroll' }}>
                    {props.personeConvocateNonStaff.map((p, i) => (
                        <div key={i}>
                            {(opzioneVisualizzazione === opzioniVisualizzazione[1] && p.CONFERMA !== 1) ||
                             (opzioneVisualizzazione === opzioniVisualizzazione[2] && p.PRESENTE !== 1) 
                             ? null : (
                                <div className={stile.eventiConvocatiSpazioElementoRisultati}>
                                    <div className={stile.eventiConvocatiNome}>
                                        {p.COGNOME} {p.NOME}
                                    </div>
                                    <input 
                                        type="text"
                                        placeholder="risultato"
                                        defaultValue={p.RISULTATI}
                                        onChange={(e) => {
                                            const r = arrayRisultatiTemporanei;
                                            r[i] = e.target.value;
                                            setArrayRisultatiTemporanei(r);
                                        }}
                                        className="custom-class"
                                        style={{
                                            fontSize: 18,
                                            backgroundColor: "#e0e0e0",
                                            color: "#202020",
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            paddingLeft: 5,
                                            borderColor: "#000000",
                                            borderWidth: 1,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div> 
            </div>
    
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div 
                    className={`${stile.bottone3} ${stile.bottoneColoreRosso}`} 
                    onClick={() => { props.callback(""); }}
                >
                    <div className={stile.spazioIconaPiccola}>
                        <img
                            className={stile.iconaPiccolaQuadrata}
                            src={require('./annulla.png')}
                            alt="annulla"
                        />
                    </div>
                    <div className={stile.bottoneTestoNormale}>ANNULLA</div>
                </div>
                
                <div 
                    className={`${stile.bottone3} ${stile.bottoneColoreVerde}`} 
                    onClick={() => { aggiornaRisultati(); }}
                >
                    <div className={stile.spazioIconaPiccola}>
                        <img
                            className={stile.iconaPiccolaQuadrata}
                            src={require('./ok.png')}
                            alt="ok"
                        />
                    </div>
                    <div className={stile.bottoneTestoNormale}>MEMORIZZA</div>
                </div>
            </div>
        </>
    );
}

export default EventiInputRisultati;
