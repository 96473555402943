export function interoStringaCifre(numero, cifre) {
    let s = String(numero);
    while (s.length<cifre) s = "0" + s;
    return s;
}

// param="" -> converte date dal formato YYYY-MM-DD al formato DD/MM/YYYY
// param="gSett" -> converte date dal formato YYYY-MM-DD al formato GIORNO DD/MM/YYYY con GIORNO = lunedì, martedì,...
// param="gSettAbbr" -> converte date dal formato YYYY-MM-DD al formato GIORNO DD/MM/YYYY con GIORNO = lun, mar, mer,...
export function convertiDataComputer2Umano(d, param) {
    if (d==""||d==null) return "";
    const ds = d.split("-");
    if (param=="") return ds[2]+"/"+ds[1]+"/"+ds[0];
    const giornoSettimana = new Date(d).getDay();
    const giorni = ["domenica","lunedì","martedì","mercoledì","giovedì","venerdì","sabato"];
    const giorniAbbr = ["dom","lun","mar","mer","gio","ven","sab"];
    if (param=="gSett") return giorni[giornoSettimana] + " " + ds[2]+"/"+ds[1]+"/"+ds[0];
    return giorniAbbr[giornoSettimana] + " " + ds[2]+"/"+ds[1]+"/"+ds[0];
}

export function oggiData() {
    return new Date().toISOString().split('T')[0];
}

export function oggiDataOra() {
    return new Date().toISOString();
}