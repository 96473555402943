
import React, {useState, useEffect} from 'react';
import Clessidra from '../Pagine/Clessidra';
import Home from './Home';
import BarraTitolo from './BarraTitolo';
import AllenamentiSchede from './AllenamentiSchede';
import AllenamentiMenuStaff from './AllenamentiMenuStaff';
import AllenamentiClassificazioneEsercizi from './AllenamentiClassificazioneEsercizi';
import AllenamentiEsercizi from './AllenamentiEsercizi';
import Eventi from './Eventi';
import Qrcode from './qrcode';
import Scadenze from './Scadenze';
import News from './News';
import Movimenti from './Movimenti';
import PrenotazioniCorsi from './PrenotazioniCorsi';
import ImpostazioniMenu from './ImpostazioniMenu';
import CambioPassword from './CambioPassword';
import Informazioni from './Informazioni';

function MainClienti(props) { 

  const PAGINECLIENTI={
    "init":0,
    "login":1,
    "selezionaIdentita":2,
    "home":3,
    "prenotazioni":4,
    "impostazioni":5,
    "eventi":6,
    "logout":7,
    "allenamenti":8,
    "allenamentiEsercizi":9,
    "allenamentiClassificazioneEsercizi":10,
    "allenamentiSchedePredefinite":11,
    "allenamentiSchedeAssegnate":12,
    "cambioPassword":13,
    "scadenze":14,
    "news":15,
    "movimenti":16,
    "aggiornaApp":17,
    "informazioni":18,
    "qrcode":19
  };

  const altezzaBarraTitolo=90;
  const finestraLarghezza = window.innerWidth;
  const [titolo, setTitolo] = useState([]);
  const [flagRichiestaBack, setFlagRichiestaBack] = useState(false);
  const [paginaClienti, setPaginaClienti] = useState(PAGINECLIENTI.home); 
  const [aggiornaChatPerEvento, setAggiornaChatPerEvento] = useState("");

  function callbackGenerica(p, nuovaIdentitaSelezionata=[],flagAzzeraCredenziali=false) {
    console.log("callbackGenerica("+p+")")
    
    /*
    if (flagAzzeraCredenziali) _storeData();
    if (nuovaIdentitaSelezionata.urlbackend!=undefined) {
      setIdentitaSelezionata(nuovaIdentitaSelezionata);
      if (nuovaIdentitaSelezionata.urlbackend!=undefined) {
        if (nuovaIdentitaSelezionata.urlbackend != "primario") setUrlBackend(nuovaIdentitaSelezionata.urlbackend + "?vapp=" + VersioneApp);
        else setUrlBackend(UrlBackendPrimario);
      }
    }
      */

    if (p===PAGINECLIENTI.news) {      
      // se entra nella pagina news, azzero il flag news per spegnere l'eventuale indicazione rossa che indica "ci sono news da leggere"
      var id = props.identitaSelezionata;
      id.flag_news=0;
      props.setIdentitaSelezionata(id);
    }

    setPaginaClienti(p);
  }

  /*

  function callbackAggiornaApp() {
    setIdentitaSelezionata([]);
    setLogoSocieta("");
    setTipoLogoSocieta("");
    setUrlBackend(UrlBackendPrimario);
    setPagina(PAGINECLIENTI.aggiornaApp);
  }

  function callbackLogin(email,password) {
    var v=[];
    v.email = email;
    v.password = password;
    setCredenzialiInserite(v);
    setUrlBackend(UrlBackendPrimario);
    setPagina(PAGINECLIENTI.selezionaIdentita);
  }
    */

  useEffect(() => {
    // qui sono definiti i titoli che compaiono nella barra in alto
    //console.log("App: cambio [pagina]: nuova pagina=",pagina);
    switch(paginaClienti) {
      case PAGINECLIENTI.init: setTitolo(""); break;
      case PAGINECLIENTI.login: props.setPagina(props.PAGINE.tornaLogin); break;
      case PAGINECLIENTI.logout: 
        if (localStorage.getItem("emailEMA")) localStorage.setItem("emailEMA","");
        if (localStorage.getItem("passEMA")) localStorage.setItem("passEMA","");
        props.setPagina(props.PAGINE.tornaLogin); 
        break;
      /*
      case PAGINECLIENTI.selezionaIdentita: 
        setTitolo("Seleziona identità"); // ATTENZIONE: se cambi questa etichetta, devi cambiare anche in BarraTitolo l'if che c'è nel onPress di TITOLO DELLA PAGINA
        setIdentitaSelezionata([]); 
        setLogoSocieta("");
      break;
      */
      case PAGINECLIENTI.prenotazioni: setTitolo("Prenotazioni"); break;
      case PAGINECLIENTI.eventi: setTitolo("Eventi"); break;
      case PAGINECLIENTI.allenamenti: setTitolo("Allenamenti"); break;
      case PAGINECLIENTI.allenamentiEsercizi: setTitolo("Esercizi"); break;
      case PAGINECLIENTI.allenamentiClassificazioneEsercizi: setTitolo("Classificazione Esercizi"); break;
      case PAGINECLIENTI.allenamentiSchedePredefinite: setTitolo("Schede Predefinite"); break;
      case PAGINECLIENTI.allenamentiSchedeAssegnate: setTitolo("Schede Assegnate"); break;
      case PAGINECLIENTI.impostazioni: setTitolo("Impostazioni"); break;
      case PAGINECLIENTI.informazioni: setTitolo("Informazioni"); break;
      case PAGINECLIENTI.cambioPassword: setTitolo("Cambia Password"); break;
      case PAGINECLIENTI.scadenze: setTitolo("Scadenze"); break;
      case PAGINECLIENTI.news: setTitolo("News"); break;
      case PAGINECLIENTI.movimenti: setTitolo("Pagamenti"); break;
      case PAGINECLIENTI.qrcode: setTitolo("QRCode personale"); break;
      case PAGINECLIENTI.aggiornaApp: 
        setTitolo("Necessario aggiornamento"); // ATTENZIONE: se cambi questa etichetta, devi cambiare anche in BarraTitolo l'if che c'è nel onPress di TITOLO DELLA PAGINA
      break;
      default: setTitolo("Home");
    }

    // se va in scadenze, potrebbe vedere un dato aggiornato rispetto a com'era prima e quindi
    // va aggiornato anche il flag, potrebbe spegnersi il bottone rosso
    //if (paginaClienti===PAGINECLIENTI.scadenze) setFlagAggiornaIdentitaSelezionata(true);

    // se va in home dopo aver fatto login, mostra splashscreen
    /*
    if (pagina===PAGINECLIENTI.home && (paginaPrecedente===PAGINECLIENTI.cambioPassword || paginaPrecedente===PAGINECLIENTI.login || paginaPrecedente===PAGINECLIENTI.selezionaIdentita)) {
      //console.log("App: lancio ricercaLogo(2500)")
      ricercaLogo(2500);
    }
      */

  },[paginaClienti])

  return (   
      <> 
      <BarraTitolo 
        {...props}
        flagIcone={true}
        paginaClienti={paginaClienti}
        titolo={titolo}
        altezza={altezzaBarraTitolo}
        finestraLarghezza={finestraLarghezza}
        setFlagRichiestaBack={setFlagRichiestaBack}
        callbackMenu={callbackGenerica}
        PAGINECLIENTI={PAGINECLIENTI}
      ></BarraTitolo>

      {paginaClienti === PAGINECLIENTI.init ? 
        <Clessidra loading={true} />
      : <></>}      

      {paginaClienti === PAGINECLIENTI.scadenze ? 
        <Scadenze
          {...props}
          flagRichiestaBack={flagRichiestaBack}
          setFlagRichiestaBack={setFlagRichiestaBack}
          callback={callbackGenerica}
          PAGINECLIENTI={PAGINECLIENTI}
        />
      : <></> }

      {paginaClienti === PAGINECLIENTI.news ? 
        <News
          {...props}
          flagRichiestaBack={flagRichiestaBack}
          setFlagRichiestaBack={setFlagRichiestaBack}
          callback={callbackGenerica}
          PAGINECLIENTI={PAGINECLIENTI}
        />
      : <></> }

      {paginaClienti === PAGINECLIENTI.movimenti ? 
        <Movimenti
          {...props}
          flagRichiestaBack={flagRichiestaBack}
          setFlagRichiestaBack={setFlagRichiestaBack}
          callback={callbackGenerica}
          PAGINECLIENTI={PAGINECLIENTI}
        />
      : <></> }      

      {paginaClienti === PAGINECLIENTI.prenotazioni ? 
        <PrenotazioniCorsi
          {...props}
          flagRichiestaBack={flagRichiestaBack}
          setFlagRichiestaBack={setFlagRichiestaBack}
          callback={callbackGenerica}
          PAGINECLIENTI={PAGINECLIENTI}
          larghezza={finestraLarghezza}
        />
      : <></> }

      {paginaClienti === PAGINECLIENTI.eventi ? 
        <Eventi
          {...props}
          callback={callbackGenerica}
          PAGINECLIENTI={PAGINECLIENTI}
          flagRichiestaBack={flagRichiestaBack}
          setFlagRichiestaBack={setFlagRichiestaBack}
          aggiornaChatPerEvento={aggiornaChatPerEvento}
          setAggiornaChatPerEvento={setAggiornaChatPerEvento}          
        />
      : <></> }
      
      {paginaClienti === PAGINECLIENTI.allenamenti ? 

        props.identitaSelezionata.flag_staff ?
          <AllenamentiMenuStaff
            {...props}
            PAGINECLIENTI={PAGINECLIENTI}
            flagRichiestaBack={flagRichiestaBack}
            setFlagRichiestaBack={setFlagRichiestaBack}
            callback={callbackGenerica}
            larghezza={finestraLarghezza}
          />
        : 
          <AllenamentiSchede
            {...props}
            tipo={"assegnate"}
            flagRichiestaBack={flagRichiestaBack}
            setFlagRichiestaBack={setFlagRichiestaBack}
            PAGINECLIENTI={PAGINECLIENTI}
            callback={callbackGenerica}
            larghezza={finestraLarghezza}
          />
      : 
        <></> 
      }

      {paginaClienti === PAGINECLIENTI.allenamentiEsercizi ? 
        <AllenamentiEsercizi
          {...props}
          flagRichiestaBack={flagRichiestaBack}
          setFlagRichiestaBack={setFlagRichiestaBack}
          PAGINECLIENTI={PAGINECLIENTI}
          callback={callbackGenerica}
          larghezza={finestraLarghezza}
        />
      : <></> }

      {paginaClienti === PAGINECLIENTI.allenamentiClassificazioneEsercizi ? 
        <AllenamentiClassificazioneEsercizi
          {...props}
          flagRichiestaBack={flagRichiestaBack}
          setFlagRichiestaBack={setFlagRichiestaBack}
          PAGINECLIENTI={PAGINECLIENTI}
          callback={callbackGenerica}          
          larghezza={finestraLarghezza}          
        />
      : <></> }

      {paginaClienti === PAGINECLIENTI.allenamentiSchedePredefinite ? 
        <AllenamentiSchede
          {...props}
          tipo={"predefinite"}
          flagRichiestaBack={flagRichiestaBack}
          setFlagRichiestaBack={setFlagRichiestaBack}
          PAGINECLIENTI={PAGINECLIENTI}
          callback={callbackGenerica}
          larghezza={finestraLarghezza}
        />
      : <></> }

      {paginaClienti === PAGINECLIENTI.allenamentiSchedeAssegnate ? 
        <AllenamentiSchede
          {...props}
          tipo={"assegnate"}
          flagRichiestaBack={flagRichiestaBack}
          setFlagRichiestaBack={setFlagRichiestaBack}
          PAGINECLIENTI={PAGINECLIENTI}
          callback={callbackGenerica}
          larghezza={finestraLarghezza}
        />
      : <></> }

      {paginaClienti === PAGINECLIENTI.impostazioni ? 
        <ImpostazioniMenu
          {...props}
          flagRichiestaBack={flagRichiestaBack}
          setFlagRichiestaBack={setFlagRichiestaBack}
          callback={callbackGenerica}
          PAGINECLIENTI={PAGINECLIENTI}
        />
      : <></> }

      {paginaClienti === PAGINECLIENTI.informazioni ? 
        <Informazioni
          {...props}
          flagRichiestaBack={flagRichiestaBack}
          setFlagRichiestaBack={setFlagRichiestaBack}
          callback={callbackGenerica}
          PAGINECLIENTI={PAGINECLIENTI}
        />
      : <></> }


      {paginaClienti === PAGINECLIENTI.cambioPassword ? 
        <CambioPassword
          {...props}
          flagRichiestaBack={flagRichiestaBack}
          setFlagRichiestaBack={setFlagRichiestaBack}
          callback={callbackGenerica}
          PAGINECLIENTI={PAGINECLIENTI}
        />
      : <></> }

      {paginaClienti === PAGINECLIENTI.qrcode ? 
        <Qrcode
          {...props}
          flagRichiestaBack={flagRichiestaBack}
          setFlagRichiestaBack={setFlagRichiestaBack}
          callback={callbackGenerica}
          PAGINECLIENTI={PAGINECLIENTI}
        />
      : <></> }

      {paginaClienti === PAGINECLIENTI.home ? 
        <Home
          {...props}
          callback={callbackGenerica}
          PAGINECLIENTI={PAGINECLIENTI}
        />
      : <></> }

    </>
  );
}

export default MainClienti;
