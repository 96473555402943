/* VisualizzaAllegato.js 1.1 */

import React, {useEffect, useState, useRef} from 'react';
import { Button } from 'reactstrap';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import FadeLoader from "react-spinners/FadeLoader"; // non uso Clessidra.js perché va posizionato diversamente, nel riquadro e non a centro schermo

/*
Nota: il bottone Scarica deve essere disabilitato in caso di modifica del contenuto, perché in caso di PDF il file
appena caricato non è in forma di URI (scaricabile) ma è ancora un oggetto (che poi viene mandato aggiungendolo con
formData.append() al body della fetch al backend, che lo riceve nella $_FILES e non nella $_POST), quindi provando
a scaricarlo non funzionerebbe. Per le immagini invece funzionerebbe.
Il flag che memorizza se il contenuto è stato modificato e quindi disabilita il bottone deve stare nel js che usa
il componente e non qui nel componente, perché in seguito alla modifica del contenuto c'è un nuovo rendering e il 
flag tornerebbe sempre allo stato iniziale, se stesse qui nel componente.
*/

function VisualizzaAllegato(props) {
    const [chiave, setChiave] = useState(0);
    const [videoUrl, setVideoUrl] = useState('');
    const inputFileRef = useRef(null); // Riferimento all'input file

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file.size > 50000000) {
            alert("Seleziona un file che non sia più grande di 50 MB");
            return;
        }
        if (file.type.startsWith("video")) {
            const url = URL.createObjectURL(file);
            setVideoUrl(url);
        }
        props.callbackAggiornaFile(e); // Passa il file al callback principale
    };

    useEffect(() => {
        if (props.contenutoFile) {
            setVideoUrl(props.contenutoFile);
        } else {
            setVideoUrl(''); // Reset in caso di contenuto vuoto
        }
    }, [props.contenutoFile]);

    return (
        <>
            <div style={{ position: "relative" }}>
                <input
                    type="file"
                    className="bottone"
                    key={chiave}
                    ref={inputFileRef} // Assegniamo il riferimento
                    accept={props.estensioniAccettate}
                    onChange={handleFileChange}
                />
            </div>

            <div className={props.cssContenitore}>
                {props.tipoFile.startsWith("image") ? (
                    <img className={props.cssImmagine} src={props.contenutoFile} alt="File immagine" />
                ) : props.tipoFile.startsWith("video") ? (
                    <video className={props.cssVideo} key={videoUrl} controls>
                        <source src={videoUrl} type={props.tipoFile} />
                        Your browser does not support the video tag.
                    </video>
                ) : props.tipoFile === "application/pdf" ? (
                    <div className={props.cssPdf}>
                        <Document file={props.contenutoFile}>
                            <Page scale={props.scalaPdf} pageNumber={1} />
                        </Document>
                    </div>
                ) : (
                    props.estensioneFile != "" && <div className={props.cssTestoEstensione}>{props.estensioneFile}</div>
                )}
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                    style={{ flex: 1, border: "1px solid grey", marginRight: 1, marginTop: 2 }}
                    onClick={() => inputFileRef.current.click()} // Click sul riferimento
                >
                    {props.estensioneFile != "" ? "Cambia" : "Aggiungi" }
                </Button>
            </div>

            {props.contenutoFile !== '' && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                        disabled={props.flagContenutoModificato}
                        style={{ flex: 1, border: "1px solid grey", marginRight: 1, marginTop: 2 }}
                        onClick={() => {
                            if (!props.flagContenutoModificato) props.callbackScarica();
                        }}
                    >
                        Scarica
                    </Button>
                    <Button
                        style={{ flex: 1, border: "1px solid grey", marginLeft: 1, marginTop: 2 }}
                        onClick={() => {
                            setChiave(chiave + 1);
                            props.callbackElimina();
                        }}
                    >
                        Elimina
                    </Button>
                </div>
            )}
        </>
    );
}

export default VisualizzaAllegato;