import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

//richiede npm install react-datepicker

const SelezionaDataInPrenotazioni = ({ data, setData, flagAperto, setFlagAperto, titolo }) => {
  const [dataSelezionata, setDataSelezionata] = useState(data);
  const oggi = new Date();

  const handleChange = (nuovaData) => {
    if (nuovaData >= oggi) {

    const data = new Date(nuovaData);
    // Estrai l'anno, mese e giorno
    const year = data.getFullYear();
    const month = (data.getMonth() + 1).toString().padStart(2, '0'); // Mese da 0 a 11, quindi aggiungi 1
    const day = data.getDate().toString().padStart(2, '0');
    // Costruisci la stringa nel formato yyyy-MM-dd
    const nuovaDataFormattata = `${year}-${month}-${day}`;
        

      setDataSelezionata(nuovaDataFormattata);
      console.log("selezionato"+nuovaDataFormattata);
      setData(nuovaDataFormattata);
      chiudiCalendario();
    }
  };

  const chiudiCalendario = () => {
    setFlagAperto(false);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <h3>{titolo}</h3>

      {/* Mostra il calendario solo se flagAperto è true */}
      {flagAperto && (
        <div
          className="calendario-popup"
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            background: '#fff',
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: '10px',
            zIndex: 1000,
            marginTop: '5px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }}
        >
          <DatePicker
            selected={dataSelezionata}
            onChange={handleChange}
            minDate={oggi}
            dateFormat="yyyy-MM-dd"
            inline
          />
        </div>
      )}

      {/* Overlay per chiudere il calendario quando si clicca fuori */}
      {flagAperto && (
        <div
          className="overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.4)',
            zIndex: 999
          }}
          onClick={chiudiCalendario}
        ></div>
      )}
    </div>
  );
};

export default SelezionaDataInPrenotazioni;



{/*import React, { useState, useRef, useEffect } from 'react';

const SelezionaDataInPrenotazioni = ({ data, setData, flagAperto, setFlagAperto, titolo }) => {
  const [dataSelezionata, setDataSelezionata] = useState(data);
  const inputRef = useRef(null);
  const oggi = new Date().toISOString().split('T')[0];

  useEffect(() => {
    if (flagAperto && inputRef.current) {
      // Apre il picker solo quando flagAperto è true
      inputRef.current.showPicker && inputRef.current.showPicker();
    }
  }, [flagAperto]);

  const handleChange = (event) => {
    const nuovaData = event.target.value;
    if (nuovaData >= oggi) {
      setDataSelezionata(nuovaData);
      setData(nuovaData);
      chiudiCalendario();
    }
  };

  const chiudiCalendario = () => {
    setFlagAperto(false);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <h3>{titolo}</h3>

      
      {flagAperto && (
        <div
          className="calendario-popup"
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            background: '#fff',
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: '10px',
            zIndex: 1000,
            marginTop: '5px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }}
        >
          <input 
            ref={inputRef}
            type="date" 
            value={dataSelezionata} 
            min={oggi}
            onChange={handleChange} 
            style={{
              display: 'block', 
              width: '100%', 
              padding: '10px', 
              fontSize: '16px',
              visibility: flagAperto ? 'visible' : 'hidden' // Nasconde l'input quando non serve
            }}
          />
        </div>
      )}
      
   
      {flagAperto && (
        <div
          className="overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.4)',
            zIndex: 999
          }}
          onClick={chiudiCalendario}
        ></div>
      )}
    </div>
  );
};

export default SelezionaDataInPrenotazioni;*/}
