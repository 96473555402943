import React, {useState, useEffect, useRef} from 'react';
import Tendina2 from './Tendina2';
import SelezionaData from './SelezionaData';
import AllenamentiVisualizzaScheda from './AllenamentiVisualizzaScheda';
import {convertiDataComputer2Umano} from './funzioni.js'
import Clessidra from '../Pagine/Clessidra'
import stile from './stile.module.css';

function AllenamentiSchede(props) {
    const PAGINE = {
        "caricamentoDati": 1,
        "elencoSchede": 2,
        "schedaModifica": 3,
        "schedaInserisci": 4,
        "schedaVisualizza": 5,
        "scegliSchedaDaAssegnare": 6,
    };
    const [pagina, setPagina] = useState(PAGINE.caricamentoDati);
    const [elencoSchede, setElencoSchede] = useState([]); // array di oggetti json
    const [elencoSchedeAssegnabili, setElencoSchedeAssegnabili] = useState([]); // array di oggetti json
    const [elencoNomiEsercizi, setElencoNomiEsercizi] = useState([]); // array di stringhe
    const [elencoNomiEserciziPerClasse, setElencoNomiEserciziPerClasse] = useState([]); // array di json
    const [elencoEsercizi, setElencoEsercizi] = useState([]); // array di oggetti json
    const [elencoClassi, setElencoClassi] = useState([]); // array di stringhe
    const [idScheda, setIdScheda] = useState(0);
    const [nomeScheda, setNomeScheda] = useState("");
    const [noteScheda, setNoteScheda] = useState("");
    const [elencoEserciziScheda, setElencoEserciziScheda] = useState([]); // array di oggetti json
    const [idPersonaSelezionata, setIdPersonaSelezionata] = useState(0);
    const [nomePersonaSelezionata, setNomePersonaSelezionata] = useState("");
    const [elencoNomiPersone, setElencoNomiPersone] = useState([]);
    const [elencoIdPersone, setElencoIdPersone] = useState([]);
    const [dataInizio,setDataInizio] = useState("");
    const [dataFine, setDataFine] = useState("");
    const opzioniVisualizzazioneSchede = ["solo le schede attive","tutte le schede, anche quelle passate"];
    const [opzioneVisualizzazioneSchede, setOpzioneVisualizzazioneSchede] = useState(opzioniVisualizzazioneSchede[0]);
    const refDropdownClassi = useRef([]);
    const refDropdownEsercizi = useRef([]);

    // variabileDestinazione: in quale variabile di stato va a finire il risultato. Valori possibili: "elencoSchede" oppure "elencoSchedeAssegnabili"
    function scaricaElencoSchede(idPersona, variabileDestinazione, paginaSeguente, opzioneVisualizzazioneSchede) {
        // questo non si può fare, altrimenti non scarica le schede predefinite da assegnare alla persona 
        // if (props.tipo!=="predefinite" && idPersona===0) return;

        setPagina(PAGINE.caricamentoDati);
        var requestOptions;
        if (idPersona == 0) {
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ op: "elencoSchedeAllenamento", standard:1, emak: props.emak, sessionId:props.sessionId })
            };
        } else {
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ op: "schedeAllPersoneRecuperaDati", idPersona: idPersona, noDatiPersone:1, filtro: opzioneVisualizzazioneSchede===opzioniVisualizzazioneSchede[0]?"attive":"tutte", emak: props.emak, sessionId:props.sessionId })
            };
        }
        setPagina(PAGINE.caricamentoDati);
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Operazione fallita\nSistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica");
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    if (variabileDestinazione=="elencoSchede") setElencoSchede(valore_di_ritorno.risultatoRitorno);
                    if (variabileDestinazione=="elencoSchedeAssegnabili") setElencoSchedeAssegnabili(valore_di_ritorno.risultatoRitorno);
                    if (elencoEsercizi.length==0) {
                        // primo caricamento: deve caricare gli esercizi e poi andrà in PAGINE.elencoSchede
                        scaricaElencoEsercizi();
                    } else {
                        // esercizi già caricati, non li carico di nuovo ma vado nella pagina indicata
                        setPagina(paginaSeguente); 
                    }
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );                    
    }

    function scaricaElencoPersone() {
        setPagina(PAGINE.caricamentoDati);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "elencoPersone", "ridotto":1, emak: props.emak, sessionId:props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    var localElencoNomi = [], localElencoId = [], nomeCompleto;
                    for (var i=0; i<valore_di_ritorno.risultatoRitorno.length; i++) {
                        localElencoId.push(valore_di_ritorno.risultatoRitorno[i].ID);
                        nomeCompleto = "";
                        if (valore_di_ritorno.risultatoRitorno[i].COGNOME!=undefined) nomeCompleto = valore_di_ritorno.risultatoRitorno[i].COGNOME + " ";
                        if (valore_di_ritorno.risultatoRitorno[i].NOME!=undefined) nomeCompleto += valore_di_ritorno.risultatoRitorno[i].NOME;
                        localElencoNomi.push(nomeCompleto);
                    }
                    setElencoIdPersone(localElencoId);
                    setElencoNomiPersone(localElencoNomi);
                    setPagina(PAGINE.elencoSchede);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );                    
    }

    function indiceClasse(elencoClassi, classe) {
        for (var i=0; i<elencoClassi.length; i++) {
            if (elencoClassi[i] == classe) return i;
        }
        return -1;
    }

    function scaricaElencoEsercizi() {
        setPagina(PAGINE.caricamentoDati);
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "elencoClassiEsercizi", emak: props.emak, sessionId:props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    
                    var ec=['']; // prima voce delle tendine classi: vuota (significa nessun filtro sulla classe)
                    var esNomiPerClasse=[];
                    for (let i=0; i<valore_di_ritorno.risultatoRitorno.length; i++) {
                        ec[i+1] = valore_di_ritorno.risultatoRitorno[i].DESCRIZIONE; // +1 perché la prima posizione, cioè la zero, è occupata dalla voce vuota
                        esNomiPerClasse[i+1] = [];                        
                    }
                    setElencoClassi(ec);                    

                    requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ op: "elencoEsercizi", elencoCompleto:1, emak: props.emak, sessionId:props.sessionId })
                    };
                    fetch(props.UrlBackend, requestOptions)
                        .then(risposta => risposta.json())
                        .then(
                            (valore_di_ritorno) => {
                                if (valore_di_ritorno.verifvers === 0) {
                                    // necessario aggiornare la app
                                    props.callbackAggiornaApp();
                                    return;
                                }
                                if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                                if (valore_di_ritorno.risultatoOperazione !== 1) { 
                                    window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                                    props.callback(props.PAGINECLIENTI.login);
                                    return; 
                                }
                                var esNomi=[], es=[], indice;
                                for (let i=0; i<valore_di_ritorno.risultatoRitorno.length; i++) {
                                    esNomi[i] = valore_di_ritorno.risultatoRitorno[i].DESCRIZIONE;
                                    indice = indiceClasse(ec, valore_di_ritorno.risultatoRitorno[i].CLASSE);
                                    if (indice>0) {
                                        esNomiPerClasse[indice].push(valore_di_ritorno.risultatoRitorno[i].DESCRIZIONE);
                                    }
                                    es[i] = {
                                        id:valore_di_ritorno.risultatoRitorno[i].ID,
                                        classe:valore_di_ritorno.risultatoRitorno[i].CLASSE,
                                        serie:valore_di_ritorno.risultatoRitorno[i].SERIE_STD,
                                        ripetizioni:valore_di_ritorno.risultatoRitorno[i].RIPETIZIONI_STD,
                                        recupero:valore_di_ritorno.risultatoRitorno[i].RECUPERO_TRA_SERIE_STD,
                                        note:valore_di_ritorno.risultatoRitorno[i].NOTE_ESERCIZIO_STD
                                    };
                                }
                                setElencoNomiEsercizi(esNomi);
                                setElencoNomiEserciziPerClasse(esNomiPerClasse);
                                setElencoEsercizi(es);
                                setPagina(PAGINE.elencoSchede);                    
                            },
                            (error) => {
                                props.callback(props.PAGINECLIENTI.login);
                                return;
                            }
                        );
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );                         
    }

    function insModScheda() {        
        if (nomeScheda=="") {
            window.alert("Impossibile memorizzare: indica il nome della scheda"); 
            return;
        }
        if (elencoEserciziScheda.length<=0) {
            window.alert("Impossibile memorizzare: inserisci almeno un esercizio nella scheda"); 
            return;
        }

        // creazione delle variabili richieste dal backend dall'array elencoEserciziScheda
        var eserciziScheda=[], serieEserciziScheda=[], ripetizioniEserciziScheda=[], recuperoEserciziScheda=[], noteEserciziScheda=[];
        for (var i=0; i<elencoEserciziScheda.length; i++) {
            if (!elencoEserciziScheda[i].nome || elencoEserciziScheda[i].nome == "") continue; // non inserisco un esercizio vuoto
            eserciziScheda.push(elencoEserciziScheda[i].nome);
            serieEserciziScheda.push(elencoEserciziScheda[i].serie);
            ripetizioniEserciziScheda.push(elencoEserciziScheda[i].ripetizioni);
            recuperoEserciziScheda.push(elencoEserciziScheda[i].recupero);
            noteEserciziScheda.push(elencoEserciziScheda[i].note);
        }

        if (eserciziScheda.length<=0) {
            window.alert("Impossibile memorizzare: inserisci almeno un esercizio nella scheda"); 
            return;
        }
       
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: props.tipo=="predefinite" ? pagina===PAGINE.schedaModifica ? "schedaAllenamentoMod" : "schedaAllenamentoIns" : pagina===PAGINE.schedaModifica ? "schedeAllPersoneMod" : "schedeAllPersoneIns", 
                idElemento: pagina===PAGINE.schedaModifica ? idScheda : 0,
                idPersona: props.tipo=="predefinite" ? 0 : idPersonaSelezionata,
                descrizione: nomeScheda,
                note: noteScheda,
                dataDa: dataInizio,
                dataA: dataFine,
                eserciziScheda: eserciziScheda,
                serieEserciziScheda: serieEserciziScheda,
                ripetizioniEserciziScheda: ripetizioniEserciziScheda,
                recuperoEserciziScheda: recuperoEserciziScheda,
                noteEserciziScheda: noteEserciziScheda, 
                emak: props.emak, 
                sessionId:props.sessionId
            })
        };
        setPagina(PAGINE.caricamentoDati);
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    props.tipo=="predefinite" ? scaricaElencoSchede(0,"elencoSchede",PAGINE.elencoSchede,opzioneVisualizzazioneSchede) : scaricaElencoSchede(idPersonaSelezionata,"elencoSchede",PAGINE.elencoSchede,opzioneVisualizzazioneSchede);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function cancScheda(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "schedaAllenamentoCanc", 
                idElemento: id,
                emak: props.emak, 
                sessionId:props.sessionId
            })
        };
        setPagina(PAGINE.caricamentoDati);
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    scaricaElencoSchede(idPersonaSelezionata,"elencoSchede",PAGINE.elencoSchede,opzioneVisualizzazioneSchede);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function scaricaSchedaPerModifica(id, paginaSeguente) {
        setPagina(PAGINE.caricamentoDati);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "elencoEserciziScheda", 
                id: id,
                emak: props.emak,
                sessionId:props.sessionId
            })
        };
        setPagina(PAGINE.caricamentoDati);
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    setIdScheda(id);
                    if (valore_di_ritorno.risultatoRitorno[0] == undefined) {
                        // scheda vuota
                        setNomeScheda("");
                        setNoteScheda("");
                        setElencoEserciziScheda([]);
                    } else{
                        setNomeScheda(valore_di_ritorno.risultatoRitorno[0].DESCRIZIONE);
                        setNoteScheda(valore_di_ritorno.risultatoRitorno[0].NOTE_SCHEDA);
                        if (valore_di_ritorno.risultatoRitorno[0].DATA_INIZIO!==undefined && valore_di_ritorno.risultatoRitorno[0].DATA_INIZIO!==null) {
                            setDataInizio(valore_di_ritorno.risultatoRitorno[0].DATA_INIZIO);
                        } else {
                            setDataInizio("");
                        }
                        if (valore_di_ritorno.risultatoRitorno[0].DATA_FINE!==undefined && valore_di_ritorno.risultatoRitorno[0].DATA_FINE!==null) {
                            setDataFine(valore_di_ritorno.risultatoRitorno[0].DATA_FINE);
                        } else {
                            setDataFine("");
                        }
                        var el=[], esercizio, localClasse, localIndiceClasse;
                        for (var i=0; i<valore_di_ritorno.risultatoRitorno.length; i++) {
                            localClasse = valore_di_ritorno.risultatoRitorno[i].CLASSE;
                            localIndiceClasse = indiceClasse(elencoClassi, localClasse);
                            esercizio = {
                                nome:valore_di_ritorno.risultatoRitorno[i].ESERCIZIO, 
                                indiceClasse:localIndiceClasse,
                                classe:localClasse,
                                ripetizioni:valore_di_ritorno.risultatoRitorno[i].RIPETIZIONI,
                                serie:valore_di_ritorno.risultatoRitorno[i].SERIE,
                                recupero:valore_di_ritorno.risultatoRitorno[i].RECUPERO_TRA_SERIE,
                                note:valore_di_ritorno.risultatoRitorno[i].NOTE_ESERCIZIO
                            };
                            el.push(esercizio);
                        }
                    }
                    setElencoEserciziScheda(el);
                    setPagina(paginaSeguente);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function aggiungiEsercizio(flagPrimo) {
        var elenco=[];
        if (!flagPrimo) elenco = [...elencoEserciziScheda];
        var esercizio = {nome:"", indiceClasse:0, classe:"", ripetizioni:0, serie:0, recupero:"", note:""};
        elenco.push(esercizio);
        setElencoEserciziScheda(elenco);
    }

    function impostaValore(indiceEsercizio, campo, valore) {
        //console.log("impostaValore("+indiceEsercizio+","+campo+","+valore+")");
        var es = [...elencoEserciziScheda];
        switch (campo) {
            case "indiceClasse": es[indiceEsercizio].indiceClasse = valore; break;
            case "classe": 
                es[indiceEsercizio].classe = valore; 
                es[indiceEsercizio].nome = "";
                es[indiceEsercizio].serie = "";
                es[indiceEsercizio].ripetizioni = "";
                es[indiceEsercizio].recupero = "";
                es[indiceEsercizio].note = "";
            break;
            case "nome": 
                es[indiceEsercizio].nome = valore; 
                var found = false;
                for (var it=0; it<elencoNomiEsercizi.length; it++) {
                    if (elencoNomiEsercizi[it] == valore) {
                        found = true;
                        break;
                    }
                }
                if (found) {
                    //console.log("es[",indiceEsercizio,"].classe = elencoEsercizi[",it,"].classe")
                    
                    //es[indiceEsercizio].classe = elencoEsercizi[it].classe;                                        
                    //es[indiceEsercizio].indiceClasse = indiceClasse(elencoClassi,elencoEsercizi[it].classe);

                    es[indiceEsercizio].serie = elencoEsercizi[it].serie;
                    es[indiceEsercizio].ripetizioni = elencoEsercizi[it].ripetizioni;
                    es[indiceEsercizio].recupero = elencoEsercizi[it].recupero;
                    es[indiceEsercizio].note = elencoEsercizi[it].note;
                    //console.log(it,elencoEsercizi[it].classe,elencoEsercizi[it].serie);
                }
            break;
            case "serie": es[indiceEsercizio].serie = valore; break;
            case "ripetizioni": es[indiceEsercizio].ripetizioni = valore; break;
            case "recupero": es[indiceEsercizio].recupero = valore; break;
            case "note": es[indiceEsercizio].note = valore; break;
        }
        setElencoEserciziScheda(es);        
    }

    function spostaInAlto(i) {
        if (i==0) return;
        var es = [...elencoEserciziScheda];
        var esTemp = es[i];
        es[i] = es[i-1];
        es[i-1] = esTemp;
        setElencoEserciziScheda(es);
    }

    function spostaInBasso(i) {
        if (i>=elencoEserciziScheda.length-1) return;
        var es = [...elencoEserciziScheda];
        var esTemp = es[i];
        es[i] = es[i+1];
        es[i+1] = esTemp;
        setElencoEserciziScheda(es);
    }

    function elimina(i) {
        var es = [...elencoEserciziScheda];
        for (var n=i; n<elencoEserciziScheda.length-1; n++) es[n] = es[n+1];
        es.pop();
        setElencoEserciziScheda(es);
    }

    useEffect(() => {
        setDataInizio("");
        setDataFine("");
        if (props.tipo == "predefinite") {
            scaricaElencoSchede(0,"elencoSchede",PAGINE.elencoSchede,opzioneVisualizzazioneSchede); 
        } else {
            if (props.identitaSelezionata.flag_staff) {
                scaricaElencoPersone();
            } else {
                setIdPersonaSelezionata(props.identitaSelezionata.idpersona);
                scaricaElencoSchede(props.identitaSelezionata.idpersona,"elencoSchede",PAGINE.elencoSchede,opzioneVisualizzazioneSchede);
            }
        }
    }, [])
   
    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            switch (pagina) {
                case PAGINE.caricamentoDati: break;
                case PAGINE.elencoSchede: props.identitaSelezionata.flag_staff ? props.callback(props.PAGINECLIENTI.allenamenti) : props.callback(props.PAGINECLIENTI.home); break;
                case PAGINE.schedaModifica: setPagina(PAGINE.elencoSchede); break;
                case PAGINE.schedaInserisci: setPagina(PAGINE.elencoSchede); break;
                case PAGINE.schedaVisualizza: setPagina(PAGINE.elencoSchede); break;
                case PAGINE.scegliSchedaDaAssegnare: setPagina(PAGINE.elencoSchede); break;
            }
            
        } 
    }, [props.flagRichiestaBack])

    if (pagina === PAGINE.caricamentoDati) {
        return (<Clessidra loading={true} />)
    }

    if (pagina === PAGINE.schedaInserisci || pagina === PAGINE.schedaModifica) {
        const altezzaContenuto = `calc(100vh - ${props.altezzaBarraTitolo}px)`;
        return (
            <div style={{ flex: 1, display: "flex", flexDirection: "column", height: "1px" }}>
                <div style={{ flex: 1, overflowY: "auto", padding:5 }}>
                    {/* Contenuto scorrevole */}
                    <div class={stile.eventiModificaTestoTitoli} style={{ paddingBottom: 5 }}>
                    {props.tipo === "predefinite"
                        ? pagina === PAGINE.schedaModifica
                        ? "Modifica scheda predefinita"
                        : "Inserisci nuova scheda predefinita"
                        : pagina === PAGINE.schedaModifica
                        ? `Modifica scheda per ${nomePersonaSelezionata}`
                        : `Nuova scheda per ${nomePersonaSelezionata}`}
                    </div>

                    <span class={stile.eventiModificaTestoTitoli}>Nome della scheda*</span>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 5 }}>
                    <input
                        value={nomeScheda}
                        onChange={(e) => setNomeScheda(e.target.value)}
                        maxLength={200}
                        style={{ flex: 1 }}
                    />
                    <div onClick={() => setNomeScheda("")}>
                        <img class={stile.iconaPiccolaQuadrata} src={require("./x.png")} alt="Annulla" style={{marginLeft:5}} />
                    </div>
                    </div>

                    {props.tipo !== "predefinite" && (
                    <>
                        <span class={stile.eventiModificaTestoTitoli}>Scheda da eseguire dal giorno</span>
                        <SelezionaData data={dataInizio} setData={setDataInizio} titolo={"Scheda da eseguire dal giorno"} />
                        <span class={stile.eventiModificaTestoTitoli}>al giorno</span>
                        <SelezionaData data={dataFine} setData={setDataFine} titolo={"Scheda da eseguire fino al giorno"} />
                    </>
                    )}

                    <span class={stile.eventiModificaTestoTitoli}>Note aggiuntive sulla scheda</span>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 5 }}>
                    <textarea
                        value={noteScheda}
                        onChange={(e) => setNoteScheda(e.target.value)}
                        maxLength={1000}
                        style={{ flex: 1 }}
                    />
                    <div onClick={() => setNoteScheda("")}><img class={stile.iconaPiccolaQuadrata} src={require("./x.png")} alt="Annulla" style={{marginLeft:5}} /></div>
                    </div>

                    {elencoEserciziScheda.map((x, i) => (
                    <div key={i} class={stile.eventiElencoContenitoreElemento} style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                        <div style={{ flex: 10 }}>
                        <span class={stile.eserciziModificaNumeroEsercizio}>ESERCIZIO N. {i + 1} di {elencoEserciziScheda.length}</span>
                        <div class={stile.eserciziModificaTestoTitoli}>Classificazione</div>
                        <Tendina2
                            className={stile.genericaDropdownBianca}
                            options={elencoClassi}
                            defaultValue={x.classe===undefined ? " " : x.classe==="" ? " " : x.classe}
                            onSelect={(t) => {
                                impostaValore(i,"indiceClasse",t);
                                impostaValore(i, "classe", elencoClassi[t]);
                                impostaValore(i, "nome", "");
                            }}
                        />

                        <div class={stile.eserciziModificaTestoTitoli}>Esercizio</div>
                        <Tendina2
                            className={stile.genericaDropdownBianca}
                            options={x.indiceClasse === 0 ? elencoNomiEsercizi : elencoNomiEserciziPerClasse[x.indiceClasse]}
                            defaultValue={x.nome===undefined ? " " : x.nome==="" ? " " : x.nome}
                            onSelect={(t) => {
                                x.indiceClasse===0 ? impostaValore(i,"nome",elencoNomiEsercizi[t]) : impostaValore(i,"nome",elencoNomiEserciziPerClasse[x.indiceClasse][t]);                     
                            }}
                        />

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            
                            <div style={{ flex: 1 }}>
                                <div class={stile.eserciziModificaTestoTitoli}>N. serie</div>
                                <div style={{display: 'flex', flexDirection:'row'}}>
                                    <input
                                        type="text"
                                        value={x.serie > 0 ? x.serie : ""}
                                        onChange={(e) => impostaValore(i, "serie", e.target.value)}
                                        maxLength={50}
                                        style={{ flex: 1 }}
                                    />
                                    <div onClick={() => impostaValore(i, "serie", 0)}>
                                        <img class={stile.iconaPiccolaQuadrata} src={require("./xgrigia.png")} alt="Annulla" style={{marginLeft:5}} />
                                    </div>
                                </div>
                            </div>

                            <div style={{ flex: 1, marginLeft: 5 }}>
                                <div class={stile.eserciziModificaTestoTitoli}>Ripetizioni</div>
                                    <div style={{display: 'flex', flexDirection:'row'}}>
                                    <input
                                        type="text"
                                        value={x.ripetizioni > 0 ? x.ripetizioni : ""}
                                        onChange={(e) => impostaValore(i, "ripetizioni", e.target.value)}
                                        maxLength={50}
                                        style={{ flex: 1 }}
                                    />
                                    <div onClick={() => impostaValore(i, "ripetizioni", 0)}><img class={stile.iconaPiccolaQuadrata} src={require("./xgrigia.png")} alt="Annulla" style={{marginLeft:5}} /></div>
                                </div>
                            </div>
                        </div>

                        <div class={stile.eserciziModificaTestoTitoli}>Recupero tra serie</div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <input
                                type="text"
                                value={x.recupero > 0 ? x.recupero : ""}
                                onChange={(e) => impostaValore(i, "recupero", e.target.value)}
                                maxLength={200}
                                style={{ flex: 1 }}
                            />
                            <div onClick={() => impostaValore(i, "recupero", "")}><img class={stile.iconaPiccolaQuadrata} src={require("./xgrigia.png")} alt="Annulla" style={{marginLeft:5}} /></div>
                        </div>

                        <div class={stile.eserciziModificaTestoTitoli}>Note</div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <textarea
                                value={x.note}
                                onChange={(e) => impostaValore(i, "note", e.target.value)}
                                maxLength={1000}
                                style={{ flex: 1, width:"100%" }}
                            />
                            <div onClick={() => impostaValore(i, "note", "")}><img class={stile.iconaPiccolaQuadrata} src={require("./xgrigia.png")} alt="Annulla" style={{marginLeft:5}} /></div>
                        </div>

                        </div>

                        <div style={{ flex: 1, marginLeft: 10 }}>
                        <div 
                            className={`${stile.bottone3} ${stile.bottoneColoreNormale}`} style={{justifyContent:'center'}} 
                            onClick={() => spostaInAlto(i)}>
                            <img class={stile.iconaPiccolaQuadrata} src={require("./up4.png")} alt="Sposta su" />
                        </div>
                        <div className={`${stile.bottone3} ${stile.bottoneColoreRosso}`} style={{justifyContent:'center',marginTop:10, marginBottom:10}} 
                            onClick={() => elimina(i)}>
                        <img class={stile.iconaPiccolaQuadrata} src={require("./elimina.png")} alt="Elimina" />
                        </div>
                        <div 
                            className={`${stile.bottone3} ${stile.bottoneColoreNormale}`} style={{justifyContent:'center'}} 
                            onClick={() => spostaInBasso(i)}>
                            <img class={stile.iconaPiccolaQuadrata} src={require("./down4.png")} alt="Sposta giù" />
                        </div>
                        </div>
                    </div>
                    ))}

                    <div className={`${stile.bottone3} ${stile.bottoneColoreNormale}`} onClick={() => aggiungiEsercizio(false)}>
                        <img class={stile.iconaPiccolaQuadrata} src={require("./stella.png")} alt="Aggiungi" style={{marginLeft:5}} />
                        AGGIUNGI ESERCIZIO
                    </div>
                </div>

                {/* Azioni in fondo */}
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, position: 'sticky', bottom: 0, padding: '10px 0' }}>
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreRosso}`} 
                        onClick={() => props.tipo === "predefinite" ? scaricaElencoSchede(0, "elencoSchede", PAGINE.elencoSchede, opzioneVisualizzazioneSchede) : scaricaElencoSchede(idPersonaSelezionata, "elencoSchede", PAGINE.elencoSchede, opzioneVisualizzazioneSchede)}
                    >
                        <div class={stile.spazioIconaPiccola}>
                            <img 
                                class={stile.iconaPiccolaQuadrata} 
                                src={require("./annulla.png")} 
                                alt="Annulla" 
                            />
                        </div>
                        <div class={stile.bottoneTestoNormale}>ANNULLA</div>
                    </div>
    
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreVerde}`} 
                        onClick={() => { insModScheda(); }}
                    >
                        <div class={stile.spazioIconaPiccola}>
                            <img 
                                class={stile.iconaPiccolaQuadrata}
                                src={require('./modifica.png')}
                                alt="Memorizza" 
                            />
                        </div>
                        <div class={stile.bottoneTestoNormale}>MEMORIZZA</div>
                    </div>
                </div>

            </div>
        );
    }

    if (pagina === PAGINE.elencoSchede) {
        return (
            <div style={{ overflowY: 'scroll', maxHeight: '100vh' }}>
                {props.tipo === "assegnate" ? (
                    <>
                        {props.identitaSelezionata.flag_staff && (
                            <>
                                <p style={{ paddingTop: 5 }} class={stile.eventiModificaTestoTitoli }>
                                    {nomePersonaSelezionata !== "" ? "Atleta:" : "Seleziona atleta:"}
                                </p>
                                <Tendina2
                                    className={stile.genericaDropdown}
                                    options={elencoNomiPersone}
                                    defaultValue={nomePersonaSelezionata === "" ? " " : nomePersonaSelezionata}
                                    onSelect={(t) => {
                                        setNomePersonaSelezionata(elencoNomiPersone[t]);
                                        setIdPersonaSelezionata(elencoIdPersone[t]);
                                        scaricaElencoSchede(elencoIdPersone[t], "elencoSchede", PAGINE.elencoSchede, opzioneVisualizzazioneSchede);
                                    }}
                                />
                            </>
                        )}
                        <p style={{ paddingTop: 5 }} class={stile.eventiModificaTestoTitoli }>
                            Visualizza le schede:
                        </p>
                        <Tendina2
                            className={stile.genericaDropdown}
                            options={opzioniVisualizzazioneSchede}
                            defaultValue={opzioneVisualizzazioneSchede}
                            onSelect={(t) => {
                                setOpzioneVisualizzazioneSchede(opzioniVisualizzazioneSchede[t]);
                                if (idPersonaSelezionata !== 0) {
                                    scaricaElencoSchede(idPersonaSelezionata, "elencoSchede", PAGINE.elencoSchede, opzioniVisualizzazioneSchede[t]);
                                }
                            }}
                        />

                        <div style={{height:5}}></div>

                        {nomePersonaSelezionata !== "" && (
                            <div style={{ display: "flex", flexDirection: "row", padding: '5px 10px', marginBottom: 5, backgroundColor: "#E0E0E0" }}>
                                <div
                                    className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                                    onClick={() => {
                                        scaricaElencoSchede(0, "elencoSchedeAssegnabili", PAGINE.scegliSchedaDaAssegnare);
                                    }}
                                >
                                    <div class={stile.spazioIconaPiccola}>
                                        <img
                                            class={stile.iconaPiccolaQuadrata}
                                            src={require('./stella.png')}
                                            alt="Assegna"
                                        />
                                    </div>
                                    <span class={stile.bottoneTestoNormale}>Assegna una nuova scheda</span>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <div style={{ display: "flex", flexDirection: "row", padding: '5px 10px', marginBottom: 5, backgroundColor: "#E0E0E0" }}>
                        <div
                            className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                            onClick={() => {
                                setNomeScheda("");
                                setElencoEserciziScheda([]);
                                aggiungiEsercizio(true);
                                setPagina(PAGINE.schedaInserisci);
                            }}
                        >
                            <div class={stile.spazioIconaPiccola}>
                                <img
                                    class={stile.iconaPiccolaQuadrata}
                                    src={require('./stella.png')}
                                    alt="Inserisci"
                                />
                            </div>
                            <span class={stile.bottoneTestoNormale}>Inserisci nuova</span>
                        </div>
                    </div>
                )}
    
                {elencoSchede.map((x, i) => (
                    <div key={i} class={stile.elencoSchedeContenitoreElemento}>
                        <div
                            style={{ flex: 1 }}
                            onClick={() => {
                                setIdScheda(x.ID);
                                setNomeScheda(props.tipo === "predefinite" ? x.DESCRIZIONE : x.NOMESCHEDA);
                                setNoteScheda(x.NOTE_SCHEDA);
                                setPagina(PAGINE.schedaVisualizza);
                            }}
                        >
                            <span class={stile.elencoSchedeNome}>
                                {props.tipo === "predefinite" ? x.DESCRIZIONE : x.NOMESCHEDA}
                            </span>
    
                            {props.tipo === "predefinite" ? null : (
                                x.DATA_DA !== "" && x.DATA_DA !== null && x.DATA_A !== "" && x.DATA_A !== null ? (
                                    <div class={stile.elencoSchedeDateAssegnazione}>
                                        Da eseguire dal {convertiDataComputer2Umano(x.DATA_DA, "")} al {convertiDataComputer2Umano(x.DATA_A, "")}
                                    </div>
                                ) : x.DATA_DA !== "" && x.DATA_DA !== null ? (
                                    <div class={stile.elencoSchedeDateAssegnazione}>
                                        Da eseguire dal {convertiDataComputer2Umano(x.DATA_DA, "")}
                                    </div>
                                ) : x.DATA_A !== "" && x.DATA_A !== null ? (
                                    <div class={stile.elencoSchedeDateAssegnazione}>
                                        Da eseguire fino al {convertiDataComputer2Umano(x.DATA_A, "")}
                                    </div>
                                ) : null
                            )}
                        </div>
    
                        {props.identitaSelezionata.flag_staff && (
                            <>
                                <div
                                    style={{ width: 50, display: "flex", alignItems: "center" }}
                                    onClick={() => {
                                        scaricaSchedaPerModifica(x.ID, PAGINE.schedaModifica);
                                    }}
                                >
                                    <img
                                        class={stile.iconaPiccolaQuadrata}
                                        src={require('./modifica.png')}
                                        alt="Modifica"
                                    />
                                </div>
    
                                <div
                                    style={{ width: 50, display: "flex", alignItems: "center" }}
                                    onClick={() => {
                                        if (window.confirm("Confermi eliminazione della scheda "+(props.tipo === "predefinite" ? x.DESCRIZIONE : x.NOMESCHEDA) + "?")) {
                                            cancScheda(x.ID);
                                        }
                                    }}
                                >
                                    <img
                                        class={stile.iconaPiccolaQuadrata}
                                        src={require('./xgrigia-nobordo.png')}
                                        alt="Elimina"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        );
    }

    if (pagina === PAGINE.scegliSchedaDaAssegnare) {
        return (
            <div style={{ overflowY: 'scroll', maxHeight: '100vh' }}>
                <p style={{ paddingBottom: 5 }} class={stile.eventiModificaTestoTitoli }>
                    Seleziona la scheda da assegnare a {nomePersonaSelezionata}:
                </p>
                <div 
                    class={stile.elencoSchedeContenitoreElemento}
                    onClick={() => { 
                        setPagina(PAGINE.schedaInserisci);
                    }}
                >
                    <span class={stile.elencoSchedeNome}>
                        Scheda vuota
                    </span>
                </div>
                {elencoSchedeAssegnabili.map((x, i) => (
                    <div 
                        key={i}
                        class={stile.elencoSchedeContenitoreElemento}
                        onClick={() => { 
                            scaricaSchedaPerModifica(x.ID, PAGINE.schedaInserisci);
                        }}
                    >
                        <span class={stile.elencoSchedeNome}>
                            {x.DESCRIZIONE}
                        </span>
                    </div>
                ))}
            </div>
        );
    }
    

    if (pagina === PAGINE.schedaVisualizza) {
        return (
        <AllenamentiVisualizzaScheda
            {...props}
            idScheda={idScheda}
            nomeScheda={nomeScheda}
            noteScheda={noteScheda}
            idPersonaSelezionata={idPersonaSelezionata}
            nomePersonaSelezionata={nomePersonaSelezionata}
        />
        )
    }

    return (
        <div>Errore pagina non trovata (AllenamentiSchede)</div>
    )
}

export default AllenamentiSchede;
