import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';

import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import FormattaImportoVideo from '../FormattaImportoVideo.js';
import FormattaImportoDb from '../FormattaImportoDb.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record

const STATO_SCARICA_PDF = 8;
const STATO_PREPARA_PDF = 9;

// fornire props.tipo: "associativa" o "abbonamento"
function SchedaIncassiAbbAss(props) {

    let coloreRosso = "#D83D00"; //(PER DEBITI)
    let coloreVerde = "#43BC6A"; //(PER CREDITI)

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno

    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagAttivaModaleRichiediPermessoInserire, setFlagAttivaModaleRichiediPermessoInserire] = useState(0);
    const [flagAttivaModaleRichiediData, setFlagAttivaModaleRichiediData] = useState(0);
    const [flagAttivaModaleRichiediRisorsa, setFlagAttivaModaleRichiediRisorsa] = useState(0);
    const [flagAttivaModaleRichiediVoceBilancio, setFlagAttivaModaleRichiediVoceBilancio] = useState(0);
    const [flagAttivaModaleRichiediImporto, setFlagAttivaModaleRichiediImporto] = useState(0);
    const [flagAttivaModaleRichiediNumeroTessera, setFlagAttivaModaleRichiediNumeroTessera] = useState(0);
    const [flagAttivaModaleInserisciIscritto, setFlagAttivaModaleInserisciIscritto] = useState(0);
    const [flagAttivaModaleImportoErrato, setFlagAttivaModaleImportoErrato] = useState(0);
    const [flagAttivaModaleSpiegaAttivita, setFlagAttivaModaleSpiegaAttivita] = useState(0);
 
    //const risorsa   = useRef();
    const descrizione   = useRef();
    const note   = useRef();
    const numeroFattura   = useRef();
    const importo   = useRef();
    const dataPagamento   = useRef();
    //utile per indicazione del cognome in caso di iscritti con più cognomi (o cognomi composti) e/o più nomi:
    const cognome = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [statoTendinaRisorse,setStatoTendinaRisorse]=useState(false);
    const [statoTendinaVocidiBilancio,setStatoTendinaVocidiBilancio]=useState(false);
    const [statoTendinaIscritti,setStatoTendinaIscritti]=useState(false);

    const [statoRicercaDatiQuoteIscritto,setStatoRicercaDatiQuoteIscritto]=useState(false);
    
    const [error,setError]=useState('');

    const [risultatoServerDatiRisorse,setRisultatoServerDatiRisorse]=useState('');
    const [risultatoServerDatiVocidiBilancio,setRisultatoServerDatiVocidiBilancio]=useState('');
    const [risultatoServerDatiIscritti,setRisultatoServerDatiIscritti]=useState('');
    const [risultatoServerDatiDovutoQuoteIscritto,setRisultatoServerDatiDovutoQuoteIscritto]=useState([]);
    //const [risultatoServerDatiDovutoQuoteTotaleIscritto,setRisultatoServerDatiDovutoQuoteTotaleIscritto]=useState('');
    const [risultatoServerDatiVersatoQuoteIscritto,setRisultatoServerDatiVersatoQuoteIscritto]=useState('');
    //const [risultatoServerDatiVersatoQuoteTotaleIscritto,setRisultatoServerDatiVersatoQuoteTotaleIscritto]=useState('');

    const [richiediDistinzCognomeNome,setRichiediDistinzCognomeNome]=useState(0);
    const [permessoInserirePersona,setPermessoInserirePersona]=useState(0);
    const [permessoInserireRisorsa,setPermessoInserireRisorsa]=useState(0);
    const [permessoInserireVoceDiBilancio,setPermessoInserireVoceDiBilancio]=useState(0);
  
    const [descrizioneDefault,setDescrizioneDefault]=useState('');
    const [noteDefault,setNoteDefault]=useState('');
    const [numeroFatturaDefault,setNumeroFatturaDefault]=useState('');
    const [IVADefault,setIVADefault]=useState('N1');
    const [importoDefault,setImportoDefault]=useState('');
    const [dataPagamentoDefault,setDataPagamentoDefault]=useState(dataOggi);
   
    const [insRisorsaEdi,setInsRisorsaEdi]=useState('');
    const [insVociDiBilancioEdi,setInsVociDiBilancioEdi]=useState('');
    const [insVociDiBilancioAttEdi,setInsVociDiBilancioAttEdi]=useState('');
    const [insIscrittiEdi,setInsIscrittiEdi]=useState('');
    const [placeholderDescrizione,setPlaceholderDescrizione]=useState('');
    const [codiceFiscaleIscritto,setCodiceFiscaleIscritto]=useState('');

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [ingrigisciSpecchiettoQuote,setIngrigisciSpecchiettoQuote]=useState("main-card mb-3");

    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    const [elencoRiferimenti, setElencoRiferimenti] = useState([]);
    const [indiceRiferimentoSelezionato, setIndiceRiferimentoSelezionato] = useState(-1);
    const [jsonRicevuta, setJsonRicevuta] = useState("");

    const [ricevuta,setRicevuta]=useState(0);

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);

    const [fineIscritti, setFineIscritti] = useState(0);

    const [operazioneInterna,setOperazioneInterna]=useState('');
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    useEffect(() => {
        if(statoPagina === STATO_PREPARA_PDF){
            setStatoPagina(STATO_SCARICA_PDF);
        }
    },[statoPagina]);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaRisorse(false);
        setStatoTendinaVocidiBilancio(false);
        setStatoTendinaIscritti(false);
        setStatoRicercaDatiQuoteIscritto(false);
        setPermessoInserirePersona(0);
        setPermessoInserireRisorsa(0);
        setPermessoInserireVoceDiBilancio(0); 
        scaricaLogo();
    },[]);

    useEffect(() => {       
        if(statoTendinaIscritti === true && statoTendinaRisorse === true && statoTendinaVocidiBilancio === true && statoRicercaDatiQuoteIscritto === true){                      
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaIscritti,statoTendinaRisorse,statoTendinaVocidiBilancio,statoRicercaDatiQuoteIscritto]);

    

    useEffect(() => {
        //avvio la ricerca della situazione quote dell'iscritto

        setElencoRiferimenti([]);

        if(insIscrittiEdi!==''){
            if (props.idIscritto||props.ope=="modifica"||props.ope=="cancella") {
                // se arriva l'idIscritto allora assumo che l'utente esista senza cercarlo, non potrei nemmeno farlo
                // perché non ho popolato l'elenco delle persone dato che la persona non è modificabile. Stessa cosa
                // se l'operazione è modifica o cancella, non ho l'elenco delle persone e so che l'utente esiste. In entrambi i
                // casi, quindi, faccio la ricerca delle quote dovute e versate di questo iscritto
                ricercaDatiDovutoQuoteIscritto(insIscrittiEdi);
            } else {
                // in questo caso, prima di cercare le quote dovute e versate, verifico se insIscrittiEdi
                // identifica univocamente un iscritto esistente
                if (iscrittoEsistenteUnivoco(insIscrittiEdi)) {
                    ricercaDatiDovutoQuoteIscritto(insIscrittiEdi);
                } else {
                    setStatoRicercaDatiQuoteIscritto(false);
                }
            }
        }
    },[insIscrittiEdi]);

    useEffect(() => {
        if(permessoInserirePersona===1 && permessoInserireRisorsa===1 && permessoInserireVoceDiBilancio===1){
            if(richiediDistinzCognomeNome!==1 && richiediDistinzCognomeNome!==2){
                if(ricevuta===1) fetchOperazioneServer(0,1,1);
                else fetchOperazioneServer(0,0,1);
            }
        }
    },[permessoInserirePersona,permessoInserireRisorsa,permessoInserireVoceDiBilancio]);

    useEffect(() => {
        if(props.ope==='inserisci' || operazioneInterna === 'copia') setOperazione("movPrimaNotaIns");
        if(props.ope==='modifica' && operazioneInterna === '') setOperazione("movPrimaNotaMod");
        if(props.ope==='cancella') setOperazione("movPrimaNotaCanc");


        if(operazioneInterna === 'copia'){
            setDisabilitaBottoni(false);
            setDataPagamentoDefault('');
            setNumeroFatturaDefault('');
            setIVADefault('');
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }else{
            if(props.ope==="modifica" || props.ope==="cancella"){
                //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
                // parametri per inviare i dati al server:
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({op:"movPrimaNotaRecuperaDati",
                        id:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                };
                setStatoPagina(STATO_RICERCA_DATI);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                    (valoreDiRitorno) => {
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                        }
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione===1){

                            //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                            setInsRisorsaEdi(valoreDiRitorno.risultatoRitorno[0].RISORSA);
                            //setDefaultRisorsa(valoreDiRitorno.risultatoRitorno[0].RISORSA);

                            setInsVociDiBilancioEdi(valoreDiRitorno.risultatoRitorno[0].VOCE_DI_BILANCIO);
                            setInsVociDiBilancioAttEdi(valoreDiRitorno.risultatoRitorno[0].TIPO_ATTIVITA);

                            //setDefaultVoceDiBilancio(valoreDiRitorno.risultatoRitorno[0].VOCE_DI_BILANCIO);
                            let componiPersona="";
                            componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].COGNOME;
                            if(valoreDiRitorno.risultatoRitorno[0].NOME !== "" && componiPersona !== "" ) componiPersona = componiPersona + " ";
                            componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].NOME;
                            if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== "" && componiPersona !== "") componiPersona = componiPersona + " ("+valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA+")";
                            setInsIscrittiEdi(componiPersona);//serve per settare la var di stato se non cambi valore nella tendina in caso di modifica
                            //setDefaultPersona(componiPersona);
                            setCodiceFiscaleIscritto(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);

                            setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                            setNoteDefault(valoreDiRitorno.risultatoRitorno[0].NOTE);
                            setNumeroFatturaDefault(valoreDiRitorno.risultatoRitorno[0].NUMERO_FATTURA);
                            setIVADefault(valoreDiRitorno.risultatoRitorno[0].ALIQUOTA_IVA);
                            if(valoreDiRitorno.risultatoRitorno[0].SEGNO==='-1'){
                                setImportoDefault(-valoreDiRitorno.risultatoRitorno[0].IMPORTO);
                            }else{
                                setImportoDefault(valoreDiRitorno.risultatoRitorno[0].IMPORTO);
                            }
                            //setSegnoDefault(valoreDiRitorno.risultatoRitorno[0].SEGNO);
                            setDataPagamentoDefault(valoreDiRitorno.risultatoRitorno[0].DATA_PAGAMENTO);
                            //setTipoFatturaDefault(valoreDiRitorno.risultatoRitorno[0].TIPOLOGIA);

                            if(props.tipo=="abbonamento") setPlaceholderDescrizione("Quota Abbonamento" + " di "+componiPersona);
                            else setPlaceholderDescrizione("Quota Associativa" + " di "+componiPersona);
                            
                            setJsonRicevuta([]);
                            if (valoreDiRitorno.risultatoRitorno[0].JSON_RICEVUTA) {
                                if (valoreDiRitorno.risultatoRitorno[0].JSON_RICEVUTA!="") setJsonRicevuta(JSON.parse(valoreDiRitorno.risultatoRitorno[0].JSON_RICEVUTA));
                            }

                            ricercaDatiRisorse();                    
                            ricercaDatiVociDiBilancio();

                            // per passare dallo stato di ricerca a quello di modifica:
                            setStatoRicercaDatiQuoteIscritto(true);
                            setStatoTendinaIscritti(true);
                            
                            //ricercaDatiIscritti();        
                        }
                    },
                    (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                ); 
            }else{
                setStatoPagina(STATO_RICERCA_DATI);
                ricercaDatiRisorse();
                ricercaDatiVociDiBilancio();
                ricercaDatiIscritti();
                setStatoRicercaDatiQuoteIscritto(true);
            }
        }
    },[props.ope,operazioneInterna]);

    function copiaMovimento(){
        if (window.confirm("Sei sicuro di voler procedere con la copia dell' incasso selezionato?")) {
            setDisabilitaBottoni(true);
            setStatoPagina(STATO_RICERCA_DATI);
            setOperazioneInterna("copia");
        }
    }

    function callbacksetValoriVoceBilancio(valore,posizione,valoreNonVisibile){
        setInsVociDiBilancioEdi(valore);
        setInsVociDiBilancioAttEdi(valoreNonVisibile);
    }

    // verifica se stringa rappresenta uno e un solo iscritto, con ricerca in risultatoServerDatiIscritti che deve essere ordinato alfabeticamente
    function iscrittoEsistenteUnivoco(stringa) {        
        var cognomeNome="";
        var tessera="";
        var posizioneParentesiAperta = stringa.indexOf("(");
        if (posizioneParentesiAperta > 0) {
            var posizioneParentesiChiusa = stringa.indexOf(")");
            cognomeNome = stringa.substring(0,posizioneParentesiAperta).trim();
            if (posizioneParentesiChiusa > 0) tessera = stringa.substring(posizioneParentesiAperta+1, posizioneParentesiChiusa);
            else tessera = stringa.substring(posizioneParentesiAperta+1);
        } else {
            cognomeNome = stringa;
        }
        cognomeNome = cognomeNome.toUpperCase().trim();
        tessera = tessera.toUpperCase().trim();        

        //console.log("Ricerca se '"+cognomeNome+ "' con tessera '"+tessera+"' è iscritto esistente univoco")

        var cognomeNomeTrovati = 0;
        for (var indice=0; indice<risultatoServerDatiIscritti.length; indice++) {
            var cognomeNomeCfr = (risultatoServerDatiIscritti[indice].COGNOME+" "+risultatoServerDatiIscritti[indice].NOME).toUpperCase().trim();
            var tesseraCfr = risultatoServerDatiIscritti[indice].NUMERO_TESSERA.toUpperCase().trim();
            if (cognomeNome < cognomeNomeCfr) break;
            if (cognomeNome == cognomeNomeCfr) {
                if (tessera == tesseraCfr) return true;
                cognomeNomeTrovati++;                
            }
        }
        if (cognomeNomeTrovati==1) return true;
        return false;
    }

    function scaricaLogo() {
        //console.log("scaricaLogo");
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {          
              if(valoreDiRitorno.risultatoOperazione===1){
                  if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                    setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                    setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                    setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                    setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                    setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                    setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                    setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
                  }
                  setRicercaLogo(1);
              }
          },
          (error) => {console.log("Errore connessione");}
        );        
    }

    function ricercaDatiDovutoQuoteIscritto(persona) {
        //ricerco il saldo delle quote dovute:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"ricercaDovutoQuoteIscritto",persona:persona,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica (e.incassiabbass.1)");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    //console.log("ricevo " + valoreDiRitorno.risultatoRitorno.QUOTA_ABBONAMENTO)
                    setRisultatoServerDatiDovutoQuoteIscritto(valoreDiRitorno.risultatoRitorno);
                    ricercaDatiVersatoQuoteIscritto(persona);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }    
    function ricercaDatiVersatoQuoteIscritto(persona) {
        //ricerco il saldo delle quote versate:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"ricercaVersatoQuoteIscritto",persona:persona,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica (e.incassiabbass.2)");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    setRisultatoServerDatiVersatoQuoteIscritto(valoreDiRitorno.risultatoRitorno);
                    ricercaRiferimentiIscritto(persona);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaRiferimentiIscritto(persona) {
        //ricerco il saldo delle quote versate:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoRiferimenti",persona:persona,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica (e.incassiabbass.3)");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    setElencoRiferimenti(valoreDiRitorno.risultatoRitorno);
                    setStatoRicercaDatiQuoteIscritto(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    
    function ricercaDatiRisorse() {
        //ricerco le risorse che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoRisorse",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiRisorse(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaRisorse(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaDatiIscritti() {
        if(props.idIscritto){
            //l'iscritto è gia selezionato e fissato per cui:
            let componiPersona="";
            if(props.persona !== undefined) {
                componiPersona = props.persona; 
            } else {
                var flagRichiestoSpazio = false;
                if(props.cognome !== undefined && props.cognome !== "") {
                    componiPersona += props.cognome;
                    flagRichiestoSpazio = true;
                }
                if(props.nome !== undefined && props.nome !== "") {
                    if (flagRichiestoSpazio) componiPersona += " ";
                    componiPersona += props.nome;
                    flagRichiestoSpazio = true;
                }
                if(props.tessera !== undefined && props.tessera !== "") {
                    if (flagRichiestoSpazio) componiPersona += " ";
                    componiPersona += "(" + props.tessera + ")";
                }
            }
            
            setInsIscrittiEdi(componiPersona);      
            if(props.tipo=="abbonamento") setPlaceholderDescrizione("Quota Abbonamento" + " di "+componiPersona);
            else setPlaceholderDescrizione("Quota Associativa" + " di "+componiPersona);      
            
            setStatoTendinaIscritti(true);

            setImportoDefault(props.importo);
        }else{
            //ricerco gli iscritti che mi servono per popolare le tendine:
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"elencoPersone",ridotto:1,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
            };
    
            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                (valoreDiRitorno) => {
                    setLoading(false);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    setRisultatoServerDatiIscritti(valoreDiRitorno.risultatoRitorno);
                    setStatoTendinaIscritti(true);

                    }
                },
                (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
        }
    }
    function ricercaDatiVociDiBilancio() {
        //ricerco le voci di bilancio che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoVociDiBilancio",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiVocidiBilancio(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaVocidiBilancio(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function callbackSetValoreTendEditabileIscritti(valore){
        setInsIscrittiEdi(valore);
        
        setIndiceRiferimentoSelezionato(-1);

        if(props.tipo=="abbonamento") setPlaceholderDescrizione("Quota Abbonamento" + " di "+valore);
        else setPlaceholderDescrizione("Quota Associativa" + " di "+valore);
        //setPlaceholderDescrizione("Quota " + props.tipo=="abbonamento"?"Abbonamento":"Associativa" + " di "+valore);
    }

    function tornaElenco(eseguitoModifica=0,idIscritto=0) {
        //eseguitoModifica e idIscritto servono per quando arrivi da SchedaIscritto
        props.callbackTermineModifica(eseguitoModifica,idIscritto);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }
    function annullaInserimentoDatiNuovi(){
        setRichiediDistinzCognomeNome(0);
        setPermessoInserirePersona(0);
        setPermessoInserireRisorsa(0);
        setPermessoInserireVoceDiBilancio(0);

        setFlagAttivaModaleRichiediPermessoInserire(0);
        setFlagAttivaModaleRichiediData(0);
        setFlagAttivaModaleRichiediRisorsa(0);
        setFlagAttivaModaleRichiediVoceBilancio(0);
        setFlagAttivaModaleRichiediImporto(0);
        setFlagAttivaModaleRichiediNumeroTessera(0);
        setFlagAttivaModaleInserisciIscritto(0);
        setFlagAttivaModaleImportoErrato(0);
        setRicevuta(0);
        setFineIscritti(0);
        //setStatoPagina(STATO_INSERISCI);
    }

    function confermaInserimentoDatiNuovi(){
        setPermessoInserirePersona(1);
        setPermessoInserireRisorsa(1);
        setPermessoInserireVoceDiBilancio(1);
        //setStatoPagina(STATO_INSERISCI);
    }
    function fetchOperazioneServer(bypassaControlli=0,presentaRicevuta=0,permessoInserire) {
        setFlagAttivaModaleRichiediPermessoInserire(0);
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;

        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(insIscrittiEdi=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciIscritto(1);
            }
            if(importo.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleRichiediImporto(1);
            }
            if(dataPagamento.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleRichiediData(1);
            }
            if(insRisorsaEdi=== ''){
                controlli = 1;
                setFlagAttivaModaleRichiediRisorsa(1);
            }
            if(insVociDiBilancioEdi=== ''){
                controlli = 1;
                setFlagAttivaModaleRichiediVoceBilancio(1);
            }
            if(isNaN(FormattaImportoDb(importo.current.value))){
                controlli = 1;
                setFlagAttivaModaleImportoErrato(1);
            }
        }
        if(controlli===0){ 
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
             
                tornaElenco();
            }else{
                // parametri per inviare i dati al server:
                let cog="";
                if(richiediDistinzCognomeNome===1) cog = cognome.current.value;
                setLoading(true);
                // generaRicevuta: è props.tipo che può essere "abbonamento" o "associativa"
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ?  
                    JSON.stringify({op:operazione,
                        risorsa:insRisorsaEdi,
                        persona:insIscrittiEdi,
                        cognome:cog,
                        permessoInserirePersona:permessoInserire,
                        permessoInserireRisorsa:permessoInserire,
                        permessoInserireVoceDiBilancio:permessoInserire,
                        tipoQuota:props.tipo=="abbonamento"?"2":"1",
                        vociDiBilancio:insVociDiBilancioEdi,
                        descrizione:descrizione.current.value,
                        note:note.current.value,
                        numeroFattura:numeroFattura.current.value,
                        IVA:IVADefault,
                        importo:FormattaImportoDb(importo.current.value),
                        dataPagamento:dataPagamento.current.value,
                        idElemento:props.id,
                        cognomeRiferimentoSelezionato:indiceRiferimentoSelezionato>=0?elencoRiferimenti[indiceRiferimentoSelezionato].COGNOME:"",
                        nomeRiferimentoSelezionato:indiceRiferimentoSelezionato>=0?elencoRiferimenti[indiceRiferimentoSelezionato].NOME:"",
                        codiceFiscaleRiferimentoSelezionato:indiceRiferimentoSelezionato>=0?elencoRiferimenti[indiceRiferimentoSelezionato].CODICE_FISCALE:"",
                        generaRicevuta: props.tipo,
                        jsonRicevuta: jsonRicevuta,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1){
                            if(presentaRicevuta === 0){
                                if(props.idIscritto) tornaElenco(1,props.idIscritto);
                                else tornaElenco(1);
                            }else{
                                setJsonRicevuta(valoreDiRitorno.jsonRicevuta);
                                setStatoPagina(STATO_PREPARA_PDF)
                            }
                        }
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Indicare numero di tessera per individuazione") !== -1){
                                    setFlagAttivaModaleRichiediNumeroTessera(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire iscritto") !== -1){
                                    setPermessoInserirePersona(-1);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Indica quale è cognome e quale nome") !== -1){
                                    setPermessoInserirePersona(-1);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                    setRichiediDistinzCognomeNome(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Reindica quale è cognome e quale nome") !== -1){
                                    setPermessoInserirePersona(-1);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                    setRichiediDistinzCognomeNome(2);
                                } 
                        
                                if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire risorse") !== -1){
                                    setPermessoInserireRisorsa(-1);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire voci di bilancio") !== -1){
                                    setPermessoInserireVoceDiBilancio(-1);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Data pagamento obbligatoria") !== -1){
                                    setFlagAttivaModaleRichiediData(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Risorsa obbligatoria") !== -1){
                                    setFlagAttivaModaleRichiediRisorsa(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Voce bilancio obbligatoria") !== -1){
                                    setFlagAttivaModaleRichiediVoceBilancio(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Importo pagamento obbligatorio") !== -1){
                                    setFlagAttivaModaleRichiediImporto(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Indica titolare movimento") !== -1){
                                    setFlagAttivaModaleInserisciIscritto(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Importo errato") !== -1){
                                    setFlagAttivaModaleImportoErrato(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Iscritti Terminati") !== -1){
                                    setFineIscritti(1);
                                }
                            }
                            //altrimenti vado in errore generico
                            if(!valoreDiRitorno.mess) setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    function RiepilogoQuote() {
        var etichetta = "";
        var importoDovutoQuoteSelezionate = 0;
        var importoVersatoQuoteSelezionate = 0;
        var importoDovutoTotaleQuote = 0;
        var importoVersatoTotaleQuote = 0;

        if (props.tipo=="abbonamento") {
            etichetta = "abbonamento";
            if (risultatoServerDatiDovutoQuoteIscritto.QUOTA_ABBONAMENTO!==undefined) importoDovutoQuoteSelezionate=risultatoServerDatiDovutoQuoteIscritto.QUOTA_ABBONAMENTO;
            if (risultatoServerDatiVersatoQuoteIscritto.QUOTA_ABBONAMENTO!==undefined) importoVersatoQuoteSelezionate=risultatoServerDatiVersatoQuoteIscritto.QUOTA_ABBONAMENTO;
        }
        if (props.tipo=="associativa") {
            etichetta = "associative";
            if (risultatoServerDatiDovutoQuoteIscritto.QUOTA_ASSOCIATIVA!==undefined) importoDovutoQuoteSelezionate=risultatoServerDatiDovutoQuoteIscritto.QUOTA_ASSOCIATIVA;
            if (risultatoServerDatiVersatoQuoteIscritto.QUOTA_ASSOCIATIVA!==undefined) importoVersatoQuoteSelezionate=risultatoServerDatiVersatoQuoteIscritto.QUOTA_ASSOCIATIVA;            
        }
        if (risultatoServerDatiDovutoQuoteIscritto.QUOTA_ABBONAMENTO!==undefined) importoDovutoTotaleQuote+=risultatoServerDatiDovutoQuoteIscritto.QUOTA_ABBONAMENTO;
        if (risultatoServerDatiVersatoQuoteIscritto.QUOTA_ABBONAMENTO!==undefined) importoVersatoTotaleQuote+=risultatoServerDatiVersatoQuoteIscritto.QUOTA_ABBONAMENTO;
        if (risultatoServerDatiDovutoQuoteIscritto.QUOTA_ASSOCIATIVA!==undefined) importoDovutoTotaleQuote+=risultatoServerDatiDovutoQuoteIscritto.QUOTA_ASSOCIATIVA;
        if (risultatoServerDatiVersatoQuoteIscritto.QUOTA_ASSOCIATIVA!==undefined) importoVersatoTotaleQuote+=risultatoServerDatiVersatoQuoteIscritto.QUOTA_ASSOCIATIVA;            

        var saldoQuote = importoVersatoQuoteSelezionate - importoDovutoQuoteSelezionate;
        var saldoTotaleQuote = importoVersatoTotaleQuote - importoDovutoTotaleQuote;

        return (
            <>
                <div className='schedaSottotitolo'>
                    Riepilogo Quote
                </div>
                <Card className={ingrigisciSpecchiettoQuote}>
                    <Row form>
                        <Col md={12}>
                            <Table responsive>
                                <thead>
                                    <tr><th>Dovuto quote {etichetta} dell'iscritto: {FormattaImportoVideo(importoDovutoQuoteSelezionate)}</th></tr>  
                                    <tr><th>Versato quote {etichetta} dell'iscritto: {FormattaImportoVideo(importoVersatoQuoteSelezionate)}</th></tr>  

                                    {saldoQuote >0 ? <tr><th style={{color: coloreVerde}}>Credito quote {etichetta} dell'iscritto: {FormattaImportoVideo(saldoQuote)}</th></tr> :""}
                                    {saldoQuote <0 ? <tr><th style={{color: coloreRosso}}>Debito quote {etichetta} dell'iscritto: {FormattaImportoVideo(-saldoQuote)}</th></tr> :""}

                                    <tr><th>Dovuto totale quote dell'iscritto: {FormattaImportoVideo(importoDovutoTotaleQuote)}</th></tr>                                    
                                    <tr><th>Versato totale quote dell'iscritto: {FormattaImportoVideo(importoVersatoTotaleQuote)}</th></tr>

                                    {saldoTotaleQuote >0 ? <tr><th style={{color: coloreVerde}}>Credito totale quote dell'iscritto: {FormattaImportoVideo(saldoTotaleQuote)}</th></tr> :""}
                                    {saldoTotaleQuote <0 ? <tr><th style={{color: coloreRosso}}>Debito totale quote dell'iscritto: {FormattaImportoVideo(-saldoTotaleQuote)}</th></tr> :""}
                                </thead>
                            </Table>
                        </Col>
                    </Row>
                </Card> 
            </>            
        )
    }

    var stringaIscrittoResidente1="";
    var stringaIscrittoResidente2="";
    var stringaIscrittoNascita="";
    var stringaRiferimentoResidente1="";
    var stringaRiferimentoResidente2="";    
    var stringaRiferimentoNascita="";
    
    // costruzione delle stringhe per la ricevuta PDF
    if (statoPagina === STATO_SCARICA_PDF) {
        if (jsonRicevuta.iscritto.INDIRIZZO != "") stringaIscrittoResidente1 = "Residente in " + jsonRicevuta.iscritto.INDIRIZZO;

        if (jsonRicevuta.iscritto.CAP != "" || jsonRicevuta.iscritto.CITTA || jsonRicevuta.iscritto.PROVINCIA) {
            if (jsonRicevuta.iscritto.CAP != "") stringaIscrittoResidente2 += jsonRicevuta.iscritto.CAP;
            if (jsonRicevuta.iscritto.CITTA != "") {
                if (stringaIscrittoResidente2 != "") stringaIscrittoResidente2 += ", ";
                stringaIscrittoResidente2 += jsonRicevuta.iscritto.CITTA;
            }
            if (jsonRicevuta.iscritto.PROVINCIA != "") {
                if (stringaIscrittoResidente2 != "") stringaIscrittoResidente2 += " (" + jsonRicevuta.iscritto.PROVINCIA + ")";
                else stringaIscrittoResidente2 += "Provincia " + jsonRicevuta.iscritto.PROVINCIA;
            }
        }

        if (jsonRicevuta.iscritto.LUOGO_NASCITA != "" && jsonRicevuta.iscritto.DATA_NASCITA) {
            stringaIscrittoNascita = "Nato/a a " + jsonRicevuta.iscritto.LUOGO_NASCITA + " il " + TrasformaDateAUmano({"data":jsonRicevuta.iscritto.DATA_NASCITA});
        }

        // il riferimento può non esserci nel json
        if (jsonRicevuta.riferimento) {            
            if (jsonRicevuta.riferimento.INDIRIZZO != "") stringaRiferimentoResidente1 = "Residente in " + jsonRicevuta.riferimento.INDIRIZZO;
            
            if (jsonRicevuta.riferimento.CAP != "" || jsonRicevuta.riferimento.CITTA || jsonRicevuta.riferimento.PROVINCIA) {
                if (jsonRicevuta.riferimento.CAP != "") stringaRiferimentoResidente2 += jsonRicevuta.riferimento.CAP;
                if (jsonRicevuta.riferimento.CITTA != "") {
                    if (stringaRiferimentoResidente2 != "") stringaRiferimentoResidente2 += ", ";
                    stringaRiferimentoResidente2 += jsonRicevuta.riferimento.CITTA;
                }
                if (jsonRicevuta.riferimento.PROVINCIA != "") {
                    if (stringaRiferimentoResidente2 != "") stringaRiferimentoResidente2 += " (" + jsonRicevuta.riferimento.PROVINCIA + ")";
                    else stringaRiferimentoResidente2 += "Provincia " + jsonRicevuta.riferimento.PROVINCIA;
                }
            }
            
            if (jsonRicevuta.riferimento.LUOGO_NASCITA != "" && jsonRicevuta.riferimento.DATA_NASCITA) {
                stringaRiferimentoNascita = "Nato/a a " + jsonRicevuta.riferimento.LUOGO_NASCITA + " il " + TrasformaDateAUmano({"data":jsonRicevuta.riferimento.DATA_NASCITA});
            }    
        }
    }

    return (        
        <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}>
            {statoPagina === STATO_SCARICA_PDF ? 
                    <div>
                    {/*<PDFViewer style={styles.viewer}>*/}
                    <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
                        <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
                        <Document>
                        <Page size="A4" style={styles.page}>
                            <View style={{width:"100%"}} >
                                <View style={styles.tableIntestazione}>{/*apre table intestazione*/}
                                    <View wrap={false} style={styles.tableRowIntestazione}> 
                                        <View style={styles.tableColIntestazioneSx}> 
                                            <View style={styles.tableCell}> 
                                                {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                                            </View> 
                                        </View> 
                                        <View style={styles.tableColIntestazioneDx}>   
                                            <View style={styles.tableCellIntestazione}> 
                                                {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                                                
                                                {indirizzoSocieta !== "" ?
                                                    <Text style={styles.datisocieta}>{indirizzoSocieta}
                                                    {cittaSocieta !== "" ?
                                                        capSocieta !== "" ?
                                                            " "+capSocieta+" "+cittaSocieta
                                                        :" "+cittaSocieta
                                                    :""}
                                                    </Text>
                                                :<Text></Text>}
                                                {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                                                {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                                                
                                                <Text style={styles.titolo}>
                                                    {jsonRicevuta.titolo}
                                                    {props.persona ? <View style={{width:"100%"}} ><Text> da {props.persona}</Text></View> : ""}
                                                </Text>
                                            </View> 
                                        </View> 
                                    </View> 
                                </View>{/*chiude table intestazione*/}

                                <View style={styles.tableNoBordo}>{/*apre table dati*/}
                                    <View style={styles.rigaRicevuta}> 
                                        <Text style={styles.testoGrande}>{jsonRicevuta.ricevente}</Text>
                                    </View>  

                                    {jsonRicevuta.riferimento &&
                                        <>
                                            <View style={styles.rigaRicevutaCentrata}> 
                                                <Text style={styles.testoGrassetto}>{jsonRicevuta.riferimento.COGNOME} {jsonRicevuta.riferimento.NOME}</Text>
                                                {jsonRicevuta.riferimento.CODICE_FISCALE != "" && <Text style={styles.testoNormale10}>Codice fiscale {jsonRicevuta.riferimento.CODICE_FISCALE}</Text>}
                                                {stringaRiferimentoResidente1 != "" && <Text style={styles.testoNormale10}>{stringaRiferimentoResidente1}</Text>}
                                                {stringaRiferimentoResidente2 != "" && <Text style={styles.testoNormale10}>{stringaRiferimentoResidente2}</Text>}
                                                {stringaRiferimentoNascita != "" && <Text style={styles.testoNormale10}>{stringaRiferimentoNascita}</Text>}
                                            </View> 
                                            <View style={styles.rigaRicevuta}>
                                                <Text style={styles.testoGrande}>per l'iscritto</Text>
                                            </View>
                                        </>
                                    }

                                    <View style={styles.rigaRicevutaCentrata}> 
                                        <Text style={styles.testoGrassetto}>{jsonRicevuta.iscritto.COGNOME} {jsonRicevuta.iscritto.NOME}</Text>
                                        {jsonRicevuta.iscritto.CODICE_FISCALE != "" && <Text style={styles.testoNormale10}>Codice fiscale {jsonRicevuta.iscritto.CODICE_FISCALE}</Text>}
                                        {stringaIscrittoResidente1 != "" && <Text style={styles.testoNormale10}>{stringaIscrittoResidente1}</Text>}
                                        {stringaIscrittoResidente2 != "" && <Text style={styles.testoNormale10}>{stringaIscrittoResidente2}</Text>}
                                        {stringaIscrittoNascita != "" && <Text style={styles.testoNormale10}>{stringaIscrittoNascita}</Text>}                                           
                                        {jsonRicevuta.iscritto.NUMERO_TESSERA != "" && <Text style={styles.testoNormale10}>Tessera n. {jsonRicevuta.iscritto.NUMERO_TESSERA}</Text>}                                        
                                    </View>

                                    <View style={styles.rigaRicevuta}> 
                                        <Text style={styles.testoGrande}>la somma pari a</Text>
                                    </View> 

                                    <View style={styles.rigaRicevutaCentrata}> 
                                        <Text style={styles.testoGrassetto}>{FormattaImportoVideo(jsonRicevuta.importo)} euro</Text>
                                    </View>                                     

                                    <View style={styles.rigaRicevuta}> 
                                        <Text style={styles.testoGrande}>per</Text>
                                    </View> 

                                    <View style={styles.rigaRicevutaCentrata}> 
                                        <Text style={styles.testoGrassetto}>{jsonRicevuta.descrizione}</Text>
                                    </View>

                                    {jsonRicevuta.note != "" && 
                                        <View style={styles.rigaRicevuta}> 
                                            <Text style={styles.testoGrande}>Note: {jsonRicevuta.note}</Text>
                                        </View>                                     
                                    }

                                    <View style={styles.rigaRicevutaCentrata}> 
                                        <Text style={styles.testoNormale10}>{jsonRicevuta.finale}</Text>
                                    </View>                                                               

                                </View>

                                <View style={styles.tabellaDataFirma}>
                                    <View style={styles.tabellaDataFirmaRow}>
                                        <View style={styles.tabellaDataFirmaCol}>
                                            <Text style={styles.tabellaDataFirmaCellHeader}>DATA</Text>
                                            <Text style={styles.tabellaDataFirmaCellData}><TrasformaDateAUmano data={jsonRicevuta.data}/></Text>
                                        </View>
                                        <View style={styles.tabellaDataFirmaCol}>
                                            <Text style={styles.tabellaDataFirmaCellHeader}>FIRMA</Text>
                                            <View style={styles.rigaPerFirma} />
                                        </View>
                                    </View>
                                </View>        

                            </View>

                        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                        </Page>
                    </Document>
                    </PDFViewer>
                </div>

            :""}
            
            {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
            {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1C_PriNotQABB {error.message}</div> :""}
            {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
            {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
            {statoPagina === STATO_PREPARA_PDF ?   <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

            {statoPagina === STATO_RICERCA_DATI_CORRETTA || statoPagina === STATO_SCARICA_PDF ||statoPagina === STATO_PREPARA_PDF  ?
            <div style={{display:statoPagina === STATO_SCARICA_PDF?"none":"block"}}>
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                    
                    {flagAttivaModaleRichiediPermessoInserire===1 ?
                    <Modale 
                    titolo="Richiesta permesso inserimento"
                    flagErrore={true}
                    larghezza="lg"
                    contenuto={<div>

                        {permessoInserirePersona===-1 ? <>Vuoi davvero inserire {insIscrittiEdi} nell'elenco degli iscritti?<br/></> :""}
                        {permessoInserireRisorsa===-1 ? <>Vuoi davvero inserire {insRisorsaEdi} nell'elenco delle risorse?<br/></> :""}
                        {permessoInserireVoceDiBilancio===-1 ?<>Vuoi davvero inserire {insVociDiBilancioEdi} nell'elenco delle voci di bilancio?<br/><br/>Inizialmente la nuova voce di bilancio verrà impostata come attività principale.<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagAttivaModaleSpiegaAttivita(1)}/><br/> E' possibile modificare l'impostazione recandosi in Gestione Economica, Voci di Bilancio, selezionare la voce desiderata e attuare le modifiche.<br/></> :""}

                        {richiediDistinzCognomeNome===1 && permessoInserirePersona!==-1 ? <>Indica qual'è il Cognome dell'Iscritto che intendi inserire:<br/></> :""} 
                        {richiediDistinzCognomeNome===2 && permessoInserirePersona!==-1 ? <>Indicazione del Cognome non ritenuta valida. Indicare nuovamente qual'è il Cognome dell'Iscritto che intendi inserire:<br/></> :""}
                    
                        {(richiediDistinzCognomeNome===1 || richiediDistinzCognomeNome===2) && permessoInserirePersona!==-1?
                            <FormGroup>
                            <Label for="cognome">Cognome</Label>
                            <Input  maxLength={50} type="text" name="cognome" id="cognome"
                                    innerRef={cognome} 
                                        placeholder=""
                                    defaultValue={insIscrittiEdi}
                            /> 
                            </FormGroup>
                        :""}
                    </div>}      
                    bottoni={
                            [{
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setDisabilitaBottoni(false);confermaInserimentoDatiNuovi()}
                            },  
                            {
                            "etichetta":"Annulla",
                            "tipo":"primary",
                            callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }] 
                    }
                    />
                :""}

                {fineIscritti=== 1 ?
                        <Modale 
                        titolo="Informativa numero massimo iscritti"
                        flagErrore={true}
                        contenuto={<div>E' stato raggiunto il numero massimo di iscritti</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                    :""}


                {flagAttivaModaleRichiediNumeroTessera===1 ?
                    <Modale 
                        titolo="Richiesta numero tessera"
                        flagErrore={true}
                        contenuto={<div>Nel database ci sono più iscritti con il Cognome ed il Nome: {insIscrittiEdi} Si prega di indicare il numero di tessera in modo da poter individuare quello desiderato oppure per procedere con l'inserimento di un nuovo iscritto qualora il numero di tessera non corrispondesse a nessuno degli iscritti individuati</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleRichiediData===1 ?
                    <Modale 
                        titolo="Richiesta data di pagamento"
                        flagErrore={true}
                        contenuto={<div>Non è stata indicata la data di pagamento, che è necessaria</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleRichiediVoceBilancio===1 ?
                    <Modale 
                        titolo="Richiesta voce di bilancio"
                        flagErrore={true}
                        contenuto={<div>Non è stata indicata la voce di bilancio, che è necessaria</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleRichiediRisorsa===1 ?
                    <Modale 
                        titolo="Richiesta risorsa"
                        flagErrore={true}
                        contenuto={<div>Non è stata indicata la risorsa, che è necessaria</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleRichiediImporto===1 ?
                    <Modale 
                        titolo="Richiesta iscritto"
                        flagErrore={true}
                        contenuto={<div>Non è stato indicato l'importo, che è necessario</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleInserisciIscritto===1 ?
                    <Modale 
                        titolo="Richiesta iscritto"
                        flagErrore={true}
                        contenuto={<div>Non è stato indicato l'iscritto, che è necessario</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleImportoErrato=== 1 ?
                    <Modale 
                        titolo="Informativa importo errato"
                        flagErrore={true}
                        contenuto={<div>L'importo inserito per l'importo non è un numero: completare il campo con un valore numerico</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                :""}
                {flagAttivaModaleSpiegaAttivita=== 1 ?
                        <Modale 
                        titolo="Informativa Attività Principali / Secondarie"
                        flagErrore={false}
                        larghezza="lg"
                        contenuto={<div><p>La classificazione di una voce di bilancio come attività principale dipende dalla sua natura.</p> 
                            <p>Per attività principale si intende tutto ciò che è connesso direttamente alle attività sportive ed alle responsabilità istituzionali.
                            L'attivita principale coinvolge i ruoli fondamentali per la pratica sportiva stessa, come istruttori, allenatori, arbitri, tecnici e atleti.
                            Inoltre riguarda tutto ciò che è indispensabile allo svolgimento delle attività sportive: attrezzature, materiali sportivi e strutture adeguate ecc..</p>
                            <p>Sono invece da includere nelle attività secondarie tutto ciò che non è da considerarsi indispensabile per la pratica sportiva diretta.
                            Si tratta spesso di attività legate all’organizzazione e al funzionamento delle strutture sportive.
                            Ad esempio: addetti alla manutenzione degli impianti, addetti alla reception, gestione dell'area ristoro o del merchandising sportivo ecc.. </p>
                            <p>La distinzione tra le attività è utile per adempiere alle norme relative alla Riforma dello Sport.</p>
                            Il <b>report "Attività Principali / Secondarie"</b>, disponibile nella sezione Report, può essere un utile strumento per confrontare il volume di attività 
                            principali e secondarie. 
                            <p>Fornisce inoltre un'analisi dettagliata del rapporto tra attività secondarie e ricavi totali, nonché tra attività secondarie e costi totali.</p>
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);setFlagAttivaModaleSpiegaAttivita(0)}
                            }    
                        ]}
                        />
                    :""}

                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                        {props.ope === "inserisci" ? <CardTitle>Inserimento Incasso Quota {props.tipo=="abbonamento"?"Abbonamento":"Associativa"}</CardTitle> :""}
                        {props.ope === "modifica" ? 
                            operazioneInterna === 'copia' ?
                                <CardTitle>Inserimento Incasso Quota {props.tipo=="abbonamento"?"Abbonamento":"Associativa"}</CardTitle> 
                            :
                                <CardTitle>Modifica Incasso Quota {props.tipo=="abbonamento"?"Abbonamento":"Associativa"}</CardTitle>
                        :""}
                
                        
                        <Clessidra loading={loading}/>

                        {props.ope === "cancella" ?
                            <FinestraDialogo 
                                titolo="Conferma eliminazione"
                                flagErrore={true}                                
                                contenuto={<div>
                                    Confermi l'eliminazione dell'incasso della quota {props.tipo=="abbonamento"?"Abbonamento":"Associativa"} di <b>{insIscrittiEdi}</b> con importo <b>{FormattaImportoVideo(importoDefault)}</b> euro e data pagamento <b><TrasformaDateAUmano data={dataPagamentoDefault}></TrasformaDateAUmano></b> ?<br/> 
                                </div>}
                                bottoni={[
                                    {
                                        "etichetta":"Conferma",
                                        "tipo":"primary",
                                        callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                    },
                                    {
                                        "etichetta":"Annulla",
                                        "tipo":"primary",
                                        callback: () => {tornaElenco()}
                                    }
                                ]}
                            />                        

                        :
                            <>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            {props.ope === "inserisci" && props.idIscritto ? <Label>Iscritto: {insIscrittiEdi}</Label> : <Label>Iscritto*</Label>}
                                        
                                            {props.ope === "inserisci" && !(props.idIscritto) ? 
                                            <TendinaEditabile
                                                titolo={""}
                                                nomeCampo="COGNOME"
                                                nomeCampo2="NOME"
                                                nomeCampoParentesi="NUMERO_TESSERA"
                                                valoreDefault={insIscrittiEdi}
                                                arrayValori={risultatoServerDatiIscritti}
                                                callbackSetVariazioneCampi={setVariazioneCampi}
                                                callbackSetValoreTendEditabile={callbackSetValoreTendEditabileIscritti}
                                            ></TendinaEditabile> 
                                            :""}
                                            {props.ope === "modifica" ? <Input readOnly={true} value={insIscrittiEdi}/> : ""}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form> 
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="descrizione">Descrizione (causale del pagamento che compare in ricevuta)</Label>                                          
                                            <Input readOnly={props.ope !== "inserisci"  && operazioneInterna === ''} maxLength={200} type="text" name="descrizione" id="descrizione"
                                                innerRef={descrizione} 
                                                placeholder={placeholderDescrizione} 
                                                defaultValue={descrizioneDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value)}}
                                            />  
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form> 
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="importo">Importo*</Label>                                            
                                            <Input readOnly={props.ope !== "inserisci" && operazioneInterna === ''} maxLength={12} type="text" name="importo" id="importo"
                                                innerRef={importo} 
                                                placeholder=""
                                                defaultValue={FormattaImportoVideo(importoDefault,1)}
                                                onInput={(e)=>{handleCambiamentoMod(e);setImportoDefault(importo.current.value);setIngrigisciSpecchiettoQuote("main-card mb-3-opaca");}}
                                            /> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="dataPagamento">Data Pagamento*</Label>
                                            <Input readOnly={props.ope !== "inserisci" && operazioneInterna === ''} maxLength={10} type="date" name="dataPagamento" id="dataPagamento"
                                                innerRef={dataPagamento} 
                                                placeholder=""
                                                defaultValue={dataPagamentoDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataPagamentoDefault(dataPagamento.current.value)}}
                                            />  
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={6}>
                                    <FormGroup>
                                    <Label>Risorsa*</Label>   
                                    <TendinaEditabile
                                        //titolo={"Risorsa*"}
                                        nomeCampo="DESCRIZIONE"
                                        valoreDefault={insRisorsaEdi}
                                        arrayValori={risultatoServerDatiRisorse}
                                        callbackSetVariazioneCampi={setVariazioneCampi}
                                        callbackSetValoreTendEditabile={setInsRisorsaEdi}
                                    ></TendinaEditabile>
                                    </FormGroup>
                                    </Col>
                                    <Col md={6}><FormGroup>
                                    <label htmlFor="voce">
                                        Voce di Bilancio*
                                        {insVociDiBilancioAttEdi === '1' 
                                            ?  <> (attività principale<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagAttivaModaleSpiegaAttivita(1)}/>)</>
                                            : insVociDiBilancioAttEdi === '0' 
                                            ? <> (attività secondaria<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagAttivaModaleSpiegaAttivita(1)}/>)</>
                                            : ''
                                        }
                                    </label>
                                    <TendinaEditabile
                                        //titolo={"Voce di Bilancio*"}
                                        nomeCampo="DESCRIZIONE"
                                        nomeCampoNonVisibile = "TIPO_ATTIVITA"
                                        valoreDefault={insVociDiBilancioEdi}
                                        arrayValori={risultatoServerDatiVocidiBilancio}
                                        callbackSetVariazioneCampi={setVariazioneCampi}
                                        callbackSetValoreTendEditabile={callbacksetValoriVoceBilancio} 
                                    ></TendinaEditabile>
                                    </FormGroup>
                                    </Col>                                    
                                </Row>
                                
                                {(props.ope === "inserisci" || operazioneInterna === 'copia') && elencoRiferimenti.length > 0 &&
                                    <Row form> 
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="riferimento">Pagamento effettuato da (ad es. in caso di iscritto minorenne, per generare ricevuta a chi paga)*</Label>
                                                <br/>
                                                <select className="inputStandard" value={indiceRiferimentoSelezionato} onChange={(e) => {handleCambiamentoMod(e);setIndiceRiferimentoSelezionato(e.target.value)}}>
                                                    <option value={-1}>L'iscritto stesso</option>
                                                    {elencoRiferimenti.map((elemento, indice) => <option key={indice} value={indice}>{elemento.COGNOME+" "+elemento.NOME}</option>)}
                                                </select>                                                

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }

                                {props.ope === "modifica" && operazioneInterna === '' && jsonRicevuta.riferimento &&
                                    <Row form> 
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="riferimento">
                                                    Pagamento effettuato da (ad es. in caso di iscritto minorenne, per generare ricevuta a chi paga)*: {jsonRicevuta.riferimento.COGNOME} {jsonRicevuta.riferimento.NOME}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }

                                <Row form> 
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="numeroFattura">Numero Fattura (se presente, genera fattura elettronica)</Label>                                          
                                            <Input readOnly={props.ope !== "inserisci" && operazioneInterna === ''} maxLength={200} type="text" name="numeroFattura" id="numeroFattura"
                                                innerRef={numeroFattura}                                                 
                                                defaultValue={numeroFatturaDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setNumeroFatturaDefault(numeroFattura.current.value)}}
                                            />  
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="iva">IVA (necessaria per fatt. elettroniche)</Label>                                          
                                                <select disabled={props.ope !== "inserisci" && operazioneInterna === ''} className="inputStandard" value={IVADefault} onChange={(e) => {handleCambiamentoMod(e);setIVADefault(e.target.value)}}>
                                                    <option value={"N1"}>0% escluse ex art. 15</option>
                                                    <option value={"N2.1"}>0% non soggette ad IVA ai sensi degli artt. da 7 a 7-septies del DPR 633/72</option>
                                                    <option value={"N2.2"}>0% non soggette - altri casi</option>
                                                    <option value={"N3.1"}>0% non imponibili - esportazioni</option>
                                                    <option value={"N3.2"}>0% non imponibili - cessioni intracomunitarie</option>
                                                    <option value={"N3.3"}>0% non imponibili - cessioni verso San Marino</option>
                                                    <option value={"N3.4"}>0% non imponibili - operazioni assimilate alle cessioni all'esportazione</option>
                                                    <option value={"N3.5"}>0% non imponibili - a seguito di dichiarazioni d'intento</option>
                                                    <option value={"N3.6"}>0% non imponibili - altre operazioni che non concorrono alla formazione del plafond</option>
                                                    <option value={"N4"}>0% esenti</option>
                                                    <option value={"N5"}>0% regime del margine / IVA non esposta in fattura</option>
                                                    <option value={"N6.1"}>Reverse charge - cessione di rottami e altri materiali di recupero</option>
                                                    <option value={"N6.2"}>Reverse charge - cessione di oro e argento</option>
                                                    <option value={"N6.3"}>Reverse charge - subappalto nel settore edile</option>
                                                    <option value={"N6.4"}>Reverse charge - cessione di fabbricati</option>
                                                    <option value={"N6.5"}>Reverse charge - cessione di telefoni cellulari</option>
                                                    <option value={"N6.6"}>Reverse charge - cessione di prodotti elettronici</option>
                                                    <option value={"N6.7"}>Reverse charge - prestazioni comparto edile e settori connessi</option>
                                                    <option value={"N6.8"}>Reverse charge - operazioni settore energetico</option>
                                                    <option value={"N6.9"}>Reverse charge - altri casi</option>
                                                    <option value={"N7"}>IVA assolta in altro Stato UE</option>
                                                    <option value={"4"}>4%</option>
                                                    <option value={"5"}>5%</option>
                                                    <option value={"10"}>10%</option>
                                                    <option value={"22"}>22%</option>
                                                </select> 
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form> 
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="note">Note Pagamento</Label>                                          
                                                <Input maxLength={200} type="text" name="note" id="note"
                                                innerRef={note}                                                 
                                                defaultValue={noteDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setNoteDefault(note.current.value)}}/>  
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {props.ope!=="inserisci" && operazioneInterna === '' && <>Non sono modificabili i campi che vengono usati per generare ricevute e fatture elettroniche. In caso di errore, cancellare il pagamento e inserirlo nuovamente.</>}

                                {statoRicercaDatiQuoteIscritto ? <RiepilogoQuote /> : <></> }
                            </>
                        }
                            
                        
                    </div>

                    <div className="schedaSpazioInferiore">
                        {props.ope === "inserisci" || operazioneInterna === 'copia'  ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci senza ricevuta</Button> :""}
                        {props.ope === "inserisci" || operazioneInterna === 'copia'   ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer(0,1);setRicevuta(1)}}>Inserisci con ricevuta</Button> :""}
                        {props.ope === "modifica" && variazioneCampi === 1 && operazioneInterna === ''? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                        
                        {props.ope === "modifica" && variazioneCampi === 0 && operazioneInterna === ''? 
                            <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {copiaMovimento()}}>Copia Incasso</Button>
                        :""}

                        <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => tornaElenco()} >
                        {(props.ope === "inserisci" && props.idIscritto) || operazioneInterna === 'copia' ? 
                            props.labelRitorno && props.labelRitorno !== "" ?
                                <Fragment>{props.labelRitorno}</Fragment> 
                            :
                                <Fragment>Annulla inserimento incasso</Fragment> 
                        :
                        (variazioneCampi === 1 && props.ope === "modifica" && operazioneInterna === '') ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>
                        }
                        </Button>

                        {(variazioneCampi === 0 && props.ope === "modifica" && jsonRicevuta.iscritto && operazioneInterna === '') ?
                            <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);setStatoPagina(STATO_PREPARA_PDF)}}>Stampa ricevuta</Button>
                        :""}
                    </div>
                </div>
            </div>
            :""}
            
        </div>
    );
}
export default SchedaIncassiAbbAss;
