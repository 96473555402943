import React, { useState, useEffect } from 'react';
import { convertiDataComputer2Umano } from './funzioni.js'
import TrasformaDateAUmano from '../Pagine/TrasformaDateAUmano.js';
import FormattaImportoVideo from '../Pagine/FormattaImportoVideo.js';
import Clessidra from '../Pagine/Clessidra'
import stile from './stile.module.css';
import { Page, Text, View, Document, Image, pdf } from '@react-pdf/renderer';
import styles from '../Pagine/stiliPdf.js'

function Movimenti(props) {
    const PAGINE = {
        "caricamentoDati": 1,
        "elencoMovimenti": 2,
    };
    const [pagina, setPagina] = useState(PAGINE.caricamentoDati);
    const [elencoMovimenti, setElencoMovimenti] = useState([]);
    const [importoTotale, setImportoTotale] = useState(0);
    const [jsonRicevuta, setJsonRicevuta] = useState([]);

    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    function scaricaLogo() {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
        };
    
        fetch(props.UrlBackend, requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {          
              if(valoreDiRitorno.risultatoOperazione===1){
                  if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                    setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                    setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                    setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                    setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                    setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                    setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                    setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
                  }
                  setPagina(PAGINE.elencoMovimenti);
              }
          },
          (error) => {console.log("Errore connessione");}
        );        
    }

    const DocumentoPDF = ({ jsonRicevuta }) => {
        var stringaIscrittoResidente1="";        
        var stringaIscrittoResidente2="";
        var stringaIscrittoNascita="";
        var stringaRiferimentoResidente1="";
        var stringaRiferimentoResidente2="";    
        var stringaRiferimentoNascita="";

        if (jsonRicevuta.iscritto.INDIRIZZO != "") stringaIscrittoResidente1 = "Residente in " + jsonRicevuta.iscritto.INDIRIZZO;

        if (jsonRicevuta.iscritto.CAP != "" || jsonRicevuta.iscritto.CITTA || jsonRicevuta.iscritto.PROVINCIA) {
            if (jsonRicevuta.iscritto.CAP != "") stringaIscrittoResidente2 += jsonRicevuta.iscritto.CAP;
            if (jsonRicevuta.iscritto.CITTA != "") {
                if (stringaIscrittoResidente2 != "") stringaIscrittoResidente2 += ", ";
                stringaIscrittoResidente2 += jsonRicevuta.iscritto.CITTA;
            }
            if (jsonRicevuta.iscritto.PROVINCIA != "") {
                if (stringaIscrittoResidente2 != "") stringaIscrittoResidente2 += " (" + jsonRicevuta.iscritto.PROVINCIA + ")";
                else stringaIscrittoResidente2 += "Provincia " + jsonRicevuta.iscritto.PROVINCIA;
            }
        }

        if (jsonRicevuta.iscritto.LUOGO_NASCITA != "" && jsonRicevuta.iscritto.DATA_NASCITA) {
            stringaIscrittoNascita = "Nato/a a " + jsonRicevuta.iscritto.LUOGO_NASCITA + " il " + TrasformaDateAUmano({"data":jsonRicevuta.iscritto.DATA_NASCITA});
        }

        // il riferimento può non esserci nel json
        if (jsonRicevuta.riferimento) {            
            if (jsonRicevuta.riferimento.INDIRIZZO != "") stringaRiferimentoResidente1 = "Residente in " + jsonRicevuta.riferimento.INDIRIZZO;
            
            if (jsonRicevuta.riferimento.CAP != "" || jsonRicevuta.riferimento.CITTA || jsonRicevuta.riferimento.PROVINCIA) {
                if (jsonRicevuta.riferimento.CAP != "") stringaRiferimentoResidente2 += jsonRicevuta.riferimento.CAP;
                if (jsonRicevuta.riferimento.CITTA != "") {
                    if (stringaRiferimentoResidente2 != "") stringaRiferimentoResidente2 += ", ";
                    stringaRiferimentoResidente2 += jsonRicevuta.riferimento.CITTA;
                }
                if (jsonRicevuta.riferimento.PROVINCIA != "") {
                    if (stringaRiferimentoResidente2 != "") stringaRiferimentoResidente2 += " (" + jsonRicevuta.riferimento.PROVINCIA + ")";
                    else stringaRiferimentoResidente2 += "Provincia " + jsonRicevuta.riferimento.PROVINCIA;
                }
            }
            
            if (jsonRicevuta.riferimento.LUOGO_NASCITA != "" && jsonRicevuta.riferimento.DATA_NASCITA) {
                stringaRiferimentoNascita = "Nato/a a " + jsonRicevuta.riferimento.LUOGO_NASCITA + " il " + TrasformaDateAUmano({"data":jsonRicevuta.riferimento.DATA_NASCITA});
            }    
        }

        return (
          <Document>
            <Page size="A4" orientation="portrait" style={styles.page}>
                <View style={{width:"100%"}} >
                    <View style={styles.tableIntestazione}>{/*apre table intestazione*/}
                        <View wrap={false} style={styles.tableRowIntestazione}> 
                            <View style={styles.tableColIntestazioneSx}> 
                                <View style={styles.tableCell}> 
                                    {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                                </View> 
                            </View> 
                            <View style={styles.tableColIntestazioneDx}>   
                                <View style={styles.tableCellIntestazione}> 
                                    {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                                    
                                    {indirizzoSocieta !== "" ?
                                        <Text style={styles.datisocieta}>{indirizzoSocieta}
                                        {cittaSocieta !== "" ?
                                            capSocieta !== "" ?
                                                " "+capSocieta+" "+cittaSocieta
                                            :" "+cittaSocieta
                                        :""}
                                        </Text>
                                    :<Text></Text>}
                                    {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                                    {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                                    
                                    <Text style={styles.titolo}>
                                        {jsonRicevuta.titolo}
                                        {props.persona ? <View style={{width:"100%"}} ><Text> da {props.persona}</Text></View> : ""}
                                    </Text>
                                </View> 
                            </View> 
                        </View> 
                    </View>{/*chiude table intestazione*/}

                    <View style={styles.tableNoBordo}>{/*apre table dati*/}
                        <View style={styles.rigaRicevuta}> 
                            <Text style={styles.testoGrande}>{jsonRicevuta.ricevente}</Text>
                        </View>  

                        {jsonRicevuta.riferimento &&
                            <>
                                <View style={styles.rigaRicevutaCentrata}> 
                                    <Text style={styles.testoGrassetto}>{jsonRicevuta.riferimento.COGNOME} {jsonRicevuta.riferimento.NOME}</Text>
                                    {jsonRicevuta.riferimento.CODICE_FISCALE != "" && <Text style={styles.testoNormale10}>Codice fiscale {jsonRicevuta.riferimento.CODICE_FISCALE}</Text>}
                                    {stringaRiferimentoResidente1 != "" && <Text style={styles.testoNormale10}>{stringaRiferimentoResidente1}</Text>}
                                    {stringaRiferimentoResidente2 != "" && <Text style={styles.testoNormale10}>{stringaRiferimentoResidente2}</Text>}
                                    {stringaRiferimentoNascita != "" && <Text style={styles.testoNormale10}>{stringaRiferimentoNascita}</Text>}
                                </View> 
                                <View style={styles.rigaRicevuta}>
                                    <Text style={styles.testoGrande}>per l'iscritto</Text>
                                </View>
                            </>
                        }

                        <View style={styles.rigaRicevutaCentrata}> 
                            <Text style={styles.testoGrassetto}>{jsonRicevuta.iscritto.COGNOME} {jsonRicevuta.iscritto.NOME}</Text>
                            {jsonRicevuta.iscritto.CODICE_FISCALE != "" && <Text style={styles.testoNormale10}>Codice fiscale {jsonRicevuta.iscritto.CODICE_FISCALE}</Text>}
                            {stringaIscrittoResidente1 != "" && <Text style={styles.testoNormale10}>{stringaIscrittoResidente1}</Text>}
                            {stringaIscrittoResidente2 != "" && <Text style={styles.testoNormale10}>{stringaIscrittoResidente2}</Text>}
                            {stringaIscrittoNascita != "" && <Text style={styles.testoNormale10}>{stringaIscrittoNascita}</Text>}                                           
                            {jsonRicevuta.iscritto.NUMERO_TESSERA != "" && <Text style={styles.testoNormale10}>Tessera n. {jsonRicevuta.iscritto.NUMERO_TESSERA}</Text>}                                        
                        </View>

                        <View style={styles.rigaRicevuta}> 
                            <Text style={styles.testoGrande}>la somma pari a</Text>
                        </View> 

                        <View style={styles.rigaRicevutaCentrata}> 
                            <Text style={styles.testoGrassetto}>{FormattaImportoVideo(jsonRicevuta.importo)} euro</Text>
                        </View>                                     

                        <View style={styles.rigaRicevuta}> 
                            <Text style={styles.testoGrande}>per</Text>
                        </View> 

                        <View style={styles.rigaRicevutaCentrata}> 
                            <Text style={styles.testoGrassetto}>{jsonRicevuta.descrizione}</Text>
                        </View>

                        {jsonRicevuta.note != "" && 
                            <View style={styles.rigaRicevuta}> 
                                <Text style={styles.testoGrande}>Note: {jsonRicevuta.note}</Text>
                            </View>                                     
                        }

                        <View style={styles.rigaRicevutaCentrata}> 
                            <Text style={styles.testoNormale10}>{jsonRicevuta.finale}</Text>
                        </View>                                                               

                    </View>

                    <View style={styles.tabellaDataFirma}>
                        <View style={styles.tabellaDataFirmaRow}>
                            <View style={styles.tabellaDataFirmaCol}>
                                <Text style={styles.tabellaDataFirmaCellHeader}>DATA</Text>
                                <Text style={styles.tabellaDataFirmaCellData}><TrasformaDateAUmano data={jsonRicevuta.data}/></Text>
                            </View>
                            <View style={styles.tabellaDataFirmaCol}>
                                <Text style={styles.tabellaDataFirmaCellHeader}>FIRMA</Text>
                                <View style={styles.rigaPerFirma} />
                            </View>
                        </View>
                    </View>        

                </View>
            </Page>
          </Document>
        )
    };

    async function generaRicevutaPDF(jsonRicevuta) {
        const blob = await pdf(<DocumentoPDF jsonRicevuta={jsonRicevuta} />).toBlob(); // Convert the document to a Blob
        const url = URL.createObjectURL(blob); // Create a temporary URL
        const link = document.createElement("a"); // Create a link element
        link.href = url; // Assign the Blob URL
        link.download = "Ricevuta"; // Set the download filename
        document.body.appendChild(link); // Append the link to the document
        link.click(); // Simulate a click to trigger the download
        document.body.removeChild(link); // Clean up
        URL.revokeObjectURL(url); // Release the memory        
    }

    function scaricaElencoMovimenti() {
        setPagina(PAGINE.caricamentoDati);
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "restituisciSituazioneInsolvente", emak: props.emak, sessionId: props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    setElencoMovimenti(valore_di_ritorno.movimenti);
                    for (var i=0; i<valore_di_ritorno.totali.length; i++) {
                        if (valore_di_ritorno.totali[i].DATA=="Totale Quote (Associative + Abbonamento)") setImportoTotale(valore_di_ritorno.totali[i].IMPORTO);
                    }
                    scaricaLogo();                    
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );                                 
    }

    function scaricaRicevuta(id) {
        setPagina(PAGINE.caricamentoDati);
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "scaricaRicevuta", id:id, emak: props.emak, sessionId: props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    
                    generaRicevutaPDF(valore_di_ritorno.jsonRicevuta);
                    setPagina(PAGINE.elencoMovimenti);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );                                 
    }

    useEffect(() => {
        scaricaElencoMovimenti();
    }, [])
    
    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            props.callback(props.PAGINECLIENTI.home);
        } 
    }, [props.flagRichiestaBack])  

    if (pagina === PAGINE.caricamentoDati) {
        return (<Clessidra loading={true} />)
    }

    if (pagina === PAGINE.elencoMovimenti) {
        return (        
            <div style={{overflowY:'auto'}}>
                <div className={stile.movimentiElencoContenitoreElemento}>
                    <div className={importoTotale < 0 ? stile.movimentiTestoImportoNegativo : stile.movimentiTestoImportoPositivo}>
                        {
                            importoTotale < 0 ? "DEBITO " + props.identitaSelezionata.cognome + " " + props.identitaSelezionata.nome + ":\n" + (-importoTotale) + " € da pagare" :
                            importoTotale > 0 ? "CREDITO " + props.identitaSelezionata.cognome + " " + props.identitaSelezionata.nome + ":\n" + importoTotale + " € già pagati" :
                            "Non hai debiti o crediti"
                        }                        
                    </div>
                </div>
    
                {elencoMovimenti.length > 0 ? (
                    <div>
                        <div className={stile.movimentiSpazioTitoloDettagli}>
                            <div className={stile.movimentiTestoTitoloDettagli}>Dettaglio</div>
                        </div>
    
                        {elencoMovimenti.map((x, i) => 
                            <div key={i} className={stile.movimentiElencoContenitoreElemento}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div style={{flex: 1, justifyContent: 'center', paddingRight: 2}}>
                                        <div className={stile.movimentiTestoData}>{convertiDataComputer2Umano(x.DATA)}</div>
                                    </div>
                                    <div style={{flex: 1, justifyContent: 'center', paddingLeft: 2}}>
                                        <div className={x.IMPORTO < 0 ? stile.movimentiTestoImportoNegativo : stile.movimentiTestoImportoPositivo}>
                                            {
                                                x.IMPORTO < 0 ? "Pagare " + (-x.IMPORTO) + " €" :
                                                x.IMPORTO > 0 ? "Pagato " + x.IMPORTO + " €" :
                                                x.IMPORTO + " €"
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div style={{paddingTop: 5}}>
                                    <div className={stile.movimentiTestoOperazione}>{x.OPERAZIONE}</div>
                                </div>
                                {x.IMPORTO > 0 && <div className={stile.movimentiBottoneScaricaRicevuta} onClick={() => scaricaRicevuta(x.ID)}>Scarica Ricevuta</div>}
                            </div>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        );
    }    

    return (
        <div>Errore pagina non trovata (Movimenti)</div>
    )
}

export default Movimenti;
