import React, {Fragment,useState,useEffect,Suspense} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button } from 'reactstrap';
import Parametri from '../../parametri';
import FormGridFormRow from '../SchedaEnteConvenzione/FormGridFormRow';
import TabellaRicerca from '../tabellaricerca.js';
import Clessidra from '../Clessidra.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

import Modale from '../Modale.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
const STATO_VAI_IN_ELIMINA= 5;
const STATO_VAI_IN_INSERISCI= 6;


function TabellaEntiConvenzioni(props) {
    
    const operazione = "elencoEntiConvenzioni";
      
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const [idModifica,setIdModifica]=useState('');
    const [flagModaleInfoPopolamento, setFlagModaleInfoPopolamento] = React.useState(false);
    
    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Denominazione',
        accessor: 'COGNOME',
        pdf: 1,
      },
      {
        etichetta: 'Descrizione',
        accessor: 'NOME',
        pdf: 1,
      },
      {
        etichetta: 'Cod. Fiscale',
        accessor: 'CODICE_FISCALE',
        pdf: 1,
      },
      {
        etichetta: 'P.IVA',
        accessor: 'P_IVA',
        pdf: 1,
      },
      {
        etichetta: 'Città',
        accessor: 'CITTA',
        pdf: 1,
      },
      {
        etichetta: 'Indirizzo',
        accessor: 'INDIRIZZO',
        pdf: 1,
      },
      {
        etichetta: 'Prov.',
        accessor: 'PROVINCIA',
        pdf: 1,
      },
      {
        etichetta: 'Email',
        accessor: 'EMAIL',
        pdf: 1,
      },
      {
        etichetta: 'Sito Web',
        accessor: 'SITO_WEB',
     
      },
      {
        etichetta: 'Cellulare',
        accessor: 'CELLULARE',
        pdf: 1,
      },
      {
        etichetta: 'Tel. Fisso',
        accessor: 'TELEFONO',
        pdf: 1,
      },
      {
        etichetta: 'Note',
        accessor: 'NOTE',
     
      },
    ];

    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function VaiElimina(id) {
      setStatoPagina(STATO_VAI_IN_ELIMINA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }
    function tornaElenco() {
      setFlagModaleInfoPopolamento(false);
    }

    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
              setRisultatoServer(valoreDiRitorno.risultatoRitorno);setStatoPagina(STATO_OPERAZIONE_CORRETTA);}
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])

  
  return (
      <div>

      {statoPagina === STATO_VAI_IN_INSERISCI ? 
        <div>
        <Suspense fallback={
          <div className="loader-container">
          <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda ente convenzione in corso...</h6></div>
          </div>
        }>
        <FormGridFormRow {...props} ope={"inserisci"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
        </Suspense>
      </div>
      :""} 

      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda ente convenzione in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_VAI_IN_ELIMINA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda ente convenzione in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"cancella"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_EnConv</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
          {flagModaleInfoPopolamento=== true ?
                    <Modale 
                        larghezza = "lg"
                        titolo="Informativa Enti Convenzioni"
                        flagErrore={false}
                        contenuto={<div>Questo ambiente consente di gestire gli enti che andranno a popolare la tendina Ente Convenzione nell'area Sponsor / Convenzioni - Convenzioni.<br></br><br></br>
                          Durante l'inserimento o la modifica di una convenzione, è possibile creare direttamente un nuovo ente. <br></br>
                          Successivamente, sarà possibile completare qui l'operazione aggiungendo i campi mancanti non inseriti nella fase iniziale.<br></br><br></br>
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {tornaElenco()}
                            }    
                        ]}
                    />
                :""}
          <CardBody>
            <CardTitle>Elenco Enti Convenzioni</CardTitle>
            <Button color="success" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo</Button>
            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoPopolamento(true)}/>
          </CardBody>
          {risultatoServer !== undefined && risultatoServer !== "" ?
            <TabellaRicerca 
            titolo={`Elenco Enti Convenzioni`} 
            columns={colonne} data={risultatoServer} campoChiave={0} campoOrdinamento={1} nascondiColonna={0} callbackSelezionaElemento={VaiModifica} callbackEliminaElemento={VaiElimina}/>
          : 
            <Table responsive bordered hover striped className="mb-0">
            <thead>
              <tr>
                <th>Nessun dato presente</th>
              </tr>
            </thead>
            </Table>
          }
        </div>
      :""}
    </div>
   );
}
export default TabellaEntiConvenzioni;