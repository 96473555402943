import React, {useEffect, useState} from 'react';
import Clessidra from '../Pagine/Clessidra'
import stile from './stile.module.css';
import SelezionaData from './SelezionaData';
import Tendina2 from './Tendina2';
import { convertiDataComputer2Umano } from './funzioni.js'

// metti una modale per scegliere una sola persona nel report risultati

function ReportEventi(props) {
    const PAGINE = {
        "caricamentoDati": 1,
        "selezione": 2,
        "esito": 3,
    }    
    const [pagina, setPagina] = useState(PAGINE.caricamentoDati);
    const [dataInizio, setDataInizio] = useState("");
    const [dataFine, setDataFine] = useState("");
    const [elencoTipiEventi, setElencoTipiEventi] = useState([]);
    const [elencoIdTipiEventi, setElencoIdTipiEventi] = useState([]);
    const [flagIdTipiEventiSelezionati, setFlagIdTipiEventiSelezionati] = useState([]);
    const [elencoGruppi, setElencoGruppi] = useState([]);
    const [elencoIdGruppi, setElencoIdGruppi] = useState([]);
    const [flagIdGruppiSelezionati, setFlagIdGruppiSelezionati] = useState([]);
    const [esitoReport, setEsitoReport] = useState([]);

    const [elencoNomiPersone, setElencoNomiPersone] = useState([]);
    const [elencoIdPersone, setElencoIdPersone] = useState([]);
    const [idPersonaSelezionata, setIdPersonaSelezionata] = useState(-1);
    const [nomePersonaSelezionata, setNomePersonaSelezionata] = useState("");

    const opzioniOrdinamento = ["crescente per risultato","decrescente per risultato","crescente per data","decrescente per data","nessuno"];
    const [opzioneOrdinamento, setOpzioneOrdinamento] = useState(opzioniOrdinamento[0]);

    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            props.setPagina(props.PAGINE.menuEventi);            
        } 
    }, [props.flagRichiestaBack])

    function scaricaElenchi() {
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "elencoTipiEventi", emak: props.emak, sessionId: props.sessionId })
        };
        setPagina(PAGINE.caricamentoDati);
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    var desct=[];
                    var idt=[];
                    var flagt=[];
                    for (let i=0; i<valore_di_ritorno.risultatoRitorno.length; i++) {
                        desct.push(valore_di_ritorno.risultatoRitorno[i]["DESCRIZIONE"]);
                        idt.push(valore_di_ritorno.risultatoRitorno[i]["ID"]);
                        flagt.push(false); // se vuoi accenderli di default, metti qui true
                    }
                    setElencoTipiEventi(desct);
                    setElencoIdTipiEventi(idt);
                    setFlagIdTipiEventiSelezionati(flagt);
                   
                    if (props.report==="presenze"||props.report==="risultati") {
                        if (props.identitaSelezionata.flag_staff==1) {
                            requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ op: "elencoGruppi", emak: props.emak, sessionId: props.sessionId })
                            };
                            fetch(props.UrlBackend, requestOptions)
                                .then(risposta => risposta.json())
                                .then(
                                    (valore_di_ritorno) => {
                                        if (valore_di_ritorno.verifvers === 0) {
                                            // necessario aggiornare la app
                                            props.callbackAggiornaApp();
                                            return;
                                        }
                                        if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                                        if (valore_di_ritorno.risultatoOperazione !== 1) { 
                                            window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                                            props.callback(props.PAGINECLIENTI.login);
                                            return; 
                                        }
                                        var desct=[];
                                        var idt=[];
                                        var flagt=[];
                                        for (let i=0; i<valore_di_ritorno.risultatoRitorno.length; i++) {
                                            desct.push(valore_di_ritorno.risultatoRitorno[i]["DESCRIZIONE"]);
                                            idt.push(valore_di_ritorno.risultatoRitorno[i]["ID"]);
                                            flagt.push(false); // se vuoi accenderli di default, metti qui true
                                        }
                                        setElencoGruppi(desct);
                                        setElencoIdGruppi(idt);
                                        setFlagIdGruppiSelezionati(flagt);
                                        setPagina(PAGINE.selezione);
                                    },
                                    (error) => {
                                        props.callback(props.PAGINECLIENTI.login);
                                        return;
                                    }
                                );
                        } else {
                            // non si seleziona la persona, perché è utente normale, quindi vede solo i suoi risultati
                            setPagina(PAGINE.selezione);
                        }
                    }

                    if (props.report==="risultatiSingolo") {
                        if (props.identitaSelezionata.flag_staff==1) {
                            // serve l'elenco delle persone
                            requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ op: "elencoPersone", "ridotto":1, emak: props.emak, sessionId: props.sessionId })
                            };
                            fetch(props.UrlBackend, requestOptions)
                                .then(risposta => risposta.json())
                                .then(
                                    (valore_di_ritorno) => {
                                        if (valore_di_ritorno.verifvers === 0) {
                                            // necessario aggiornare la app
                                            props.callbackAggiornaApp();
                                            return;
                                        }
                                        if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                                        if (valore_di_ritorno.risultatoOperazione !== 1) { 
                                            window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                                            props.callback(props.PAGINECLIENTI.login);
                                            return; 
                                        }
                                        var localElencoNomi = [], localElencoId = [], nomeCompleto;
                                        for (var i=0; i<valore_di_ritorno.risultatoRitorno.length; i++) {
                                            localElencoId.push(valore_di_ritorno.risultatoRitorno[i].ID);
                                            nomeCompleto = "";
                                            if (valore_di_ritorno.risultatoRitorno[i].COGNOME!=undefined) nomeCompleto = valore_di_ritorno.risultatoRitorno[i].COGNOME + " ";
                                            if (valore_di_ritorno.risultatoRitorno[i].NOME!=undefined) nomeCompleto += valore_di_ritorno.risultatoRitorno[i].NOME;
                                            localElencoNomi.push(nomeCompleto);
                                        }
                                        setElencoIdPersone(localElencoId);
                                        setElencoNomiPersone(localElencoNomi);
                                        setPagina(PAGINE.selezione);
                                    },
                                    (error) => {
                                        props.callback(props.PAGINECLIENTI.login);
                                        return;
                                    }
                                );
                        } else {
                            // non si seleziona la persona, perché è utente normale, quindi vede solo i suoi risultati
                            setPagina(PAGINE.selezione);
                        }
                    }

                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function scaricaReport(tipoReport) {
        var elencoIdGruppiSelezionati="";
        var elencoIdTipiEventiSelezionati="";
        var flagVirgola;

        flagVirgola=false;
        for (var i=0; i<elencoIdTipiEventi.length; i++) {
            if (flagIdTipiEventiSelezionati[i]) {
                if (flagVirgola) elencoIdTipiEventiSelezionati+=","; else flagVirgola=true;
                elencoIdTipiEventiSelezionati+=elencoIdTipiEventi[i];
            }
        }

        flagVirgola=false;
        for (var i=0; i<elencoIdGruppi.length; i++) {
            if (flagIdGruppiSelezionati[i]) {
                if (flagVirgola) elencoIdGruppiSelezionati+=","; else flagVirgola=true;
                elencoIdGruppiSelezionati+=elencoIdGruppi[i];
            }
        }

        //console.log("Tipi selezionati: "+elencoIdTipiEventiSelezionati);
        //console.log("Gruppi selezionati: "+elencoIdGruppiSelezionati);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: tipoReport==="presenze"?"eventiReportPresenze":"eventiReportRisultati",
                dataInizio: dataInizio,
                dataFine: dataFine,
                elencoIdTipiEventiSelezionati: elencoIdTipiEventiSelezionati,
                elencoIdGruppiSelezionati: props.identitaSelezionata.flag_staff==1 ? elencoIdGruppiSelezionati : "",
                opzioneOrdinamento: opzioneOrdinamento,
                idPersonaSelezionata: props.identitaSelezionata.flag_staff==1 ? idPersonaSelezionata : props.identitaSelezionata.idpersona,
                emak: props.emak, 
                sessionId: props.sessionId
            })
        };
        setPagina(PAGINE.caricamentoDati);
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    setEsitoReport(valore_di_ritorno.esito);
                    setPagina(PAGINE.esito);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    useEffect(() => { scaricaElenchi(); }, [])

    if (pagina===PAGINE.caricamentoDati) {
        return (<Clessidra loading={true} />)
    }

    if (pagina === PAGINE.selezione) {
        return (
            <div className={stile.view} style={{ flex: 1, padding: 10 }}>
                <div style={{ flex: 1 }}>
    
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className={stile.eventiModificaTestoTitoli} style={{ paddingBottom: 5 }}>
                            {props.identitaSelezionata.flag_staff === 1
                                ? props.report === "presenze"
                                    ? "Report Presenze Atleti"
                                    : props.report === "risultati"
                                    ? "Report Risultati Atleti"
                                    : "Report Risultati Singolo Atleta"
                                : props.report === "presenze"
                                ? "Report Presenze"
                                : "Report Risultati"}
                        </div>
                    </div>
    
                    {props.report === "risultatiSingolo" && props.identitaSelezionata.flag_staff === 1 ? (
                        <Tendina2
                            className={stile.genericaDropdown}
                            options={elencoNomiPersone}
                            defaultValue={nomePersonaSelezionata === "" ? " " : nomePersonaSelezionata}
                            onSelect={(t) => {
                                setNomePersonaSelezionata(elencoNomiPersone[t]);
                                setIdPersonaSelezionata(elencoIdPersone[t]);
                            }}
                        />
                    ) : null}
    
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                            <div className={stile.eventiModificaTestoTitoli}>Dal giorno</div>
                            <SelezionaData data={dataInizio} setData={setDataInizio} titolo="dal giorno" />
                        </div>
                        <div style={{ flex: 1, marginLeft: 5 }}>
                            <div className={stile.eventiModificaTestoTitoli}>Al giorno</div>
                            <SelezionaData data={dataFine} setData={setDataFine} titolo="al giorno" />
                        </div>
                    </div>
    
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className={stile.eventiModificaTestoTitoli}>Tipo di evento</div>
                        <div className={stile.eventiConvocatiScrollView}>
                            {elencoTipiEventi.map((p, i) => (
                                <div
                                    key={i}
                                    className={flagIdTipiEventiSelezionati[i]
                                        ? stile.eventiConvocareSpazioElementoSelezionato
                                        : stile.eventiConvocareSpazioElemento}
                                    onClick={() => {
                                        var es = [...flagIdTipiEventiSelezionati];
                                        es[i] = !es[i];
                                        setFlagIdTipiEventiSelezionati(es);
                                    }}
                                >
                                    <img
                                        className={stile.eventiIconaCerchio}
                                        src={require(flagIdTipiEventiSelezionati[i] ? './cerchio_ok.png' : './cerchio_vuoto.png')}
                                        alt="Icona"
                                    />
                                    <div className={stile.eventiConvocatiNome}>{p}</div>
                                </div>
                            ))}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div
                                className={`${stile.bottone5} ${stile.bottoneColoreNormale}`}
                                onClick={() => {
                                    var p = [];
                                    for (let i = 0; i < flagIdTipiEventiSelezionati.length; i++) p[i] = true;
                                    setFlagIdTipiEventiSelezionati(p);
                                }}
                            >
                                <div className={stile.bottoneTestoNormale}>Selez. Tutti</div>
                            </div>
                            <div
                                className={`${stile.bottone5} ${stile.bottoneColoreNormale}`}
                                onClick={() => {
                                    var p = [];
                                    for (let i = 0; i < flagIdTipiEventiSelezionati.length; i++) p[i] = false;
                                    setFlagIdTipiEventiSelezionati(p);
                                }}
                            >
                                <div className={stile.bottoneTestoNormale}>Selez. Nessuno</div>
                            </div>
                        </div>
                    </div>
    
                    {props.report === "risultati" || props.report === "risultatiSingolo" ? (
                        <>
                            <div className={stile.eventiModificaTestoTitoli} style={{ paddingTop: 5 }}>
                                Ordinamento risultati:
                            </div>
                            <Tendina2
                                className={stile.genericaDropdown}
                                options={opzioniOrdinamento}
                                defaultValue={opzioneOrdinamento}
                                onSelect={(t) => {
                                    setOpzioneOrdinamento(opzioniOrdinamento[t]);
                                }}
                            />
                        </>
                    ) : null}
    
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <div
                            className={`${stile.bottone3} ${stile.bottoneColoreVerde}`}
                            onClick={() => {
                                if (
                                    props.report === "risultatiSingolo" &&
                                    nomePersonaSelezionata === "" &&
                                    props.identitaSelezionata.flag_staff === 1
                                )
                                    return;
                                setPagina(PAGINE.caricamentoDati);
                                if (props.report === "presenze") scaricaReport("presenze");
                                else scaricaReport("risultati");
                            }}
                        >
                            <div className={stile.spazioIconaPiccola}>
                                <img className={stile.iconaPiccolaQuadrata} src={require('./ok.png')} alt="OK" />
                            </div>
                            <div className={stile.bottoneTestoNormale}>GENERA REPORT</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    if (pagina === PAGINE.esito) {
        if (esitoReport.length === 0) {
            return (
                <>
                    <div className={stile.eventiModificaTestoTitoli} style={{ paddingTop: 5 }}>
                        {props.report === "presenze"
                            ? "Nessuna presenza ad eventi secondo i criteri impostati, verifica le impostazioni e genera un nuovo report"
                            : "Non sono stati trovati risultati secondo i criteri impostati, verifica le impostazioni e genera un nuovo report"}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <div
                            className={`${stile.bottone3} ${stile.bottoneColoreVerde}`}
                            onClick={() => {
                                setPagina(PAGINE.selezione);
                            }}
                        >
                            <div className={stile.spazioIconaPiccola}>
                                <img className={stile.iconaPiccolaQuadrata} src={require('./ok.png')} alt="OK" />
                            </div>
                            <div className={stile.bottoneTestoNormale}>OK</div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <div style={{ flex: 1, padding: 10 }}>
                    <div className={`${stile.eventiConvocareSpazioElemento}`} style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ flex: 2, color: "#404080", fontSize: 16, fontWeight: 'bold' }}>Atleta</div>
                        <div style={{ flex: 1, color: "#404080", fontSize: 16, fontWeight: 'bold' }}>Presenze</div>
                    </div>
                    {esitoReport.map((v, i) => (
                        <div key={i} className={`${stile.eventiConvocareSpazioElemento}`} style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flex: 2, color: "#404080", fontSize: 16 }}>
                                {v.COGNOME} {v.NOME}
                            </div>
                            <div style={{ flex: 1, color: "#404080", fontSize: 16 }}>{v.PRESENZE}</div>
                        </div>
                    ))}
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <div
                            className={`${stile.bottone3} ${stile.bottoneColoreVerde}`}
                            onClick={() => {
                                setPagina(PAGINE.selezione);
                            }}
                        >
                            <div className={stile.spazioIconaPiccola}>
                                <img className={stile.iconaPiccolaQuadrata} src={require('./ok.png')} alt="OK" />
                            </div>
                            <div className={stile.bottoneTestoNormale}>OK</div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    

    return (
        <div>Errore pagina non trovata (ReportEventi)</div>
    )

}

export default ReportEventi;
