import React, {Fragment,useState,useEffect,Suspense} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button } from 'reactstrap';
import Parametri from '../../parametri';

import FormGridFormRow from '../SchedaAllenamento/FormGridFormRow';

import TabellaRicerca from '../tabellaricerca.js';

import Clessidra from '../../Pagine/Clessidra.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import Modale from '../Modale.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
const STATO_VAI_IN_ELIMINA= 5;
const STATO_VAI_IN_INSERISCI= 6;


function TabellaSchedeAllenamento(props) {
    
    const operazione = "elencoSchedeAllenamento";
      
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const [idModifica,setIdModifica]=useState('');
    const [flagModaleInfoPopolamento, setFlagModaleInfoPopolamento] = React.useState(false);
    
    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Nome della Scheda',
        accessor: 'DESCRIZIONE',
        pdf: 1,
      },
      {
        etichetta: 'Note',
        accessor: 'NOTE_SCHEDA',
        pdf: 1,
      },
    ];

    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function VaiElimina(id) {
      setStatoPagina(STATO_VAI_IN_ELIMINA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }
    function tornaElenco() {
      setFlagModaleInfoPopolamento(false);
    }
    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,standard:1,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServer(valoreDiRitorno.risultatoRitorno);setStatoPagina(STATO_OPERAZIONE_CORRETTA);}
              },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])

  return (
      <div>
  
      {statoPagina === STATO_VAI_IN_INSERISCI ? 
        <div>
        <Suspense fallback={
          <div className="loader-container">
          <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda allenamento in corso...</h6></div>
          </div>
        }>
        <FormGridFormRow {...props} ope={"inserisci"} tipo={"standard"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
        </Suspense>
      </div>
      :""} 

      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda allenamento in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} tipo={"standard"} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_VAI_IN_ELIMINA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda allenamento in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} tipo={"standard"} ope={"cancella"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_ScAll</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
            {flagModaleInfoPopolamento=== true ?
                    <Modale 
                        larghezza = "lg"
                        titolo="Informativa schede allenamento predefinite"
                        flagErrore={false}
                        contenuto={<div>
                          Questo ambiente consente di creare, modificare ed eliminare schede di allenamento predefinite, componendole con gli esercizi definiti nell’area Allenamenti - Esercizi.
                          Le scheda predefinite sarenno disponibili all'assegnazione nella tendina presente in Allenamenti - Schede Allenamento Assegnate (indicare l'iscritto e successivamente premere il bottone in basso: Assegna nuova scheda)
                          <br></br><br></br>
                          <p style={{ textAlign: 'left' }}>
                          Funzionalità principali:<br></br>
                          
                              - Selezione degli esercizi:
                                  è possibile scegliere direttamente un esercizio dalla tendina Esercizio, che mostra tutti gli esercizi caricati a sistema.
                                  In alternativa, si può filtrare prima per Classificazione (ad esempio: Riscaldamento, Forza, Massa, ecc.) e successivamente selezionare un esercizio dalla tendina Esercizio, che mostrerà solo quelli appartenenti alla classificazione scelta.<br></br>
                          
                              - Creazione e Modifica della scheda in modo flessibile:
                                  è possibile aggiungere, rimuovere e riordinare gli esercizi nella scheda in base alle necessità.
                                  Per ogni esercizio selezionato, è possibile personalizzare i dati caratteristici, come il numero di serie, le ripetizioni, il recupero tra serie, e altri parametri.<br></br>
                          
                              - Esportazione:
                                  la scheda può essere esportata in formato PDF per consentirne la stampa e una condivisione più agevole.
                          </p>
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {tornaElenco()}
                            }    
                        ]}
                    />
                :""}
                    <CardBody>
                            <CardTitle>Elenco Schede Allenamento Predefinite
                            </CardTitle>
                            <Button color="success" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo</Button>
                            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoPopolamento(true)}/>
                    </CardBody>    
                        
                            {risultatoServer !== undefined && risultatoServer !== "" ?
                              <TabellaRicerca titolo={`Elenco Schede Allenamento`} columns={colonne} data={risultatoServer} campoChiave={0} campoOrdinamento={1} nascondiColonna={0} callbackSelezionaElemento={VaiModifica} callbackEliminaElemento={VaiElimina}/>
                            : 
                              <Table responsive bordered hover striped className="mb-0">
                              <thead>
                                <tr>
                                  <th>Nessun dato presente</th>
                                </tr>
                              </thead>
                              </Table>
                          }
                      

        </div>
      :""}
    </div>
   );
}
export default TabellaSchedeAllenamento;