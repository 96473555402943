import React, {Fragment,useState,useEffect,Suspense,useRef} from 'react';
import { Table,
  Card, CardBody,
  CardTitle,Button,Row, Col,FormGroup,Label,Input} from 'reactstrap';

import Parametri from '../../parametri';
import FormGridFormRow from '../SchedaIscritto/FormGridFormRow';
import TabellaRicerca from '../tabellaricerca.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js'

import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

import moment from 'moment';//npm install moment

import { calcolaAnnoOggi } from '../../funzioni.js';

const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 5;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
const STATO_VAI_IN_ELIMINA= 1;
{/*const STATO_VAI_IN_INSERISCI= 6;*/}

const STATO_SCARICA_PDF = 7;
const STATO_PREPARA_PDF = 8;


function TabellaDirettivo(props) {
    
    const operazione = "elencoPersoneDirettivo";

    const [modaleImpostazioni, setModaleImpostazioni] = React.useState(false);
      
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');
    
    const [idModifica,setIdModifica]=useState('');

    const  dataPartenza = useRef();

    var today = new Date();
    
    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);

    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);

    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;
    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno

    const [necessitaRicalcolo,setNecessitaRicalcolo]=useState(0);

    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');

    const  radioPeriodo = useRef();
    const [radioPeriodoDefault,setRadioPeriodoDefault]=useState("1");

    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');

    const  dataInizioPeriodo = useRef();
    const  dataFinePeriodo = useRef();

    const [dataInizioPeriodoDefault,setDataInizioPeriodoDefault]=useState(dataOggi);
    const dataOggiPiuUnAnno = moment(dataOggi).add(1, "years").format("YYYY-MM-DD");
    const [dataFinePeriodoDefault,setDataFInePeriodoDefault]=useState(dataOggiPiuUnAnno);

    const [flagModaleInfoPopolamento, setFlagModaleInfoPopolamento] = React.useState(false);
    
    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Cognome',
        accessor: 'COGNOME'
      },
      {
        etichetta: 'Nome',
        accessor: 'NOME'
      },
      {
        etichetta: 'N. Tessera',
        accessor: 'NUMERO_TESSERA'
      },
      {
        etichetta: 'Posizioni in Società',
        accessor: 'POSIZIONI'
      }
      
    ];
    const arrayTipiDati=[];

    function chiudiModaleImpostazioni() {
      setModaleImpostazioni(false);
      if(necessitaRicalcolo === 2) setNecessitaRicalcolo(1);
      //if(cambiamento === 1) aggiornaImpostazioniReport();
    }

    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function VaiElimina(id) {
      setStatoPagina(STATO_VAI_IN_ELIMINA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }

    function scaricaLogo() {
      //console.log("scaricaLogo");
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          
            if(valoreDiRitorno.risultatoOperazione===1){
                if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                  setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                  setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                  setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                }
                setRicercaLogo(1);
            }
        },
        (error) => {console.log("Errore connessione");}
      );        
    }

    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      //resetto tutte le var di stato:
      if(necessitaRicalcolo===1){
        setRisultatoServer('');
        setStatoPagina(STATO_RICERCA_DATI); 
        setNecessitaRicalcolo(0);   
      }
    },[necessitaRicalcolo])

    useEffect(() => {
      if(statoPagina === STATO_PREPARA_PDF){
        if(ricercaLogo===1) setStatoPagina(STATO_SCARICA_PDF);
      }
      if(statoPagina===STATO_RICERCA_DATI){
        scaricaLogo();
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,
            emak: props.emak,
            dataPartenza:dataPartenzaDefault,
            radioPeriodo:radioPeriodoDefault,
            dataInizioPeriodo:dataInizioPeriodoDefault,
            dataFinePeriodo:dataFinePeriodoDefault,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServer(valoreDiRitorno.risultatoRitorno[0].sociAnnoInCorso);
                setInizioPeriodo(valoreDiRitorno.risultatoRitorno[1].periodo[0].DATA_INIZIO_PERIODO);
                setFinePeriodo(valoreDiRitorno.risultatoRitorno[1].periodo[0].DATA_FINE_PERIODO);
                setStatoPagina(STATO_OPERAZIONE_CORRETTA);}
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])
    //},[props.numPagina])
    
    function tornaElenco() {
      setFlagModaleInfoPopolamento(false);
  }
  
  return (
      <div>

      {statoPagina === STATO_SCARICA_PDF ? 
        <div>
          <PDFViewer style={styles.viewer}>
              <Document>
                <Page size="A4" style={styles.page}>
                  <View style={{width:"100%"}} >
                    <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                      <View wrap={false} style={styles.tableRowIntestazione}> 
                        <View style={styles.tableColIntestazioneSx}> 
                          <View style={styles.tableCell}> 
                            {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                          </View> 
                        </View> 
                      <View style={styles.tableColIntestazioneDx}>   
                        <View style={styles.tableCellIntestazione}> 
                          {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                          {radioPeriodoDefault !=='3'? 
                            <Text style={styles.titolo}>Direttivo {dataPartenzaDefault} dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano></Text>
                          :
                            <Text style={styles.titolo}>Direttivo dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano></Text>
                          }
                        </View> 
                      </View> 
                    </View> 
                  </View> {/*chiude table intestazione*/}

                  <View style={styles.table}>{/*apre table dati*/}
                    
                      <View wrap={false} style={styles.tableRowBlu}> 
                          <View style={styles.tableCol4}> 
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}>Cognome</Text> 
                          </View> 
                            <View style={styles.tableCol4}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Nome</Text> 
                            </View>
                            <View style={styles.tableCol4}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>N. Tessera</Text> 
                            </View> 
                            <View style={styles.tableCol4}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Posizione in Società</Text> 
                            </View>    
                    </View>

                    {/*apre table con dentro i dati*/}
                    {risultatoServer.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={styles.tableCol4}>                   
                            <Text style={styles.tableCellAllineatoSinistra}>{risultatoServer[index].COGNOME}</Text>       
                        </View>
                        <View style={styles.tableCol4}>                   
                            <Text style={styles.tableCellAllineatoSinistra}>{risultatoServer[index].NOME}</Text>       
                        </View>
                        <View style={styles.tableCol4}>                   
                            <Text style={styles.tableCellAllineatoSinistra}>{risultatoServer[index].NUMERO_TESSERA}</Text>       
                        </View>
                        <View style={styles.tableCol4}>                   
                            <Text style={styles.tableCellAllineatoSinistra}>{risultatoServer[index].POSIZIONI}</Text>       
                        </View>
                        </View>
                    )}

                  </View>
                </View>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
              </Page>
            </Document>
          </PDFViewer>
      </div>

      :""}





      {/*{statoPagina === STATO_VAI_IN_INSERISCI ? 
        <div>
        <Suspense fallback={
          <div className="loader-container">
          <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda componente direttivo in corso...</h6></div>
          </div>
        }>
        <FormGridFormRow {...props} ope={"inserisci"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
        </Suspense>
      </div>
      :""} */}

      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda componente direttivo in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_VAI_IN_ELIMINA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda componente direttivo in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"cancella"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_CompDir</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA || statoPagina === STATO_SCARICA_PDF ||statoPagina === STATO_PREPARA_PDF ?
        <div style={{display:statoPagina === STATO_SCARICA_PDF?"none":"block"}}>
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>

        {flagModaleInfoPopolamento=== true ?
                    <Modale 
                        larghezza = "lg"
                        titolo="Come popolare l'elenco del direttivo"
                        flagErrore={false}
                        contenuto={<div>I componenti del Direttivo sono tutti gli iscritti che nel periodo in esame risultino associati ad almeno una Posizione in Società 
                        per la quale è abilitato il checkbox 'Direttivo'.<br/>
                        Per aggiungere o modificare le Posizioni in Società dal menu principale recarsi in Impostazioni, Posizioni in Società.<br/>
                        Nella scheda della posizione, è possibile indicare se la posizione deve appartenere al Direttivo tramite l'apposito checkbox.<br/>
                        Successivamente, recandosi sulla scheda di un iscritto (menu principale, Anagrafiche, Elenco Iscritti), nella sezione 'Posizioni e Cariche Sociali'
                        sarà possibile assegnare la Posizione all'Iscritto indicando le date di inizio e, se necessario, di fine assegnazione. <br/>
                        Se la posizione è associata al Direttivo, l'iscritto comparirà nell'elenco del Direttivo per il periodo di assegnazione indicato.</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {tornaElenco()}
                            }    
                        ]}
                    />
                :""}



          <CardBody>
            <CardTitle>Elenco Componenti Direttivo
              {radioPeriodoDefault !=='3'?
                            <>&nbsp;
                            <select name="dataPartenza" id="dataPartenza" innerRef={dataPartenza} value={parseInt(dataPartenzaDefault)} onChange={(e)=>{setDataPartenzaDefault(e.target.value);setNecessitaRicalcolo(1);}}>
                              {[...Array(11)].map((elemento, indice) => (
                                <option key={indice} value={annoOggi + 1 - indice}>
                                  {annoOggi + 1 - indice}
                                </option>
                              ))}                              
                            </select> 
                            </>
              :""}
                            &nbsp; dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano> </CardTitle>
            {/*<Button color="success"  onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo Iscritto</Button>*/}
            <Button color="success" onClick={() => {setModaleImpostazioni(true)}}>Impostazioni</Button>
            <Button color="success" className="bottoneMargineASinistra" onClick={(e)=>{setStatoPagina(STATO_PREPARA_PDF);}}>Esporta il PDF</Button>
            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoPopolamento(true)}/>
            {modaleImpostazioni === true ?
              <Modale 
                larghezza = "lg"
                titolo="Impostazioni Elenco Direttivo"
                flagErrore={false}
                contenuto={<div>
                <CardBody>
                  
                  <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                    <Col><b><center>Periodo Considerato</center></b></Col></Row>
                      <Row style={{marginLeft:20,marginRight:20}} className="border">
                        <Col><center>
                          <FormGroup check>
                            <Label check>
                              <Input type="radio" name="radioPeriodo" 
                                innerRef={radioPeriodo} 
                                defaultValue={radioPeriodoDefault}
                                defaultChecked = {radioPeriodoDefault === "1" ?  'checked' :""}
                                value="1"
                                onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setDataPartenzaDefault(annoOggi)}}/> {'Apertura/Chiusura Esercizio'}
                            </Label>
                           </FormGroup>
                        </center></Col>
                        <Col><center>
                          <FormGroup check>
                            <Label check>
                              <Input type="radio" name="radioPeriodo" 
                                innerRef={radioPeriodo} 
                                defaultValue={radioPeriodoDefault}
                                defaultChecked = {radioPeriodoDefault === "2" ?  'checked' :""}
                                value="2"
                                onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setDataPartenzaDefault(new Date().getFullYear())}}/> {'Anno Solare'}
                            </Label>
                          </FormGroup>
                        </center></Col>
                        <Col><center>
                          <FormGroup check>
                            <Label check>
                              <Input type="radio" name="radioPeriodo" 
                                innerRef={radioPeriodo} 
                                defaultValue={radioPeriodoDefault}
                                defaultChecked = {radioPeriodoDefault === "3" ?  'checked' :""}
                                value="3"
                                onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2)}}/> {'Data Custom'}
                            </Label>
                          </FormGroup>
                        </center></Col>
                        {radioPeriodoDefault === "3" ? 
                          <Col><center>
                              <FormGroup>
                                <Label for="inizioPeriodo">Inizio Periodo Custom</Label>
                                <Input maxLength={10} type="date" name="dataInizioPeriodo" id="dataInizioPeriodo"  defaultValue={dataInizioPeriodoDefault} innerRef={dataInizioPeriodo}
                                    placeholder="" onInput={(e)=>{setNecessitaRicalcolo(2);setDataInizioPeriodoDefault(dataInizioPeriodo.current.value)}}/> 
                                </FormGroup>
                              <FormGroup>
                                <Label for="finePeriodo">Fine Periodo Custom</Label>
                                <Input maxLength={10} type="date" name="dataFinePeriodo" id="dataFinePeriodo"  defaultValue={dataFinePeriodoDefault} innerRef={dataFinePeriodo}
                                    placeholder="" onInput={(e)=>{setNecessitaRicalcolo(2);setDataFInePeriodoDefault(dataFinePeriodo.current.value)}}/> 
                                </FormGroup>
                          </center></Col>
                        :""}
                      </Row>
                      <Row style={{marginLeft:20,marginRight:20}} className="border">
                      <Col><b>
                      I componenti del Direttivo sono tutti gli iscritti che nel periodo in esame risultino associati ad almeno una Posizione in Società 
                      la quale abbia il checkbox 'Direttivo' abilitato.<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoPopolamento(true)}/>
                      </b></Col>
                    </Row>
                    </CardBody>
                  </div>}
                bottoni={[
                  {
                    "etichetta":"OK",
                    "tipo":"primary",
                    callback:() => {chiudiModaleImpostazioni()}
                  },    
                ]}
              />
            :""}
          </CardBody>
          
          {risultatoServer !== undefined && risultatoServer !== "" ?
            <TabellaRicerca columns={colonne} data={risultatoServer} arrayTipiDati={arrayTipiDati} campoOrdinamento={1} campoChiave={0} nascondiColonna={0} callbackSelezionaElemento={VaiModifica} callbackEliminaElemento={VaiElimina}/>
          : 
            <Table responsive bordered hover striped className="mb-0">
            <thead>
              <tr><th>Nessun dato presente</th></tr>
            </thead>
            </Table>
        }
        </div>
        </div>
      : ""}

      {statoPagina === STATO_INDEFINITO ?
        <Fragment>
          Errore pagina Direttivo, contattare l'assistenza tecnica
        </Fragment>
      :""}
    </div>
   );
}
export default TabellaDirettivo;