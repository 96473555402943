import React, {useEffect, useState} from 'react';
import Clessidra from '../Pagine/Clessidra'
import stile from './stile.module.css';

function Qrcode(props) {
    const [flagCaricamento, setFlagCaricamento] = useState(false);
    const [immagine, setImmagine] = useState("");
    const screenWidth = window.innerWidth;

    function scaricaQrcode() {  
        setFlagCaricamento(true);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ op:"scaricaQrcode", emak: props.emak, sessionId: props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
        .then(risposta => risposta.json())
        .then(
          (valore_di_ritorno) => {
            setFlagCaricamento(false);
            if (valore_di_ritorno.verifvers===0) {
              // necessario aggiornare la app
              props.callbackAggiornaApp();
              return;
            }
            if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
            if (valore_di_ritorno.risultatoOperazione === 1) { 
                setImmagine(valore_di_ritorno.qrcode);
            }
          },
          (error) => { 
            setFlagCaricamento(false);
            return;
          }
        );          
      }
    
    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            props.callback(props.PAGINECLIENTI.home);            
        } 
    }, [props.flagRichiestaBack])

    useEffect(() => {
        scaricaQrcode();
    },[])

    if (flagCaricamento) {
        return (<Clessidra loading={true} />)
    }
        
    return (
        <div className={stile.contenitoreBody} style={{flex:1, display: 'flex', flexDirection: 'column'}}>                
    
            {immagine !== "" ?
                <>
                    {/* Questa div prende tutto lo spazio rimanente e centra il contenuto */}
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img           
                            style={{
                                maxWidth: "100%",   // Larghezza massima disponibile (100% della finestra)
                                maxHeight: "100%",  // Altezza massima disponibile (100% della finestra)
                                objectFit: "contain",  // L'immagine si ridimensiona senza deformarsi
                            }}    
                            src={"data:image/png;base64," + immagine}
                            alt="QRCODE"
                        />
                    </div>
                </>
            :
                <div style={{ alignSelf: 'center', marginTop:20, marginBottom:30, width: "75%" }} >
                    <div className={stile.testoNormaleCentrato}>QRCODE PERSONALE NON DEFINITO</div>
                </div>
            }
    
            <div style={{display:'flex', flexDirection:'row', paddingBottom:50, marginLeft:10, marginRight:10}}>              
                <div 
                    className={`${stile.bottone3} ${stile.bottoneColoreNormale}`} 
                    onClick={() => { props.callback(props.PAGINECLIENTI.home) }}
                >
                    <div className={stile.spazioIconaPiccola}>
                        <img
                            className={stile.iconaPiccolaQuadrata}
                            src={require('./ok.png')}
                            alt="OK"
                        />
                    </div>
                    <div className={stile.bottoneTestoNormale}>OK</div>
                </div>
            </div>
    
        </div>
    );
    
    
}

export default Qrcode;
