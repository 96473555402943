import React, {useState, useEffect} from 'react';
import Clessidra from '../Pagine/Clessidra'
import stile from './stile.module.css';

function TipiEvento(props) {
    const PAGINE = {
        "caricamentoDati": 1,
        "elenco": 2,
        "modifica": 3,
        "inserisci": 4,
    };
    const [pagina, setPagina] = useState(PAGINE.caricamentoDati);
    const [elencoTipiEventi, setElencoTipiEventi] = useState([]); // json
    const [idTipoElenco, setIdTipoElenco] = useState(0);
    const [nomeTipoElenco, setNomeTipoElenco] = useState("");

    function scaricaElencoTipiEventi() {
        setPagina(PAGINE.caricamentoDati);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "elencoTipiEventi", emak: props.emak, sessionId: props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    setElencoTipiEventi(valore_di_ritorno.risultatoRitorno);
                    setPagina(PAGINE.elenco);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function insMod() {
        if (nomeTipoElenco=="") {
            window.alert("Indica la descrizione del tipo di evento"); 
            return;
        }
        setPagina(PAGINE.caricamentoDati);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: pagina===PAGINE.modifica ? "tipiEventiMod" : "tipiEventiIns", 
                idElemento: pagina===PAGINE.modifica ? idTipoElenco : 0,
                descrizione: nomeTipoElenco,
                emak: props.emak, 
                sessionId: props.sessionId
            })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    scaricaElencoTipiEventi();
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function canc(id) {
        setPagina(PAGINE.caricamentoDati);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "tipiEventiCanc", 
                idElemento: id,
                emak: props.emak, 
                sessionId: props.sessionId
            })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Impossibile cancellare il tipo evento. Verifica che non sia usato da qualche evento, anche passato."); 
                    }
                    scaricaElencoTipiEventi();
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    useEffect(() => {
        scaricaElencoTipiEventi();
    }, [])

    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            switch (pagina) {
                case PAGINE.caricamentoDati: break;
                case PAGINE.elenco: setPagina(PAGINE.menuEventi); break;
                case PAGINE.modifica: setPagina(PAGINE.elenco); break;
                case PAGINE.inserisci: setPagina(PAGINE.elenco); break;
            }
            
        } 
    }, [props.flagRichiestaBack])   

    if (pagina === PAGINE.caricamentoDati) {
        return (<Clessidra loading={true} />)
    }

    if (pagina === PAGINE.inserisci || pagina === PAGINE.modifica) {
        return (
            <>
                <div style={{ overflowY: 'auto' }}>
                    <div className={stile.eventiModificaTestoTitoli} style={{ paddingBottom: 5 }}>
                        {pagina === PAGINE.esercizioModifica ? "Modifica descrizione del tipo di evento" : "Inserisci nuovo tipo di evento"}
                    </div>
    
                    <div className={stile.eventiModificaTestoTitoli}>Descrizione del tipo di evento*</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <input 
                            type="text" 
                            value={nomeTipoElenco} 
                            onChange={(e) => setNomeTipoElenco(e.target.value)} 
                            maxLength={200} 
                            className={`${stile.eventiModificaInputTesto}`} 
                            style={{ flex: 1 }}
                        />
                        <div onClick={() => setNomeTipoElenco("")} style={{ cursor: 'pointer' }}>
                            <img
                                className={stile.iconaPiccolaQuadrata}
                                src={require('./x.png')}
                                alt="cancel"
                                style={{ marginLeft: 5 }}
                            />
                        </div>
                    </div>
    
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <div 
                            className={`${stile.bottone3} ${stile.bottoneColoreRosso}`} 
                            onClick={() => { scaricaElencoTipiEventi(); }}
                            style={{ cursor: 'pointer' }}
                        >
                            <div className={stile.spazioIconaPiccola}>
                                <img
                                    className={stile.iconaPiccolaQuadrata}
                                    src={require('./annulla.png')}
                                    alt="annulla"
                                />
                            </div>
                            <div className={stile.bottoneTestoNormale}>ANNULLA</div>
                        </div>
                        <div 
                            className={`${stile.bottone3} ${stile.bottoneColoreVerde}`} 
                            onClick={() => { insMod(); }}
                            style={{ cursor: 'pointer' }}
                        >
                            <div className={stile.spazioIconaPiccola}>
                                <img
                                    className={stile.iconaPiccolaQuadrata}
                                    src={require('./modifica.png')}
                                    alt="memorizza"
                                />
                            </div>
                            <div className={stile.bottoneTestoNormale}>MEMORIZZA</div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    
    if (pagina === PAGINE.elenco) {
        return (      
            <> 
                <div style={{ overflowY: 'scroll' }}>
                    <div style={{ display: "flex", flexDirection: "row", paddingLeft: 10, paddingRight: 10, paddingBottom: 10, paddingTop: 5, marginBottom: 5, backgroundColor: "#E0E0E0" }}>
                        <div 
                            className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}  
                            onClick={() => { 
                                setIdTipoElenco(0);                            
                                setNomeTipoElenco("");
                                setPagina(PAGINE.inserisci);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <div className={stile.spazioIconaPiccola}>
                                <img
                                    className={stile.iconaPiccolaQuadrata}
                                    src={require('./stella.png')}
                                    alt="inserisci nuovo"
                                />
                            </div>
                            <div className={stile.bottoneTestoNormale}>Inserisci nuovo</div>
                        </div>
                    </div>
    
                    {elencoTipiEventi.map((x, i) => (
                        <div key={i} className={stile.elencoSchedeContenitoreElemento}>
                            <div 
                                style={{ flex: 1, cursor: 'pointer' }} 
                                onClick={() => { 
                                    setIdTipoElenco(x.ID);
                                    setNomeTipoElenco(x.DESCRIZIONE);
                                    setPagina(PAGINE.modifica);
                                }}
                            >
                                <div className={stile.elencoSchedeNome}>
                                    {x.DESCRIZIONE}
                                </div>
                            </div>
    
                            <div style={{ width: 50, alignItems: "center", cursor: 'pointer' }} onClick={() => { 
                                setIdTipoElenco(x.ID);
                                setNomeTipoElenco(x.DESCRIZIONE);
                                setPagina(PAGINE.modifica);
                            }}>
                                <img
                                    className={stile.iconaPiccolaQuadrata}
                                    src={require('./modifica.png')}
                                    alt="modifica"
                                />
                            </div>
    
                            <div style={{ width: 50, alignItems: "center", cursor: 'pointer' }} onClick={() => { 
                                if (window.confirm(`Confermi eliminazione del tipo di evento: ${x.DESCRIZIONE}`)) canc(x.ID);
                            }}>
                                <img
                                    className={stile.iconaPiccolaQuadrata}
                                    src={require('./xgrigia-nobordo.png')}
                                    alt="elimina"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </>
        )
    }   

    return (
        <div>Errore pagina non trovata (TipiEvento)</div>
    )
}

export default TipiEvento;
