import React, { useState, useEffect, useRef } from 'react';
import Tendina2 from './Tendina2';
import stile from './stile.module.css';

function Messaggi(props) {
    const scrollViewRef = useRef();
    const dropDownRef = useRef();
    const [primoScroll, setPrimoScroll] = useState(true);

    const [currentScrollHeight, setCurrentScrollHeight] = useState(0); // Altezza dello scroll prima del caricamento

    const [elencoMessaggi, setElencoMessaggi] = useState([]); // mandati dal server, compresi i miei
    const [elencoMessaggiInviati, setElencoMessaggiInviati] = useState([]); // i miei, in attesa che il server li recepisca
    const [minIdMessaggio, setMinIdMessaggio] = useState(0); // il minore id tra quelli dei messaggi finora scaricati
    const [maxIdMessaggio, setMaxIdMessaggio] = useState(0); // il maggiore id tra quelli dei messaggi finora scaricati
    const [idCli, setIdCli] = useState(0); // progressivo locale, generato da questa app, dei messaggi generati da me, per sapere quando sono recepiti dal server
    const [messaggio, setMessaggio] = useState("");
    const [destinatario, setDestinatario] = useState("TUTTI");
    const [idDestinatario, setIdDestinatario] = useState(0);
    const [messaggioSelezionato, setMessaggioSelezionato] = useState(0);
    const [elencoDestinatari, setElencoDestinatari] = useState([]);
    const [elencoIdDestinatari, setElencoIdDestinatari] = useState([]);
    const [ws, setWs] = useState(null);
   
    const websocketRef = useRef(null); // Usare una ref per mantenere il WebSocket

    const connectWebSocket = () => {
        if (websocketRef.current) return; // Previene la creazione multipla di WebSocket

        const websocket = new WebSocket(props.urlWebSocket);
        
        websocket.onopen = () => {
            websocket.send(JSON.stringify({
                type: 'REGISTER',
                IDPERSONA: props.identitaSelezionata.idpersona,
                IDEVENTO: props.eventoSelezionato.ID_EVENTO
            }));
        };

        websocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'NEW_MESSAGE') {
                scaricaMessaggi(props.eventoSelezionato.ID_EVENTO, "");
            }
        };

        websocket.onclose = () => {
            websocketRef.current = null; // Rimuove il riferimento al WebSocket chiuso
        };

        websocketRef.current = websocket; // Salva il WebSocket attivo nella ref

        scaricaMessaggi(props.eventoSelezionato.ID_EVENTO, "");
    };

    const disconnectWebSocket = () => {
        console.log("disconnectWebSocket")
        if (websocketRef.current) {
            console.log("websocketRef.current.close()")
            websocketRef.current.close();
            websocketRef.current = null;
        }
    };

    function scaricaMessaggi(idEvento, direzione) {
        //console.log("scaricaMessaggi inizio: idEvento="+idEvento+",direzione="+direzione+" minIdMessaggio="+minIdMessaggio+" maxIdMessaggio="+maxIdMessaggio);
        var requestOptions;
        if (direzione=="") {
            // primo scaricamento di messaggi
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ op: "scaricaMessaggiChat", id_evento: idEvento, token: props.token, emak: props.emak, sessionId: props.sessionId })
            };
        }
        if (direzione=="su") {
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ op: "scaricaMessaggiChat", id_evento: idEvento, id_massimo: minIdMessaggio, token: props.token, emak: props.emak, sessionId: props.sessionId })
            };
        }
        if (direzione=="giu") {
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ op: "scaricaMessaggiChat", id_evento: idEvento, id_minimo: maxIdMessaggio, token: props.token, emak: props.emak, sessionId: props.sessionId })
            };
        }
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers == 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione == 2) { props.callback(props.PAGINEHOME.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    
                    props.setAggiornaChatPerEvento(0);
                    if (direzione=="") {
                        //console.log("memorizzo elencoMessaggi senza conservare i precedenti")
                        setElencoMessaggi(valore_di_ritorno.elencoMessaggi); 
                        setMinIdMessaggio(valore_di_ritorno.minIdMessaggio);
                        setMaxIdMessaggio(valore_di_ritorno.maxIdMessaggio);
                        setPrimoScroll(true);
                    }
                    if (direzione=="su") {
                        //console.log("memorizzo elencoMessaggi prima dei precedenti")
                        setElencoMessaggi([...valore_di_ritorno.elencoMessaggi, ...elencoMessaggi]);
                        setMinIdMessaggio(valore_di_ritorno.minIdMessaggio);
                        setPrimoScroll(false);
                    }
                    if (direzione=="giu") {
                        //console.log("memorizzo elencoMessaggi dopo i precedenti")
                        setElencoMessaggi([...elencoMessaggi, ...valore_di_ritorno.elencoMessaggi]);
                        setMaxIdMessaggio(valore_di_ritorno.maxIdMessaggio);
                        setPrimoScroll(false);
                    }

                    // se ci sono messaggi nel json elencoMessaggiInviati, cioè messaggi inviati da me al server, che ho messo nella pagina senza
                    // data e ora di invio, allora devo vedere se in questo scaricamento il server mi ha inviato qualcuno di questi messaggi, che
                    // deve allora uscire da elencoMessaggiInviati perché è entrato in elencoMessaggi
                    if (elencoMessaggiInviati.length > 0) {
                        var e = [];
                        // ricopio in e solo i messaggi di elencoMessaggiInviati che non sono presenti in elencoMessaggi
                        for (var i=0; i<elencoMessaggiInviati.length; i++) {
                            //console.log("verifico se server ha recepito msg inviato n."+i+": idcli="+elencoMessaggiInviati[i].idcli+" m="+elencoMessaggiInviati[i].m);
                            var found=false;
                            for (var j=0; j<valore_di_ritorno.elencoMessaggi.length; j++) {
                                if (valore_di_ritorno.elencoMessaggi[j].idm==props.identitaSelezionata.idpersona) {
                                    //console.log(valore_di_ritorno.elencoMessaggi[j].idcli+"=="+elencoMessaggiInviati[i].idcli);
                                    if (valore_di_ritorno.elencoMessaggi[j].idcli==elencoMessaggiInviati[i].idcli){
                                        //console.log("found");
                                        found=true;
                                        break;
                                    } else {
                                        //console.log("not found")
                                    }
                                }
                            }
                            if (!found) e.push(elencoMessaggiInviati[i]);
                        }
                        setElencoMessaggiInviati(e);
                    }
                },
                (error) => {
                    props.callback(props.PAGINEHOME.login);
                    return;
                }
            );
    }

    function inviaMessaggio() {
        if (messaggio == "") return;
        //console.log("Invio: " + messaggio)
        const testo = messaggio; // perché "messaggio" lo azzero subito e nel frattempo la fetch manda "testo"
        var e = [...elencoMessaggiInviati];
        const nuovoIdCli = idCli + 1;
        setIdCli(nuovoIdCli);
        e.push({ "idcli": nuovoIdCli, "idm": props.identitaSelezionata.idpersona, "da": "me", "m": messaggio, "idd": idDestinatario, "h": "..." });
        setElencoMessaggiInviati(e);
        setMessaggio("");
        setPrimoScroll(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "aggiungiMessaggioChat", id_evento: props.eventoSelezionato.ID_EVENTO, id_destinatario: idDestinatario, testo: testo, idcli: nuovoIdCli, emak: props.emak, sessionId: props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers == 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione == 2) { props.callback(props.PAGINEHOME.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    
                    //console.log("id_messaggio = ",valore_di_ritorno.id_messaggio)
                    if (valore_di_ritorno.id_messaggio == -1) {
                        window.alert("Impossibile inserire il messaggio, la tua convocazione a questo evento è stata annullata");
                        props.callback(props.PAGINEHOME.home);
                        return;
                    }

                    // elimino il messaggio dall'elenco dei messaggi inviati (cioè quelli in attesa di essere ricevuti dal server)
                    var emi = [];
                    for (var i=0; i<elencoMessaggiInviati.length; i++) {
                        if (elencoMessaggiInviati.idCli != nuovoIdCli) emi.push(elencoMessaggiInviati[i]);
                    }
                    setElencoMessaggiInviati(emi);
                    
                    // inserisco il messaggio nell'elenco dei messaggi
                    var em = [...elencoMessaggi];
                    em.push({ "idcli": nuovoIdCli, "idm": props.identitaSelezionata.idpersona, "da": "me", "m": messaggio, "idd": idDestinatario, "h": valore_di_ritorno.data_messaggio });
                    setElencoMessaggi(em);

                },
                (error) => {
                    props.callback(props.PAGINEHOME.login);
                    return;
                }
            );
    }

    function nomeDaId(id) {
        var ret = "";
        for (var i=0; i<elencoIdDestinatari.length; i++) {
            if (elencoIdDestinatari[i] == id) {
                ret = elencoDestinatari[i];
                break;
            }
        }
        return ret;
    }

    useEffect(() => {
        if (props.identitaSelezionata.flag_staff) {
            var d=[];
            var idd=[];
            var destSel="";
            d.push("TUTTI");
            idd.push(0);            
            d.push("STAFF");
            idd.push(-1);
            for (var i=0; i<props.personeConvocateNonStaff.length; i++) {
                d.push(props.personeConvocateNonStaff[i]["COGNOME"]+" "+props.personeConvocateNonStaff[i]["NOME"]);
                idd.push(props.personeConvocateNonStaff[i]["ID_PERSONA"]);
                if (props.idDestinatarioSelezionato==props.personeConvocateNonStaff[i]["ID_PERSONA"]) {
                    destSel = props.personeConvocateNonStaff[i]["COGNOME"]+" "+props.personeConvocateNonStaff[i]["NOME"];
                }
            }
            setElencoDestinatari(d);
            setElencoIdDestinatari(idd);

            if (props.idDestinatarioSelezionato>0) {
                // come si entra, si deve selezionare questo destinatario invece di TUTTI
                setIdDestinatario(props.idDestinatarioSelezionato);
                setDestinatario(destSel);
            }
        }
        setMinIdMessaggio(0);
        setMaxIdMessaggio(0);

        scaricaMessaggi(props.eventoSelezionato.ID_EVENTO, ""); // il secondo parametro vuoto significa: scarica i primi messaggi (più recenti) per la prima volta, non ci sono messaggi già visualizzati

        // Funzione per gestire la visibilità della pagina
        const handleVisibilityChange = () => {
            console.log("handleVisibilityChange")
            if (document.visibilityState === 'visible') {
                connectWebSocket();  // Connette solo quando la pagina è visibile
            } else {
                disconnectWebSocket();  // Disconnette quando la pagina diventa invisibile
            }
        };

        // Avvia il WebSocket solo se la pagina è visibile all'inizio
        if (document.visibilityState === 'visible') {
            connectWebSocket();
        }

        // Aggiunge il listener per la visibilità della pagina
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Rimuove l'event listener e chiude il WebSocket al termine
        return () => {
            disconnectWebSocket();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [])

    useEffect(() => {
        //console.log("DEBUG: useEffect su aggiornaChatPerEvento: verifico se "+props.aggiornaChatPerEvento+"=="+props.eventoSelezionato.ID_EVENTO+" o se vale -1");
        if (props.aggiornaChatPerEvento==props.eventoSelezionato.ID_EVENTO || props.aggiornaChatPerEvento==-1) {
            scaricaMessaggi(props.eventoSelezionato.ID_EVENTO, ""); // il secondo parametro vuoto significa: scarica i primi messaggi (più recenti) per la prima volta, non ci sono messaggi già visualizzati
        }
    }, [props.aggiornaChatPerEvento])

    // Funzione di disconnessione da websocket opzionale (ad esempio su uscita chat)
    const disconnect = () => {
        if (ws) {
        ws.close();
        setWs(null);
        }
    };

    // Effettua lo scroll iniziale verso il basso
    useEffect(() => {
        if (primoScroll && scrollViewRef.current) {
            scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
        }
    }, [primoScroll, elencoMessaggi]);

    // Mantiene lo scroll sul messaggio corrente dopo il caricamento di nuovi messaggi
    useEffect(() => {
        if (!primoScroll && scrollViewRef.current) {
            const newScrollHeight = scrollViewRef.current.scrollHeight;
            const addedHeight = newScrollHeight - currentScrollHeight;

            // Riposiziona lo scroll per mantenere il messaggio corrente visibile
            scrollViewRef.current.scrollTop = addedHeight;
        }
    }, [primoScroll, elencoMessaggi, currentScrollHeight]);

    // Gestisce lo scroll verso l'alto per scaricare nuovi messaggi
    const handleScroll = () => {
        if (scrollViewRef.current) {
            const { scrollTop } = scrollViewRef.current;
            
            console.log(scrollTop);

            if (scrollTop === 0) {
                setCurrentScrollHeight(scrollViewRef.current.scrollHeight); // Memorizza l'altezza attuale

                // Scarica nuovi messaggi sopra quelli esistenti
                scaricaMessaggi(props.eventoSelezionato.ID_EVENTO, "su");
            }
        }
    };

    return (
        <>
            <div
                style={{ backgroundColor: "#6397D0", flex:1, overflowY:'auto' }}
                ref={scrollViewRef}
                onScroll={handleScroll}
            >
                {elencoMessaggi.map((msg, i) => (
                    <div key={i} className={msg.idm === props.identitaSelezionata.idpersona ? `${stile.alignEnd}` : `${stile.flexRow}`}>
                        {msg.idm == props.identitaSelezionata.idpersona ? (
                            <div className={
                                msg.idd == -1 
                                    ? stile.eventiChatBollaMiaAStaff
                                    : msg.idd == 0
                                        ? stile.eventiChatBollaMiaATutti
                                        : stile.eventiChatBollaMiaAUno
                            }>
                                {/* messaggi inviati da me */}
                                {msg.idd == 0 ? null : (
                                    <div className={stile.eventiChatTestoBollaMittente}>
                                        {msg.idm == props.identitaSelezionata.idpersona ? "" : msg.da + " "}
                                        {msg.idd == -1 ? "a Staff" : ""}
                                        {msg.idd > 0 ? "a " + nomeDaId(msg.idd) : ""}
                                    </div>
                                )}
                                <div className={stile.eventiChatTestoBollaTesto}>{msg.m}</div>
                                <div className={stile.eventiChatTestoBollaDataOra}>{msg.h}</div>
                            </div>
                        ) : (
                            <div>
                                {/* messaggi inviati dagli altri */}
                                {props.identitaSelezionata.flag_staff ? (
                                    <div className={
                                        msg.idd == 0 
                                            ? stile.eventiChatBollaAltriATutti
                                            : msg.idd == -1
                                                ? stile.eventiChatBollaAltriAStaff
                                                : stile.eventiChatBollaAltriAMe
                                    } onClick={() => setMessaggioSelezionato(msg.idmsg)}>
                                        <div className={stile.eventiChatTestoBollaMittente}>
                                            {msg.idm == props.identitaSelezionata.idpersona ? "" : msg.da + " "}
                                            {msg.idd == -1 ? "a Staff" : ""}
                                            {msg.idd > 0 ? msg.idd == props.identitaSelezionata.idpersona ? "a me" : "a " + nomeDaId(msg.idd) : ""}
                                        </div>
                                        <div className={stile.eventiChatTestoBollaTesto}>{msg.m}</div>
                                        <div className={stile.eventiChatTestoBollaDataOra}>{msg.h}</div>
                                    </div>
                                ) : (
                                    <div className={
                                        msg.idd == 0 
                                            ? stile.eventiChatBollaAltriATutti
                                            : msg.idd == -1
                                                ? stile.eventiChatBollaAltriAStaff
                                                : stile.eventiChatBollaAltriAMe
                                    }>
                                        <div className={stile.eventiChatTestoBollaMittente}>
                                            {msg.idm == props.identitaSelezionata.idpersona ? "" : msg.da + " "}
                                            {msg.idd == -1 ? "a Staff" : ""}
                                            {msg.idd > 0 ? msg.idd == props.identitaSelezionata.idpersona ? "a me" : "a " + nomeDaId(msg.idd) : ""}
                                        </div>
                                        <div className={stile.eventiChatTestoBollaTesto}>{msg.m}</div>
                                        <div className={stile.eventiChatTestoBollaDataOra}>{msg.h}</div>
                                    </div>
                                )}
                            </div>
                        )}

                        {msg.idmsg == messaggioSelezionato ? (
                            <div style={{ justifyContent: 'center' }} onClick={() => {setIdDestinatario(msg.idm); setDestinatario(msg.da); dropDownRef.current.select(2)}}>
                                <img
                                    style={{ resizeMode: "contain", width: 40, paddingTop: 10, backgroundColor: "#63b0e2" }}
                                    src={require('./busta.png')}
                                />
                            </div>
                        ) : null}
                    </div>
                ))}

                {elencoMessaggiInviati.map((msg, i) => (
                    <div key={i} style={{ alignItems: 'flex-end' }}>
                        <div className={
                            msg.idd == -1 
                                ? stile.eventiChatBollaMiaAStaff
                                : msg.idd == 0
                                    ? stile.eventiChatBollaMiaATutti
                                    : stile.eventiChatBollaMiaAUno
                        }>
                            <div className={stile.eventiChatTestoBollaTesto}>{msg.m}</div>
                            <div style={{ alignItems: 'flex-end' }}>
                                <div className={stile.eventiChatTestoBollaDataOra}>{msg.h}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
    
            <div className={stile.eventiChatContenitoreInviaA}>
                <div className={stile.eventiChatDidascaliaInviaA}>
                    <div className={stile.eventiChatTestoScriviA}>Scrivi a:</div>
                </div>
                {props.identitaSelezionata.flag_staff ? (
                    <Tendina2
                        className={stile.eventiChatDropDownDestinatari}                        
                        textStyle={idDestinatario == 0 ? stile.eventiChatTestoBottoneInviaTuttiSelezionato : idDestinatario == -1 ? stile.eventiChatTestoBottoneInviaStaffSelezionato : stile.eventiChatTestoBottoneInviaSingoloSelezionato}
                        dropdownStyle={stile.eventiChatDropDownContenitoreElementi}
                        dropdownTextStyle={stile.eventiChatDropDownTestoElementi}
                        options={elencoDestinatari}
                        defaultValue={destinatario}
                        onSelect={(t) => { 
                            setIdDestinatario(elencoIdDestinatari[t]);
                            setDestinatario(elencoDestinatari[t]);
                        }}
                    />            
                ) : ( 
                    idDestinatario == -1 ? (
                        <div className={stile.eventiChatBottoneInviaStaff} onClick={() => { setIdDestinatario(0); setDestinatario("TUTTI")}}>
                            <div className={stile.eventiChatTestoBottoneInviaStaffSelezionato}>STAFF</div>
                        </div>
                    ) : (
                        <div className={stile.eventiChatBottoneInviaTutti} onClick={() => { setIdDestinatario(-1); setDestinatario("STAFF")}}>
                            <div className={stile.eventiChatTestoBottoneInviaTuttiSelezionato}>TUTTI</div>
                        </div>
                    )
                )}
            </div>
    
            <div className={stile.eventiChatContenitoreScrivi}>
                <input 
                    placeholder='Messaggio'
                    placeholderTextColor="#909090"
                    multiline={true}
                    value={messaggio}
                    onChange={(e) => setMessaggio(e.target.value)}
                    maxLength={1000}
                    className={stile.eventiChatTestoScrivi}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault(); // Previene l'andare a capo
                            inviaMessaggio();
                        }
                    }}                    
                />
                <div 
                    className={idDestinatario == 0 ? stile.eventiChatSpazioIconaInviaTutti : idDestinatario == -1 ? stile.eventiChatSpazioIconaInviaStaff : stile.eventiChatSpazioIconaInviaSingolo} 
                    onClick={() => inviaMessaggio()}
                >
                    <img
                        className={stile.eventiChatIconaInvia}
                        src={require('./frecciadestra2.png')}
                    />
                    <div className={stile.eventiChatTestoInvia}>Invia</div>
                </div>
            </div>
        </>
    );
}    

export default Messaggi;
