/* AAAAeslint-disable no-undef */
// Attenzione: quello sopra non è un commento ma una direttiva a ESLint di ignorare l'errore che darebbe su safari non definito

import React, {useState, useEffect, isValidElement} from 'react';
import {
  Card, CardBody, CardTitle, Button
} from 'reactstrap';
import Menu from './Menu'
import AppMain from './AppMain'
import PaginaInserimentoCredenziali from './PaginaInserimentoCredenziali'
import PaginaResetPassword from './PaginaResetPassword'
import Parametri from './parametri';
import "./generale.css"
import "./base.css"
import imgMenu from './immagini/menu1.png';
import imgCasa from './immagini/casa1.png';
import logo from './logoTitolo.jpeg';
import iphoneCondividi from './immagini/iphoneCondividi.png';
import iphoneEsportaSchermataHome from './immagini/iphoneEsportaSchermataHome.png';
import iphoneAggiungiHome from './immagini/iphoneAggiungiHome.png';
import androidCondividi from './immagini/androidCondividi.png';

import MainClienti from './AppClienti/MainClienti'

import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyAvJCjt5tvePbQ6W-RR2jgUo274bPwMytY",
    authDomain: "comunica-72ae1.firebaseapp.com",
    projectId: "comunica-72ae1",
    storageBucket: "comunica-72ae1.appspot.com",
    messagingSenderId: "623837245023",
    appId: "1:623837245023:web:faadd4f3bca7bd20e875ea",
    measurementId: "G-50B0VK4VDK"
};

function App() {
  const [sessionId, setSessionId] = useState([]);
  const [dimensioniApp, setDimensioniApp] = useState({x: 0, y:0, yTitolo:0, yMain:0});
  
  const [flagMenuVisibile, setFlagMenuVisibile] = useState(true);
  const [flagMenuRidotto, setFlagMenuRidotto] = useState(false);
  const [chiudiTuttiSottomenu, setChiudiTuttiSottomenu] = useState(false);

  const [identitaSelezionata, setIdentitaSelezionata] = useState([]);
  const [ricarica, setRicarica] = useState(false);
  const [fileLogo,setFileLogo]=useState('');
  const [typeLogo,setTypeLogo]=useState('');
  const [flagAggiornaFileLogo, setFlagAggiornaFileLogo]=useState(0);

  // permessi per le notifiche
  const [permission, setPermission] = useState("");
  const [permissionGranted, setPermissionGranted] = useState(permission === 'granted');
  const [token,setToken] = useState(null);
  const [permessoRichiesto, setPermessoRichiesto] = useState(false);
  const [tokenRichiesto, setTokenRichiesto] = useState(false);
  const [statoVerificaNotifiche, setStatoVerificaNotifiche]=useState("");

  const [aggiornaChatPerEvento, setAggiornaChatPerEvento] = useState(-1);

  const [flagNonChiederloPiu, setFlagNonChiederloPiu] = useState(false);

  const emak = "w-HkwNcfPrGuxs0GI2CGPCGflKzg30otG3D+V8Aj0vDc80zmbKo1=.9OOEADH:,F.hXuAzvL|JS,GQ_1mXG3HN1i11GmQJEf?PJI";
  
  // ATTENZIONE: oltre a questi codici, nella var di stato permission ci finiscono gli stati definiti da Notification.permission
  // quindi questi sono codici aggiuntivi miei che definiscono stati particolari
  const CODICI_PERMISSION = {
    "indica_di_salvare_in_home": 0,
    "serviceWorker_non_disp": 1,
    "ignora_notifiche": 2,
    "spiegazione":3,
    "spiegazioneApple":4,
    "spiegazioneAndroid":5
  }

  // ATTENZIONE ALLE NUMERAZIONI: SE LE CAMBI QUI, DEVI CAMBIARE ANCHE LE PAGINE RICHIAMATE DAI SUGGERIMENTI IN home.php
  const PAGINE = {
    "benvenuto": 0,
    "tornaLogin": 1,
    "resettaPassword": 2,
    "logout":3,
    "debugLocalStorage":4,

    "iscritti": 101,
    "direttivo": 102,
    "gruppi": 103,
    "ruoli": 104,
    "clientiFornitori": 105,
    "socAnaloghe": 106,
    "contattiUtili": 107,
    "libroSoci": 108,
    "associaTessera": 109,
    "inviaMessaggio": 110,

    "abbonati": 201,
    "abbonamenti": 202,
    "corsi": 203,
    //"prenotazioneCorsi": 204,
    "prenotazioneCorsi2": 205,
    "accessi": 206,
    "accessiQR": 207,

    "quoteAssociative": 301,
    "primaNota": 302,
    "fatture": 303,
    "spese": 304,
    "rimborsi": 305,
    "incassiDiversi": 306,
    "incassiDiversiIscritti": 307,
    "incassiQuoteAbb": 308,
    "incassiQuoteAss": 309,
    "risorse": 310,
    "vociDiBilancio": 311,
    "fattureElettroniche": 312,

    "federEntiTesseram": 401,
    "tipiTesseram": 402,
    "catTesseram": 403,
    "tesseram": 404,

    "adempimenti": 501,
    "scadenziario": 502,

    "news": 601,

    "eventi": 701,
    "tipiEventi": 702,

    "esercizi": 801,
    "classiEsercizi": 802,
    "schedeAllenamento": 803,
    "assegnazioniSchede": 804,
    "schedeAllenamentoMAS": 805,
    "assegnazioniSchedeMAS": 806,

    "catArticoli": 901,
    "articoli": 902,

    "convenzioni": 1001,
    "entiConvenzioni": 1002,
    "sponsor": 1003,
    "campagneSponsor": 1004,

    "bilancio": 1101,
    "insolventi": 1102,
    "reportTesseramenti": 1103,
    "reportEconPersone": 1104,
    "reportEconEsterni": 1105,
    "reportSponsor": 1106,
    "reportRimborsi": 1107,
    "reportVincoli": 1108,
    "reportPrenotazioniCorsi": 1109,
    "reportEventi": 1110,
    "reportElencoPrenotazioni": 1111,
    "certScad": 1112,
    "docScad": 1113,
    "reportElencoArticoli": 1114,
    "reportAccessi": 1115,
    "privacyScad": 1116,
    "abbonamentiScad": 1117,
    "creditori": 1118,
    "reportAttivita": 1119,

    //"verbaliAssemblee": 1201,
    "documenti": 1202,
    "tipiDocumenti": 1203,

    "tipiDocIdentita": 1301,
    "impostazioniSocieta": 1302,
    "mioAbbonamento": 1303,
    "importazioneMassiva": 1304,
    "nomiGruppi": 1305,
    "giorniChiusura": 1306,
    "posizioniSocieta": 1307,
    "cambioPassword": 1308,

    "faq":1401,

    "controlloAccessi": 1501,

    "settaggiCrm": 1601,
    "mezziComunicazCrm": 1602,
    "comunicazioniCrm": 1603,
    "datiCrm": 1604,
    "prospectCrm": 1605,
    "contattatiCrm": 1606, 
    "reportAzioniCrm": 1607, 
  };

  const xMinimaPerMenuNonRidotto = 1000;

  const [pagina, setPagina] = useState(PAGINE.benvenuto);
  
  const isAndroid = () => {
    const userAgent = navigator.userAgent || window.opera;
    const isAndroid = /Android/i.test(userAgent);
    
    return isAndroid;
  };

  const isIOS = () => {
    const userAgent = navigator.userAgent || window.opera;
    const isIOS = /iPhone|iPod|iPad/i.test(userAgent);
    const isIPad = navigator.maxTouchPoints > 1 && /Macintosh/i.test(userAgent); // per iPad moderni (che si identificano come "Mac") 
    
    return isIOS || isIPad;
  };

  const isMobile = () => {
    return isAndroid() || isIOS();
  };

  const keepSessionAlive = async () => {
    console.log("Keep alive!");
    try {
      const response = await fetch(Parametri("serverURL"), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({op:"noop",emak: emak, sessionId: sessionId})
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log('Session kept alive:', data);
    } catch (error) {
      console.error('Error keeping session alive:', error);
    }
  };

  function scaricaLogo() {
    console.log("scaricaLogo");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({op:"ricercaLogo",emak: emak, sessionId: sessionId})
    };

    fetch(Parametri("serverURL", identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1){
              if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
              }
          }
      },
      (error) => {console.log("Errore connessione");}
    );        
  }

  function LogoApplicazione() {
    return (
        <div 
            style={{textAlign:"center", paddingBottom:15}}
            onClick={() => {
                alert("Gestione Sportiva Web App v. " + Parametri("release"))
            }}
        >                
            <img className="logo" style={{border: "1px solid #D0D0D0"}} height={100} src={logo} alt="Logo"/>
        </div>
    )
  }

  const requestNotificationPermission = () => {
    if ("Notification" in window) {
      // Verifica stato attuale delle notifiche
      switch (Notification.permission) {
        case "granted":
          setPermission("granted");
          break;
        case "denied":
          setPermission("denied");
          break;
        case "default":
          // Richiedi permessi solo se lo stato è "default"
          Notification.requestPermission()
            .then(permission => {
              console.log("Permesso ricevuto:", permission);
              setPermission(permission || "denied"); // Aggiorna con il valore ricevuto
            })
            .catch(error => {
              console.error("Errore nella richiesta delle notifiche:", error);
              setPermission("denied"); // Considera negato in caso di errore
            });
          break;
        default:
          console.warn("Stato imprevisto per Notification.permission:", Notification.permission);
          setPermission("denied");
      }
    } else {
      // notification non disponibile: devi salvare su home del telefono?
      setPermission(CODICI_PERMISSION.indica_di_salvare_in_home);
    }
  };

  useEffect(() => {
    // keep alive: la sessione non scade mai per questi database
    var intervalId;
    if (identitaSelezionata.nome_database==='EMA18' || identitaSelezionata.nome_database==='EMA48') {
      intervalId = setInterval(keepSessionAlive, 180000); // 180000ms = 3 minuti
    }    

    return () => {
      clearInterval(intervalId); // Pulisce l'intervallo quando il componente si smonta
    };    
  },[sessionId])
 
  useEffect(() => {
    if (ricarica) {
      setRicarica(false)
    }
  },[ricarica])

  useEffect(() => {

    const isSavedToHomeScreen = window.matchMedia('(display-mode: standalone)').matches;

    // Se l'app non è stata salvata sulla home e l'utente è su un dispositivo mobile
    if (!isSavedToHomeScreen && isMobile()) {
      if (localStorage.getItem("salvahomeEMA")!="0") {
        setPermission(CODICI_PERMISSION.indica_di_salvare_in_home);
      }
    }

    // Verifica lo stato del permesso quando il componente viene montato
    if ("Notification" in window) {
      console.log('Permesso per le notifiche:', Notification.permission);
      setPermission(Notification.permission);
    }

    
  
    const calcolaDimensioniApp = () => {      
      const x = document.getElementById("principale").clientWidth;
      const y = document.getElementById("principale").clientHeight;
      var yTitolo = y/20;
      if (yTitolo<40) yTitolo = 40;
      var yMain = y-yTitolo;;
      setDimensioniApp({x:x, y:y, yTitolo:yTitolo, yMain: yMain});
      setFlagMenuRidotto(x<xMinimaPerMenuNonRidotto);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    
    calcolaDimensioniApp();
    window.addEventListener('resize', calcolaDimensioniApp);

    window.addEventListener("focus", () => {
      console.log("App in focus");
    });

    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const parametroResetPassword = url.searchParams.get("djakgfh89724wygr");
    if (parametroResetPassword) {      
      setPagina("resettaPassword");
    }    

    // commenta a regime, consente di vedere i parametri salvati nel browser, cosa che potrebbe fare anche un inspector quindi non è un problema di sicurezza
    const parametroDebugLocalStorage = url.searchParams.get("debuglocalstorage");
    if (parametroDebugLocalStorage) {      
      setPagina("debugLocalStorage");
    }    
    // fine commenta a regime

    return () => {
     window.removeEventListener('resize', calcolaDimensioniApp);
    };    
  }, []);

  useEffect(() => {
    if (permission === "granted" && !token) {
      try {
        // Inizializzazione dell'app Firebase
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);

        // Ri-registra il Service Worker se è necessario
        navigator.serviceWorker.getRegistration('/firebase-messaging-sw.js')
          .then((registration) => {
            if (registration) {
              // Annulla la registrazione e registra di nuovo il Service Worker
              registration.unregister().then(() => {
                navigator.serviceWorker.register('/firebase-messaging-sw.js')
                  .then((newRegistration) => {                    
                    console.log('Nuovo Service Worker registrato:', newRegistration);
                    richiestaToken(messaging, newRegistration);

                    // Imposta un listener per le notifiche in primo piano una sola volta
                    if (!window.firebaseMessageListener) {
                      window.firebaseMessageListener = true;
                      onMessage(messaging, (payload) => {
                        console.log('Messaggio ricevuto in primo piano:', payload);
                        if (payload.notification.body=="Notifiche Attive") {
                          // uso un messaggio con testo comprensibile anche nel caso in cui un utente dovesse riceverlo come notifica dal sistema,
                          // cosa che succederebbe se chiudesse l'applicazione dopo aver chiesto la verifica e prima di aver ricevuto questo messaggio
                          setStatoVerificaNotifiche("ok");
                        } else {
                          alert(payload.notification.body);
                        }
                      });
                    }

                  })
                  .catch((error) => {
                    console.error('Errore durante la registrazione del Service Worker:', error);
                    setPermission(CODICI_PERMISSION.serviceWorker_non_disp);
                  });
              });
            } else {
              // Se non esiste un Service Worker, registralo normalmente
              navigator.serviceWorker.register('/firebase-messaging-sw.js')
                .then((newRegistration) => {
                  richiestaToken(messaging, newRegistration);

                  // Imposta un listener per le notifiche in primo piano una sola volta
                  if (!window.firebaseMessageListener) {
                    window.firebaseMessageListener = true;
                    onMessage(messaging, (payload) => {
                      console.log('Messaggio ricevuto in primo piano:', payload);
                      if (payload.notification.body=="Notifiche Attive") {
                        // uso un messaggio con testo comprensibile anche nel caso in cui un utente dovesse riceverlo come notifica dal sistema,
                        // cosa che succederebbe se chiudesse l'applicazione dopo aver chiesto la verifica e prima di aver ricevuto questo messaggio
                        setStatoVerificaNotifiche("ok");
                      } else {
                        alert(payload.notification.body);
                      }
                    });
                  }

                })
                .catch((error) => {
                  console.error('Errore durante la registrazione del Service Worker:', error);
                  setPermission(CODICI_PERMISSION.serviceWorker_non_disp);
                });
            }
          });

      } catch (error) {
        console.log('Service Worker non supportato o errore imprevisto:', error);
        setPermission(CODICI_PERMISSION.serviceWorker_non_disp);
      }
    }
  }, [permission]);

  useEffect(() => {
    if (identitaSelezionata.societa !== undefined){
      scaricaLogo();
    } 
  },[identitaSelezionata]);
  
  useEffect(() => {
    if (flagAggiornaFileLogo==1){
      setFlagAggiornaFileLogo(0);
      setFileLogo("");
      setTypeLogo("");

      // non scarico subito, perché impostazioni società (che ha cambiato il logo e impostato il flagAggiornaFileLogo) deve avere il tempo di fare l'upload prima che qui venga lanciato scaricaLogo()
      //console.log("setto timeout")
      setTimeout(() => {
        //console.log("scattato timeout")
        scaricaLogo();
      }, 5000); // dopo 5 secondi      

      // cancellare il timeout se il componente viene smontato prima che scada il timeout: se lo faccio, non funziona più il timeout.
      // timeoutId è il return value di setTimeout, cioè const timeoutId = setTimeout(...)
      //return () => clearTimeout(timeoutId);
    } 
  },[flagAggiornaFileLogo]);

  useEffect(() => {
    if (pagina === PAGINE.tornaLogin){
      setSessionId("");
      setIdentitaSelezionata([]); 
      setPagina(PAGINE.benvenuto);     
    }

    if (pagina === PAGINE.logout){
      setSessionId("");
      setIdentitaSelezionata([]); 
      localStorage.setItem("passEMA", "");
      // non cancello la email, così non deve reinserirla al prossimo login
      setPagina(PAGINE.benvenuto);     
    }
  },[pagina]);

  const richiestaToken = (messaging, registration) => {
    console.log('inizio richiesta token');
    getToken(messaging, { 
      vapidKey: 'BHvOOjrLM66lnBfZd1-PiL9XW7Y29PE4qoavHRgTBeEpvMiusywZB7BsoJMCw_Bi54KbTuaNXCUP7nS7jaIQAsk', 
      serviceWorkerRegistration: registration 
    }).then((currentToken) => {
      if (currentToken) {
        console.log('Token ottenuto: ', currentToken);
        setToken(currentToken);
      } else {
        console.log('Nessun token disponibile. Richiedi permesso di notifica.');
      }
    }).catch((err) => {
      console.log('Errore durante l\'ottenimento del token: ', err);
    });
  };

  if (permission === CODICI_PERMISSION.indica_di_salvare_in_home) {
    return (
      <div className="contenitoreLoginAvvisiIniziali">
        <Card className="main-card mb-3 larghezzaCardLoginAvvisiIniziali">
          <CardBody> 
            <LogoApplicazione/>                        
            <CardTitle>INSTALLA LA APP</CardTitle>
            <center>
            Installa la APP semplicemente creando l'icona nel tuo telefono o tablet, con la funzione Salva in Home.
            <br/>
            Sarà più semplice usarla e potrai ricevere le notifiche.
            <br/><br/>
            <input type='checkbox' checked={flagNonChiederloPiu} onChange={(event) => {setFlagNonChiederloPiu(event.target.checked)}}/> Non chiedermelo più
            <br/><br/>
            <Button color="primary" className="mt-2" onClick={() => {
                if (isAndroid()) setPermission(CODICI_PERMISSION.spiegazioneAndroid);
                else if (isIOS()) setPermission(CODICI_PERMISSION.spiegazioneApple);
                else setPermission(CODICI_PERMISSION.spiegazione)}
              }>
              Come si fa?
            </Button>
            <br/><br/>
            <Button color="primary" className="mt-2" onClick={
              () => 
                {
                  if (flagNonChiederloPiu) localStorage.setItem("salvahomeEMA", "0");
                  setPermission(CODICI_PERMISSION.ignora_notifiche)
                }
              }>
              Lo farò più tardi
            </Button>
            </center>
          </CardBody>
        </Card>
      </div>
    )
  }

  if (permission === CODICI_PERMISSION.spiegazione) {
    return (
      <div className="contenitoreLoginAvvisiIniziali">
        <Card className="main-card mb-3 larghezzaCardLoginAvvisiIniziali">
          <CardBody> 
            <LogoApplicazione/>                        
            <CardTitle>CHE DISPOSITIVO HAI?</CardTitle>
            <center>
            
            <Button color="primary" className="mt-2" onClick={() => setPermission(CODICI_PERMISSION.spiegazioneAndroid)}>Android</Button>
            &nbsp;&nbsp;<Button color="primary" className="mt-2" onClick={() => setPermission(CODICI_PERMISSION.spiegazioneApple)}>iPhone o iPad</Button>

            </center>
          </CardBody>
        </Card>
      </div>
    )
  }

  if (permission === CODICI_PERMISSION.spiegazioneApple) {
    return (
      <div className="contenitoreLoginAvvisiIniziali">
        <Card className="main-card mb-3 larghezzaCardLoginAvvisiIniziali">
          <CardBody> 
            <LogoApplicazione/>                        
            <CardTitle>IPHONE E IPAD</CardTitle>
            <center>
            
            1. Clicca su questa icona, che trovi vicino alla barra degli indirizzi:<br/>
            <img style={{border: "1px solid #D0D0D0", maxWidth:"80%", maxHeight:"50px", height:"auto"}} src={iphoneCondividi} alt="icona condividi"/>
            <br/><br/>

            2. Clicca su "Aggiungi alla schermata Home":<br/>
            <img style={{border: "1px solid #D0D0D0", maxWidth:"80%", height:"auto"}} src={iphoneEsportaSchermataHome} alt="aggiungi alla schermata home"/>
            <br/><br/>

            3. Clicca sulla scritta "Aggiungi":<br/>
            <img style={{border: "1px solid #D0D0D0", maxWidth:"80%", height:"auto"}} src={iphoneAggiungiHome} alt="aggiungi alla schermata home"/>
            <br/><br/>

            Poi chiudi questa finestra e apri sempre l'applicazione cliccando sull'icona, come fai per le altre APP.
            <br/><br/>

            <Button color="primary" className="mt-2" onClick={() => setPermission(CODICI_PERMISSION.indica_di_salvare_in_home)}>OK</Button>

            </center>
          </CardBody>
        </Card>
      </div>
    )
  }

  if (permission === CODICI_PERMISSION.spiegazioneAndroid) {
    return (
      <div className="contenitoreLoginAvvisiIniziali">
        <Card className="main-card mb-3 larghezzaCardLoginAvvisiIniziali">
          <CardBody> 
            <LogoApplicazione/>                        
            <CardTitle>ANDROID</CardTitle>
            <center>

            Istruzioni per il browser Chrome: consigliamo di utilizzare questo browser, anche se il telefono viene fornito con un browser diverso.
            <br/><br/>

            1. Clicca sui tre puntini, che trovi vicino alla barra degli indirizzi:<br/>
            <img style={{border: "1px solid #D0D0D0", maxWidth:"80%", maxHeight:"50px", height:"auto"}} src={androidCondividi} alt="icona condividi"/>
            <br/><br/>

            2. Clicca su "Aggiungi a schermata Home"<br/>
            <br/><br/>

            3. Clicca sulla scritta "Installa"<br/>
            <br/><br/>

            Poi chiudi questa finestra e apri sempre l'applicazione cliccando sull'icona, come fai per le altre APP.
            <br/><br/>

            <Button color="primary" className="mt-2" onClick={() => setPermission(CODICI_PERMISSION.indica_di_salvare_in_home)}>OK</Button>

            </center>
          </CardBody>
        </Card>
      </div>
    )
  }

  if (permission === "default") {
    return (
      <div className="contenitoreLoginAvvisiIniziali">
        <Card className="main-card mb-3 larghezzaCardLoginAvvisiIniziali">
          <CardBody> 
            <LogoApplicazione/>                        
            <CardTitle>ATTIVAZIONE NOTIFICHE</CardTitle>
            <center>

            Gestione Sportiva necessita che autorizzi le notifiche, per ricevere messaggi. Clicca su CONSENTI quando comparirà la richiesta di autorizzazione.
            <br/><br/>

            <Button color="primary" className="mt-2" onClick={() => requestNotificationPermission()}>OK</Button>

            </center>
          </CardBody>
        </Card>
      </div>
    )
  }

  if (pagina === "resettaPassword") {
    return (
      <div id="principale" style={{height: "99.9vh", width:"100vw", backgroundColor:"#000000"}}>
        <PaginaResetPassword
          emak={emak}
        />
      </div>
    )
  }

  /*
  if (pagina === "debugLocalStorage") {
    const salvahome = localStorage.getItem("salvahomeEMA")
    const email = localStorage.getItem("emailEMA");
    const passCriptata = localStorage.getItem("passEMA");
    return (
      <div id="principale" style={{height: "99.9vh", width:"100vw", backgroundColor:"#000000"}}>
        sh={salvahome}
        <br/>
        em={email}
        <br/>
        pc={passCriptata}
      </div>
    )
  }
  */

  if (identitaSelezionata.idpersona === undefined || identitaSelezionata.idpersona === '') {
    // Attenzione: non passo isMobile ma isMobile() quindi non passo la funzione ma il risultato (booleano) della funzione. Più efficiente.
    return (
      <div id="principale" style={{height: "99.9vh", width:"100vw", backgroundColor:"#000000"}}>
        <PaginaInserimentoCredenziali
          emak={emak}
          setSessionId={setSessionId}        
          setIdentitaSelezionata={setIdentitaSelezionata}
          isMobile={isMobile()}
        />
      </div>
    )
  }

  // Temporaneo, finché non è attiva AppClienti
  /*
  if (identitaSelezionata.flag_staff == 0 && identitaSelezionata.flag_amministrazione == 0) {
    return (
      <div id="principale" style={{display: 'flex', flexDirection: 'column', height: "99.9vh", width:"100vw", backgroundColor:"#2370a2", color:"#FFFFFF", fontSize:20, padding:20, textAlign:'center'}}>
        GESTIONE SPORTIVA ACCESSO ATLETI
        <br/>
        <br/>
        Questo servizio per gli iscritti sarà disponibile a breve, con tante novità!
        <br/>
        <br/>
        <button onClick={() => setPagina(PAGINE.logout)}>OK</button>
      </div>
    )  
  }
    */

  // App clienti, per visualizzazione su telefono
  if (identitaSelezionata.flag_amministrazione == 0) {
    return (
      <div id="principale" style={{display: 'flex', flexDirection: 'column', height: 'calc(var(--vh, 1vh) * 100)', width:"100vw", backgroundColor:"#2370a2"}}>
        <MainClienti
            emak={emak}
            sessionId={sessionId}
            setSessionId={setSessionId}
            PAGINE={PAGINE}
            pagina={pagina}
            setPagina={setPagina}          
            identitaSelezionata={identitaSelezionata}
            setIdentitaSelezionata={setIdentitaSelezionata}
            UrlBackend={Parametri("serverURL")}
            urlWebSocket={Parametri("urlWebSocket")}
            VersioneApp={Parametri("release")}
            token={token}
            permission={permission}
            statoVerificaNotifiche={statoVerificaNotifiche}
        />
      </div>
    )  
  }

  // App completa, per visualizzazione su pc
  return (
    <div id="principale" style={{height: "99.9vh", width:"100vw", backgroundColor:"#000000"}}>
      <div style={{height:dimensioniApp.yTitolo, display:"flex", borderBottom: "1px solid #505050"}}>
        <div style={{backgroundColor:"#212a2f", cursor:"pointer"}} onClick={() => {setFlagMenuVisibile(!flagMenuVisibile) }}>
          <img src={imgMenu} height={dimensioniApp.yTitolo} width={dimensioniApp.yTitolo} alt=""/>
        </div>
        <div style={{backgroundColor:"#212a2f", cursor:"pointer"}} onClick={() => { setChiudiTuttiSottomenu(true);setPagina(PAGINE.benvenuto) }}>
          <img src={imgCasa} height={dimensioniApp.yTitolo} width={dimensioniApp.yTitolo} alt=""/>
        </div>
        <div style={{fontSize:dimensioniApp.yTitolo/2.8, backgroundColor: "#E0E0E0", flex:1, textAlign:'center', display:'flex', flexDirection:"column"}}>
          <div style={{fontSize:dimensioniApp.yTitolo/2.4}}>{identitaSelezionata.societa}</div>          
          <div style={{fontSize:dimensioniApp.yTitolo/3.7, marginTop:-2}}>GestioneSportiva.it</div>
        </div>
        {fileLogo !== "" ? 
          <div style={{backgroundColor: "#FFFFFF", borderLeft:"1px solid #505050"}}>
            {typeLogo === "application/pdf" || typeLogo === "text/plain" ? 
              <embed style={{maxHeight:dimensioniApp.yTitolo}} src={"data:" + typeLogo + ";base64," + fileLogo}  type={typeLogo}/>  
            :""}
            {typeLogo.substring(0, 5) === "image" ?
              <img style={{maxHeight:dimensioniApp.yTitolo}} src={"data:" + typeLogo + ";base64," + fileLogo} />
            :""}
          </div>
        :<></>}
      </div>

      <div style={{display:'flex', flexDirection:'row'}}>
        <Menu 
          identitaSelezionata={identitaSelezionata}
          dimensioniApp={dimensioniApp}
          flagVisibile={flagMenuVisibile}
          xMinimaPerMenuNonRidotto={xMinimaPerMenuNonRidotto}          
          flagMenuRidotto={flagMenuRidotto}
          setFlagMenuRidotto={setFlagMenuRidotto}
          PAGINE={PAGINE}
          pagina={pagina}
          setPagina={setPagina}
          setRicarica={setRicarica}
          chiudiTuttiSottomenu={chiudiTuttiSottomenu}
          setChiudiTuttiSottomenu={setChiudiTuttiSottomenu}
        />
        <div style={{flex:1, overflow:"auto", height:dimensioniApp.yMain, display:"flex", flexDirection:"column"}}>
        {ricarica ? <div></div> :
          <AppMain 
            setFlagAggiornaFileLogo={setFlagAggiornaFileLogo}
            dimensioniApp={dimensioniApp}
            identitaSelezionata={identitaSelezionata}
            emak={emak}
            PAGINE={PAGINE}
            pagina={pagina}
            setPagina={setPagina}
            sessionId={sessionId}
            token={token}
            permission={permission}
            aggiornaChatPerEvento={aggiornaChatPerEvento}
            setAggiornaChatPerEvento={setAggiornaChatPerEvento}
          />
        }
        </div>
      </div>
                  
      <div style={{position:'absolute', left:10, bottom:1, fontSize:7, color:"#909090"}}>{Parametri("release")}</div>

    </div>
  )
}

export default App;
