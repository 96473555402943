import React, {useEffect} from 'react';
import IconaMenu from './IconaMenu'

function EventiMenu(props) {    
    var iconaNuovo = require('./eventonuovo.png');
    var iconaTipiEvento = require('./tipievento.png');
    var iconaElenco = require('./elencoeventi.png');
    var iconaReport = require('./report_presenze_risultati.png');
    var iconaPresenze = require('./convocati2.png');
    var iconaRisultati = require('./cronometro.png');
    var iconaRisultatiSingolo = require('./cronometro-uomo.png');

    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            props.callback(props.PAGINECLIENTI.home);            
        } 
    }, [props.flagRichiestaBack])

    // attenzione: Elenco Eventi ha "evidenzia" per non staff e non lo ha per staff (che non conferma partecipazione)

    return (

        <div
            style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Centra verticalmente (perché la flexDirection è column) tutto il contenuto
            marginLeft: 5,
            marginRight: 5,
            }}
        >

            {props.identitaSelezionata.flag_staff ?
                <>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <IconaMenu
                        callback={() => props.callbackEventiMenu(props.PAGINE.elencoEventi)}
                        immagine={iconaElenco}
                        testo={"Elenco Eventi"}                        
                    />                
                </div>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <IconaMenu
                        callback={() => props.callbackEventiMenu(props.PAGINE.eventoInserisci)}
                        immagine={iconaNuovo}
                        testo={"Nuovo Evento"}
                    />
                    <IconaMenu
                        callback={() => props.callbackEventiMenu(props.PAGINE.tipiEvento)}
                        immagine={iconaTipiEvento}
                        testo={"Tipi di Evento"}
                    />
                </div>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <IconaMenu
                        callback={() => props.setPagina(props.PAGINE.reportPresenze)}
                        immagine={iconaPresenze}
                        testo={"Report\nPresenze"}
                    />
                    <IconaMenu
                        callback={() => props.setPagina(props.PAGINE.reportRisultati)}
                        immagine={iconaRisultati}
                        testo={"Report\nRisultati"}
                    />
                    <IconaMenu
                        callback={() => props.setPagina(props.PAGINE.reportRisultatiSingolo)}
                        immagine={iconaRisultatiSingolo}
                        testo={"Report\nSingolo"}
                    />
                </div>
                </>
            :
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <IconaMenu
                        callback={() => props.callbackEventiMenu(props.PAGINE.elencoEventi)}
                        immagine={iconaElenco}
                        testo={"Elenco\nEventi"}
                        evidenzia={props.identitaSelezionata.flag_nuovi_eventi>0}
                    />
                    <IconaMenu
                        callback={() => props.setPagina(props.PAGINE.reportPresenze)}
                        immagine={iconaPresenze}
                        testo={"Report\nPresenze"}
                    />
                    <IconaMenu
                        callback={() => props.setPagina(props.PAGINE.reportRisultatiSingolo)}
                        immagine={iconaRisultatiSingolo}
                        testo={"Report\nRisultati"}
                    />                    
                </div>
            }
        </div>
    )
}

export default EventiMenu;
