import React, {useState, useEffect} from 'react';
import Clessidra from '../Pagine/Clessidra'
import stile from './stile.module.css';
import FadeLoader from "react-spinners/FadeLoader"; // per i video, non uso Clessidra.js perché va posizionato diversamente, nel riquadro e non a centro schermo

function AllenamentiVisualizzaScheda(props) {
    const PAGINE = {
        "caricamentoDati": 1,
        "visualizzaScheda": 2,
    };
    const [pagina, setPagina] = useState(PAGINE.caricamentoDati);
    const [numEsercizio, setNumEsercizio] = useState(-1);
    const [elencoEserciziScheda, setElencoEserciziScheda] = useState([]); // array di oggetti json
    const [modoVisualizzazione, setModoVisualizzazione] = useState(0); // intero, 0 o 1: 0=scheda intera, 1=immagine o video a tutto schermo
    const [videoUrl, setVideoUrl] = useState('');

    function scaricaImmagineEsercizio(numEsercizio) {
        console.log("scaricaImmagineEsercizio(numEsercizio="+numEsercizio+") --> nome="+elencoEserciziScheda[numEsercizio].nome+" id="+elencoEserciziScheda[numEsercizio].id);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "esercizioRecuperaDati", 
                id: elencoEserciziScheda[numEsercizio].id,
                scaricaVideo: 1,
                emak: props.emak, 
                sessionId:props.sessionId
            })
        };
        
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    var e = [...elencoEserciziScheda];                    
                    e[numEsercizio].tipoImmagine = valore_di_ritorno.risultatoRitorno[0].TYPE;
                    if (valore_di_ritorno.risultatoRitorno[0].TYPE.startsWith("video")) {     
                        e[numEsercizio].immagineBase64 = "video";
                        console.log("setVideoUrl");
                        setVideoUrl(valore_di_ritorno.risultatoRitorno[0].FILE);  
                    } else {
                        e[numEsercizio].immagineBase64 = valore_di_ritorno.risultatoRitorno[0].FILE;
                    }
                    setElencoEserciziScheda(e);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function scaricaEserciziScheda(id, paginaSeguente) {
        setPagina(PAGINE.caricamentoDati);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "elencoEserciziScheda", 
                id: id,
                emak: props.emak, 
                sessionId:props.sessionId
            })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    if (valore_di_ritorno.risultatoRitorno[0] == undefined) {
                        // scheda vuota
                        setElencoEserciziScheda([]);
                    } else{
                        var el=[], esercizio;
                        for (var i=0; i<valore_di_ritorno.risultatoRitorno.length; i++) {
                            esercizio = {
                                id:valore_di_ritorno.risultatoRitorno[i].ID_ESERCIZIO,
                                nome:valore_di_ritorno.risultatoRitorno[i].ESERCIZIO,
                                descrizione: valore_di_ritorno.risultatoRitorno[i].SPIEGAZIONE,
                                classe:valore_di_ritorno.risultatoRitorno[i].CLASSE,
                                ripetizioni:valore_di_ritorno.risultatoRitorno[i].RIPETIZIONI,
                                serie:valore_di_ritorno.risultatoRitorno[i].SERIE,
                                recupero:valore_di_ritorno.risultatoRitorno[i].RECUPERO_TRA_SERIE,
                                note:valore_di_ritorno.risultatoRitorno[i].NOTE_ESERCIZIO,
                                immagineBase64: "?",
                                tipoImmagine: "?"
                            };
                            el.push(esercizio);
                        }
                    }
                    setElencoEserciziScheda(el);
                    setNumEsercizio(0);
                    setPagina(paginaSeguente);                    
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    useEffect(() => {
        scaricaEserciziScheda(props.idScheda, PAGINE.visualizzaScheda);
    }, [])

    useEffect(() => {
        if (elencoEserciziScheda[numEsercizio]!==undefined) {
            if (elencoEserciziScheda[numEsercizio].immagineBase64==="?") { 
                scaricaImmagineEsercizio(numEsercizio);
            }
            if (elencoEserciziScheda[numEsercizio].immagineBase64==="video") {
                setVideoUrl("");
                scaricaImmagineEsercizio(numEsercizio);
            }
        }
    }, [numEsercizio])

    if (pagina === PAGINE.caricamentoDati) {
        return (<div style={{ textAlign: 'center' }}><Clessidra loading={true} /></div>);
    }
    
    if (pagina === PAGINE.visualizzaScheda && elencoEserciziScheda === undefined) {
        return (<div style={{ textAlign: 'center' }}><Clessidra loading={true} /></div>);
    }
    
    if (pagina === PAGINE.visualizzaScheda && elencoEserciziScheda.length === 0) {
        return (
            <p style={{ paddingBottom: 5, paddingTop: 5 }} class={stile.eventiModificaTestoTitoli}>
                Errore: la scheda {props.nomeScheda} non ha esercizi
            </p>
        );
    }
    
    if (pagina === PAGINE.visualizzaScheda && elencoEserciziScheda.length > 0 && modoVisualizzazione === 0) {
        return (            
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, color: "#FFFFFF" }}>
                <div style={{ overflowY: 'scroll', backgroundColor: "#f0f0f0", flex: 1 }}>
                    <div style={{ paddingBottom: 5, paddingTop: 5}} class={stile.schedaAllenamentoTestoDati}>
                        Scheda {props.nomeScheda}
                        {props.nomePersonaSelezionata !== undefined && props.nomePersonaSelezionata !== "" ? " di " + props.nomePersonaSelezionata : ""}
                    </div>
    
                    <div style={{ position: "relative", backgroundColor: "#D0D0D0" }}>
                        <div style={{ position: "absolute", zIndex: 1, width: "100%", height: "100%", backgroundColor: "#D0D0D0" }}>
                        </div>
                        <div style={{ position: "absolute", zIndex: 2, width: (numEsercizio + 1) * 100 / elencoEserciziScheda.length + "%", height: "100%", backgroundColor: "#B0FFB0" }}>
                        </div>
                        <p style={{ position: "relative", zIndex: 3, lineHeight: "20px", paddingLeft: 5, margin: 0 }} className={stile.schedaAllenamentoTestoNumeroEsercizio}>
                            Esercizio n. {numEsercizio + 1} di {elencoEserciziScheda.length}
                        </p>
                    </div>
    
                    <div style={{ paddingBottom: 5}} class={stile.schedaAllenamentoTestoTitoli }>
                        {elencoEserciziScheda[numEsercizio].nome}
                    </div>
                    <div style={{ paddingBottom: 5}} class={stile.schedaAllenamentoTestoClasse }>
                        {elencoEserciziScheda[numEsercizio].classe}
                    </div>
                    {elencoEserciziScheda[numEsercizio].descrizione !== "" ?
                        <div style={{ paddingBottom: 5}} class={stile.schedaAllenamentoTestoDati }>
                            {elencoEserciziScheda[numEsercizio].descrizione}
                        </div>
                        :
                        <div></div>
                    }

                    <div className={stile.bottoneColoreNormale} style={{cursor:"pointer", color:"#000000", padding:5, borderTop: "1px solid black"}} onClick={() => setModoVisualizzazione(1)}>
                        <img
                            class={stile.iconaPiccolaQuadrata}
                            src={require('./zoompiu.png')}
                            alt="Ingrandisci"
                        />
                        &nbsp;INGRANDISCI {elencoEserciziScheda[numEsercizio].tipoImmagine.startsWith("video") ? "VIDEO" : elencoEserciziScheda[numEsercizio].tipoImmagine.startsWith("image") ? "IMMAGINE" : ""}
                    </div>                            
    
                    <div style={{ display: 'flex', flexDirection: 'row' }}>                    
                        <div class={stile.schedaAllenamentoSpazioImmagineEsercizio}>                            
                            {elencoEserciziScheda[numEsercizio].immagineBase64 === "" ? 
                                <div></div>
                            :
                                elencoEserciziScheda[numEsercizio].immagineBase64 === "?" ?                                
                                    <FadeLoader color={"#000000"} loading={true} aria-label="Loading Spinner" data-testid="loader"/>                                
                                :
                                    elencoEserciziScheda[numEsercizio].tipoImmagine.startsWith("video") ?
                                        videoUrl == "" ?
                                            <FadeLoader color={"#000000"} loading={true} aria-label="Loading Spinner" data-testid="loader"/>
                                        :
                                            <video className={stile.schedaAllenamentoImmagineEsercizio} key={videoUrl} controls>
                                                <source 
                                                    src={videoUrl} 
                                                    type={elencoEserciziScheda[numEsercizio].tipoImmagine} 
                                                />
                                                Your browser does not support the video tag.
                                            </video>
                                    :
                                        <img
                                            class={stile.schedaAllenamentoImmagineEsercizio}
                                            src={elencoEserciziScheda[numEsercizio].immagineBase64}
                                            alt="Esercizio"
                                        />
                            }
                        </div>
                        <div style={{ flex: 1, borderTop: "1px solid black" }}>
                            <div class={stile.schedaAllenamentoTestoTitoli}>
                                Serie:
                            </div>
                            <div style={{ paddingBottom: 5}} class={stile.schedaAllenamentoTestoDati }>
                                {elencoEserciziScheda[numEsercizio].serie}
                            </div>
                            <div class={stile.schedaAllenamentoTestoTitoli}>
                                Ripetizioni:
                            </div>
                            <div style={{ paddingBottom: 5}} class={stile.schedaAllenamentoTestoDati }>
                                {elencoEserciziScheda[numEsercizio].ripetizioni}
                            </div>
                            <div class={stile.schedaAllenamentoTestoTitoli}>
                                Recupero:
                            </div>
                            <div style={{ paddingBottom: 5}} class={stile.schedaAllenamentoTestoDati }>
                                {elencoEserciziScheda[numEsercizio].recupero}
                            </div>
                        </div>
                    </div>
                    
                    {elencoEserciziScheda[numEsercizio].note !== "" ?
                        <>
                            <div class={stile.schedaAllenamentoTestoTitoli}>
                                Note esercizio:
                            </div>
                            <div style={{ paddingTop: 0}} class={stile.schedaAllenamentoTestoDati }>
                                {elencoEserciziScheda[numEsercizio].note}
                            </div>
                        </> 
                    : 
                        <div></div>
                    }
    
                    {props.noteScheda !== undefined && props.noteScheda !== "" ?
                        <>
                            <div class={stile.schedaAllenamentoTestoTitoli}>
                                Note scheda:
                            </div>
                            <div style={{ paddingTop: 0}} class={stile.schedaAllenamentoTestoDati }>
                                {props.noteScheda}
                            </div>
                        </> 
                    :
                        <div></div>
                    }
                </div>
    
                <div style={{ display: "flex", flexDirection: 'row' }}>
                    <div 
                        className={`${stile.bottone3} ${numEsercizio > 0 ? stile.bottoneColoreNormale : stile.bottoneColoreGrigio}`} 
                        onClick={() => { if (numEsercizio > 0) setNumEsercizio(numEsercizio - 1) }}
                    >
                        <img
                            class={stile.iconaPiccolaQuadrata}
                            src={require('./frecciasinistra.png')}
                            alt="Freccia Sinistra"
                        />
                        <span class={stile.bottoneTestoNormale}>PRECEDENTE</span>
                    </div>
    
                    <div 
                        className={`${stile.bottone3} ${numEsercizio < elencoEserciziScheda.length - 1 ? stile.bottoneColoreNormale : stile.bottoneColoreGrigio}`}
                        onClick={() => { if (numEsercizio < elencoEserciziScheda.length - 1) setNumEsercizio(numEsercizio + 1) }}
                    >
                        <img
                            class={stile.iconaPiccolaQuadrata}
                            src={require('./frecciadestra.png')}
                            alt="Freccia Destra"
                        />
                        <span class={stile.bottoneTestoNormale}>SEGUENTE</span>
                    </div>
                </div>                
            </div>
        );
    }

    if (pagina === PAGINE.visualizzaScheda && elencoEserciziScheda.length > 0 && modoVisualizzazione === 1) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden', backgroundColor: "#F0F0F0" }}>
                {/* Intestazione */}
                <div style={{ position: "relative", backgroundColor: "#D0D0D0" }}>
                    <div style={{ position: "absolute", zIndex: 1, width: "100%", height: "100%", backgroundColor: "#D0D0D0" }}>
                    </div>
                    <div style={{ position: "absolute", zIndex: 2, width: (numEsercizio + 1) * 100 / elencoEserciziScheda.length + "%", height: "100%", backgroundColor: "#B0FFB0" }}>
                    </div>
                    <p style={{ position: "relative", zIndex: 3, lineHeight: "20px", paddingLeft: 5, margin: 0 }} className={stile.schedaAllenamentoTestoNumeroEsercizio}>
                        Esercizio n. {numEsercizio + 1} di {elencoEserciziScheda.length}
                    </p>
                </div>

                <div class={stile.schedaAllenamentoTestoTitoli }>
                    {elencoEserciziScheda[numEsercizio].nome}
                </div>

                <div class={stile.schedaAllenamentoTestoDati }>
                    {elencoEserciziScheda[numEsercizio].serie} 
                    {elencoEserciziScheda[numEsercizio].ripetizioni!="" && elencoEserciziScheda[numEsercizio].ripetizioni!=0 && " x "+elencoEserciziScheda[numEsercizio].ripetizioni}
                    {elencoEserciziScheda[numEsercizio].recupero!="" && " Recup. " + elencoEserciziScheda[numEsercizio].recupero}
                </div>

                <div className={stile.bottoneColoreNormale} style={{cursor:"pointer", color:"#000000", padding:5, borderTop: "0.5px solid black", borderBottom: "0.5px solid black"}} onClick={() => setModoVisualizzazione(0)}>
                    <img
                        class={stile.iconaPiccolaQuadrata}
                        src={require('./zoommeno.png')}
                        alt="Riduci"
                    />
                    &nbsp;RIDUCI {elencoEserciziScheda[numEsercizio].tipoImmagine.startsWith("video") ? "VIDEO" : elencoEserciziScheda[numEsercizio].tipoImmagine.startsWith("image") ? "IMMAGINE" : ""}
                </div> 

                {/* Contenuto principale */}
                <div 
                    style={{ 
                        flex: 1, 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        overflow: 'hidden',
                        position: 'relative', // Per controllare il video dentro lo spazio
                    }}
                >
                    {/* Video o immagine */}
                    {elencoEserciziScheda[numEsercizio].immagineBase64 === "?" ?                                
                        <FadeLoader color={"#000000"} loading={true} aria-label="Loading Spinner" data-testid="loader"/>                                
                    :
                        elencoEserciziScheda[numEsercizio].tipoImmagine.startsWith("video") ?
                            videoUrl === "" ?
                                <FadeLoader color={"#000000"} loading={true} aria-label="Loading Spinner" data-testid="loader"/>
                            :
                                <video 
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain', // Rende visibile tutto il contenuto del video
                                    }} 
                                    key={videoUrl} 
                                    controls
                                >
                                    <source 
                                        src={videoUrl} 
                                        type={elencoEserciziScheda[numEsercizio].tipoImmagine} 
                                    />
                                    Your browser does not support the video tag.
                                </video>
                        :
                            <img
                                className={stile.schedaAllenamentoImmagineEsercizioFull}
                                src={elencoEserciziScheda[numEsercizio].immagineBase64}
                                alt="Esercizio"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain', // Rende visibile tutta l'immagine
                                }}
                            />
                    }
                </div>

                {/* Bottoni in basso */}
                <div style={{ display: "flex", flexDirection: 'row', height: '50px', flexShrink: 0 }}>
                    <div
                        className={`${stile.bottone3} ${numEsercizio > 0 ? stile.bottoneColoreNormale : stile.bottoneColoreGrigio}`}                          
                        onClick={() => { if (numEsercizio > 0) setNumEsercizio(numEsercizio - 1) }}
                    >
                        <img
                            className={stile.iconaPiccolaQuadrata}
                            src={require('./frecciasinistra.png')}
                            alt="Freccia Sinistra"
                        />
                        <span className={stile.bottoneTestoNormale}>PRECEDENTE</span>
                    </div>

                    <div 
                        className={`${stile.bottone3} ${numEsercizio < elencoEserciziScheda.length - 1  ? stile.bottoneColoreNormale : stile.bottoneColoreGrigio}`} 
                        onClick={() => { if (numEsercizio < elencoEserciziScheda.length - 1) setNumEsercizio(numEsercizio + 1) }}
                    >
                        <img
                            className={stile.iconaPiccolaQuadrata}
                            src={require('./frecciadestra.png')}
                            alt="Freccia Destra"
                        />
                        <span className={stile.bottoneTestoNormale}>SEGUENTE</span>
                    </div>
                </div>
            </div>
        )
    }    

    return (
        <div>Errore pagina non trovata (AllenamentiVisualizzaScheda)</div>
    )
}

export default AllenamentiVisualizzaScheda;
