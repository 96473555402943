import React, {Fragment,useState,useEffect,Suspense} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button } from 'reactstrap';
import Parametri from '../../parametri';
import FormGridFormRow from '../SchedaEsercizio/FormGridFormRow';

import TabellaRicerca from '../tabellaricerca.js';

import Clessidra from '../Clessidra.js';
import FinestraDialogo from '../FinestraDialogo.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import Modale from '../Modale.js';


//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
const STATO_VAI_IN_ELIMINA= 5;
const STATO_VAI_IN_INSERISCI= 6;


function TabellaEsercizi(props) {
    
    const operazione = "elencoEsercizi";
      
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const [idModifica,setIdModifica]=useState('');
    const [flagModaleInfoPopolamento, setFlagModaleInfoPopolamento] = React.useState(false);
  
    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Denominazione',
        accessor: 'DESCRIZIONE',
        pdf: 1,
      },
      {
        etichetta: 'Classificazione',
        accessor: 'CLASSE',
        pdf: 1,
      },
    ];

    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function VaiElimina(id) {
      setStatoPagina(STATO_VAI_IN_ELIMINA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }
    function tornaElenco() {
      setFlagModaleInfoPopolamento(false);
    }

    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])
    
    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
              setRisultatoServer(valoreDiRitorno.risultatoRitorno);setStatoPagina(STATO_OPERAZIONE_CORRETTA);}
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])

  
  return (
      <div>

      {statoPagina === STATO_VAI_IN_INSERISCI ? 
        <div>
        <Suspense fallback={
          <div className="loader-container">
          <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda esercizio in corso...</h6></div>
          </div>
        }>
        {/*<Route path="/SchedaAliquota" component={SchedaAliquota}/>*/}
        <FormGridFormRow {...props} ope={"inserisci"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
        </Suspense>
      </div>
      :""} 

      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda esercizio in corso...</h6></div>
            </div>
          }>
          {/*<Route path="/SchedaAliquota" component={SchedaAliquota}/>*/}
          <FormGridFormRow {...props} id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_VAI_IN_ELIMINA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda esercizio in corso...</h6></div>
            </div>
          }>
          {/*<Route path="/SchedaAliquota" component={SchedaAliquota}/>*/}
          <FormGridFormRow {...props} id={idModifica} ope={"cancella"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_ESE</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
            {flagModaleInfoPopolamento=== true ?
                    <Modale 
                        larghezza = "lg"
                        titolo="Informativa esercizi"
                        flagErrore={false}
                        contenuto={<div>Questo ambiente consente una gestione completa degli esercizi.<br></br>
                        Gli esercizi inseriti in questa sezione alimenteranno la tendina Esercizi utilizzabile sia nell’ambiente di creazione e modifica delle schede predefinite (Allenamenti - Schede Allenamento Predefinite), sia nelle schede assegnate agli utenti (Allenamenti - Schede Allenamento Assegnate).
                        <br></br><br></br>
                        I dati caratteristici definiti per ogni esercizio, come il numero di serie, le ripetizioni, il recupero tra serie, ecc., verranno proposti come valori predefiniti quando l’esercizio viene utilizzato per comporre una scheda. 
                        <br></br>Questi valori, tuttavia, possono essere modificati in base alle esigenze.
                        <br></br><br></br>
                        La tendina delle classificazioni (ad esempio: Riscaldamento, Forza, Massa, ecc.) può essere popolata nell’ambiente Allenamenti - Classificazioni Esercizi oppure direttamente durante l’inserimento o modifica di un esercizio, specificando una classificazione non ancora presente nella tendina.
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {tornaElenco()}
                            }    
                        ]}
                    />
                :""}
                    <CardBody>
                            <CardTitle>Elenco Esercizi
                            </CardTitle>
                            <Button color="success" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo</Button>
                            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoPopolamento(true)}/>
                    </CardBody>        
                        
                            {risultatoServer !== undefined && risultatoServer !== "" ?
                              <TabellaRicerca titolo={`Elenco Esercizi`} columns={colonne} data={risultatoServer} campoChiave={0} campoOrdinamento={1} nascondiColonna={0} callbackSelezionaElemento={VaiModifica} callbackEliminaElemento={VaiElimina}/>
                            : 
                              <Table responsive bordered hover striped className="mb-0">
                              <thead>
                                <tr>
                                  <th>Nessun dato presente</th>
                                </tr>
                              </thead>
                              </Table>
                          }
                      


        </div>
      :""}
    </div>
   );
}
export default TabellaEsercizi;