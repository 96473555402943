import React, {useState, useEffect} from 'react';

import Tendina2 from './Tendina2';
import Clessidra from '../Pagine/Clessidra'
import stile from './stile.module.css';

import Resizer from "react-image-file-resizer";

function AllenamentiEsercizi(props) {
    const PAGINE = {
        "caricamentoDati": 1,
        "elencoEsercizi": 2,
        "esercizioModifica": 3,
        "esercizioInserisci": 4,
        "esercizioSostituisci": 5
    };
    const [pagina, setPagina] = useState(PAGINE.caricamentoDati);
    const [elencoEsercizi, setElencoEsercizi] = useState([]); // json
    const [elencoNomiEsercizi, setElencoNomiEsercizi] = useState([]); // stringa
    const [elencoClassi, setElencoClassi] = useState([]);
    const [idEsercizio, setIdEsercizio] = useState(0);
    const [classeEsercizio, setClasseEsercizio] = useState("");
    const [classeEsercizioPerFiltroElenco, setClasseEsercizioPerFiltroElenco] = useState("");
    const [nomeEsercizio, setNomeEsercizio] = useState("");
    const [descrizione, setDescrizione] = useState("");
    const [serie, setSerie] = useState(0);
    const [ripetizioni, setRipetizioni] = useState(0);
    const [recupero, setRecupero] = useState("");
    const [noteEsercizio, setNoteEsercizio] = useState("");
    const [nomeImmagine, setNomeImmagine] = useState("");
    const [tipoImmagine, setTipoImmagine] = useState("");
    const [immagineBase64, setImmagineBase64] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [flagFotoModificata, setFlagFotoModificata] = useState(false);
    const [flagEliminaFoto, setFlagEliminaFoto] = useState(false);
    const [semaforoSelezioneImmagine, setSemaforoSelezioneImmagine] = useState(false);
    const [flagVisualizzaModaleEsNonCancellabile, setFlagVisualizzaModaleEsNonCancellabile] = useState(false);
    const [opzioneCancellazione, setOpzioneCancellazione] = useState(-1);
    const [numeroSchedeConEsercizio, setNumeroSchedeConEsercizio] = useState(0);
    const [idEsercizioSorgente, setIdEsercizioSorgente] = useState(0);
    const [idEsercizioDestinazione, setIdEsercizioDestinazione] = useState(0);

    function scaricaElencoEsercizi() {
        //console.log("scarica elenco esercizi", props)
        setPagina(PAGINE.caricamentoDati);
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "elencoClassiEsercizi", emak: props.emak, sessionId: props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    
                    var es=['']; // prima voce vuota
                    for (let i=0; i<valore_di_ritorno.risultatoRitorno.length; i++) {
                        es[i+1] = valore_di_ritorno.risultatoRitorno[i].DESCRIZIONE;
                    }
                    setElencoClassi(es);

                    requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ op: "elencoEsercizi", emak: props.emak, sessionId: props.sessionId })
                    };
                    fetch(props.UrlBackend, requestOptions)
                        .then(risposta => risposta.json())
                        .then(
                            (valore_di_ritorno) => {
                                if (valore_di_ritorno.verifvers === 0) {
                                    // necessario aggiornare la app
                                    props.callbackAggiornaApp();
                                    return;
                                }
                                if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                                if (valore_di_ritorno.risultatoOperazione !== 1) { 
                                    window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                                    props.callback(props.PAGINECLIENTI.login);
                                    return; 
                                }
                                setElencoEsercizi(valore_di_ritorno.risultatoRitorno);
                                var ar=[];
                                for (var i=0; i<valore_di_ritorno.risultatoRitorno.length; i++) {
                                    ar[i] = valore_di_ritorno.risultatoRitorno[i].DESCRIZIONE;
                                }
                                setElencoNomiEsercizi(ar);
                                setPagina(PAGINE.elencoEsercizi);                    
                            },
                            (error) => {
                                props.callback(props.PAGINECLIENTI.login);
                                return;
                            }
                        );                    
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );                
        setPagina(PAGINE.caricamentoDati);         
    }

    function insModEsercizio() {
        if (nomeEsercizio=="") {
            window.alert("Impossibile memorizzare: indica il nome dell'esercizio"); 
            return;
        }
        setPagina(PAGINE.caricamentoDati);
        const estensione = nomeImmagine.split('.').pop();

        const formData = new FormData();
        formData.append('op', pagina===PAGINE.esercizioModifica ? "esercizioMod" : "esercizioIns");
        formData.append('descrizione',nomeEsercizio);
        formData.append('spiegazione',descrizione);

        formData.append('serieStd',serie);
        formData.append('ripetizioniStd',ripetizioni);
        formData.append('recuperoStd',recupero);
        formData.append('noteStd',noteEsercizio);

        formData.append('classe', classeEsercizio===undefined ? "" : classeEsercizio);
        formData.append('sceltaClasse', 1);

        if (flagFotoModificata) {
            formData.append('contenutoFileFoto', tipoImmagine.startsWith("video") ? videoUrl : immagineBase64);
            formData.append('estensioneFileFoto', estensione);
            formData.append('typeFileFoto', tipoImmagine);
        }
            
        formData.append('flagEliminaFoto', flagEliminaFoto?"on":"");
            
        formData.append('idElemento', pagina===PAGINE.esercizioModifica ? idEsercizio : 0);
        formData.append('emak', props.emak);
            
        formData.append('sessionId', props.sessionId);

        const requestOptions = {
            method: 'POST',
            body: formData
        }

        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) {
                        if (valore_di_ritorno.mess=="Spazio Terminato") {
                            window.alert("Operazione fallita: spazio insufficiente, contatta il responsabile degli acquisti per aumentare lo spazio di archiviazione di Gestione Sportiva");
                        } else {
                            window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica");
                        }
                    }
                    scaricaElencoEsercizi();                    
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function cancEsercizio(id) {
        var bodyRichiesta;
        switch (opzioneCancellazione) {
            case 1:
                bodyRichiesta = JSON.stringify({ 
                    op: "esercizioCanc", 
                    idElemento: id,
                    eliminaEsercizioDalleSchede: 1,
                    emak: props.emak, 
                    sessionId: props.sessionId
                })
                break;
            case 2:
                bodyRichiesta = JSON.stringify({ 
                    op: "esercizioCanc", 
                    idElemento: id,
                    eliminaSchedeConEsercizio: 1,
                    emak: props.emak, 
                    sessionId: props.sessionId
                })
                break;            
            default:
                bodyRichiesta = JSON.stringify({ 
                    op: "esercizioCanc", 
                    idElemento: id,
                    emak: props.emak, 
                    sessionId: props.sessionId
                })
        }

        // reset delle opzioni di cancellazione nella modale "cosa vuoi fare?"
        setOpzioneCancellazione(-1);
        // questo serve per avere l'id dell'esercizio da cancellare nella var di stato, per il passo successivo
        // cioè per la risposta alla modale "cosa vuoi fare?"
        if (idEsercizio!=id) setIdEsercizio(id);
        setPagina(PAGINE.caricamentoDati);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodyRichiesta
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        if (valore_di_ritorno.mess==="Dato non cancellabile: presente in schede allenamento") {
                            setFlagVisualizzaModaleEsNonCancellabile(true);
                            setOpzioneCancellazione(-1);
                            setNumeroSchedeConEsercizio(valore_di_ritorno.numSchede);                 
                            return;
                        } else {
                            window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        }
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    scaricaElencoEsercizi();                    
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function sostituisciEsercizio(idEsercizioSorgente, idEsercizioDestinazione) {      
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "esercizioSostituisci", 
                idEsercizioSorgente: idEsercizioSorgente,
                idEsercizioDestinazione: idEsercizioDestinazione, 
                emak: props.emak, 
                sessionId: props.sessionId
            })
        };
        setPagina(PAGINE.caricamentoDati);
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    setPagina(PAGINE.elencoEsercizi);                    
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function scaricaEsercizioPerModifica(id) {
        setPagina(PAGINE.caricamentoDati);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "esercizioRecuperaDati", 
                id: id,
                scaricaVideo: 1,
                emak: props.emak, 
                sessionId: props.sessionId
            })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Operazione fallita: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    setIdEsercizio(id);
       
                    setClasseEsercizio(valore_di_ritorno.risultatoRitorno[0].DESCRIZIONE_CLASSE);
                    setNomeEsercizio(valore_di_ritorno.risultatoRitorno[0].DESCRIZIONE);
                    setDescrizione(valore_di_ritorno.risultatoRitorno[0].SPIEGAZIONE);
                    setSerie(valore_di_ritorno.risultatoRitorno[0].SERIE_STD);
                    setRipetizioni(valore_di_ritorno.risultatoRitorno[0].RIPETIZIONI_STD);
                    setRecupero(valore_di_ritorno.risultatoRitorno[0].RECUPERO_TRA_SERIE_STD);
                    setNoteEsercizio(valore_di_ritorno.risultatoRitorno[0].NOTE_ESERCIZIO_STD);
                    setNomeImmagine("foto_esercizio."+valore_di_ritorno.risultatoRitorno[0].ESTENSIONE_FOTO);
                    setImmagineBase64(valore_di_ritorno.risultatoRitorno[0].FILE);
                    setTipoImmagine(valore_di_ritorno.risultatoRitorno[0].TYPE);
                    setPagina(PAGINE.esercizioModifica);

                    console.log(valore_di_ritorno);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function scegliImmagine(valore) {

        console.log("scegli",valore)

        if (valore.size > 50000000) {
            alert("Seleziona un file che non sia più grande di 50 MB");
            return;
        }

        //MEMORIZZO
        let typeFile = '';
        
        if (valore.type.substring(0, 5) === "image") {
            if (semaforoSelezioneImmagine) return; // c'è già una elaborazione di immagine in corso
            setSemaforoSelezioneImmagine(true);
            var nuovoTipo = valore.type.substring(6);
            if (nuovoTipo.toUpperCase()!="JPG" && nuovoTipo.toUpperCase()!="JPEG" && nuovoTipo.toUpperCase()!="PNG") {
                nuovoTipo = "JPEG";
                typeFile = "image/jpeg";
            }else{
                typeFile = valore.type;
            }

            try {
                Resizer.imageFileResizer(
                    valore,
                    600,
                    600,
                    nuovoTipo,
                    100,
                    0,
                    (uri) => {
                        setImmagineBase64(uri);
                        setSemaforoSelezioneImmagine(false);
                        setFlagEliminaFoto(false);
                    },
                    "base64"
                );                
            } catch (err) {
                // console.log(err);
                alert("Si è verificato un errore nel ridimensionamento dell'immagine, contattare l'assistenza tecnica");
            }
        } else {            
            if (valore.type.substring(0, 5) === "video") {
                typeFile = valore.type;
                // Converti il video in Base64
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImmagineBase64(reader.result); // Salva il video come stringa Base64
                    setFlagEliminaFoto(false);
                };
                reader.readAsDataURL(valore); // Legge il file come Data URL (Base64)
            } else {
                setImmagineBase64("");
            }
        }
        //const estensione = valore.name.split('.').pop();
        //console.log("ImmagineBase64---"+valore);
        //console.log("typeFile---"+typeFile);
        //console.log("valore.name---"+valore.name);
        setTipoImmagine(typeFile); 
        setNomeImmagine(valore.name);        
    }

    useEffect(() => {
        scaricaElencoEsercizi();
    }, [])

    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            switch (pagina) {
                case PAGINE.caricamentoDati: break;
                case PAGINE.elencoEsercizi: props.identitaSelezionata.flag_staff ? props.callback(props.PAGINECLIENTI.allenamenti) : props.callback(props.PAGINECLIENTI.home); break;
                case PAGINE.esercizioModifica: setPagina(PAGINE.elencoEsercizi); break;
                case PAGINE.esercizioInserisci: setPagina(PAGINE.elencoEsercizi); break;
                case PAGINE.esercizioSostituisci: setPagina(PAGINE.elencoEsercizi); break;
            }
            
        } 
    }, [props.flagRichiestaBack])   

    useEffect(() => {
        if (immagineBase64) {
            if (immagineBase64 != "") {
                setVideoUrl(immagineBase64);
            }        
        }
    }, [immagineBase64]);

    if (pagina === PAGINE.caricamentoDati) {
        return (<Clessidra loading={true} />)
    }

    if (pagina === PAGINE.esercizioSostituisci) {
        return (
            <div style={{ margin: 5 }}>
                <div class={stile.eventiModificaTestoTitoli} style={{paddingTop:5, paddingBottom:5}}>
                    Sostituisci, in tutte le schede di allenamento, l'esercizio:
                </div>
                <Tendina2 
                    className={stile.genericaDropdown}
                    options={elencoNomiEsercizi}
                    defaultValue={" "}
                    onSelect={(t) => setIdEsercizioSorgente(elencoEsercizi[t].ID) }
                />
                <div class={stile.eventiModificaTestoTitoli} style={{paddingTop:5, paddingBottom:5}}>
                    con l'esercizio:
                </div>
                <Tendina2 
                    className={stile.genericaDropdown}
                    options={elencoNomiEsercizi}
                    defaultValue={" "}
                    onSelect={(t) => setIdEsercizioDestinazione(elencoEsercizi[t].ID) }
                />
    
                <div style={{display:'flex', flexDirection:'row', paddingTop:20}}>
                    <div class={`${stile.bottone3} ${stile.bottoneColoreNormale}`} onClick={() => { setPagina(PAGINE.elencoEsercizi) }}>
                        <div class={stile.spazioIconaPiccola}>
                            <img class={stile.iconaPiccolaQuadrata} src={require("./annulla.png")} alt="Annulla" />
                        </div>
                        <div class={stile.bottoneTestoNormale}>ANNULLA</div>
                    </div>
                    <div 
                        className={`${stile.bottone3} ${idEsercizioSorgente==0 || idEsercizioDestinazione==0 || idEsercizioSorgente==idEsercizioDestinazione ? stile.bottoneColoreGrigio : stile.bottoneColoreRosso}`} 
                        onClick={() => { 
                            if (idEsercizioSorgente==0 || idEsercizioDestinazione==0 || idEsercizioSorgente==idEsercizioDestinazione) return;
                            sostituisciEsercizio(idEsercizioSorgente, idEsercizioDestinazione);
                        }}
                    >
                        <div class={stile.spazioIconaPiccola}>
                            <img class={stile.iconaPiccolaQuadrata} src={require("./ok.png")} alt="Conferma" />
                        </div>
                        <div class={stile.bottoneTestoNormale}>CONFERMA</div>
                    </div>
                </div>
            </div>
        )
    }
    
    
    if (pagina === PAGINE.esercizioInserisci || pagina === PAGINE.esercizioModifica) {
        return (
            <div style={{ margin: 5, overflowY: 'auto' }}>
                <div>
                    <div class={stile.eventiModificaTestoTitoli} style={{paddingBottom:5}}>
                        {pagina === PAGINE.esercizioModifica ? "Modifica esercizio" : "Inserisci nuovo esercizio"}
                    </div>
    
                    <div class={stile.eventiModificaTestoTitoli}>Classificazione esercizio</div>
                    <Tendina2 
                        className={stile.genericaDropdown}
                        options={elencoClassi}
                        defaultValue={classeEsercizio === undefined ? " " : classeEsercizio}
                        onSelect={(t) => setClasseEsercizio(elencoClassi[t]) }
                    />
    
                    <div class={stile.eventiModificaTestoTitoli}>Nome esercizio*</div>
                    <div style={{display: 'flex', flexDirection:'row'}}>
                        <input value={nomeEsercizio} onChange={(e) => setNomeEsercizio(e.target.value)} maxLength={200} class={stile.eventiModificaInputTesto} style={{flex:1}} />
                        <div onClick={() => setNomeEsercizio("")}>
                            <img class={stile.iconaPiccolaQuadrata} src={require("./x.png")} alt="Annulla" style={{marginLeft:5}} />
                        </div>
                    </div>                            
    
                    <div class={stile.eventiModificaTestoTitoli}>Immagine o Video {tipoImmagine}</div>
                    <div style={{display: 'flex', flexDirection:'row'}}>
                        {semaforoSelezioneImmagine ?
                            <div class={stile.eserciziSpazioImmagine}> 
                                <Clessidra loading={true}/>
                            </div>
                        :
                            <>
                            <div style={{ borderColor: "#ffffff", borderWidth: 1, borderStyle:"solid" }}>
                                { 
                                    tipoImmagine.startsWith("image") ?
                                        <img 
                                            className={stile.eserciziImmagine} 
                                            src={immagineBase64 === "" ? require('./allenamenti.png') : immagineBase64} 
                                            alt="" 
                                        />
                                    :
                                        tipoImmagine.startsWith("video") ? 
                                            <video className={stile.eserciziImmagine} key={videoUrl} controls>
                                            <source src={videoUrl} type={tipoImmagine} />
                                                Your browser does not support the video tag.
                                            </video>
                                        :
                                        <></>
                                }
                                <div 
                                    class={`${stile.bottone3} ${stile.bottoneColoreNormale}`} 
                                    style={{marginBottom:"5px", minWidth:"100px"}} 
                                    onClick={() => {setFlagFotoModificata(true); document.getElementById("fileInput").click()}}
                                >
                                    <div class={stile.spazioIconaPiccola}>
                                        <img class={stile.iconaPiccolaQuadrata} src={require("./modifica.png")} alt="Cambia" />
                                    </div>
                                    <div class={stile.bottoneTestoNormale}>{tipoImmagine==""?"Aggiungi":"Cambia"}</div>
                                </div>                                
                            </div>

                            <div onClick={() => { setImmagineBase64(""); setTipoImmagine(""); setNomeImmagine(""); setFlagEliminaFoto(true);}}>
                                <img class={stile.iconaPiccolaQuadrata} src={require("./x.png")} alt="Annulla" style={{marginLeft:5}} />
                            </div>                        
                            <input
                                type="file"
                                id="fileInput"
                                accept={".jpg,.jpeg,.gif,.png,.webp,.m4v,.mp4,.avi,.mkv,.webm,.mov"}
                                style={{ display: "none" }} // Nasconde l'input file
                                onChange={(e) => scegliImmagine(e.target.files[0])}
                            />
                            </>
                        }
                        
                    </div>

    
                </div>
    
                <div style={{display:"flex", flexDirection:"row"}}>
                    <div style={{flex:1}}>
                        <div class={stile.eventiModificaTestoTitoli}>N. serie</div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <input value={serie > 0 ? serie.toString() : ""} type="number" onChange={(e) => setSerie(e.target.value)} maxLength={50} class={stile.eventiModificaInputTesto} style={{flex:1}} />
                            <div onClick={() => setSerie(0)}>
                                <img class={stile.iconaPiccolaQuadrata} src={require("./x.png")} alt="Annulla" style={{marginLeft:5}} />
                            </div>
                        </div>
                    </div>            
    
                    <div style={{flex:1, marginLeft: 5}}>
                        <div class={stile.eventiModificaTestoTitoli}>Ripetizioni</div>
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <input value={ripetizioni > 0 ? ripetizioni.toString() : ""} type="number" onChange={(e) => setRipetizioni(e.target.value)} maxLength={50} class={stile.eventiModificaInputTesto} style={{flex:1}} />
                            <div onClick={() => setRipetizioni("")}>
                                <img class={stile.iconaPiccolaQuadrata} src={require("./x.png")} alt="Annulla" style={{marginLeft:5}} />
                            </div>
                        </div>
                    </div>            
                </div>
    
                <div>
                    <div class={stile.eventiModificaTestoTitoli}>Recupero tra serie</div>
                    <div style={{display: 'flex', flexDirection:'row'}}>
                        <textarea 
                            value={recupero} 
                            onChange={(e) => setRecupero(e.target.value)} 
                            maxLength={200} 
                            class={stile.eventiModificaInputTesto}
                            style={{flex:1}}
                        />
                        <div onClick={() => setRecupero("")}>
                            <img class={stile.iconaPiccolaQuadrata} src={require("./x.png")} alt="Annulla" style={{marginLeft:5}} />
                        </div>
                    </div>
                </div>
    
                <div class={stile.eventiModificaTestoTitoli}>Descrizione</div>
                <div style={{display: 'flex', flexDirection:'row'}}>
                    <textarea 
                        value={descrizione} 
                        onChange={(e) => setDescrizione(e.target.value)} 
                        maxLength={200} 
                        class={stile.eventiModificaInputTesto}
                        style={{flex:1}}
                    />
                    <div onClick={() => setDescrizione("")}>
                        <img class={stile.iconaPiccolaQuadrata} src={require("./x.png")} alt="Annulla" style={{marginLeft:5}} />
                    </div>
                </div>
    
                <div class={stile.eventiModificaTestoTitoli}>Note esercizio</div>
                <div style={{display: 'flex', flexDirection:'row'}}>
                    <textarea 
                        value={noteEsercizio} 
                        onChange={(e) => setNoteEsercizio(e.target.value)} 
                        maxLength={200} 
                        class={stile.eventiModificaInputTesto}
                        style={{flex:1}}
                    />
                    <div onClick={() => setNoteEsercizio("")}>
                        <img class={stile.iconaPiccolaQuadrata} src={require("./x.png")} alt="Annulla" style={{marginLeft:5}} />
                    </div>
                </div>
    
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreRosso}`} 
                        onClick={() => { scaricaElencoEsercizi(); }}
                    >
                        <div class={stile.spazioIconaPiccola}>
                            <img 
                                class={stile.iconaPiccolaQuadrata} 
                                src={require("./annulla.png")} 
                                alt="Annulla" 
                            />
                        </div>
                        <div class={stile.bottoneTestoNormale}>ANNULLA</div>
                    </div>
    
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreVerde}`} 
                        onClick={() => { insModEsercizio(); }}
                    >
                        <div class={stile.spazioIconaPiccola}>
                            <img 
                                class={stile.iconaPiccolaQuadrata}
                                src={require('./modifica.png')}
                                alt="Memorizza" 
                            />
                        </div>
                        <div class={stile.bottoneTestoNormale}>MEMORIZZA</div>
                    </div>
                </div>
            </div>
        )
    }
    

    if (pagina === PAGINE.elencoEsercizi) {
        return (
            <>
                {flagVisualizzaModaleEsNonCancellabile ? 
                    <>
                        <div 
                            style={{
                                display: 'flex', flexDirection: 'row', justifyContent: "center",
                                alignItems: "center", marginTop: 22, margin: 20, backgroundColor: "lightgray",
                                borderRadius: 20, padding: 15, shadowColor: "#000", shadowOffset: { width: 5, height: 8 },
                                shadowOpacity: 0.5, shadowRadius: 4, elevation: 5, borderWidth: 2, borderColor: "black"
                            }}
                        >
                            <div style={{ flex: 1 }}>
                                <p style={{ fontSize: 16, fontWeight: 'bold', paddingBottom: 10 }}>
                                    L'esercizio che vuoi eliminare si trova dentro {numeroSchedeConEsercizio} {numeroSchedeConEsercizio === 1 ? "scheda" : "schede"} allenamento.
                                </p>
                                <p style={{ width: "100%", textAlign: 'center', fontSize: 20, color: "#606060" }}>
                                    Cosa vuoi fare?
                                </p>
    
                                <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }} onClick={() => setOpzioneCancellazione(0)}>
                                    <img
                                        className={stile.iconaPiccolaQuadrata}
                                        src={opzioneCancellazione === 0 ? './cerchio_ok.png' : './cerchio_vuoto.png'}
                                        alt="Cerchio"
                                    />
                                    <p style={{ paddingLeft: 10, paddingRight: 15, fontSize: 16 }}>Non eliminare questo esercizio.</p>
                                </div>
    
                                <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }} onClick={() => setOpzioneCancellazione(1)}>
                                    <img
                                        className={stile.iconaPiccolaQuadrata}
                                        src={opzioneCancellazione === 1 ? './cerchio_ok.png' : './cerchio_vuoto.png'}
                                        alt="Cerchio"
                                    />
                                    <p style={{ paddingLeft: 10, paddingRight: 15, fontSize: 16 }}>Elimina l'esercizio da tutte le schede in cui si trova e poi elimina l'esercizio dall'elenco degli esercizi. Attenzione: quelle schede avranno quindi quell'esercizio in meno.</p>
                                </div>
    
                                <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }} onClick={() => setOpzioneCancellazione(2)}>
                                    <img
                                        className={stile.iconaPiccolaQuadrata}
                                        src={opzioneCancellazione === 2 ? './cerchio_ok.png' : './cerchio_vuoto.png'}
                                        alt="Cerchio"
                                    />
                                    <p style={{ paddingLeft: 10, paddingRight: 15, fontSize: 16 }}>Elimina tutte le schede in cui si trova questo esercizio e poi elimina l'esercizio dall'elenco degli esercizi.</p>
                                </div>
    
                                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 20 }}>
                                    <div 
                                        className={`${stile.bottone3} ${stile.bottoneColoreNormale}`} 
                                        onClick={() => { setFlagVisualizzaModaleEsNonCancellabile(false) }}
                                    >
                                        <div className={stile.spazioIconaPiccola}>
                                            <img className={stile.iconaPiccolaQuadrata} src={require("./annulla.png")} alt="Annulla" />
                                        </div>
                                        <p className={stile.bottoneTestoNormale}>ANNULLA</p>
                                    </div>
    
                                    <div 
                                        className={`${stile.bottone3} ${opzioneCancellazione < 0 ? stile.bottoneColoreGrigio : stile.bottoneColoreRosso}`} 
                                        onClick={() => { 
                                            if (opzioneCancellazione === -1) return;
                                            setFlagVisualizzaModaleEsNonCancellabile(false);
                                            if (opzioneCancellazione > 0) cancEsercizio(idEsercizio);
                                        }}
                                    >
                                        <div className={stile.spazioIconaPiccola}>
                                            <img className={stile.iconaPiccolaQuadrata} src={require("./ok.png")} alt="Conferma" />
                                        </div>
                                        <p className={stile.bottoneTestoNormale}>CONFERMA</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ position: 'absolute', top: 0, left: 0, width: "100%", height: "100%", backgroundColor: 'rgba(52, 52, 52, 0.8)', zIndex: 10 }}></div>
                    </>
                : null }
    
                <div className={stile.scrollView}>
                    <div style={{ display: "flex", flexDirection: "row", paddingLeft: 10, paddingRight: 10, paddingBottom: 10, paddingTop: 5, marginBottom: 5, backgroundColor: "#E0E0E0" }}>
                        <div 
                            className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}  
                            onClick={() => { 
                                setIdEsercizio(0);
                                setClasseEsercizio("");
                                setNomeEsercizio("");
                                setDescrizione("");
                                setSerie("");
                                setRipetizioni("");
                                setRecupero("");
                                setNoteEsercizio("");
                                setNomeImmagine("");
                                setImmagineBase64("");
                                setTipoImmagine("");
                                setPagina(PAGINE.esercizioInserisci);
                            }}
                        >
                            <div className={stile.spazioIconaPiccola}>
                                <img className={stile.iconaPiccolaQuadrata}
                                src={require('./stella.png')} alt="Inserisci nuovo" />
                            </div>
                            <p className={stile.bottoneTestoNormale}>Inserisci nuovo</p>
                        </div>
    
                        <div 
                            className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}  
                            onClick={() => { 
                                setIdEsercizioSorgente(0);
                                setIdEsercizioDestinazione(0);                    
                                setPagina(PAGINE.esercizioSostituisci);
                            }}
                        >
                            <div className={stile.spazioIconaPiccola}>
                                <img className={stile.iconaPiccolaRettangolare} 
                                src={require("./frecciasxdx.png")} alt="Sostituisci" />
                            </div>
                            <p className={stile.bottoneTestoNormale}>Sostituisci esercizio</p>
                        </div>
                    </div>
    
                    <div style={{ paddingBottom: 5 }}>
                        <p className={stile.eventiModificaTestoTitoli} style={{ paddingLeft: 10 }}>Visualizza esercizi con classificazione:</p>
                        <Tendina2 
                            className={stile.genericaDropdown}
                            options={elencoClassi}
                            defaultValue={classeEsercizioPerFiltroElenco === undefined ? " " : classeEsercizioPerFiltroElenco === "" ? " " : classeEsercizioPerFiltroElenco}
                            onSelect={(t) => { setClasseEsercizioPerFiltroElenco(elencoClassi[t]) }}
                        />
                    </div>
    
                    {elencoEsercizi.map((x, i) => 
                        x.CLASSE === classeEsercizioPerFiltroElenco || classeEsercizioPerFiltroElenco === "" ? 
                            <div key={i} className={stile.elencoSchedeContenitoreElemento}>
                                <div style={{ flex: 1 }} onClick={() => { scaricaEsercizioPerModifica(x.ID); }}>
                                    <p className={stile.elencoSchedeNome}>{x.DESCRIZIONE}</p>
                                </div>
                                <div style={{ width: 50, alignItems: "center" }} onClick={() => { scaricaEsercizioPerModifica(x.ID); }}>
                                    <img className={stile.iconaPiccolaQuadrata} src={require("./modifica.png")} alt="Modifica" />
                                </div>
                                <div 
                                    style={{ width: 50, alignItems: "center" }} 
                                    onClick={() => { 
                                        if (window.confirm("Confermi eliminazione dell'esercizio "+x.DESCRIZIONE+"?")) {
                                            cancEsercizio(x.ID) }
                                        }
                                    }
                                >
                                    <img className={stile.iconaPiccolaQuadrata} src={require("./xgrigia-nobordo.png")} alt="Elimina" />
                                </div>
                            </div>
                        : <div key={i} />
                    )}
                </div>
            </>
        );
    }
    

    return (
        <div>Errore pagina non trovata (AllenamentiEsercizi)</div>
    )
}

export default AllenamentiEsercizi;
