import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';

import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input, Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import FormattaImportoVideo from '../FormattaImportoVideo.js';
import FormattaImportoDb from '../FormattaImportoDb.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record

const STATO_RICHIEDI_PERMESSO_INSERIRE = 8;


function FormGridFormRow(props) {
    
    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno;

    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagAttivaModaleDataPagamento, setFlagAttivaModaleDataPagamento]  = useState(0);
    const [flagAttivaModaleRisorsa, setFlagAttivaModaleRisorsa]  = useState(0);
    const [flagAttivaModaleVoceBilancio, setFlagAttivaModaleVoceBilancio]  = useState(0);
    const [flagAttivaModaleImporto, setFlagAttivaModaleImporto]  = useState(0);
    const [flagAttivaModaleDescrizione, setFlagAttivaModaleDescrizione]  = useState(0);
    const [flagAttivaModaleCampiTendina, setFlagAttivaModaleCampiTendina]  = useState(0);
    const [flagAttivaModaleImportoErrato, setFlagAttivaModaleImportoErrato] = useState(0);
    const [flagAttivaModaleIvaErrata, setFlagAttivaModaleIvaErrata] = useState(0);
    const [flagAttivaModaleSpiegaAttivita, setFlagAttivaModaleSpiegaAttivita] = useState(0);

    const descrizione   = useRef();
    const importo   = useRef();
    const iva  = useRef();
    const dataPagamento   = useRef();
    const note   = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [statoTendinaRisorse,setStatoTendinaRisorse]=useState(false);
    const [statoTendinaVocidiBilancio,setStatoTendinaVocidiBilancio]=useState(false);
    
    const [error,setError]=useState('');

    const [risultatoServerDatiRisorse,setRisultatoServerDatiRisorse]=useState('');
    const [risultatoServerDatiVocidiBilancio,setRisultatoServerDatiVocidiBilancio]=useState('');
  
    const [descrizioneDefault,setDescrizioneDefault]=useState('');
    const [importoDefault,setImportoDefault]=useState('');
    const [ivaDefault,setIvaDefault]=useState('');
    const [dataPagamentoDefault,setDataPagamentoDefault]=useState(dataOggi);
    const [idFatturaDefault,setIdFatturaDefault]=useState(0);
    const [segnoDefault,setSegnoDefault]=useState(1);

    const [noteDefault,setNoteDefault]=useState('');

    const [tipoQuota,setTipoQuota]=useState(0);
    const [statoXml,setStatoXml]=useState('');

    const [insRisorsaEdi,setInsRisorsaEdi]=useState('');
    const [insVociDiBilancioEdi,setInsVociDiBilancioEdi]=useState('');
    const [insVociDiBilancioAttEdi,setInsVociDiBilancioAttEdi]=useState('');
    const [risorsaNuova,setRisorsaNuova]=useState(0);
    const [voceNuova,setVoceNuova]=useState(0);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [operazioneInterna,setOperazioneInterna]=useState('');

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaRisorse(false);
        setStatoTendinaVocidiBilancio(false);
    },[]);

    useEffect(() => {
        if(statoTendinaRisorse === true && statoTendinaVocidiBilancio === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaRisorse,statoTendinaVocidiBilancio]);

    useEffect(() => {
        if(props.ope==='inserisci' || operazioneInterna === 'copia') setOperazione("movPrimaNotaIns");
        if(props.ope==='modifica' && operazioneInterna === '') setOperazione("movPrimaNotaMod");
        if(props.ope==='cancella') setOperazione("movPrimaNotaCanc");

        if(operazioneInterna === 'copia'){
            setDisabilitaBottoni(false);
            setDataPagamentoDefault('');
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }else{
            if(props.ope==="modifica" || props.ope==="cancella"){
                //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
                // parametri per inviare i dati al server:
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({op:"movPrimaNotaRecuperaDati",
                        id:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                };
                setStatoPagina(STATO_RICERCA_DATI);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                    (valoreDiRitorno) => {
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                        }
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione===1){
                        //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                        setInsRisorsaEdi(valoreDiRitorno.risultatoRitorno[0].RISORSA);
                        //setDefaultRisorsa(valoreDiRitorno.risultatoRitorno[0].RISORSA);
                        setInsVociDiBilancioEdi(valoreDiRitorno.risultatoRitorno[0].VOCE_DI_BILANCIO);
                        setInsVociDiBilancioAttEdi(valoreDiRitorno.risultatoRitorno[0].TIPO_ATTIVITA);
                    
                        //setDefaultVoceDiBilancio(valoreDiRitorno.risultatoRitorno[0].VOCE_DI_BILANCIO);

                        setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);

                        if(valoreDiRitorno.risultatoRitorno[0].SEGNO =='-1'){
                            setImportoDefault(-valoreDiRitorno.risultatoRitorno[0].IMPORTO);
                            setIvaDefault(-valoreDiRitorno.risultatoRitorno[0].IVA);
                        }else{ 
                            setImportoDefault(valoreDiRitorno.risultatoRitorno[0].IMPORTO);
                            setIvaDefault(valoreDiRitorno.risultatoRitorno[0].IVA);
                        }
                        setSegnoDefault(parseInt(valoreDiRitorno.risultatoRitorno[0].SEGNO));
                        setDataPagamentoDefault(valoreDiRitorno.risultatoRitorno[0].DATA_PAGAMENTO);
                        setIdFatturaDefault(valoreDiRitorno.risultatoRitorno[0].IDFATTURA);
                        //setTipoFatturaDefault(valoreDiRitorno.risultatoRitorno[0].TIPOLOGIA);
                        setNoteDefault(valoreDiRitorno.risultatoRitorno[0].NOTE);

                        setTipoQuota(valoreDiRitorno.risultatoRitorno[0].TIPO_QUOTA);
                        setStatoXml(valoreDiRitorno.risultatoRitorno[0].STATO_XML);

                        ricercaDatiRisorse();
                        ricercaDatiVociDiBilancio();
                        }
                        },
                    (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                ); 
            }else{
                setStatoPagina(STATO_RICERCA_DATI);
                ricercaDatiRisorse();
                ricercaDatiVociDiBilancio();
            }
        }
    },[props.ope,operazioneInterna]);

    function copiaMovimento(){
        if (window.confirm("Sei sicuro di voler procedere con la copia del movimento selezionato in prima nota?")) {
            setDisabilitaBottoni(true);
            setStatoPagina(STATO_RICERCA_DATI);
            setOperazioneInterna("copia");
        }
    }

    function callbacksetValoriVoceBilancio(valore,posizione,valoreNonVisibile){
        setInsVociDiBilancioEdi(valore);
        setInsVociDiBilancioAttEdi(valoreNonVisibile);
    }

    function ricercaDatiRisorse() {
        //ricerco le risorse che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoRisorse",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiRisorse(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaRisorse(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaDatiVociDiBilancio() {
        //ricerco le voci di bilancio che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoVociDiBilancio",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiVocidiBilancio(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaVocidiBilancio(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function tornaElenco() {
        props.callbackTermineModifica();
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function annullaInserimentoDatiNuovi(){


        setFlagAttivaModaleDataPagamento(0);
        setFlagAttivaModaleRisorsa(0);
        setFlagAttivaModaleVoceBilancio(0);
        setFlagAttivaModaleImporto(0);
        setFlagAttivaModaleDescrizione(0);
        setFlagAttivaModaleCampiTendina(0);
        setFlagAttivaModaleImportoErrato(0);
        setFlagAttivaModaleIvaErrata(0);

        //setStatoPagina(STATO_INSERISCI);
    }

    function fetchOperazioneServer(bypassaControlli=0) {
        
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;

        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){

            if(note.current.value.length>500){
                controlli = 1;
                alert("Il campo Note contiene troppi caratteri (massimo accettato 500)");
                setDisabilitaBottoni(false);
            }
            
            if(iva.current.value === '') iva.current.value = 0;
            
            //controllo preventivamente i campi:
            if(dataPagamento.current.value===''){
                controlli = 1;
                setFlagAttivaModaleDataPagamento(1);
            }
            if(insRisorsaEdi===''){
                controlli = 1;
                setFlagAttivaModaleRisorsa(1);
            }
            if(insVociDiBilancioEdi===''){
                controlli = 1;
                setFlagAttivaModaleVoceBilancio(1);
            }
            if(importo.current.value===''){
                controlli = 1;
                setFlagAttivaModaleImporto(1);
            }
            if(idFatturaDefault == 0){
                if(descrizione.current.value===''){
                    controlli = 1;
                    setFlagAttivaModaleDescrizione(1);
                }
            }
            if(isNaN(FormattaImportoDb(importo.current.value))){
                controlli = 1;
                setFlagAttivaModaleImportoErrato(1);
            }
            if(isNaN(FormattaImportoDb(iva.current.value))){
                controlli = 1;
                setFlagAttivaModaleIvaErrata(1);
            }

            let presenteRisorsa = -1;
            if(insRisorsaEdi !== ''){
                presenteRisorsa = 0;
                //controllo se la risorsa non è presente nell'elenco attuale:
                for (let i=0; i<risultatoServerDatiRisorse.length ; i++) {
                    if(risultatoServerDatiRisorse[i]["DESCRIZIONE"] === insRisorsaEdi){
                        presenteRisorsa=1;
                        break;
                    } 
                }
                if(presenteRisorsa === 0){
                    setRisorsaNuova(1);
                    setFlagAttivaModaleCampiTendina(1);
                    controlli = 1;
                } 
            }

            let presenteVoce = -1;
            if(insVociDiBilancioEdi !== ''){
                presenteVoce = 0;
                //controllo se la voce non è presente nell'elenco attuale:
                for (let i=0; i<risultatoServerDatiVocidiBilancio.length ; i++) {
                    if(risultatoServerDatiVocidiBilancio[i]["DESCRIZIONE"] === insVociDiBilancioEdi){
                        presenteVoce=1;
                        break;
                    } 
                }
            }
        
            if(presenteVoce === 0){
                setVoceNuova(1);
                setFlagAttivaModaleCampiTendina(1);
                controlli = 1;
            } 
            if(presenteRisorsa===0 || presenteVoce===0) setFlagAttivaModaleCampiTendina(1);
        }

        
        if(controlli===0){   
        
        if(props.ope==='modifica' && variazioneCampi===0){
            //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
            tornaElenco();
        }else{
            // parametri per inviare i dati al server:
            //console.log("sono qui"+idFatturaDefault);
            let descri = "";
            if(props.ope==='modifica' && idFatturaDefault == 0){
                descri = descrizione.current.value;
            }else{
                descri = descrizioneDefault;
            }

            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: props.ope!=='cancella' ?  
                JSON.stringify({op:operazione,
                    persona:"",
                    risorsa:insRisorsaEdi,
                    vociDiBilancio:insVociDiBilancioEdi,
                    permessoInserireRisorsa:1,
                    permessoInserireVoceDiBilancio:1,
                    descrizione:descri,
                    idFattura : idFatturaDefault,
                    importo:FormattaImportoDb(importo.current.value)*segnoDefault,
                    iva:FormattaImportoDb(iva.current.value),
                    dataPagamento:dataPagamento.current.value,
                    note:note.current.value,
                    idElemento:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
                : 
                    JSON.stringify({op:operazione,
                    idElemento:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId})
            };
            
           
            
            // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
            // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
            // variabile di stato "risultato_server"

            setStatoPagina(STATO_MEMORIZZAZIONE);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                (valoreDiRitorno) => {
                    setLoading(false);
                    if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        if(valoreDiRitorno.mess !== undefined){
                            if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire risorse") !== -1){
                                setFlagAttivaModaleCampiTendina(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire voci di bilancio") !== -1){
                                setFlagAttivaModaleCampiTendina(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Data pagamento obbligatoria") !== -1){
                                setFlagAttivaModaleDataPagamento(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Risorsa obbligatoria") !== -1){
                                setFlagAttivaModaleRisorsa(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Voce bilancio obbligatoria") !== -1){
                                setFlagAttivaModaleVoceBilancio(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Importo pagamento obbligatorio") !== -1){
                                setFlagAttivaModaleImporto(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Descrizione pagamento obbligatoria") !== -1){
                                setFlagAttivaModaleDescrizione(1);
                            } 
                            if(valoreDiRitorno.mess.indexOf("Importo errato") !== -1){
                                setFlagAttivaModaleImportoErrato(1);
                            }
                            if(valoreDiRitorno.mess.indexOf("Iva errata") !== -1){
                                setFlagAttivaModaleIvaErrata(1);
                            }
                        }
                        //altrimenti vado in errore generico
                        setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    } 
                },
                (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
        }

        }
    }

    
    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
               
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1C_PriNot {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina ===STATO_RICHIEDI_PERMESSO_INSERIRE 
                || statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleDataPagamento===1 ?
                        <Modale 
                            titolo="Richiesta data pagamento"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la data di pagamento, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleVoceBilancio===1 ?
                        <Modale 
                            titolo="Richiesta voce di bilancio"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la voce di bilancio, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleRisorsa===1 ?
                        <Modale 
                        titolo="Richiesta risorsa"
                        flagErrore={true}
                        contenuto={<div>Non è stata indicata la risorsa, che è necessaria</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                        />
                    :""}

                    {flagAttivaModaleImporto===1 ?
                        <Modale 
                        titolo="Richiesta risorsa"
                        flagErrore={true}
                        contenuto={<div>Non è stato indicato l'importo, che è necessario</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                        />
                    :""}

                    {flagAttivaModaleDescrizione===1 ?
                        <Modale 
                        titolo="Richiesta risorsa"
                        flagErrore={true}
                        contenuto={<div>Non è stata indicata la descrizione del pagamento, che è necessaria</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                        />
                    :""}
                    

                    {flagAttivaModaleCampiTendina===1 ?
                        <Modale 
                        titolo="Richiesta permesso inserimento"
                        flagErrore={true}
                        larghezza="lg"
                        contenuto={<div>
                            {risorsaNuova===1 ? <>Vuoi davvero inserire {insRisorsaEdi} nell'elenco delle risorse?<br/></> :""}
                            {voceNuova===1 ? <>Vuoi davvero inserire {insVociDiBilancioEdi} nell'elenco delle voci di bilancio?<br/><br/>Inizialmente la nuova voce di bilancio verrà impostata come attività principale.<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagAttivaModaleSpiegaAttivita(1)}/><br/> E' possibile modificare l'impostazione recandosi in Gestione Economica, Voci di Bilancio, selezionare la voce desiderata e attuare le modifiche.<br/></> :""}
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);fetchOperazioneServer(1)}
                            },  
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            } 
                        ]}
                        />
                    :""}


                    {flagAttivaModaleImportoErrato===1 ?
                        <Modale 
                        titolo="Informativa importo errato"
                        flagErrore={true}
                        contenuto={<div>L'importo inserito per l'importo non è un numero: completare il campo con un valore numerico</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                        />
                    :""}

                    {flagAttivaModaleIvaErrata===1 ?
                        <Modale 
                        titolo="Informativa importo IVA errato"
                        flagErrore={true}
                        contenuto={<div>L'importo inserito per l'IVA non è un numero: completare il campo con un valore numerico</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                        />
                    :""}
                    {flagAttivaModaleSpiegaAttivita=== 1 ?
                        <Modale 
                        titolo="Informativa Attività Principali / Secondarie"
                        flagErrore={false}
                        larghezza="lg"
                        contenuto={<div><p>La classificazione di una voce di bilancio come attività principale dipende dalla sua natura.</p> 
                            <p>Per attività principale si intende tutto ciò che è connesso direttamente alle attività sportive ed alle responsabilità istituzionali.
                            L'attivita principale coinvolge i ruoli fondamentali per la pratica sportiva stessa, come istruttori, allenatori, arbitri, tecnici e atleti.
                            Inoltre riguarda tutto ciò che è indispensabile allo svolgimento delle attività sportive: attrezzature, materiali sportivi e strutture adeguate ecc..</p>
                            <p>Sono invece da includere nelle attività secondarie tutto ciò che non è da considerarsi indispensabile per la pratica sportiva diretta.
                            Si tratta spesso di attività legate all’organizzazione e al funzionamento delle strutture sportive.
                            Ad esempio: addetti alla manutenzione degli impianti, addetti alla reception, gestione dell'area ristoro o del merchandising sportivo ecc.. </p>
                            <p>La distinzione tra le attività è utile per adempiere alle norme relative alla Riforma dello Sport.</p>
                            Il <b>report "Attività Principali / Secondarie"</b>, disponibile nella sezione Report, può essere un utile strumento per confrontare il volume di attività 
                            principali e secondarie. 
                            <p>Fornisce inoltre un'analisi dettagliata del rapporto tra attività secondarie e ricavi totali, nonché tra attività secondarie e costi totali.</p>
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);setFlagAttivaModaleSpiegaAttivita(0)}
                            }    
                        ]}
                        />
                    :""}


                    {/*<Card className={"main-card mb-3"+(statoPagina === STATO_RICHIEDI_PERMESSO_INSERIRE ? " nonvisibile":"")}>*/}
                    <div className="schedaSpazioCentrale">
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Movimento Prima Nota</CardTitle> :""}
                            {props.ope === "modifica" ? 
                                operazioneInterna === 'copia' ?
                                    <CardTitle>Inserimento Movimento Prima Nota</CardTitle> 
                                :
                                    <CardTitle>Modifica Movimento Prima Nota</CardTitle>  
                            :""}
                           
                            <Clessidra loading={loading}/>

                            {props.ope === "cancella" ?
                                    <FinestraDialogo 
                                        titolo="Conferma eliminazione" 
                                        flagErrore={true}                                
                                        contenuto={<div>
                                            Confermi l'eliminazione del movimento <b>{descrizioneDefault}</b> con importo <b>{FormattaImportoVideo(importoDefault)}</b> euro e con data pagamento <b><TrasformaDateAUmano data={dataPagamentoDefault}></TrasformaDateAUmano></b> ?<br/>                                    
                                        </div>}
                                        bottoni={[
                                            {
                                                "etichetta":"Conferma",
                                                "tipo":"primary",
                                                callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                            },
                                            {
                                                "etichetta":"Annulla",
                                                "tipo":"primary",
                                                callback: () => {tornaElenco()}
                                            }
                                        ]}
                                    />                        

                                :
                                <>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="descrizione">Descrizione*</Label>
                                            {idFatturaDefault == 0 ?
                                                <Input readOnly={props.ope!=="inserisci"&&(statoXml>0||tipoQuota>0)} maxLength={200} type="text" name="descrizione" id="descrizione"
                                                innerRef={descrizione} 
                                                placeholder=""
                                                defaultValue={descrizioneDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value);}}/>  
                                            :""}
                                            {idFatturaDefault != 0 ? <Label>: {descrizioneDefault}</Label> : ""}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="importo">Importo (comprensivo di IVA e segno: negativo se è un'uscita)*</Label>
                                                <Input readOnly={props.ope!=="inserisci"&&(statoXml>0||tipoQuota>0)} maxLength={12} type="text" name="importo" id="importo"
                                                innerRef={importo} 
                                                placeholder=""
                                                defaultValue={FormattaImportoVideo(importoDefault,1)}
                                                onInput={(e)=>{handleCambiamentoMod(e);setImportoDefault(importo.current.value);}}/>  
                                        </FormGroup>
                                    </Col>                                  
                                </Row>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="iva">IVA</Label>
                                                <Input readOnly={props.ope!=="inserisci"&&(statoXml>0||tipoQuota>0)} maxLength={12} type="text" name="iva" id="iva"
                                                innerRef={iva} 
                                                placeholder=""
                                                defaultValue={FormattaImportoVideo(ivaDefault,1)}
                                                onInput={(e)=>{handleCambiamentoMod(e);setIvaDefault(iva.current.value);}}/>  
                                        </FormGroup>
                                    </Col>                                  
                                </Row>
                                <Row form>  
                                    <Col md={12}>
                                    <FormGroup>
                                    <Label for="risorsa">Risorsa*</Label>
                                    <TendinaEditabile
                                        //titolo={"Risorsa*"}
                                        nomeCampo="DESCRIZIONE"
                                        valoreDefault={insRisorsaEdi}
                                        arrayValori={risultatoServerDatiRisorse}
                                        callbackSetVariazioneCampi={setVariazioneCampi}
                                        callbackSetValoreTendEditabile={setInsRisorsaEdi}
                                    ></TendinaEditabile>
                                    </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={12}>
                                    <FormGroup>
                                    <label htmlFor="voce">
                                        Voce di Bilancio*
                                        {insVociDiBilancioAttEdi === '1' 
                                            ? <> (attività principale<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagAttivaModaleSpiegaAttivita(1)}/>)</>
                                            : insVociDiBilancioAttEdi === '0' 
                                            ? <> (attività secondaria<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagAttivaModaleSpiegaAttivita(1)}/>)</>
                                            : ''
                                        }
                                    </label>
                                    <TendinaEditabile
                                        //titolo={"Voce di Bilancio*"}
                                        nomeCampo="DESCRIZIONE"
                                        nomeCampoNonVisibile = "TIPO_ATTIVITA"
                                        valoreDefault={insVociDiBilancioEdi}
                                        arrayValori={risultatoServerDatiVocidiBilancio}
                                        callbackSetVariazioneCampi={setVariazioneCampi}
                                        callbackSetValoreTendEditabile={callbacksetValoriVoceBilancio} 
                                    ></TendinaEditabile>
                                    </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>    
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="dataPagamento">Data Pagamento*</Label>
                                                <Input readOnly={props.ope!=="inserisci"&&(statoXml>0||tipoQuota>0)} maxLength={10} type="date" name="dataPagamento" id="dataPagamento"
                                                innerRef={dataPagamento} 
                                                placeholder=""
                                                defaultValue={dataPagamentoDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataPagamentoDefault(dataPagamento.current.value);}}/>  
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="note">Note</Label>
                                                <Input type="text" name="note" id="note"
                                                innerRef={note} 
                                                placeholder=""
                                                defaultValue={noteDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setNoteDefault(note.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>
                                </>
                                }

                                {(props.ope === "inserisci" || props.ope=="modifica") && <>* = campo necessario<br/><br/></>}
                                {props.ope!=="inserisci" && props.ope!=="cancella" &&(statoXml>0||tipoQuota>0) && <>Non sono modificabili i campi che sono stati usati per generare una fattura elettronica o una ricevuta. In caso di errore, cancellare il movimento e inserirlo nuovamente.</>}

                                {props.ope === "inserisci" && <>
                                <b>Attenzione</b>: utilizza questa pagina per inserire i movimenti di Prima Nota generici, <b>diversi</b> da quote pagate dai soci, quote di abbonamento, incassi da esterni o da iscritti, spese e rimborsi.<br/>
                                Per queste cose, invece, utilizza le voci di menu presenti sotto Gestione Economica: i record di prima nota verranno creati automaticamente e i dati inseriti verranno usati per i report e le funzionalità del programma, per esempio per tener traccia delle quote versate e dovute da ciascun socio.
                                <br/><br/>
                                <b>Suggerimento</b>: se devi inserire tanti movimenti (per esempio i costi bancari di un intero anno), potresti trovare utile metterli in un foglio elettronico (come Excel, Calc) e poi inserirli in Gestione Sportiva con un solo comando. Puoi farlo usando la voce di menu Impostazioni - Importazione Massiva e poi selezionando "Movimenti Prima Nota" dalla tendina.
                                </>}

                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" || operazioneInterna === 'copia'? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                
                                {props.ope === "modifica" && variazioneCampi === 1 && operazioneInterna === ''? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                               
                                {props.ope === "modifica" && variazioneCampi === 0 && operazioneInterna === '' && idFatturaDefault==0 && tipoQuota == 0? 
                                    <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {copiaMovimento()}}>Copia Movimento</Button>
                                :""}

                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica")  ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>

                                </div>
                            
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
