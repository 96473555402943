/* 
Selezione di una data. 

Parametri da passare:
- data: stringa, contiene la data da indicare inizialmente, in formato YYYY-MM-DD o stringa vuota
- setData: funzione richiamata quando l'utente seleziona una nuova data, riceve come parametro la data in formato YYYY-MM-DD
- titolo: stringa (ignorata in questa versione, era <label>{titolo}</label>)
- style: eventuale stile
- className: eventuale className

*/

import React from 'react';

const SelezionaData = ({ data, setData, titolo, style, className }) => {
  const handleChange = (event) => {
    setData(event.target.value);
  };

  return (
    <div>
      <input 
        style={style}
        className={className}
        type="date" 
        value={data} 
        onChange={handleChange} 
      />
    </div>
  );
};

export default SelezionaData;
