import React, {useEffect} from 'react';
import IconaMenu from './IconaMenu'

function AllenamentiMenuStaff(props) {    
    var iconaSchedePredefinite = require('./schede_predefinite.png');
    var iconaSchedeAssegnate = require('./schede_assegnate.png');
    var iconaEsercizi = require('./allenamenti.png');
    var iconaClassificazione = require('./cartelle.png');

    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            props.callback(props.PAGINECLIENTI.home);            
        } 
    }, [props.flagRichiestaBack])

    return (
        
        <div
            style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Centra verticalmente (perché la flexDirection è column) tutto il contenuto
            marginLeft: 5,
            marginRight: 5,
            }}
        >

            <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.allenamentiSchedePredefinite)}
                    immagine={iconaSchedePredefinite}
                    testo={"Schede\nPredefinite"}
                />
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.allenamentiSchedeAssegnate)}
                    immagine={iconaSchedeAssegnate}
                    testo={"Schede\nAssegnate"}
                />
            </div>

            <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.allenamentiEsercizi)}
                    immagine={iconaEsercizi}
                    testo={"Elenco\nEsercizi"}
                />
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.allenamentiClassificazioneEsercizi)}
                    immagine={iconaClassificazione}
                    testo={"Classificaz.\nEsercizi"}
                />
            </div>
        </div>
    )
}

export default AllenamentiMenuStaff;
