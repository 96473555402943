import React, { useState, useEffect } from 'react';
import Clessidra from '../Pagine/Clessidra';
import stile from './stile.module.css';
import SelezionaData from './SelezionaData';
import Tendina2 from './Tendina2';
import { convertiDataComputer2Umano } from './funzioni.js'
import EventiInputRisultati from './EventiInputRisultati';
import EventiMenu from './EventiMenu';
import ReportEventi from './ReportEventi';
import TipiEvento from './TipiEvento';
import Messaggi from './Messaggi.js';

function Eventi(props) {
    const PAGINE = {
        "caricamentoDati": 1,
        "menuEventi": 2,
        "elencoEventi": 3,
        "convocatiEvento": 4,
        "chatEvento": 5,
        "convocatiAggiungi": 6,
        "convocatiRimuovi": 7,
        "convocatiAggiungiStaff": 8,
        "convocatiRimuoviStaff": 9,
        "eventoModifica": 10,
        "eventoInserisci": 11,
        "eventoElimina": 12,
        "eventoCopia": 13,
        "eventiInputRisultati": 14,
        "eventiMenuReport": 15,
        "reportPresenze": 16,
        "reportRisultati": 17,
        "reportRisultatiSingolo": 18,
        "tipiEvento": 19
    };
    const [pagina, setPagina] = useState(PAGINE.menuEventi);
    const [elencoEventi, setElencoEventi] = useState([]);
    const [elencoTipiEventi, setElencoTipiEventi] = useState([]);
    const [elencoIdTipiEventi, setElencoIdTipiEventi] = useState([]);
    const [tipo, setTipo] = useState("");
    const [eventoSelezionato, setEventoSelezionato] = useState([]);
    const [dataInizio, setDataInizio] = useState("");
    const [oraInizio, setOraInizio] = useState("");
    const [dataFine, setDataFine] = useState("");
    const [oraFine, setOraFine] = useState("");
    const [luogo, setLuogo] = useState("");
    const [descrizione, setDescrizione] = useState("");

    // variabili di stato per la chat
    const [idDestinatarioSelezionato, setIdDestinatarioSelezionato] = useState(-1);

    // variabili di stato per le convocazioni    
    const [nonStaffConferma, setNonStaffConferma] = useState(0);
    const [nonStaffNonConferma, setNonStaffNonConferma] = useState(0);    
    // array di oggetti persona, contiene NOME, COGNOME, CONVOCATA,...
    const [personeConvocateStaff, setPersoneConvocateStaff] = useState([]);
    // array di oggetti persona, contiene NOME, COGNOME, CONVOCATA,...
    const [personeConvocateNonStaff, setPersoneConvocateNonStaff] = useState([]);    
    // array di boolean: true per le persone selezionate da utente, per aggiungerle o rimuoverle
    const [personeSelezionate, setPersoneSelezionate] = useState([]);
    // array di boolean: true per le persone filtrate dai raggruppamenti (appartengono o non appartengono ai raggruppamenti selezionati)
    const [personeFiltrate, setPersoneFiltrate] = useState([]);
    // array di oggetti persona, contiene NOME, COGNOME, CONVOCATA,...
    const [persone, setPersone] = useState([]);
    // array di oggetti raggruppamento, contiene ID, DESCRIZIONE
    const [raggruppamenti, setRaggruppamenti] = useState([]);
    // array di boolean: true per i raggruppamenti selezionati
    const [raggruppamentiSelezionati, setRaggruppamentiSelezionati] = useState([]);
    // filtroConvocati: Convocati o Aderiscono
    const [filtroConvocati, setFiltroConvocati] = useState("Convocati");

    // variabili di stato per la copia evento
    const [tipoCopia, setTipoCopia] = useState(0);

    const [flagCopiaConvocati, setFlagCopiaConvocati] = useState(false);

    // costanti per la copia evento
    const tipiCopia = ["Una volta", "Uno al giorno", "Uno alla settimana", "Uno al mese"];

    const opzioniVisualizzazioneEventi = ["solo i prossimi eventi","tutti gli eventi, anche quelli passati"];
    const [opzioneVisualizzazioneEventi, setOpzioneVisualizzazioneEventi] = useState(opzioniVisualizzazioneEventi[0]);

    useEffect(() => {
        if (pagina===PAGINE.eventoInserisci) {
            setDataInizio('');
            setOraInizio('');
            setDataFine('');
            setOraFine('');
            setLuogo('');
            setDescrizione('');
            setEventoSelezionato('');
            setTipo('');
        }
    },[pagina]);

    function callbackEventiMenu(paginaSeguente) {
        setPagina(PAGINE.caricamentoDati);
        if (paginaSeguente===PAGINE.elencoEventi) {
            scaricaEventiElencoPerPersona();
            return;
        }
        if (paginaSeguente===PAGINE.eventoInserisci) {
            setPagina(PAGINE.caricamentoDati);
            scaricaElencoTipiEventi(PAGINE.eventoInserisci);
        }
        setPagina(paginaSeguente);
    }

    function insModEvento() {
        if (dataInizio=="") {
            window.alert("Indica la data d'inizio dell'evento"); 
            return;
        }
        if (tipo=="") {
            window.alert("Indica il tipo di evento"); 
            return;
        }
        if (luogo=="") {
            window.alert("Indica il luogo dell'evento"); 
            return;
        }
        if (dataInizio>dataFine && dataFine!="") {
            window.alert("Verifica le date inserite, la data di fine evento non può essere antecedente la data d'inizio"); 
            return;                        
        }                
        const oggi = new Date().toISOString().split('T')[0];
        if (dataInizio<oggi && pagina==PAGINE.eventoInserisci) {            
            window.alert("Verifica la data di inizio, non può essere prima di oggi"); 
            return;                        
        }                
        setPagina(PAGINE.caricamentoDati);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: pagina===PAGINE.eventoModifica ? "eventiMod" : "eventiIns", 
                idElemento: eventoSelezionato.ID_EVENTO!==null ? eventoSelezionato.ID_EVENTO : 0,
                dataInizio: dataInizio,
                oraInizio: oraInizio,
                dataFine: dataFine,
                oraFine: oraFine,
                tipo: tipo,
                luogo: luogo,
                descrizione: descrizione,
                idPersona: props.identitaSelezionata.idpersona,
                emak: props.emak, 
                sessionId: props.sessionId
            })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    scaricaEventiElencoPerPersona();                    
                },
                (error) => {                    
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function cancEvento() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "eventiCanc", 
                idElemento: eventoSelezionato.ID_EVENTO!==null ? eventoSelezionato.ID_EVENTO : 0,
                cancella_comunque: 1,
                emak: props.emak, 
                sessionId: props.sessionId
            })
        };
        setPagina(PAGINE.caricamentoDati);
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    scaricaEventiElencoPerPersona();                    
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function segnaPresenzaConvocato(idPersona, flag) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "segnaPresenzaConvocato", 
                idEvento: eventoSelezionato.ID_EVENTO!==null ? eventoSelezionato.ID_EVENTO : 0,
                idPersona: idPersona,
                presente:flag?1:0,
                emak: props.emak, 
                sessionId: props.sessionId
            })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                                    
                    var pcns=[...personeConvocateNonStaff];
                    for (var i=0; i<pcns.length; i++) {
                        //console.log(pcns[i].ID_PERSONA+"=="+idPersona)
                        if (pcns[i].ID_PERSONA==idPersona) {
                            pcns[i].PRESENTE=flag;
                            break;
                        }
                    }
                    setPersoneConvocateNonStaff(pcns);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function scaricaEventiElencoPerPersona(flagNonVisualizzareClessidra=false) {        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "eventiElencoPerPersona", idpersona: props.identitaSelezionata.idpersona, emak: props.emak, sessionId: props.sessionId })
        };
        if (!flagNonVisualizzareClessidra) setPagina(PAGINE.caricamentoDati);
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    setElencoEventi(valore_di_ritorno.risultatoRitorno);
                    setPagina(PAGINE.elencoEventi);                    
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            ); 
    }

    function scaricaElencoTipiEventi(paginaSuccessiva) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "elencoTipiEventi", emak: props.emak, sessionId: props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    var e=[];
                    var eid=[];
                    for (let i=0; i<valore_di_ritorno.risultatoRitorno.length; i++) {
                        e.push(valore_di_ritorno.risultatoRitorno[i]["DESCRIZIONE"]);
                        eid[valore_di_ritorno.risultatoRitorno[i]["ID"]]=valore_di_ritorno.risultatoRitorno[i]["DESCRIZIONE"];                        
                    }
                    setElencoTipiEventi(e);
                    setElencoIdTipiEventi(eid);
                    setPagina(paginaSuccessiva);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function scaricaPersoneConvocate(idEvento, paginaSeguente) {
        setPagina(PAGINE.caricamentoDati)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "eventiPersoneConvocate", idEvento: idEvento, emak: props.emak, sessionId: props.sessionId })
        }
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    setPersoneConvocateStaff(valore_di_ritorno.personeConvocateStaff);
                    setPersoneConvocateNonStaff(valore_di_ritorno.personeConvocateNonStaff);
                    setNonStaffConferma(valore_di_ritorno.nonStaffConferma);
                    setNonStaffNonConferma(valore_di_ritorno.nonStaffNonConferma);
                    if (paginaSeguente==PAGINE.chatEvento) setIdDestinatarioSelezionato(-1);
                    setPagina(paginaSeguente);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );            
    }

    function scaricaPersoneNonConvocate(idEvento, flagStaff) {
        setPagina(PAGINE.caricamentoDati)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "eventiPersoneNonConvocate", idEvento: idEvento, intStaff:flagStaff?1:0, emak: props.emak, sessionId: props.sessionId })
        }
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }

                    setRaggruppamenti(valore_di_ritorno.raggruppamenti);
                    setPersone(valore_di_ritorno.persone);

                    var fs=[];
                    for (let i=0; i<valore_di_ritorno.persone.length; i++) {
                        fs[i]=true;
                    }
                    setPersoneFiltrate(fs);                    

                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function partecipantiEventiConferma(idEvento, conferma) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "partecipantiEventiConferma", idEvento: idEvento, conferma: conferma, emak: props.emak, sessionId: props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    scaricaEventiElencoPerPersona(true);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function partecipantiAggiungiSelezionati(flagStaff) {
        setPagina(PAGINE.caricamentoDati);
        var elenco = [];
        for (let i=0; i<persone.length; i++) {
            if (personeSelezionate[i]) {
                elenco.push(persone[i].ID); // qui ID è l'id della persona, quello in tabella PERSONE
            }
        }    

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "partecipantiEventiAggiungi", idEvento: eventoSelezionato.ID_EVENTO, elenco: elenco, flagStaff: flagStaff, emak: props.emak, sessionId: props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    scaricaPersoneConvocate(eventoSelezionato.ID_EVENTO, PAGINE.convocatiEvento);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function partecipantiRimuoviSelezionati(flagStaff) {
        setPagina(PAGINE.caricamentoDati);
        var elenco = [];
        if (flagStaff) {
            for (let i=0; i<personeConvocateStaff.length; i++) {
                if (personeSelezionate[i]) {
                    elenco.push(personeConvocateStaff[i].ID_PERSONA);
                }
            }    
        } else {
            for (let i=0; i<personeConvocateNonStaff.length; i++) {
                if (personeSelezionate[i]) {
                    elenco.push(personeConvocateNonStaff[i].ID_PERSONA);
                }
            }    
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "partecipantiEventiRimuovi", idEvento: eventoSelezionato.ID_EVENTO, elenco: elenco, emak: props.emak, sessionId: props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    scaricaPersoneConvocate(eventoSelezionato.ID_EVENTO, PAGINE.convocatiEvento);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function copiaEvento() {
        var numeroEventi = 0;
        if (tipoCopia==0) {
            if (dataInizio=="") {
                window.alert("Indica la data d'inizio dell'evento"); 
                return;
            }    
            if (dataInizio>dataFine && dataFine!="") {
                window.alert("Verifica le date inserite, la data di fine evento non può essere antecedente la data d'inizio"); 
                return;                        
            }      
            numeroEventi = 1;          
        } else {
            if (dataInizio=="") {
                window.alert("Indica la data fino alla quale copiare l'evento"); 
                return;
            }
            const dataEvento = new Date(eventoSelezionato.DATA_INIZIO);
            const dataFineCopia = new Date(dataInizio);
            const dataEvento_ms = dataEvento.getTime();
            const dataFineCopia_ms = dataFineCopia.getTime();
            const UN_GIORNO_MS = 1000 * 60 * 60 * 24;           
            const diff_ms = dataFineCopia_ms - dataEvento_ms;
            if (diff_ms<0) {
                window.alert("La data fino alla quale copiare l'evento deve essere successiva alla data dell'evento"); 
                return;
            }
            const giorni = Math.round(diff_ms/UN_GIORNO_MS);            
            var descrizione = "dal " + convertiDataComputer2Umano(eventoSelezionato.DATA_INIZIO,"") + " al " + convertiDataComputer2Umano(dataInizio,"");
            if (tipoCopia==1) {
                descrizione += ", tutti i giorni";
                numeroEventi = giorni;
            }
            if (tipoCopia==2) {
                descrizione += ", tutte le settimane";
                numeroEventi = Math.floor(giorni/7);
            }
            if (tipoCopia==3) {
                descrizione += ", tutti i mesi";
                const meseEvento = dataEvento.getMonth()+1;
                const annoEvento = dataEvento.getFullYear();
                const meseFineCopia = dataFineCopia.getMonth()+1;
                const annoFineCopia = dataFineCopia.getFullYear();
                numeroEventi = (annoFineCopia-annoEvento)*12 + meseFineCopia - meseEvento;
            }

            if (numeroEventi==0) {
                window.alert("Verifica le date, con quelle impostate non vengono creati nuovi eventi"); 
                return;
            }

        }
        var testoAvviso = "";
        if (numeroEventi == 1) {
            testoAvviso = "Verrà creato 1 nuovo evento, confermi?";
        } else {
            testoAvviso = "Verranno creati " + numeroEventi + " nuovi eventi, " + descrizione + ": confermi?";
        }
        if (window.confirm(testoAvviso)) {
            copiaEventoConfermato();
        }
    }

    function copiaEventoConfermato() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "eventiCopia", 
                idEvento: eventoSelezionato.ID_EVENTO, 
                tipoCopia: tipoCopia, 
                dataInizio: dataInizio,
                oraInizio: oraInizio,
                dataFine: dataFine,
                oraFine: oraFine,
                copiaConvocati:flagCopiaConvocati?1:0,
                emak: props.emak, 
                sessionId: props.sessionId
            })
        };
        setPagina(PAGINE.caricamentoDati);
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) {                         
                        var messaggioErrore = "Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica";
                        if (valore_di_ritorno.errore != undefined) {
                            if (valore_di_ritorno.errore != "") {
                                messaggioErrore = valore_di_ritorno.errore;
                            }
                        }
                        window.alert(messaggioErrore);
                        props.callback(props.PAGINECLIENTI.login);
                        return;
                    }
                    scaricaEventiElencoPerPersona();
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    // restituisce l'intersezione degli array a e b, che DEVONO essere array con elementi NUMERICI ORDINATI in ordine crescente
    function intersezioneArray(a, b) {
        var ai=0, bi=0, intersezione=[];
        while (ai<a.length && bi<b.length) {
           if (a[ai]<b[bi]) { ai++; }
           else if (a[ai]>b[bi]) { bi++; }
           else { intersezione.push(a[ai]); ai++; bi++ }
        }
        return intersezione;
    }    

    function modificaRaggruppamenti(indice) {
        var intersezione=[];
        var ragg=[...raggruppamentiSelezionati];
        ragg[indice]=!ragg[indice];
        setRaggruppamentiSelezionati(ragg);
        var raggAttivi = [];
        var pf = [];
        for (let i=0; i<ragg.length; i++) {
            if (ragg[i]) {
                //console.log("gruppo n."+i+" attivo: aggiungo "+raggruppamenti[i].ID+" agli ID dei gruppi attivi");
                raggAttivi.push(raggruppamenti[i].ID);
            }
        }
        if (raggAttivi.length>0) {
            raggAttivi.sort(); // non è ordinato a priori e intersezioneArray vuole array ordinati

            for (let i=0; i<raggAttivi.length; i++) {
                //console.log("raggAttivi "+i+": "+raggAttivi[i]);
            }

            for (let i=0; i<persone.length; i++) {
                intersezione = intersezioneArray(persone[i].GRUPPI,raggAttivi);
                if (intersezione.length>0) {
                    pf[i] = true; 
                } else {
                    pf[i] = false;
                }
            }
        } else {
            // nessun raggruppamento selezionato: tutte le persone attive
            for (let i=0; i<persone.length; i++) {            
                pf[i] = true; 
            }            
        }
        setPersoneFiltrate(pf);
    }

    function scrollInFondo({layoutMeasurement, contentOffset, contentSize}){
        // attenzione perché questo genera TANTE chiamate alla funzione quando arriva in fondo, da verificare come mai 
        // nel caso in cui la funzione servirà, ora non serve quindi non approfondisco
        return layoutMeasurement.height + contentOffset.y >= contentSize.height - 20;
    }
     
    function scrollInAlto({layoutMeasurement, contentOffset, contentSize}){
        return contentOffset.y == 0;
    }

    function DatiEvento(props) {
        const x=eventoSelezionato;
        return (
            <div className={stile.eventiElencoContenitoreElemento}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {props.flagBottoneTornaEventi ?
                        <div 
                            className={`${stile.bottone6} ${stile.bottoneColoreNormale}`} 
                            onClick={() => { scaricaEventiElencoPerPersona() }}
                        >
                            <div className={stile.spazioIconaPiccola}>
                                <img
                                    className={stile.iconaPiccolaQuadrata}
                                    src={require('./frecciasinistra.png')}
                                />
                            </div>                                
                        </div>
                    :
                        <></>
                    }
                    <div>
                        <div className={stile.eventiElencoTestoData}>
                            {x.DATA_FINE !== "" && x.DATA_FINE !== null ? "da " : ""}
                            {convertiDataComputer2Umano(x.DATA_INIZIO, "gSettAbbr")}
                            {x.ORA_INIZIO !== "" && x.ORA_INIZIO !== null ? " ore " + x.ORA_INIZIO : <></>}
                        </div>
                        {x.DATA_FINE !== "" && x.DATA_FINE !== null ?
                            <div className={stile.eventiElencoTestoData}>
                                {"a " + convertiDataComputer2Umano(x.DATA_FINE, "gSettAbbr")}
                                {x.ORA_FINE !== "" && x.ORA_FINE !== null ? " ore " + x.ORA_FINE : <></>}                            
                            </div>
                        : 
                            <></>
                        }                        
                        {x.TIPO !== null && x.TIPO !== "" ? 
                            <div className={stile.eventiElencoTestoLuogo}>{x.TIPO}</div> 
                            : 
                            <></>
                        }
                    </div>
                </div>
                <div className={stile.eventiElencoTestoLuogo}>{x.LUOGO}</div>
            </div>
        );
        
    }

    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            switch (pagina) {
                case PAGINE.caricamentoDati: break;
                case PAGINE.menuEventi: props.callback(props.PAGINECLIENTI.home); break;
                case PAGINE.elencoEventi: setPagina(PAGINE.menuEventi); break;
                case PAGINE.convocatiEvento: setPagina(PAGINE.elencoEventi); break;
                case PAGINE.chatEvento: setPagina(PAGINE.elencoEventi); break;
                case PAGINE.convocatiAggiungi: setPagina(PAGINE.convocatiEvento); break;
                case PAGINE.convocatiRimuovi: setPagina(PAGINE.convocatiEvento); break;
                case PAGINE.convocatiAggiungiStaff: setPagina(PAGINE.convocatiEvento); break;
                case PAGINE.convocatiRimuoviStaff: setPagina(PAGINE.convocatiEvento); break;
                case PAGINE.eventoModifica: setPagina(PAGINE.elencoEventi); break;
                case PAGINE.eventoInserisci: setPagina(PAGINE.menuEventi); break;
                case PAGINE.eventoElimina: setPagina(PAGINE.elencoEventi); break;
                case PAGINE.eventoCopia: setPagina(PAGINE.elencoEventi); break;
                case PAGINE.eventiInputRisultati: setPagina(PAGINE.convocatiEvento); break;
                case PAGINE.eventiMenuReport: setPagina(PAGINE.menuEventi); break;
                case PAGINE.reportPresenze: setPagina(PAGINE.menuEventi); break;
                case PAGINE.reportRisultati: setPagina(PAGINE.menuEventi); break;
                case PAGINE.reportRisultatiSingolo: setPagina(PAGINE.menuEventi); break;
                case PAGINE.tipiEvento: setPagina(PAGINE.menuEventi); break;
            }            
        } 
    }, [props.flagRichiestaBack])  

    useEffect(() => {
        // al cambio di schermata tolgo tutte le eventuali selezioni fatte dalla schermata precedente
        setPersoneSelezionate([]);
        setPersoneFiltrate([]);
        setRaggruppamentiSelezionati([]);
    }, [pagina])

    if (pagina === PAGINE.caricamentoDati) {
        return (<Clessidra loading={true} />)
    }

    if (pagina === PAGINE.menuEventi) {
        return (
            <EventiMenu
                {...props}
                setPagina={setPagina}
                callback={props.callback} 
                callbackEventiMenu={callbackEventiMenu}
                PAGINEHOME={props.PAGINECLIENTI}
                PAGINE={PAGINE}
            />
        )
    }

    if (pagina === PAGINE.reportPresenze) {
        return (
            <ReportEventi
                {...props}
                report={"presenze"} 
                callback={props.callback}
                PAGINE={PAGINE}
                PAGINEHOME={props.PAGINECLIENTI}
            />
        )
    }

    if (pagina === PAGINE.reportRisultati) {
        return (
            <ReportEventi
                {...props}
                report={"risultati"}  
                callback={props.callback}                    
                PAGINE={PAGINE}
                PAGINEHOME={props.PAGINECLIENTI}
                setPagina={setPagina}
            />
        )
    }

    if (pagina === PAGINE.reportRisultatiSingolo) {
        return (
            <ReportEventi
                {...props}
                report={"risultatiSingolo"}  
                callback={props.callback}                    
                PAGINE={PAGINE}
                PAGINEHOME={props.PAGINECLIENTI}
            />
        )
    }

    if (pagina === PAGINE.tipiEvento) {
        return (
            <TipiEvento 
                {...props}
                setPagina={setPagina}
                callback={props.callback} 
                callbackEventiMenu={callbackEventiMenu}
                PAGINEHOME={props.PAGINECLIENTI}
                PAGINE={PAGINE}
            />
        )
    }

    if (pagina === PAGINE.convocatiAggiungi || pagina === PAGINE.convocatiAggiungiStaff) {
        const numeroAtletiSelezionati = personeSelezionate.filter((selezionato) => selezionato).length;
        var oggettoConvocazione;
        if (pagina === PAGINE.convocatiAggiungi) oggettoConvocazione = "atleti"; else oggettoConvocazione = "staff";

        return (
            <div className={stile.contenitorePrincipale} style={{ display: "flex", flexDirection: "column", height: "100%", overflow: "hidden", padding:10 }}>
                {/* Titolo per il primo elenco */}
                <div className={stile.eventiModificaTestoTitoli}>Elenca solo quelli appartenenti a:</div>

                {/* Contenitore con due elenchi scrollabili */}
                <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "hidden" }}>
                    {/* Primo elenco scrollabile */}
                    {raggruppamenti.length > 0 ? (
                        <div className={stile.eventiConvocatiScrollView} style={{ flexGrow: 1, overflowY: "auto" }}>
                            {raggruppamenti.map((p, i) => (
                                <div
                                    key={i}
                                    className={
                                        raggruppamentiSelezionati[i]
                                            ? stile.eventiConvocareSpazioElementoSelezionato
                                            : stile.eventiConvocareSpazioElemento
                                    }
                                    onClick={() => modificaRaggruppamenti(i)}
                                >
                                    {raggruppamentiSelezionati[i] ? (
                                        <img className={stile.eventiIconaCerchio} src={require("./cerchio_ok.png")} />
                                    ) : (
                                        <img className={stile.eventiIconaCerchio} src={require("./cerchio_vuoto.png")} />
                                    )}
                                    <div className={stile.eventiConvocatiNome}>{p.DESCRIZIONE}</div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className={stile.eventiModificaTestoTitoli}>Non stai usando i raggruppamenti</div>
                    )}


                    {/* Titolo per il secondo elenco con il numero di atleti selezionati */}
                    <div className={stile.eventiModificaTestoTitoli}>Elenco {oggettoConvocazione} da convocare:</div>

                    {/* Verifica se ci sono persone nel secondo elenco */}
                    {personeFiltrate.some((pf) => pf === true) ? (
                        <div className={stile.eventiConvocatiScrollView} style={{ flexGrow: 1, overflowY: "auto" }}>
                            {persone.map((p, i) =>
                                p.CONVOCATA === 0 && personeFiltrate[i] ? (
                                    <div
                                        key={i}
                                        className={
                                            personeSelezionate[i]
                                                ? stile.eventiConvocareSpazioElementoSelezionato
                                                : stile.eventiConvocareSpazioElemento
                                        }
                                        onClick={() => {
                                            var ps = [...personeSelezionate];
                                            ps[i] = !ps[i];
                                            setPersoneSelezionate(ps);
                                        }}
                                    >
                                        {personeSelezionate[i] ? (
                                            <img className={stile.eventiIconaCerchio} src={require("./cerchio_ok.png")} />
                                        ) : (
                                            <img className={stile.eventiIconaCerchio} src={require("./cerchio_vuoto.png")} />
                                        )}
                                        <div className={stile.eventiConvocatiNome}>{p.COGNOME} {p.NOME}</div>
                                    </div>
                                ) : (
                                    <div key={i}></div>
                                )
                            )}
                        </div>
                    ) : (
                        <div className={stile.eventiModificaTestoTitoli}>Non ci sono {oggettoConvocazione} nei gruppi selezionati</div>
                    )}
                </div>

                {/* Barra inferiore con i pulsanti */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "auto" }}>

                    <div className={`${stile.bottone3} ${stile.bottoneColoreRosso}`} onClick={() => scaricaPersoneConvocate(eventoSelezionato.ID_EVENTO, PAGINE.convocatiEvento)}>
                        <div className={stile.spazioIconaPiccola}>
                            <img className={stile.iconaPiccolaQuadrata} src={require('./annulla.png')} />
                        </div>
                        <div className={stile.bottoneTestoNormale}>ANNULLA</div>
                    </div>

                    <div
                        className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                        onClick={() => {
                            let p = persone.map((_, i) => personeFiltrate[i] ? true : personeSelezionate[i]);
                            setPersoneSelezionate(p);
                        }}
                    >
                        <div className={stile.eventiTestoBottoniInBasso}>Seleziona<br />Elencati</div>
                    </div>

                    <div
                        className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                        onClick={() => {
                            let p = persone.map((_, i) => personeFiltrate[i] ? false : personeSelezionate[i]);
                            setPersoneSelezionate(p);
                        }}
                    >
                        <div className={stile.eventiTestoBottoniInBasso}>Annulla Sel.<br />Elencati</div>
                    </div>

                    <div className={`${stile.bottone3} ${stile.bottoneColoreVerde}`} onClick={() => partecipantiAggiungiSelezionati(false)}>
                        <div className={stile.eventiTestoBottoniInBasso}>Convoca<br/>{numeroAtletiSelezionati} {oggettoConvocazione}</div>
                    </div>
                </div>
            </div>


        );
    }
    
    if (pagina === PAGINE.convocatiRimuovi) {
        return (
            <div className={stile.contenitorePrincipale} style={{ display: "flex", flexDirection: "column", height: "100%", overflow: "hidden", padding:10 }}>
                <div className={stile.eventiModificaTestoTitoli}>Rimuovi convocazione a:</div>
                <div style={{overflowY:"auto"}}>
                    {personeConvocateNonStaff.map((p, i) =>
                        <div 
                            key={i} 
                            className={personeSelezionate[i] ? stile.eventiConvocareSpazioElementoSelezionatoNegativo : stile.eventiConvocareSpazioElemento} 
                            onClick={() => {var ps=[...personeSelezionate];ps[i]=!ps[i];setPersoneSelezionate(ps)}}
                        >
                            {personeSelezionate[i] ? 
                            <img
                                className={stile.eventiIconaCerchio}
                                src={require('./cerchio_no.png')}
                            />                    
                            :
                            <img
                                className={stile.eventiIconaCerchio}
                                src={require('./cerchio_vuoto.png')}
                            />
                            }
                            <div className={stile.eventiConvocatiNome}>{p.ID} {p.COGNOME} {p.NOME}</div>
                        </div>
                    )}
                </div>
    
                <div style={{display:"flex",flexDirection:"row"}}>

                    <div className={`${stile.bottone3} ${stile.bottoneColoreRosso}`} onClick={() => scaricaPersoneConvocate(eventoSelezionato.ID_EVENTO, PAGINE.convocatiEvento)}>
                        <div className={stile.spazioIconaPiccola}>
                            <img className={stile.iconaPiccolaQuadrata} src={require('./annulla.png')} />
                        </div>
                        <div className={stile.bottoneTestoNormale}>ANNULLA</div>
                    </div>
                    
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreNormale}`} onClick={() => {var p=[]; for(let i=0; i<personeConvocateNonStaff.length; i++) p[i]=true; setPersoneSelezionate(p)}}
                    >
                        <div className={stile.eventiTestoBottoniInBasso}>Seleziona{"\n"}Tutti</div>
                    </div>
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreNormale}`} onClick={() => {var p=[]; for(let i=0; i<personeConvocateNonStaff.length; i++) p[i]=false; setPersoneSelezionate(p)}}
                    >
                        <div className={stile.eventiTestoBottoniInBasso}>Seleziona{"\n"}Nessuno</div>
                    </div>
                    <div className={`${stile.bottone3} ${stile.bottoneColoreVerde}`} onClick={() => partecipantiRimuoviSelezionati(false)}>
                        <div className={stile.eventiTestoBottoniInBasso}>Conferma{"\n"}Rimozione</div>
                    </div>
                </div>
            </div>
        )
    }
    
    if (pagina === PAGINE.convocatiRimuoviStaff) {
        return (
            <div className={stile.contenitorePrincipale} style={{ display: "flex", flexDirection: "column", height: "100%", overflow: "hidden", padding:10 }}>
                <div>Rimuovi convocazione a (staff):</div>
                <div className={stile.eventiConvocatiScrollView}>
                    {personeConvocateStaff.map((p, i) =>
                        props.identitaSelezionata.idpersona !== p.ID_PERSONA ? 
                        <div 
                            key={i} 
                            className={`${personeSelezionate[i] ? stile.eventiConvocareSpazioElementoSelezionatoNegativo : stile.eventiConvocareSpazioElemento}`} 
                            onClick={() => {
                                var ps = [...personeSelezionate];
                                ps[i] = !ps[i];
                                setPersoneSelezionate(ps);
                            }}
                        >
                            {personeSelezionate[i] ? 
                            <img
                                className={stile.eventiIconaCerchio}
                                src={require('./cerchio_no.png')}
                            />                    
                            :
                            <img
                                className={stile.eventiIconaCerchio}
                                src={require('./cerchio_vuoto.png')}
                            />
                            }
                            <div className={stile.eventiConvocatiNome}>{p.COGNOME} {p.NOME}</div>
                        </div>
                        :
                        <div key={i}></div>
                    )}
                </div>
    
                <div style={{display:"flex", flexDirection: "row"}}>
                    <div className={`${stile.bottone3} ${stile.bottoneColoreRosso}`} onClick={() => scaricaPersoneConvocate(eventoSelezionato.ID_EVENTO, PAGINE.convocatiEvento)}>
                        <div className={stile.spazioIconaPiccola}>
                            <img className={stile.iconaPiccolaQuadrata} src={require('./annulla.png')} />
                        </div>
                        <div className={stile.bottoneTestoNormale}>ANNULLA</div>
                    </div>
                    
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                        onClick={() => {
                            var p = [];
                            for (let i = 0; i < personeConvocateStaff.length; i++) p[i] = true;
                            setPersoneSelezionate(p);
                        }}
                    >
                        <div className={stile.eventiTestoBottoniInBasso}>Seleziona{"\n"}Tutti</div>
                    </div>
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                        onClick={() => {
                            var p = [];
                            for (let i = 0; i < personeConvocateStaff.length; i++) p[i] = false;
                            setPersoneSelezionate(p);
                        }}
                    >
                        <div className={stile.eventiTestoBottoniInBasso}>Seleziona{"\n"}Nessuno</div>
                    </div>
                    <div className={`${stile.bottone3} ${stile.bottoneColoreVerde}`} onClick={() => partecipantiRimuoviSelezionati(true)}>
                        <div className={stile.eventiTestoBottoniInBasso}>Conferma{"\n"}Rimozione</div>
                    </div>
                </div>
            </div>
        )
    }
    
    if (pagina === PAGINE.convocatiEvento) {
        return (
            <div style={{display:"flex", flexDirection: "column", overflowY:"auto", paddingTop:5, paddingBottom:10}}>
                <DatiEvento flagBottoneTornaEventi={false} />
    
                <div className={stile.eventiSpazioSottotitolo}>
                    <div className={stile.eventiTestoSottotitolo}>Convocati</div>
                </div>     
    
                <div style={{display:"flex", flexDirection: "row"}}>
                    
                    { personeConvocateNonStaff.length > 0 ?
                        <div 
                            className={`${stile.bottone5} ${stile.bottoneColoreNormale}`} 
                            onClick={() => setPagina(PAGINE.eventiInputRisultati)}
                        >
                            <div className={stile.bottoneTestoNormale}>RISULTATI</div>
                        </div>
                    : <></> }
    
                    <div 
                        className={`${stile.bottone5} ${stile.bottoneColoreNormale}`}
                        onClick={() => { scaricaPersoneNonConvocate(eventoSelezionato.ID, false); setPagina(PAGINE.convocatiAggiungi); }}
                    >
                        <div className={stile.bottoneTestoBold}>AGGIUNGI</div>
                    </div>
    
                    { personeConvocateNonStaff.length > 0 ?
                        <div 
                            className={`${stile.bottone5} ${stile.bottoneColoreNormale}`}
                            onClick={() => {
                                if (personeConvocateNonStaff.length < 1) {
                                    window.alert("Rimuovi convocati: Non ci sono convocati da rimuovere");
                                    return;
                                } else {
                                    setPagina(PAGINE.convocatiRimuovi);
                                }
                            }}
                        >
                            <div className={stile.bottoneTestoBold}>RIMUOVI</div>
                        </div>
                    : <></> }
                </div>
    
                { personeConvocateNonStaff.length > 0 ?
                    <div className={stile.eventiConvocatiSpazioTitolo}>
                        <div 
                            className={`${stile.eventiConvocatiTitoloNome} ${stile.bottoneColoreNormale}`} 
                            onClick={() => { if (filtroConvocati === "Convocati") setFiltroConvocati("Aderiscono"); else setFiltroConvocati("Convocati"); }}
                        >                        
                            <div className={stile.bottoneTestoNormale}>
                                {filtroConvocati === 'Convocati' ? 'Elenco convoc. (' + personeConvocateNonStaff.length + ')' : 'Elenco ades. (' + nonStaffConferma + ')'}
                            </div>
                        </div>
                        <div className={stile.eventiConvocatiSpazioTitoloIcona} style={{ justifyContent: "flex-end" }}>
                            <div className={stile.eventiConvocatiTitoloIcona}>Ader.</div>
                        </div>
                        <div className={stile.eventiConvocatiSpazioTitoloIcona} style={{ justifyContent: "flex-end" }}>
                            <div className={stile.eventiConvocatiTitoloIcona}>Pres.</div>
                        </div>
                        <div className={stile.eventiConvocatiSpazioTitoloIcona} style={{ justifyContent: "flex-end" }}>
                            <div className={stile.eventiConvocatiTitoloIcona}>Msg</div>
                        </div>
                    </div>
                : <></> }
    
                <div className={stile.eventiConvocatiScrollView}>
                    {personeConvocateNonStaff.map((p, i) => (
                    <div key={i}>
                        {filtroConvocati === "Aderiscono" && p.CONFERMA != 1 ? <></> :
                            <div className={stile.eventiConvocatiSpazioElemento}>
                                <div className={stile.eventiConvocatiNome}>{p.COGNOME} {p.NOME}</div>
                                <div style={{ flex: 1 }} className={stile.spazioIconaPiccola}>
                                    {p.CONFERMA == 0 ? (
                                        <img
                                            className={stile.iconaPiccolaQuadrata}
                                            src={require('./pollicegiu.png')}
                                        />
                                    ) : <></>}
                                    {p.CONFERMA == 1 ? (
                                        <img
                                            className={stile.iconaPiccolaQuadrata}
                                            src={require('./pollicesu.png')}
                                        />
                                    ) : <></>}
                                    {p.CONFERMA == -1 ? (
                                        <img
                                            className={stile.iconaPiccolaQuadrata}
                                            src={require('./interrogativo.png')}
                                        />
                                    ) : <>{p.CONFERMA}</>}
                                </div>
                                <div style={{ flex: 1 }} className={stile.spazioIconaPiccola}>
                                    {p.PRESENTE == -1 ? (
                                        <div onClick={() => segnaPresenzaConvocato(p.ID_PERSONA, true)}>
                                            <img
                                                className={stile.iconaPiccolaQuadrata}
                                                src={require('./cerchio_vuoto.png')}
                                            />
                                        </div>
                                    ) : <></>}
                                    {p.PRESENTE == 0 ? (
                                        <div onClick={() => segnaPresenzaConvocato(p.ID_PERSONA, true)}>
                                            <img
                                                className={stile.iconaPiccolaQuadrata}
                                                src={require('./cerchio_no.png')}
                                            />
                                        </div>
                                    ) : <></>}
                                    {p.PRESENTE == 1 ? (
                                        <div onClick={() => segnaPresenzaConvocato(p.ID_PERSONA, false)}>
                                            <img
                                                className={stile.iconaPiccolaQuadrata}
                                                src={require('./cerchio_ok.png')}
                                            />
                                        </div>
                                    ) : <></>}
                                </div>
                                <div style={{ flex: 1 }} className={stile.spazioIconaPiccola} onClick={() => { setIdDestinatarioSelezionato(p.ID_PERSONA); setPagina(PAGINE.chatEvento); }}>
                                    <img
                                        className={stile.iconaPiccolaQuadrata}
                                        src={require('./busta.png')}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    ))}
                </div>

                <div className={stile.eventiSpazioSottotitolo}>
                    <div className={stile.eventiTestoSottotitolo}>Staff dell'evento</div>
                </div>     

                {/* ELENCO PERSONE DELLO STAFF CONVOCATE */}

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className={stile.eventiTestoTitoloConvocatiStaff}>
                        Staff ({personeConvocateStaff.length})
                    </div>

                    <div className={`${stile.bottone5} ${stile.bottoneColoreNormale}`} onClick={() => { scaricaPersoneNonConvocate(eventoSelezionato.ID, true); setPagina(PAGINE.convocatiAggiungiStaff); }}>
                        <div className={stile.bottoneTestoBold}>AGGIUNGI</div>
                    </div>                    

                    <div className={`${stile.bottone5} ${stile.bottoneColoreNormale}`} 
                        onClick={() => {
                            if (personeConvocateStaff.length < 2) {
                                window.alert("Rimuovi staff: Non ci sono membri staff da rimuovere, non puoi rimuovere te stesso");
                                return;
                            } else {
                                setPagina(PAGINE.convocatiRimuoviStaff);
                            }
                        }}
                    >                        
                        <div className={stile.bottoneTestoBold}>RIMUOVI</div>
                    </div>
                </div>

                <div className={stile.eventiConvocatiScrollView}>
                    {personeConvocateStaff.map((p, i) => (
                        <div key={i} className={stile.eventiConvocatiSpazioElemento}>
                            <div className={stile.eventiConvocatiNome}>{p.COGNOME} {p.NOME}</div>
                        </div>
                    ))}
                </div>

            </div>
        )
    }

    if (pagina === PAGINE.chatEvento) {
        return (
            <>
                <DatiEvento
                    {...props}
                    flagBottoneTornaEventi={false}                    
                />

                <Messaggi
                    {...props}                    
                    eventoSelezionato={eventoSelezionato}
                    idDestinatarioSelezionato={idDestinatarioSelezionato}                    
                    personeConvocateNonStaff={personeConvocateNonStaff}
                    PAGINEHOME={props.PAGINECLIENTI}                    
                />
            </>
        )
    }    

    if (pagina === PAGINE.eventiInputRisultati) {
        return (
            <>
                <EventiInputRisultati
                    {...props}
                    personeConvocateNonStaff={personeConvocateNonStaff}
                    idEvento = {eventoSelezionato.ID_EVENTO}
                    callback={(v) => {if (v==="login") props.setPagina(PAGINE.login); else scaricaPersoneConvocate(eventoSelezionato.ID_EVENTO, PAGINE.convocatiEvento) }}
                />

            </>
        )
    }    

    if (pagina === PAGINE.eventoModifica || pagina === PAGINE.eventoInserisci) {
        return (
            <div style={{flex:1, padding:10}}>
                <div style={{overflowY:"auto"}}>
    
                    <div style={{display:'flex', flexDirection: 'row'}}>
                        <div style={{paddingBottom:5}} className={stile.eventiModificaTestoTitoli}>
                            {pagina === PAGINE.eventoModifica ? "Modifica evento" : "Inserisci nuovo evento"}
                        </div>
                    </div>
                    <div style={{display:'flex', flexDirection: 'row'}}>
                        <div style={{flex:2}}>
                            <div className={stile.eventiModificaTestoTitoli}>Data inizio evento*</div>
                            <div style={{display:'flex', flexDirection: 'row'}}>
                                <div style={{ flexGrow: 1 }}>
                                    <SelezionaData data={dataInizio} setData={setDataInizio} style={{width:"100%"}} className={stile.eventiModificaInputTesto} />
                                </div>
                                <div onClick={() => setDataInizio("")}>
                                    <img
                                        className={stile.iconaPiccolaQuadrata}
                                        style={{marginLeft:5}}
                                        src={require('./x.png')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{flex:1, marginLeft: 5}}>
                            <div className={stile.eventiModificaTestoTitoli}>Ora</div>
                            <div style={{display:'flex', flexDirection: 'row'}}>
                                <input type="text" value={oraInizio} onChange={(e) => setOraInizio(e.target.value)} maxLength={5} style={{flex:1}} className={stile.eventiModificaInputTesto} />
                                <div onClick={() => setOraInizio("")}>
                                    <img
                                        className={stile.iconaPiccolaQuadrata}
                                        style={{marginLeft:5}}
                                        src={require('./x.png')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div style={{display:'flex', flexDirection: 'row'}}>
                        <div style={{flex:2}}>
                            <div className={stile.eventiModificaTestoTitoli}>Data fine evento</div>
                            <div style={{display:'flex', flexDirection: 'row'}}>
                                <div style={{ flexGrow: 1 }}>
                                    <SelezionaData data={dataFine} setData={setDataFine} style={{width:"100%"}} className={stile.eventiModificaInputTesto} />
                                </div>
                                <div onClick={() => setDataFine("")}>
                                    <img
                                        className={stile.iconaPiccolaQuadrata}
                                        style={{marginLeft:5}}
                                        src={require('./x.png')}
                                    />
                                </div>      
                            </div>                      
                        </div>

                        <div style={{flex:1, marginLeft: 5}}>
                            <div className={stile.eventiModificaTestoTitoli}>Ora</div>
                            <div style={{display:'flex', flexDirection: 'row'}}>
                                <input type="text" value={oraFine} onChange={(e) => setOraFine(e.target.value)} maxLength={5} style={{flex:1}} className={stile.eventiModificaInputTesto} />
                                <div onClick={() => setOraFine("")}>
                                    <img
                                        className={stile.iconaPiccolaQuadrata}
                                        style={{marginLeft:5}}
                                        src={require('./x.png')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className={stile.eventiModificaTestoTitoli}>Tipo di evento*</div>
                    {/*<Tendina2
                        className={stile.genericaDropdown}                        
                        options={elencoTipiEventi}
                        defaultValue={tipo != null ? tipo : elencoIdTipiEventi[eventoSelezionato.ID_TIPO_EVENTO]}
                        onSelect={(t) => setTipo(elencoTipiEventi[t])}
                    />*/}

                    <Tendina2
                        className={stile.genericaDropdown}
                        options={
                            pagina === PAGINE.eventoInserisci
                                ? ['', ...elencoTipiEventi]
                                : elencoTipiEventi
                        }
                        defaultValue={tipo != null ? tipo : elencoIdTipiEventi[eventoSelezionato.ID_TIPO_EVENTO]}
                        onSelect={(t) => {
                            const adjustedIndex = pagina === PAGINE.eventoInserisci ? t - 1 : t;
                            if (adjustedIndex >= 0) {
                                setTipo(elencoTipiEventi[adjustedIndex]);
                            }
                        }}
                    />
    
                    <div style={{display:'flex', flexDirection: 'row'}}>
                        <div style={{flex:1}}>
                            <div className={stile.eventiModificaTestoTitoli}>Luogo*</div>
                            <div style={{display:'flex', flexDirection: 'row'}}>
                                <input type="text" value={luogo} onChange={(e) => setLuogo(e.target.value)} maxLength={100} style={{flex:1}} className={stile.eventiModificaInputTesto} />
                                <div onClick={() => setLuogo("")}>
                                    <img
                                        className={stile.iconaPiccolaQuadrata}
                                        style={{marginLeft:5}}
                                        src={require('./x.png')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div style={{display:'flex', flexDirection: 'row'}}>
                        <div style={{flex:1}}>
                            <div className={stile.eventiModificaTestoTitoli}>Descrizione evento</div>
                            <div style={{display:'flex', flexDirection: 'row'}}>
                                <textarea
                                    value={descrizione}
                                    onChange={(e) => setDescrizione(e.target.value)}
                                    maxLength={1000}
                                    style={{flex:1, textAlignVertical:'top'}}
                                    className={stile.eventiModificaInputTesto}                                    
                                />
                                <div onClick={() => setDescrizione("")}>
                                    <img
                                        className={stile.iconaPiccolaQuadrata}
                                        style={{marginLeft:5}}
                                        src={require('./x.png')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div style={{display:"flex", flexDirection:"row", marginTop: 10}}>
                        {pagina === PAGINE.eventoModifica ? (
                            <div className={`${stile.bottone3} ${stile.bottoneColoreRosso}`} onClick={() => setPagina(PAGINE.eventoElimina)}>
                                <div className={stile.spazioIconaPiccola}>
                                    <img className={stile.iconaPiccolaQuadrata} src={require('./elimina.png')} />
                                </div>
                                <div className={stile.bottoneTestoNormale}>ELIMINA</div>
                            </div>
                        ) : null}
    
                        <div className={`${stile.bottone3} ${pagina === PAGINE.eventoInserisci ? stile.bottoneColoreRosso : stile.bottoneColoreNormale}`} onClick={() => { if (pagina === PAGINE.eventoInserisci) setPagina(PAGINE.menuEventi); else scaricaEventiElencoPerPersona() }}>
                            <div className={stile.spazioIconaPiccola}>
                                <img className={stile.iconaPiccolaQuadrata} src={require('./annulla.png')} />
                            </div>
                            <div className={stile.bottoneTestoNormale}>ANNULLA</div>
                        </div>
    
                        <div className={`${stile.bottone3} ${stile.bottoneColoreVerde}`} onClick={() => insModEvento()}>
                            <div className={stile.spazioIconaPiccola}>
                                <img className={stile.iconaPiccolaQuadrata} src={require('./modifica.png')} />
                            </div>
                            <div className={stile.bottoneTestoNormale}>MEMORIZZA</div>
                        </div>
                    </div>
    
                </div>
            </div>
        );
    }
    
    if (pagina === PAGINE.eventoElimina) {
        return (
            <>
                <DatiEvento flagBottoneTornaEventi={false} />
                <div style={{paddingTop:10, paddingBottom:10, paddingLeft:10}} className={stile.eventiModificaTestoTitoli}>
                    Confermi l'eliminazione di questo evento?
                </div>
    
                <div style={{display:'flex', flexDirection:'row', paddingLeft:5, paddingRight: 5}}>
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreNormale}`} 
                        onClick={() => { scaricaEventiElencoPerPersona() }}
                    >
                        <div className={stile.spazioIconaPiccola}>
                            <img
                                className={stile.iconaPiccolaQuadrata}
                                src={require('./annulla.png')}
                                alt="Annulla"
                            />
                        </div>
                        <div className={stile.bottoneTestoNormale}>ANNULLA</div>
                    </div>
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreRosso}`} 
                        onClick={() => { cancEvento() }}
                    >
                        <div className={stile.spazioIconaPiccola}>
                            <img
                                className={stile.iconaPiccolaQuadrata}
                                src={require('./elimina.png')}
                                alt="Conferma"
                            />
                        </div>
                        <div className={stile.bottoneTestoNormale}>CONFERMA</div>
                    </div>
                </div>
            </>
        )
    }
    
    if (pagina === PAGINE.eventoCopia) {
        return (
            <div style={{ flex: 1 }}>
                <DatiEvento flagBottoneTornaEventi={false} />
    
                <div style={{paddingTop:10, paddingBottom:10, paddingLeft:10}} className={stile.eventiModificaTestoTitoli}>
                    Verranno copiati luogo e descrizione.
                </div>
                <div 
                    style={{display:"flex", flexDirection:"row", paddingLeft: 10, paddingRight: 10, paddingTop: 10 }} 
                    onClick={() => setFlagCopiaConvocati(!flagCopiaConvocati)}
                >
                    {flagCopiaConvocati ? 
                        <img
                            className={stile.eventiIconaCerchio}
                            src={require('./cerchio_ok.png')}
                            alt="Cerchio OK"
                        />                    
                    :
                        <img
                            className={stile.eventiIconaCerchio}
                            src={require('./cerchio_vuoto.png')}
                            alt="Cerchio Vuoto"
                        />
                    }
                    <div style={{marginLeft:10}} className={stile.eventiModificaTestoTitoli}>
                        {"Copia anche l'elenco dei convocati: " + (flagCopiaConvocati ? "sì" : "no")}
                    </div>
                </div>
    
                <div style={{marginTop:20, marginLeft:10}} className={stile.eventiModificaTestoTitoli}>
                    Quante volte vuoi copiare questo evento?
                </div>
    
                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <Tendina2
                        className={stile.genericaDropdown}
                        options={tipiCopia}
                        defaultValue={tipiCopia[tipoCopia]}
                        onSelect={(t) => setTipoCopia(t)}
                    />
                </div>
    
                {tipoCopia === 0 ? 
                    <>
                        <div style={{display:"flex", flexDirection:"row",  paddingLeft: 10, paddingRight: 10 }}>
                            <div style={{ flexGrow: 2 }}>
                                <div style={{paddingTop:10}} className={stile.eventiModificaTestoTitoli}>
                                    Data inizio evento*
                                </div>
                                <SelezionaData data={dataInizio} setData={setDataInizio} style={{width:"100%"}} className={stile.eventiModificaInputTesto} />
                            </div>
                            <div style={{ flex: 1, marginLeft: 5 }}>
                                <div style={{paddingTop:10}} className={stile.eventiModificaTestoTitoli}>
                                    Ora
                                </div>
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    <input 
                                        type="text"
                                        value={oraInizio}
                                        onChange={(e) => setOraInizio(e.target.value)}
                                        maxLength={5}
                                        className={`${stile.eventiModificaInputTesto}`}
                                    />
                                    <div onClick={() => setOraInizio("")}>
                                        <img
                                            style={{marginLeft:5}}
                                            className={stile.iconaPiccolaQuadrata}
                                            src={require('./x.png')}
                                            alt="Cancella Ora"
                                        />
                                    </div>
                                </div>
                            </div>            
                        </div>
                        
                        <div style={{display:"flex", flexDirection:"row", paddingLeft: 10, paddingRight: 10 }}>
                            <div style={{ flexGrow: 2 }}>
                                <div style={{paddingTop:10}} className={stile.eventiModificaTestoTitoli}>                                
                                    Data fine evento
                                </div>
                                <SelezionaData data={dataFine} setData={setDataFine} style={{width:"100%"}} className={stile.eventiModificaInputTesto} />
                            </div>
                            <div style={{ flex: 1, marginLeft: 5 }}>
                                <div style={{paddingTop:10}} className={stile.eventiModificaTestoTitoli}>
                                    Ora
                                </div>
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    <input 
                                        type="text"
                                        value={oraFine}
                                        onChange={(e) => setOraFine(e.target.value)}
                                        maxLength={5}
                                        className={`${stile.eventiModificaInputTesto}`}
                                    />
                                    <div onClick={() => setOraFine("")}>
                                        <img
                                            style={{marginLeft:5}}
                                            className={stile.iconaPiccolaQuadrata}
                                            src={require('./x.png')}
                                            alt="Cancella Ora"
                                        />
                                    </div>
                                </div>
                            </div>            
                        </div>
                    </>
                    :
                    <div style={{display:"flex", flexDirection:"row", paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ flex: 2, marginTop: 10 }}>
                            <div className={stile.eventiModificaTestoTitoli}>
                                Dal giorno {convertiDataComputer2Umano(eventoSelezionato.DATA_INIZIO, "")} al giorno (compreso)*
                            </div>
                            <SelezionaData data={dataInizio} setData={setDataInizio} style={{width:"100%"}} className={stile.eventiModificaInputTesto} />
                        </div>
                    </div>
                }
    
                <div style={{display:"flex", flexDirection:"row", paddingLeft: 5, paddingRight: 5, marginTop: 10 }}>
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreNormale}`} 
                        onClick={() => { setPagina(PAGINE.elencoEventi) }}
                    >
                        <div className={stile.spazioIconaPiccola}>
                            <img
                                className={stile.iconaPiccolaQuadrata}
                                src={require('./annulla.png')}
                                alt="Annulla"
                            />
                        </div>
                        <div className={stile.bottoneTestoNormale}>ANNULLA</div>
                    </div>
    
                    <div 
                        className={`${stile.bottone3} ${stile.bottoneColoreVerde}`} 
                        onClick={() => { copiaEvento() }}
                    >
                        <div className={stile.spazioIconaPiccola}>
                            <img
                                className={stile.iconaPiccolaQuadrata}
                                src={require('./stella2.png')}
                                alt="Conferma"
                            />
                        </div>
                        <div className={stile.bottoneTestoNormale}>CONFERMA</div>
                    </div>
                </div>
            </div>
        )
    }

    if (pagina === PAGINE.elencoEventi) {
        const oggi = new Date().toISOString().split('T')[0]; // YYYY-MM-DD
        var flagNonCiSonoEventi = true;
        if (opzioneVisualizzazioneEventi === opzioniVisualizzazioneEventi[1]) {
            flagNonCiSonoEventi = false;
        } else {
            for (var n = 0; n < elencoEventi.length; n++) {
                if (elencoEventi[n].DATA_INIZIO >= oggi || elencoEventi[n].DATA_FINE >= oggi) {
                    flagNonCiSonoEventi = false;
                    break;
                }
            }
        }
    
        return (
            <div>
                {props.identitaSelezionata.flag_staff ? (
                    <>
                        <div className={`${stile.eventiModificaTestoTitoli} padding-top: 5px`}>
                            Visualizza gli eventi:
                        </div>
                        <Tendina2
                            className={stile.genericaDropdown} 
                            style={{marginBottom: "5px"}}
                            options={opzioniVisualizzazioneEventi}
                            defaultValue={opzioneVisualizzazioneEventi}
                            onSelect={(t) => {
                                setOpzioneVisualizzazioneEventi(opzioniVisualizzazioneEventi[t]);
                            }}
                        />
                    </>
                ) : (
                    <></>
                )}
    
                {flagNonCiSonoEventi ? (
                    <div className={stile.eventiElencoContenitoreElemento}>
                        <div className={stile.eventiElencoTestoData}>
                            Non ci sono eventi da visualizzare
                        </div>
                    </div>
                ) : (
                    <></>
                )}
    
                {elencoEventi.map((x, i) => (
                    <div key={i}>
                        {opzioneVisualizzazioneEventi === opzioniVisualizzazioneEventi[1] || x.DATA_INIZIO >= oggi || x.DATA_FINE >= oggi ? (
                            <div key={i} className={stile.eventiElencoContenitoreElemento}>
                                <div className={stile.eventiElencoTestoData}>
                                    {x.DATA_FINE !== "" && x.DATA_FINE != null ? "da " : ""}
                                    {convertiDataComputer2Umano(x.DATA_INIZIO, "gSettAbbr")}
                                    {x.ORA_INIZIO !== "" && x.ORA_INIZIO != null ? " ore " + x.ORA_INIZIO : ""}
                                </div>
                                {x.DATA_FINE !== "" && x.DATA_FINE != null ? (
                                    <div className={stile.eventiElencoTestoData}>
                                        {"a " + convertiDataComputer2Umano(x.DATA_FINE, "gSettAbbr")}
                                        {x.ORA_FINE !== "" && x.ORA_FINE != null ? " ore " + x.ORA_FINE : ""}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {x.TIPO != null && x.TIPO !== "" ? <div className={stile.eventiElencoTestoLuogo}>{x.TIPO}</div> : <></>}
                                <div className={stile.eventiElencoTestoLuogo}>{x.LUOGO}</div>
                                <div className={stile.eventiElencoTestoDescrizione}>{x.DESCRIZIONE}</div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    {props.identitaSelezionata.flag_staff ? (
                                        <>
                                            <div
                                                className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                                                onClick={() => {
                                                    setEventoSelezionato(x);
                                                    scaricaPersoneConvocate(x.ID_EVENTO, PAGINE.convocatiEvento);
                                                }}
                                            >
                                                <div className={stile.spazioIconaPiccola}>
                                                    <img
                                                        className={stile.iconaPiccolaQuadrata}
                                                        src={require('./convocati.png')}
                                                    />
                                                </div>
                                                <div className={stile.bottoneTestoNormale}>Convoca</div>
                                            </div>
    
                                            <div
                                                className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                                                onClick={() => {
                                                    setEventoSelezionato(x);
                                                    var e = [...elencoEventi];
                                                    e[i].MSG_NON_LETTI = 0;
                                                    setElencoEventi(e);
                                                    scaricaPersoneConvocate(x.ID_EVENTO, PAGINE.chatEvento);
                                                }}
                                            >
                                                <div className={stile.spazioIconaPiccola}>
                                                    <img
                                                        className={stile.iconaPiccolaQuadrata}
                                                        src={require('./busta.png')}
                                                    />
                                                </div>
                                                <div className={stile.bottoneTestoNormale}>Messaggi</div>
                                                {x.MSG_NON_LETTI > 0 ? (
                                                    <div className={stile.eventiElencoIndicatoreMsgNonLetti}>{x.MSG_NON_LETTI}</div>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
    
                                            <div
                                                className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                                                onClick={() => {
                                                    setEventoSelezionato(x);
                                                    setDataInizio(x.DATA_INIZIO);
                                                    setOraInizio(x.ORA_INIZIO);
                                                    setDataFine(x.DATA_FINE);
                                                    setOraFine(x.ORA_FINE);
                                                    setTipo(elencoIdTipiEventi[x.ID_TIPO_EVENTO]);
                                                    setLuogo(x.LUOGO);
                                                    setDescrizione(x.DESCRIZIONE);
                                                    setPagina(PAGINE.caricamentoDati);
                                                    scaricaElencoTipiEventi(PAGINE.eventoModifica);
                                                }}
                                            >
                                                <div className={stile.spazioIconaPiccola}>
                                                    <img
                                                        className={stile.iconaPiccolaQuadrata}
                                                        src={require('./modifica.png')}
                                                    />
                                                </div>
                                                <div className={stile.bottoneTestoNormale}>Modifica</div>
                                            </div>
    
                                            <div
                                                className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                                                onClick={() => {
                                                    setDataInizio("");
                                                    setOraInizio("");
                                                    setDataFine("");
                                                    setOraFine("");
                                                    setTipo("");
                                                    setLuogo("");
                                                    setDescrizione("");
                                                    setEventoSelezionato(x);
                                                    scaricaPersoneConvocate(x.ID_EVENTO, PAGINE.eventoCopia);
                                                }}
                                            >
                                                <div className={stile.spazioIconaPiccola}>
                                                    <img
                                                        className={stile.iconaPiccolaQuadrata}
                                                        src={require('./stella2.png')}
                                                    />
                                                </div>
                                                <div className={stile.bottoneTestoNormale}>Copia</div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {x.CONFERMA == 1 ? (
                                                <div className={`${stile.bottone3} ${stile.bottoneColoreNormale}`} onClick={() => partecipantiEventiConferma(x.ID_EVENTO, 0)}>
                                                    <div className={stile.spazioIconaPiccola}>
                                                        <img
                                                            className={stile.iconaPiccolaQuadrata}
                                                            src={require('./pollicesu.png')}
                                                        />
                                                    </div>
                                                    <div className={stile.bottoneTestoNormale}>CI SARAI</div>
                                                </div>
                                            ) : x.CONFERMA == 0 ? (
                                                <div className={`${stile.bottone3} ${stile.bottoneColoreNormale}`} onClick={() => partecipantiEventiConferma(x.ID_EVENTO, 1)}>
                                                    <div className={stile.spazioIconaPiccola}>
                                                        <img
                                                            className={stile.iconaPiccolaQuadrata}
                                                            src={require('./pollicegiu.png')}
                                                        />
                                                    </div>
                                                    <div className={stile.bottoneTestoNormale}>NON CI SARAI</div>
                                                </div>
                                            ) : (
                                                <div className={`${stile.bottone3} ${stile.bottoneColoreRosso}`}>
                                                    <div className={stile.bottoneTestoNormale}>CI SARAI ?</div>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div className={stile.spazioIconaPiccola} onClick={() => { partecipantiEventiConferma(x.ID_EVENTO, 1); var i = { ...props.identitaSelezionata }; i.flag_nuovi_eventi = 0; props.setIdentitaSelezionata(i); }}>
                                                            <img
                                                                className={stile.iconaPiccolaQuadrata}
                                                                src={require('./pollicesu.png')}
                                                            />
                                                        </div>
                                                        <div className={stile.spazioIconaPiccola} style={{marginLeft:30}} onClick={() => { partecipantiEventiConferma(x.ID_EVENTO, 0); var i = { ...props.identitaSelezionata }; i.flag_nuovi_eventi = 0; props.setIdentitaSelezionata(i); }}>
                                                            <img
                                                                className={stile.iconaPiccolaQuadrata}
                                                                src={require('./pollicegiu.png')}
                                                            />
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            )}

                                            <div className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                                                onClick={() => { 
                                                    // memorizzo che questo è l'evento selezionato
                                                    setEventoSelezionato(x);
                                                    // memorizzo che i messaggi sono stati letti
                                                    var e=[...elencoEventi];
                                                    e[i].MSG_NON_LETTI=0;
                                                    setElencoEventi(e);                                                
                                                    // scarico l'elenco aggiornato delle persone convocate per questo evento e poi passo alla chat 
                                                    scaricaPersoneConvocate(x.ID_EVENTO, PAGINE.chatEvento);
                                                }}
                                            >

                                                <div className={stile.spazioIconaPiccola}>
                                                        <img
                                                            className={stile.iconaPiccolaQuadrata}
                                                            src={require('./busta.png')}
                                                        />
                                                    </div>
                                                    <div className={stile.bottoneTestoNormale}>
                                                        MESSAGGI
                                                        {x.MSG_NON_LETTI>0 && <span className={stile.eventiElencoIndicatoreMsgNonLetti}>{" " + x.MSG_NON_LETTI}</span>}
                                                    </div>
                                                
                                            </div>

                                        </>                                            
                                    )}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                ))}
            </div>
        );
    }  

    return (
        <div>Errore pagina non trovata (Eventi, {pagina})</div>
    )
}

export default Eventi;
