import React, {useState, useEffect} from 'react';

import Clessidra from '../Pagine/Clessidra'
import stile from './stile.module.css';

function AllenamentiClassificazioneEsercizi(props) {
    const PAGINE = {
        "caricamentoDati": 1,
        "elenco": 2,
        "modifica": 3,
        "inserisci": 4,
    };
    const [pagina, setPagina] = useState(PAGINE.caricamentoDati);
    const [elencoClassi, setElencoClassi] = useState([]);
    const [nome, setNome] = useState("");
    const [nomePrecedente, setNomePrecedente] = useState("");
    const [idClasse, setIdClasse] = useState(-1);

    function scaricaElencoClassificazioni() {
        setPagina(PAGINE.caricamentoDati);
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "elencoClassiEsercizi", emak: props.emak, sessionId:props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Operazione fallita", "Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login); 
                        return; 
                    }
                    setElencoClassi(valore_di_ritorno.risultatoRitorno);
                    setPagina(PAGINE.elenco);                    
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );                
        setPagina(PAGINE.caricamentoDati);         
    }

    function insMod() {
        if (nome=="") {
            window.alert("Impossibile memorizzare", "Indica il nome della classificazione"); 
            return;
        }
        setPagina(PAGINE.caricamentoDati); 
       
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: pagina===PAGINE.modifica ? "classeEsercizioMod" : "classeEsercizioIns", 
                idElemento: pagina===PAGINE.modifica ? idClasse : 0,
                descrizione: nome,
                emak: props.emak,
                sessionId:props.sessionId
            })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        if (valore_di_ritorno.mess == "Dato non inseribile: presente altro record uguale") {
                            window.alert("Operazione fallita", "Esiste già una classificazione con questo nome"); 
                        } else {
                            window.alert("Operazione fallita", "Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        }
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }

                    scaricaElencoClassificazioni();                    
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    function canc(id, flagCancellaComunque) {        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                op: "classeEsercizioCanc", 
                idElemento: id,
                permessoCanc: flagCancellaComunque?1:0,
                emak: props.emak, 
                sessionId:props.sessionId
            })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        if (valore_di_ritorno.mess==="Dato non cancellabile: presente in esercizi") {
                            if (window.confirm("Alcuni esercizi hanno questa classificazione: la elimino comunque e lascio quegli esercizi senza classificazione?")) {
                                canc(id, true);
                            } else {
                                // Azione se l'utente clicca su "No"
                            }
                        } else {
                            window.alert("Operazione fallita", "Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        }
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    scaricaElencoClassificazioni();                    
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );
    }

    useEffect(() => {
        scaricaElencoClassificazioni();
    }, [])

    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            switch (pagina) {
                case PAGINE.caricamentoDati: break;
                case PAGINE.elenco: props.callback(props.PAGINECLIENTI.allenamenti); break;
                case PAGINE.modifica: setPagina(PAGINE.elenco); break;
                case PAGINE.inserisci: setPagina(PAGINE.elenco); break;
            }
            
        } 
    }, [props.flagRichiestaBack])   

    if (pagina === PAGINE.caricamentoDati) {
        return (<Clessidra loading={true} />)
    }

    if (pagina === PAGINE.inserisci || pagina === PAGINE.modifica) {
        return (
            <div style={{ margin: 5 }}>
                <p class={stile.eventiModificaTestoTitoli} style={{paddingBottom: 5 }}>
                    {pagina === PAGINE.modifica ? `Modifica classificazione "${nomePrecedente}"` : "Inserisci nuova classificazione"}
                </p>
    
                <p class={stile.eventiModificaTestoTitoli}>Nome della classificazione:</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <input 
                        type="text" 
                        value={nome} 
                        onChange={(e) => setNome(e.target.value)} 
                        maxLength={200} 
                        style={{ flex: 1 }}
                        class={stile.eventiModificaInputTesto } 
                    />
                    <div onClick={() => setNome("")}>
                        <img
                            style={{ marginLeft: 5 }}
                            class={stile.iconaPiccolaQuadrata }
                            src={require('./x.png')}
                            alt="Cancella"
                        />
                    </div>
                </div>
    
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <button 
                        className={`${stile.bottone3} ${stile.bottoneColoreRosso}`}
                        onClick={() => scaricaElencoClassificazioni()}
                    >
                        <div class={stile.spazioIconaPiccola}>
                            <img
                                class={stile.iconaPiccolaQuadrata}
                                src={require('./annulla.png')}
                                alt="Annulla"
                            />
                        </div>
                        <span class={stile.bottoneTestoNormale}>ANNULLA</span>
                    </button>
    
                    <button 
                        className={`${stile.bottone3} ${stile.bottoneColoreVerde}`}
                        onClick={() => insMod()}
                    >
                        <div class={stile.spazioIconaPiccola}>
                            <img
                                class={stile.iconaPiccolaQuadrata}
                                src={require('./modifica.png')}
                                alt="Memorizza"
                            />
                        </div>
                        <span class={stile.bottoneTestoNormale}>MEMORIZZA</span>
                    </button>
                </div>
            </div>
        );
    }
    
    if (pagina === PAGINE.elenco) {
        return (
            <>
                <div style={{ overflowY: 'scroll' }}>
                    <div style={{ display: "flex", flexDirection: "row", padding: "5px 10px", marginBottom: 5, backgroundColor: "#E0E0E0" }}>
                        <div 
                            className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                            onClick={() => {
                                setNome("");
                                setNomePrecedente("");
                                setIdClasse(-1);
                                setPagina(PAGINE.inserisci);
                            }}
                        >
                            <div className={stile.spazioIconaPiccola}>
                                <img
                                    class={stile.iconaPiccolaQuadrata}
                                    src={require('./stella.png')}
                                    alt="Inserisci nuova"
                                />
                            </div>
                            <span className={stile.bottoneTestoNormale}>Inserisci nuova</span>
                        </div>
                    </div>
    
                    {elencoClassi.map((x, i) => (
                        <div key={i} class={stile.elencoSchedeContenitoreElemento}>
                            <div
                                style={{ flex: 1,
                                    display: "flex", 
                                    height: 50,
                                    flexDirection:'column',
                                    justifyContent: "center",
                                 }}
                                onClick={() => {
                                    setIdClasse(x.ID);
                                    setNomePrecedente(x.DESCRIZIONE);
                                    setPagina(PAGINE.modifica);
                                }}
                            >
                                <p className={stile.elencoSchedeNome}>
                                    {x.DESCRIZIONE}
                                </p>
                            </div>
    
                            <div 
                                style={{ width: 50, 
                                    height: 50,
                                    display: "flex", 
                                    alignItems: "center", 
                                    justifyContent: "center" }}
                                onClick={() => {
                                    setIdClasse(x.ID);
                                    setNomePrecedente(x.DESCRIZIONE);
                                    setPagina(PAGINE.modifica);
                                }}
                            >
                                <img
                                    class={stile.iconaPiccolaQuadrata}
                                    src={require('./modifica.png')}
                                    alt="Modifica"
                                />
                            </div>
    
                            <div 
                                style={{ width: 50, 
                                    height: 50,
                                    display: "flex", 
                                    alignItems: "center", 
                                    justifyContent: "center" }}
                                onClick={() => {
                                    if (window.confirm(`Confermi eliminazione della classificazione ${x.DESCRIZIONE}?`)) {
                                        canc(x.ID, false);
                                    }
                                }}
                            >
                                <img
                                    class={stile.iconaPiccolaQuadrata}
                                    src={require('./xgrigia-nobordo.png')}
                                    alt="Elimina"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    }

    return (
        <div>Errore pagina non trovata (AllenamentiClassificazioneEsercizi)</div>
    )
}

export default AllenamentiClassificazioneEsercizi;
