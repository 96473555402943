import React, {Fragment,useState,useRef,Suspense} from 'react';

import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import FormGridFormRowIscritto from '../SchedaIscritto/FormGridFormRow';
import FormGridFormRowAbb from '../SchedaIncassiAbbAss/SchedaIncassiAbbAss';
import FormattaImportoVideo from '../FormattaImportoVideo.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';
import controllaDataAAAAMMGG from '../controllaDataAAAAMMGG.js';

import FormGridFormRowAss  from '../SchedaIncassiAbbAss/SchedaIncassiAbbAss';

//import DateFnsUtils from "@date-io/date-fns";//npm i date-fns@next @date-io/date-fns (ma serve davvero??)
//import { DatePicker } from "@material-ui/pickers"; //npm i @material-ui/pickers


const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record
const STATO_VAI_IN_MODIFICA_ISCRITTO = 7;
const STATO_INCASSO_IMMEDIATO = 8;
const STATO_VAI_IN_INSERISCI_QUOTA_ABB = 9;
const STATO_VAI_IN_INSERISCI_QUOTA_ASS = 10;

function FormGridFormRow(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagAttivaModaleInserisciIscritto, setFlagAttivaModaleInserisciIscritto] = useState(0);
    const [flagAttivaModaleInserisciAbbonamento, setFlagAttivaModaleInserisciAbbonamento] = useState(0);
    const [flagAttivaModaleErroreAbbonamento, setFlagAttivaModaleErroreAbbonamento] = useState(0);
    const [flagAttivaModaleRichiediNumeroTessera, setFlagAttivaModaleRichiediNumeroTessera] = useState(0);
    const [flagAttivaModaleRichiediPermessoInserireIscritto, setFlagAttivaModaleRichiediPermessoInserireIscritto] = useState(0);
    const [flagAttivaModaleInserisciDataInizio, setFlagAttivaModaleInserisciDataInizio] = useState(0);

    const dataInizio   = useRef();
    const dataFine   = useRef();
    //utile per indicazione del cognome in caso di iscritti con più cognomi (o cognomi composti) e/o più nomi:
    const cognomeRef = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [statoTendinaIscritto,setStatoTendinaIscritto]=useState(false);
    const [statoTendinaAbbonamento,setStatoTendinaAbbonamento]=useState(false);
    const [statoRicercaUnitaMisura,setStatoRicercaUnitaMisura]=useState(false);
   
    const [error,setError]=useState('');
    
    const [risultatoServerDatiAbbonamento,setRisultatoServerDatiAbbonamento]=useState('');
    const [risultatoServerDatiPersona,setRisultatoServerDatiPersona]=useState('');
    const [risultatoServerInsolvenza,setRisultatoServerInsolvenza]=useState([]);
    //const [risultatoServerDatiAbbonamentoPersona,setRisultatoServerDatiAbbonamentoPersona]=useState('');
    
    const [insAbbonamentoEdi,setInsAbbonamentoEdi]=useState('');
    const [insIdAbbonamentoEdi,setInsIdAbbonamentoEdi]=useState('');
    const [insPersonaEdi,setInsPersonaEdi]=useState('');
    const [insIdPersonaEdi,setInsIdPersonaEdi]=useState('');

    const [cognomeDefault,setCognomeDefault]=useState('');
    const [nomeDefault,setNomeDefault]=useState('');
    const [tesseraNumeroDefault,setTesseraNumeroDefault]=useState('');
    
    const [idPersonaAbbonato, setIdPersonaAbbonato] = useState(-1); // contiene l'id dell'abbonato restituito dal backend dopo l'operazione di ins, serve per fare incasso immediato

    const [richiediDistinzCognomeNome,setRichiediDistinzCognomeNome]=useState(0);
    const [permessoInserirePersona,setPermessoInserirePersona]=useState(0);
    
    //const [defaultAbbonamento,setDefaultAbbonamento]=useState('');
    //const [defaultAbbonamentoPeriodicita,setDefaultAbbonamentoPeriodicita]=useState('');
    const [defaultAbbonamentoImporto,setDefaultAbbonamentoImporto]=useState('');
    const [defaultAbbonamentoAccessi,setDefaultAbbonamentoAccessi]=useState(0);
    //const [defaultAbbonamentoIva,setDefaultAbbonamentoIva]=useState('');
    //const [defaultPersona,setDefaultPersona]=useState('');
    
    const [dataInizioDefault,setDataInizioDefault]=useState(dataOggi);
    const [dataFineDefault,setDataFineDefault]=useState('');

    const [fineIscritti, setFineIscritti] = useState(0);

    //const [ritornaScheda,setRitornaScheda]=useState(0);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [unitaDurataPossibili,setUnitaDurataPossibili]=useState([]);

    const [defaultDurata, setDefaultDurata] = useState(0);
    const [defaultDurataUnitaMisura, setDefaultDurataUnitaMisura] = useState(0);

    const [unitaAttuale,setUnitaAttuale]=useState('');

    const [dataFineAltroAbbonamento,setDataFineAltroAbbonamento]=useState('');

    // colori presenti anche in SchedaIscritto e TabellaInsolventi
    const coloreRosso = "#D83D00"; //(PER DEBITI)
    const coloreVerde = "#43BC6A"; //(PER CREDITI)
    
    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaIscritto(false);
        setStatoTendinaAbbonamento(false);
        setStatoRicercaUnitaMisura(false);
      
        setPermessoInserirePersona(0);
    },[]);

    useEffect(() => {
        if(statoTendinaIscritto === true && statoTendinaAbbonamento === true && statoRicercaUnitaMisura === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaIscritto,statoTendinaAbbonamento,statoRicercaUnitaMisura]);

    useEffect(() => {
        if(insIdPersonaEdi !== ''){
            setStatoPagina(STATO_RICERCA_DATI);
            ricercaInsolvenza();
        }
    },[insIdPersonaEdi]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("abbonatiIns");
        if(props.ope==='modifica') setOperazione("abbonatiMod");
        if(props.ope==='cancella') setOperazione("abbonatiCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"abbonatiRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                  
                    let componiPersona="";
                    if(valoreDiRitorno.risultatoRitorno[0].COGNOME !== '') componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].COGNOME;
                    if(valoreDiRitorno.risultatoRitorno[0].NOME !== '' && componiPersona !== "") componiPersona = componiPersona + " ";
                    if(valoreDiRitorno.risultatoRitorno[0].NOME !== '') componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].NOME;
                    if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && componiPersona !== "") componiPersona = componiPersona + " ";
                    if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '') componiPersona = componiPersona + "("+valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA+")";
                    
                    setCognomeDefault(valoreDiRitorno.risultatoRitorno[0].COGNOME);
                    setNomeDefault(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setTesseraNumeroDefault(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA);
                    
                    //setDefaultPersona(componiPersona);
                    setInsPersonaEdi(componiPersona);//serve per settare la var di stato se non cambi valore nella tendina in caso di modifica
                    setInsIdPersonaEdi(valoreDiRitorno.risultatoRitorno[0].ID_PERSONA);
                    ricercaDatiPersona(props.ope);
                    setInsAbbonamentoEdi(valoreDiRitorno.risultatoRitorno[0].NOMEABBONAMENTO);//serve per settare la var di stato se non cambi valore nella tendina in caso di modifica
                    setInsIdAbbonamentoEdi(valoreDiRitorno.risultatoRitorno[0].IDABBONAMENTO);
                    //setDefaultAbbonamento(valoreDiRitorno.risultatoRitorno[0].NOMEABBONAMENTO);
                    //setDefaultAbbonamentoPeriodicita(valoreDiRitorno.risultatoRitorno[0].PERIODICITAABBONAMENTO);
                    setDefaultAbbonamentoImporto(valoreDiRitorno.risultatoRitorno[0].IMPORTOABBONAMENTO);
                    setDefaultAbbonamentoAccessi(valoreDiRitorno.risultatoRitorno[0].ACCESSI_RESIDUI);
                    //setDefaultAbbonamentoIva(valoreDiRitorno.risultatoRitorno[0].IVAABBONAMENTO);
                    setDataInizioDefault(valoreDiRitorno.risultatoRitorno[0].DATA_INIZIO);
                    setDataFineDefault(valoreDiRitorno.risultatoRitorno[0].DATA_FINE);

                    setDefaultDurata(valoreDiRitorno.risultatoRitorno[0].DURATA_PREDEFINITA);
                    setDefaultDurataUnitaMisura(valoreDiRitorno.risultatoRitorno[0].DURATA_PREDEFINITA_UNITA_MISURA);
                    
                    ricercaDatiAbbonamento(props.ope);
                    ricercaUnitaMisura(valoreDiRitorno.risultatoRitorno[0].DURATA_PREDEFINITA_UNITA_MISURA);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else{
            setStatoPagina(STATO_RICERCA_DATI);
            ricercaDatiPersona(props.ope);
            ricercaDatiAbbonamento(props.ope);
            ricercaUnitaMisura(0);
        }
    },[props.ope]);

    useEffect(() => {
        if(permessoInserirePersona===1 && (richiediDistinzCognomeNome!==1 && richiediDistinzCognomeNome!==2)) {
            setDisabilitaBottoni(true);
            fetchOperazioneServer(false);
        }
    },[permessoInserirePersona]);

    function ricercaUnitaMisura(valoreAttuale) {
        //ricerco tutte le unità di misura del tempo possibili che mi servono per popolare la tendina:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoUnitaTempo",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){  
                    setUnitaDurataPossibili(valoreDiRitorno.risultatoRitorno);    
                    //console.log("valoreAttuale"+valoreAttuale);
                    if(parseInt(valoreAttuale) !== 0){
                        for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                            
                            if(parseInt(valoreDiRitorno.risultatoRitorno[i].ID) === parseInt(valoreAttuale)){
                                //console.log("OK"+valoreDiRitorno.risultatoRitorno[i].DESCRIZIONE_UNITA_TEMPO);
                                setUnitaAttuale(valoreDiRitorno.risultatoRitorno[i].DESCRIZIONE_UNITA_TEMPO);
                                break;
                            } 
                        }
                    } 
                    setStatoRicercaUnitaMisura(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function callbackTermineModificaIscritto(eseguitoModifica,idIscritto) {
        //ricerco i dati dell'iscritto perchè potenzialmente modificato:
        setStatoPagina(STATO_RICERCA_DATI);
        ricercaDatiPersona("ricercaDatiDopoModifica");
        //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    }

    
    function ricercaDatiAbbonamentoPersona(idpersona,idabbonamento,durata,unitaMisuraDurata) {
        //ricerco tra i Abbonamenti della persona l'ultimo abbonamento che abbia fatto a quello che stò inserendo in modo da suggerirne la continutà delle date:
        setLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoAbbonati",estrai_record_con_data_finale_determinata_maggiore:1,id_abbonamento_per_classe:idabbonamento,idIscritto:idpersona,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                //setRisultatoServerDatiAbbonamentoPersona(valoreDiRitorno.risultatoRitorno);
                
                
                if(valoreDiRitorno.risultatoRitorno[0] && valoreDiRitorno.risultatoRitorno[0].DATA_FINE != undefined && valoreDiRitorno.risultatoRitorno[0].DATA_FINE != ''){
                    //controllo se la data e maggior rispetto alla data odierna e nel caso suggerirò come DataInizioDefault la data trovata + 1 giorno in modo da 
                    //suggerire la continuità con l'abbonamento attivo ad oggi
                    if(valoreDiRitorno.risultatoRitorno[0].DATA_FINE>=dataOggi){    
                        //l'uguale nella condizione serve a non avere sovrapposizioni tra vecchio abbonamento (che finisce oggi) e nuovo (che altrimenti 
                        //inizierebbe oggi) in data odierna. In questo modo se il vecchio finisce oggi, propongo di iniziare il nuovo domani.                  
                        let dataObj = new Date(valoreDiRitorno.risultatoRitorno[0].DATA_FINE);
                        // Aggiungiamo un giorno alla data
                        dataObj.setDate(dataObj.getDate());
                        // Convertiamo nuovamente l'oggetto Date in stringa nel formato aaaa-mm-gg
                        let nuovoFormatoData = dataObj.toISOString().split('T')[0];
                        //setto la data di suggerimento di fine di un abbonamanto della stessa classe:
                        setDataFineAltroAbbonamento(nuovoFormatoData);
                    }
                    calcolaDateInizioFine(dataInizioDefault,-1,durata,unitaMisuraDurata);//la data di fine la calcolo partendo dalla data di inizio di default perchè l'abbonamento è scaduto da tempo e non si può creare continuità
                    
                }
                else{
                    calcolaDateInizioFine(dataInizioDefault,-1,durata,unitaMisuraDurata);//la data di fine la calcolo partendo dalla data di inizio di default perchè non ho trovato abbonamenti cui attaccarmici
                }
                //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiPersona(operazione) {
        if(props.idIscritto){
            //l'iscritto è gia selezionato e fissato per cui:
            let componiPersona="";
            
            var flagRichiestoSpazio = false;
            if(props.cognome !== undefined && props.cognome !== "") {
                componiPersona += props.cognome;
                flagRichiestoSpazio = true;
            }
            if(props.nome !== undefined && props.nome !== "") {
                 if (flagRichiestoSpazio) componiPersona += " ";
                 componiPersona += props.nome;
                flagRichiestoSpazio = true;
            }
            if(props.tessera !== undefined && props.tessera !== "") {
                if (flagRichiestoSpazio) componiPersona += " ";
                componiPersona += "(" + props.tessera + ")";
            }
        
            setInsPersonaEdi(componiPersona);  
            setCognomeDefault(props.cognome);
            setNomeDefault(props.nome);
            setTesseraNumeroDefault(props.tessera);   
            setInsIdPersonaEdi(props.idIscritto); 
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);

        }else{
            //ricerco tra gli iscritti che mi servono per popolare la tendina delle persone che possono essere assegnatari di un abbonamento:
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"elencoPersone",ridotto:1,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
            };
        
            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                (valoreDiRitorno) => {
                    setLoading(false);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    setRisultatoServerDatiPersona(valoreDiRitorno.risultatoRitorno);
                    setStatoTendinaIscritto(true);
                    if(operazione === "ricercaDatiDopoModifica"){
                        //aggiorno eventualmente la persona in esame se ho cambiato uno dei dati che compaioni in tendina (cognome nome o numero di tessera):
                        let componiPersona = '';
                        for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                            if(valoreDiRitorno.risultatoRitorno[i]["ID"] === insIdPersonaEdi){
                                if(valoreDiRitorno.risultatoRitorno[i].COGNOME !== '') componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[i].COGNOME;
                                if(valoreDiRitorno.risultatoRitorno[i].NOME !== '' && componiPersona !== "") componiPersona = componiPersona + " ";
                                if(valoreDiRitorno.risultatoRitorno[i].NOME !== '') componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[i].NOME;
                                if(valoreDiRitorno.risultatoRitorno[i].NUMERO_TESSERA !== '' && componiPersona !== "") componiPersona = componiPersona + " ";
                                if(valoreDiRitorno.risultatoRitorno[i].NUMERO_TESSERA !== '') componiPersona = componiPersona + "("+valoreDiRitorno.risultatoRitorno[i].NUMERO_TESSERA+")";
                            } 
                        }
                        setCognomeDefault(valoreDiRitorno.risultatoRitorno[0].COGNOME);
                        setNomeDefault(valoreDiRitorno.risultatoRitorno[0].NOME);
                        setTesseraNumeroDefault(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA);

                        //setDefaultPersona(componiPersona);
                        setInsPersonaEdi(componiPersona);
                        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
        }
    }
    function ricercaDatiAbbonamento(operazione) {
        //ricerco tra i Abbonamenti che mi servono per popolare la tendina:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoAbbonamenti",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiAbbonamento(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaAbbonamento(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function tornaElenco() {
        props.callbackTermineModifica();
    }


    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function callbacksetValoriPersona(valore,posizione,valoreNonVisibile){
        setInsPersonaEdi(valore);
        //setDefaultPersona(valore);
        setInsIdPersonaEdi(valoreNonVisibile);
        setDataFineAltroAbbonamento('');
    
        if(insIdAbbonamentoEdi != '' && props.ope==='inserisci'){
            //setStatoPagina(STATO_RICERCA_DATI);
            ricercaDatiAbbonamentoPersona(valoreNonVisibile,insIdAbbonamentoEdi,defaultDurata,defaultDurataUnitaMisura);    
        }
    }
    function callbacksetValoriAbbonamento(valore,posizione,valoreNonVisibile){
        setInsAbbonamentoEdi(valore);
        setInsIdAbbonamentoEdi(valoreNonVisibile);
        setDataFineAltroAbbonamento('');
        let unitaMisuraDurata = '';
        let durata = '';
        //setDefaultAbbonamento(valore);
        //recupero i dati dell'abbonamento prescelto:
        for (let i=0; i<risultatoServerDatiAbbonamento.length ; i++) {
            if(risultatoServerDatiAbbonamento[i]["ID"] === valoreNonVisibile){
                //setDefaultAbbonamentoPeriodicita(risultatoServerDatiAbbonamento[i]["PERIODICITA"]);
                setDefaultAbbonamentoImporto(risultatoServerDatiAbbonamento[i]["IMPORTO"]);
                setDefaultDurata(risultatoServerDatiAbbonamento[i]["DURATA_PREDEFINITA"]);
                durata = risultatoServerDatiAbbonamento[i]["DURATA_PREDEFINITA"];
                setDefaultDurataUnitaMisura(risultatoServerDatiAbbonamento[i]["DURATA_PREDEFINITA_UNITA_MISURA"]);
                unitaMisuraDurata = risultatoServerDatiAbbonamento[i]["DURATA_PREDEFINITA_UNITA_MISURA"];
                setDefaultAbbonamentoAccessi(risultatoServerDatiAbbonamento[i]["ACCESSI"]);

                for (let j=0; j<unitaDurataPossibili.length ; j++) {
                    if(parseInt(unitaDurataPossibili[j].ID) === parseInt(risultatoServerDatiAbbonamento[i]["DURATA_PREDEFINITA_UNITA_MISURA"])){
                        setUnitaAttuale(unitaDurataPossibili[j].DESCRIZIONE_UNITA_TEMPO);
                        break;
                    } 
                }
             
                if(insIdPersonaEdi != '' && props.ope==='inserisci'){
                    //setStatoPagina(STATO_RICERCA_DATI);
                    ricercaDatiAbbonamentoPersona(insIdPersonaEdi,valoreNonVisibile,durata,unitaMisuraDurata);
                }else{
                    calcolaDateInizioFine(dataInizioDefault,-1,risultatoServerDatiAbbonamento[i]["DURATA_PREDEFINITA"],risultatoServerDatiAbbonamento[i]["DURATA_PREDEFINITA_UNITA_MISURA"]);
                }
                //setDefaultAbbonamentoIva(risultatoServerDatiAbbonamento[i]["IVA"]);
                break;
            } 
        }
        //setDefaultAbbonamentoPeriodicita(valoreNonVisibile);
    }
    function ricercaInsolvenza() {
        //ricerco eventuale insolvenza della persona scelta:
        if(insIdPersonaEdi === -1){
            //non ho ancora trovato un vero e proprio iscritto ma ho solo messo delle lettere nella tendina editabile per cui non posso 
            //cercare valori di insolvenza:
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }else{
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"restituisciSituazioneInsolvente",id:insIdPersonaEdi,emak: props.emak,sessionId:props.sessionId})
            };
        
            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                (valoreDiRitorno) => {
                    setLoading(false);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                            setRisultatoServerInsolvenza(valoreDiRitorno.totali);
                            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                },
                (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
        }
    }

    function calcolaDateInizioFine(dataInizoNuova,dataFineNuova,durata,unitaDurata){

        //tenuto conto della durata predefinita del corso, va acalcolare la data di inizio o la data di fine in modo che la distanza tra le due sia la durata predefinita
        
        if(parseInt(durata) !== 0){
            //eseguo il calcolo solo se per l'abbonamento in esame è stato indicato una durata di qualsiasi valore essa sia
            let descrizioneDurata = '';
            for (let i=0; i<unitaDurataPossibili.length ; i++) {
                if(unitaDurataPossibili[i].ID === unitaDurata) descrizioneDurata = unitaDurataPossibili[i].DESCRIZIONE_UNITA_TEMPO.toUpperCase(); 
            }
            let variazioneGiorni = 0;
            let variazioneMesi = 0;
            let variazioneAnni = 0;

            if(descrizioneDurata === 'GIORNI') variazioneGiorni = parseInt(durata); 
            if(descrizioneDurata=== 'SETTIMANE') variazioneGiorni = parseInt(durata) * 7; 
            if(descrizioneDurata === 'MESI') variazioneMesi = parseInt(durata); 
            if(descrizioneDurata === 'ANNI') variazioneAnni = parseInt(durata); 

            if(dataFineNuova === -1){
                
                var oldDate = new Date(dataInizoNuova);

                let day=new Date(oldDate.getFullYear()+variazioneAnni,oldDate.getMonth()+variazioneMesi,oldDate.getDate()+variazioneGiorni);

                let sMese = String(day.getMonth()+1);
                if(sMese.length === 1) sMese = "0"+sMese;
                let sGiorno = String(day.getDate());
                if(sGiorno.length === 1) sGiorno = "0"+sGiorno;
                let newClosingDate = day.getFullYear()+'-'+sMese+'-'+sGiorno;
                setDataFineDefault(newClosingDate); // aggiunge defaultDurata giorni alla data di inizio
              
            }else{

                var oldDate = new Date(dataFineNuova);

                let day=new Date(oldDate.getFullYear()-variazioneAnni,oldDate.getMonth()-variazioneMesi,oldDate.getDate()-variazioneGiorni);
                let sMese = String(day.getMonth()+1);
                if(sMese.length === 1) sMese = "0"+sMese;
                let sGiorno = String(day.getDate());
                if(sGiorno.length === 1) sGiorno = "0"+sGiorno;
                let newClosingDate = day.getFullYear()+'-'+sMese+'-'+sGiorno;

                setDataInizioDefault(newClosingDate); 
            }
        }
    }

    function verificaData(d) {
        // Definisci il pattern per il formato AAAA-MM-DD
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;

        // Verifica che il formato corrisponda al pattern
        if (!datePattern.test(d)) {
            return false;
        }

        // Estrai i componenti della data
        const [year, month, day] = d.split('-').map(Number);

        // Verifica che l'anno sia maggiore di 2000
        if (year <= 2000) {
            return false;
        }

        // Verifica che la data sia effettivamente valida
        const date = new Date(d);
        return date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day;
    }

    function callbackTermineInserimentoIncasso(eseguitoModifica,idIscritto) {
        tornaElenco();
    }

    function annullaInserimentoIscritto(){
        setPermessoInserirePersona(0);

        setFlagAttivaModaleInserisciIscritto(0);
        setFlagAttivaModaleInserisciAbbonamento(0);
        setFlagAttivaModaleErroreAbbonamento(0);
        setFlagAttivaModaleRichiediNumeroTessera(0);
        setFlagAttivaModaleRichiediPermessoInserireIscritto(0);
        setFlagAttivaModaleInserisciDataInizio(0);
        setFineIscritti(0);

        //setStatoPagina(STATO_INSERISCI);
    }

    function confermaInserimentoIscritto(){
        setPermessoInserirePersona(1);
        fetchOperazioneServer(false);
    }

    function callbackTermineModificaQuote(eseguitoModifica,idIscritto) {
        if(eseguitoModifica === 1){
            //ho eseguito la modifica per cui procedo con un nuovo ricalcolo delle quote da presentare a video aggiornate
            ricercaInsolvenza();
        }else{
            //non è stata eseguita alcuna modifica
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    }

    function fetchOperazioneServer(flagIncassoImmediato) {

        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;

        //cancello comunque;
        if(props.ope!=='cancella'){
            var dInizio = controllaDataAAAAMMGG(dataInizio.current.value);
            var dFine = controllaDataAAAAMMGG(dataFine.current.value);

            if(insPersonaEdi === ''){
                controlli = 1;
                setFlagAttivaModaleInserisciIscritto(1);
            }
            if(insAbbonamentoEdi === ''){
                controlli = 1;
                setFlagAttivaModaleInserisciAbbonamento(1);
            }
            if(dInizio == ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDataInizio(1);
            }
            if (dInizio == "errore") {
                controlli = 1;
                alert("Data di inizio abbonamento non valida");
                setDisabilitaBottoni(false);
                annullaInserimentoIscritto()
            }
            if (dFine == "errore") {
                controlli = 1;
                alert("Data di fine abbonamento non valida");
                setDisabilitaBottoni(false);
                annullaInserimentoIscritto()
            }
            let presenteAbbonamento = -1;
            //if(insAbbonamentoEdi != ''){
                presenteAbbonamento = 0;
                //controllo se l'abbonamento non è presente nell'elenco attuale:
                for (let i=0; i<risultatoServerDatiAbbonamento.length ; i++) {
                    if(risultatoServerDatiAbbonamento[i]["NOME"] === insAbbonamentoEdi){
                        presenteAbbonamento=1;
                        break;
                    } 
                }
                if(presenteAbbonamento === 0){
                    //setLoading(true);
                    setInsAbbonamentoEdi('');
                    //setDefaultAbbonamento('');
                    //setDefaultAbbonamentoPeriodicita('');
                    setFlagAttivaModaleErroreAbbonamento(1);
                    controlli = 1;
                } 
            //}
            
        }

        if(controlli===0){ 

            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{

                // parametri per inviare i dati al server:
                let cog="";
                if(richiediDistinzCognomeNome===1 && cognomeRef.current.value!==undefined) cog = cognomeRef.current.value;
                setLoading(true);

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope !=='cancella' ? 
                        JSON.stringify({op:operazione,
                        persona:insPersonaEdi,
                        cognome:cog,
                        permessoInserirePersona:permessoInserirePersona,
                        abbonamento:insAbbonamentoEdi,
                        accessi:defaultAbbonamentoAccessi,
                        dataInizio:dInizio,
                        dataFine:dFine,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                        : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                    };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) {
                            if (flagIncassoImmediato) {
                                //alert("Abbonamento inserito a " + insPersonaEdi + ", si procede con l'incasso della quota");
                                setIdPersonaAbbonato(valoreDiRitorno.idpersona);
                                setStatoPagina(STATO_INCASSO_IMMEDIATO);
                                return;
                            }
                            tornaElenco();
                        }
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                //if(valoreDiRitorno.mess==="Indicare numero di tessera per individuazione") setStatoPagina(STATO_RICHIEDI_NUMERO_TESSERA);
                                if(valoreDiRitorno.mess==="Indicare numero di tessera per individuazione"){
                                    setFlagAttivaModaleRichiediNumeroTessera(1);
                                }
                                //if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire iscritto") !== -1) setStatoPagina(STATO_RICHIEDI_PERMESSO_INSERIRE);
                                if(valoreDiRitorno.mess==="Richiedi permesso per inserire iscritto"){
                                    setPermessoInserirePersona(-1);
                                    setFlagAttivaModaleRichiediPermessoInserireIscritto(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Indica quale è cognome e quale nome") !== -1){
                                    setPermessoInserirePersona(-1);
                                    setFlagAttivaModaleRichiediPermessoInserireIscritto(1);
                                    setRichiediDistinzCognomeNome(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Reindica quale è cognome e quale nome") !== -1){
                                    setPermessoInserirePersona(-1);
                                    setFlagAttivaModaleRichiediPermessoInserireIscritto(1);
                                    setRichiediDistinzCognomeNome(2);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Indicare l'iscritto da tendina") !== -1){
                                    setFlagAttivaModaleInserisciIscritto(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Indicare l'abbonamento da tendina") !== -1){
                                    setFlagAttivaModaleInserisciAbbonamento(1)
                                } 
                                if(valoreDiRitorno.mess.indexOf("Data inizio non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDataInizio(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Iscritti Terminati") !== -1){
                                    setFineIscritti(1);
                                }
                            }
                            //altrimenti vado in errore generico
                            if(!valoreDiRitorno.mess) setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        } 
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    //JSON.stringify(risultatoServer)
    //console.log("stato pagina"+statoPagina)

        if (statoPagina===STATO_INCASSO_IMMEDIATO) {
            return (
                <FormGridFormRowAbb 
                    {...props}
                    ope={"inserisci"} 
                    callbackTermineModifica={callbackTermineInserimentoIncasso} 
                    idIscritto={idPersonaAbbonato}
                    persona={insPersonaEdi}
                    importo={defaultAbbonamentoImporto}
                    tipo={"abbonamento"}
                >
                </FormGridFormRowAbb>
            )
        }

        return (
            <Fragment>
              
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1Abb {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_VAI_IN_INSERISCI_QUOTA_ASS ? 
                    <div>
                    <Suspense fallback={
                    <div className="loader-container">
                    <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda incasso quota associativa in corso...</h6></div>
                    </div>
                }>

                <FormGridFormRowAss 
                    {...props}
                    ope={"inserisci"} 
                    callbackTermineModifica={callbackTermineModificaQuote} 
                    sessionId={props.sessionId} 
                    idIscritto={idPersonaAbbonato} 
                    cognome={cognomeDefault} 
                    nome={nomeDefault} 
                    tessera={tesseraNumeroDefault}
                    tipo={"associativa"}
                    labelRitorno={"Torna alla scheda dell'abbonato"}
                    >
                </FormGridFormRowAss>
                </Suspense>
                </div>
                :""}

                {statoPagina === STATO_VAI_IN_INSERISCI_QUOTA_ABB ? 
                    <div>
                    <Suspense fallback={
                    <div className="loader-container">
                    <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda incasso quota abbonamento in corso...</h6></div>
                    </div>
                }>
                <FormGridFormRowAbb 
                    {...props}
                    ope={"inserisci"} 
                    callbackTermineModifica={callbackTermineModificaQuote} 
                    sessionId={props.sessionId} 
                    idIscritto={idPersonaAbbonato} 
                    cognome={cognomeDefault} 
                    nome={nomeDefault} 
                    tessera={tesseraNumeroDefault}
                    tipo={"abbonamento"}
                    labelRitorno={"Torna alla scheda dell'abbonato"}
                    >
                </FormGridFormRowAbb>
                </Suspense>
                </div>
                :""}


                {statoPagina === STATO_VAI_IN_MODIFICA_ISCRITTO ? 
                    <div>
                    <Suspense fallback={
                    <div className="loader-container">
                    <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda iscritto in corso...</h6></div>
                    </div>
                }>
                
                <FormGridFormRowIscritto 
                    {...props}
                    ope={"modifica"} 
                    callbackTermineModifica={callbackTermineModificaIscritto} 
                    sessionId={props.sessionId} 
                    id={insIdPersonaEdi} 
                    provvenienza={"schedaAbbonato"}
                    >
                </FormGridFormRowIscritto>
                </Suspense>
                </div>
                :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {fineIscritti=== 1 ?
                        <Modale 
                        titolo="Informativa numero massimo iscritti"
                        flagErrore={true}
                        contenuto={<div>E' stato raggiunto il numero massimo di iscritti</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoIscritto()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleInserisciDataInizio===1 ?
                        <Modale 
                            titolo="Richiesta data inizio"
                            flagErrore={true}
                            contenuto={<div>Verifica la data di inizio: non è stata inserita o è errata</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoIscritto()}
                                }    
                            ]}
                        />
                    :""}

                     {flagAttivaModaleInserisciAbbonamento===1?
                        <Modale 
                            titolo="Richiesta abbonamento"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato l'abbonamento, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoIscritto()}
                                }    
                            ]}
                        />
                    :""}
                    

                    {flagAttivaModaleErroreAbbonamento===1?
                        <Modale 
                            titolo="Informativa errore abbonamento"
                            flagErrore={true}
                            contenuto={<div>L'abbonamento deve essere valorizzato scegliendolo da tendina</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoIscritto()}
                                }    
                            ]}
                        />
                    :""}
                    {flagAttivaModaleInserisciIscritto===1 ?
                        <Modale 
                            titolo="Richiesta iscritto"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato l'iscritto, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoIscritto()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleRichiediPermessoInserireIscritto===1 ?
                        <Modale 
                        titolo="Richiesta permesso inserimento"
                        flagErrore={true}
                        contenuto={<div>
                     
                            {permessoInserirePersona===-1 ? <>Vuoi davvero inserire {insPersonaEdi} nell'elenco degli iscritti?<br/></> :""}
                            {richiediDistinzCognomeNome===1 && permessoInserirePersona!==-1 ? <>Indica qual'è il Cognome dell'Iscritto che intendi inserire:<br/></> :""} 
                            {richiediDistinzCognomeNome===2 && permessoInserirePersona!==-1 ? <>Indicazione del Cognome non ritenuta valida. Indicare nuovamente qual'è il Cognome dell'Iscritto che intendi inserire:<br/></> :""}
                        
                            {(richiediDistinzCognomeNome===1 || richiediDistinzCognomeNome===2) && permessoInserirePersona!==-1?
                                <FormGroup>
                                <Label for="cognome">Cognome</Label>
                                <Input  maxLength={50} type="text" name="cognome" id="cognome"
                                        innerRef={cognomeRef} 
                                         placeholder=""
                                        defaultValue={insPersonaEdi}
                                /> 
                                </FormGroup>
                            :""}
                        </div>}      
                        bottoni={ 
                                [{
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);confermaInserimentoIscritto()}
                                },  
                                {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoIscritto()}
                                }]
                        }
                        />
                    :""}

                    {flagAttivaModaleRichiediNumeroTessera===1 ?
                        <Modale 
                            titolo="Richiesta numero tessera"
                            flagErrore={true}
                            contenuto={<div>Nel database ci sono più iscritti con il Cognome ed il Nome: {insPersonaEdi} Indicare il numero di tessera in modo da poter individuare quello desiderato oppure per procedere con l'inserimento di un nuovo iscritto qualora il numero di tessera non corrispondesse a nessuno degli iscritti individuati</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoIscritto()}
                                }    
                            ]}
                        />
                    :""}

                    {risultatoServerDatiAbbonamento.length===0 ?
                       <Modale 
                       titolo="Abbonamenti disponibili non presenti"
                       flagErrore={true}
                       contenuto={<div>E' necessario popolare l'elenco degli Abbonamenti Disponibili recandosi nel menù principale sezione Anagrafiche, Abbonamenti Disponibili</div>}
                       bottoni={[
                           {
                               "etichetta":"OK",
                               "tipo":"primary",
                               callback:() => {tornaElenco()}
                           }    
                       ]}
                   /> 
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Abbonato</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Abbonato</CardTitle> :""}
                            
                                <Clessidra loading={loading}/>
                                {props.ope === "cancella" ?
                                    <FinestraDialogo 
                                        titolo="Conferma eliminazione" 
                                        flagErrore={true}                                
                                        contenuto={<div>
                                            Confermi l'eliminazione dell'abbonamento <b>{insAbbonamentoEdi}</b> con data inizio <b><TrasformaDateAUmano data={dataInizioDefault}></TrasformaDateAUmano></b> a <b>{insPersonaEdi}</b>?<br/>                                    
                                        </div>}
                                        bottoni={[
                                            {
                                                "etichetta":"Conferma",
                                                "tipo":"primary",
                                                callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer(false)}
                                            },
                                            {
                                                "etichetta":"Annulla",
                                                "tipo":"primary",
                                                callback: () => {tornaElenco()}
                                            }
                                        ]}
                                    />                        

                                :

                                <>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                        <Label>Iscritto*</Label>
                                        {props.ope === "inserisci" ?
                                            <TendinaEditabile
                                                //titolo={"Iscritto*"}
                                                nomeCampo="COGNOME"
                                                nomeCampo2="NOME"
                                                nomeCampoParentesi="NUMERO_TESSERA"
                                                nomeCampoNonVisibile = "ID"
                                                bloccoInserimento = "0"
                                                valoreDefault={insPersonaEdi}
                                                arrayValori={risultatoServerDatiPersona}
                                                callbackSetVariazioneCampi={setVariazioneCampi}
                                                callbackSetValoreTendEditabile={callbacksetValoriPersona} 
                                            ></TendinaEditabile>
                                        :""}
                                        {props.ope === "modifica" ? <Label>: {insPersonaEdi}</Label> : ""}

                                        {(props.ope === "inserisci" || props.ope === "modifica") 
                                        && (insIdPersonaEdi !== undefined &&  insIdPersonaEdi !== '' && insIdPersonaEdi !== -1) && props.idIscritto == undefined ?
                                            <Button color="success" className="mt-2 bottoneMargineASinistra" onClick={() => {setStatoPagina(STATO_VAI_IN_MODIFICA_ISCRITTO)}}>Modifica Dati Anagrafici Iscritto</Button>
                                        :""}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                        <Label>Abbonamento*</Label>
                                        
                                            <TendinaEditabile
                                                //titolo={"Abbonamento*"}
                                                nomeCampo="NOME"
                                                nomeCampoNonVisibile ="ID"
                                                valoreDefault={insAbbonamentoEdi}
                                                arrayValori={risultatoServerDatiAbbonamento}
                                                bloccoInserimento="1"
                                                callbackSetVariazioneCampi={setVariazioneCampi}
                                                callbackSetValoreTendEditabile={callbacksetValoriAbbonamento} 
                                            ></TendinaEditabile>
                                       
                                        

                                        {(defaultAbbonamentoImporto !=='' && defaultAbbonamentoImporto !== -1)
                                         ?
                                            <Table nobordered className="mb-0">
                                            <tbody>
                                            {defaultAbbonamentoImporto !=='' && defaultAbbonamentoImporto !== -1?
                                                <tr><td><b><Label>Importo :</Label></b></td><td align="right"><Label> {FormattaImportoVideo(defaultAbbonamentoImporto)}</Label></td></tr>
                                            :""}
                                            {parseInt(defaultDurata) !== 0 ?
                                                <>
                                                <tr><td><b><Label>Durata:</Label></b></td><td align="right"><Label> {defaultDurata}</Label></td></tr>
                                                <tr><td><b><Label>Unità di Misura Durata:</Label></b></td><td align="right"><Label> {unitaAttuale}</Label></td></tr>
                                                {parseInt(defaultAbbonamentoAccessi) !== -1 ?
                                                    <tr><td><b><Label>Numero Accessi Residui:</Label></b></td><td align="right"><Label> {defaultAbbonamentoAccessi}</Label></td></tr>
                                                :<tr><td><b><Label>Numero Accessi Residui:</Label></b></td><td align="right"><Label> Infiniti</Label></td></tr>}
                                                </>
                                            :""}


                                            </tbody>
                                            </Table>
                                        :""}
                                        </FormGroup>
                                    </Col>
                                </Row>
                              
                                <Row form>  
                                <Col md={6}>
                                        <FormGroup>
                                            <Label for="dataInizio">Data Inizio*</Label>
                                            
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                            <Input maxLength={10} type="date" name="dataInizio" id="dataInizio"
                                                innerRef={dataInizio} 
                                                placeholder=""
                                                defaultValue={dataInizioDefault}
                                                value={dataInizioDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataInizioDefault(dataInizio.current.value);calcolaDateInizioFine(dataInizio.current.value,-1,defaultDurata,defaultDurataUnitaMisura);
                                                }}/> 
                                            :""}
                                            {dataFineAltroAbbonamento != ''?<Label > Data di fine di abbonamento della stessa classe: <TrasformaDateAUmano data={dataFineAltroAbbonamento}></TrasformaDateAUmano></Label> :""}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="dataFine">Data Fine</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                            <Input maxLength={10} type="date" name="dataFine" id="dataFine"
                                                innerRef={dataFine} 
                                                placeholder=""
                                                defaultValue={dataFineDefault}
                                                value={dataFineDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataFineDefault(dataFine.current.value);calcolaDateInizioFine(-1,dataFine.current.value,defaultDurata,defaultDurataUnitaMisura)}}/> 
                                            :""}

                                            
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {risultatoServerInsolvenza.length>0 ?
                                    <Row form><Col md={12}>
                                    <div className='schedaSottotitolo'>Dettaglio Situazione Quote di {insPersonaEdi}</div>
                                    {risultatoServerInsolvenza.map((elemento,index) => 
                                        <div key={index} style={{color: "#F0F0F0", fontWeight:'bold', backgroundColor:elemento.IMPORTO<0?coloreRosso:coloreVerde, paddingLeft:5}}>
                                        {elemento.DATA}: {FormattaImportoVideo(elemento.IMPORTO)} €
                                        </div>
                                    )}
                                    <Button color="success" className="mt-2 bottoneMargineADestra" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI_QUOTA_ASS)}>Pagamento Quota Associativa</Button>
                                    <Button color="success" className="mt-2" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI_QUOTA_ABB)}>Pagamento Quota Abbonamento</Button>
                                    </Col>
                                    </Row>
                                :""}

                                </>
                                }
                             

                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ?
                                    <>
                                        <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer(false)}}>Inserisci</Button>
                                        <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer(true)}}>Inserisci e incassa</Button>
                                    </> 
                                :
                                    ""
                                }
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer(false)}}>Memorizza Modifiche</Button>:""}
                                
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: 
                                <Fragment>
                                    {props.idIscritto == undefined ?
                                        <>Torna all'elenco</>
                                    :  <>Torna alla scheda dell'iscritto</> 
                                    } 
                                </Fragment>}
                                </Button>
                                </div>
                            
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
