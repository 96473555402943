import React, {useState, useEffect} from 'react';
import stile from './stile.module.css';

function CambioPassword(props) {
    const [vecchiaPassword, setVecchiaPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRipeti, setPasswordRipeti] = useState("");

    function formCambioPasswordCompletato() {
        if (vecchiaPassword==="") { window.alert("Dato mancante: inserisci la vecchia password");  return; }
        if (password==="") { window.alert("Dato mancante: inserisci la nuova password");  return; }
        if (passwordRipeti==="") { window.alert("Dato mancante: inserisci la nuova password in entrambi i campi richiesti");  return; }
        if (password!==passwordRipeti) { window.alert("Dato errato: le due password inserite non coincidono");  return; }
        if (password.length<8) { window.alert("Dato errato: scegli una nuova password che abbia almeno 8 caratteri");  return; }

        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ op:"personaCambioPassword", client:"appClienti", email:props.emailSelezionata, vecchia:vecchiaPassword, nuova:password, emak: props.emak, sessionId:props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valore_di_ritorno) => {
            if (valore_di_ritorno.risultatoOperazione !== 1) { 
                switch (valore_di_ritorno.risultatoOperazione) {
                    case -1:
                        window.alert("Cambio fallito: vecchia password errata");
                        break;
                    default:
                        window.alert("Cambio fallito: sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                }
                props.callback(props.PAGINECLIENTI.home);
                return; 
            }
            if (valore_di_ritorno.esito !== "ok") { window.alert("Cambio fallito: "+valore_di_ritorno.motivazione); return; }
            
            if (localStorage.getItem("passEMA")) localStorage.setItem("passEMA",password);
            window.alert("Password modificata correttamente");
            props.callback(props.PAGINECLIENTI.home);
        },
        (error) => { 
            props.callback(props.PAGINECLIENTI.home);
            return;
        }
        );          
    }

    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            props.callback(props.PAGINECLIENTI.impostazioni);            
        } 
    }, [props.flagRichiestaBack])

    return (
        <div className={stile.contenitoreBody} style={{ flex: 1 }}>
            <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 30, width: "100%" }}>
                <div className={stile.testoNormaleCentrato}>
                    Impostazione di una nuova password
                </div>
            </div>
    
            <div style={{ textAlign: 'center', marginBottom: 20, width: "100%" }}>
                <div className={stile.testoNormaleCentrato}>Vecchia password</div>
                <input
                    onChange={(e) => setVecchiaPassword(e.target.value)}
                    type="password"
                    maxLength={50}
                    className={stile.casellaInput}
                />
            </div>
    
            <div style={{ textAlign: 'center', marginBottom: 20, width: "100%" }}>
                <div className={stile.testoNormaleCentrato}>Nuova password</div>
                <input
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    maxLength={50}
                    className={stile.casellaInput}
                />
            </div>
    
            <div style={{ textAlign: 'center', marginBottom: 20, width: "100%" }}>
                <div className={stile.testoNormaleCentrato}>Nuova password (ripeti)</div>
                <input
                    onChange={(e) => setPasswordRipeti(e.target.value)}
                    type="password"
                    maxLength={50}
                    className={stile.casellaInput}
                />
            </div>
    
            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 30, marginLeft: 10, marginRight: 10 }}>
                <div
                    className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                    onClick={() => { formCambioPasswordCompletato() }}
                >
                    <div className={stile.spazioIconaPiccola}>
                        <img
                            className={stile.iconaPiccolaQuadrata}
                            src={require('./ok.png')}
                            alt="Ok Icon"
                        />
                    </div>
                    <div className={stile.bottoneTestoNormale}>CONFERMA</div>
                </div>
            </div>
        </div>
    );
}

export default CambioPassword;
