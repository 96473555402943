import React, {useEffect} from 'react';
import stile from './stile.module.css';

function Informazioni(props) {
    
    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            props.callback(props.PAGINECLIENTI.impostazioni);            
        } 
    }, [props.flagRichiestaBack])

    return (
        <div class={stile.contenitoreBody} style={{flex:1}}>                
            <div style={{ textAlign: 'center', marginTop:20, marginBottom:30, width: "100%", color:"#FFFFFF" }} >
                Gestione Sportiva
                <br/>
                App v. {props.VersioneApp}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 30, marginLeft: 10, marginRight: 10 }}>
                <div
                    className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                    onClick={() => { 
                        window.location.reload()
                    }}
                >
                    <div className={stile.spazioIconaPiccola}>
                        <img
                            className={stile.iconaPiccolaQuadrata}
                            src={require('./stella.png')}
                            alt="Aggiorna"
                        />
                    </div>
                    <div className={stile.bottoneTestoNormale}>Aggiorna App</div>
                </div>
                <div
                    className={`${stile.bottone3} ${stile.bottoneColoreNormale}`}
                    onClick={() => { 
                        props.callback(props.PAGINECLIENTI.impostazioni) 
                    }}
                >
                    <div className={stile.spazioIconaPiccola}>
                        <img
                            className={stile.iconaPiccolaQuadrata}
                            src={require('./ok.png')}
                            alt="Ok"
                        />
                    </div>
                    <div className={stile.bottoneTestoNormale}>Torna al Menu</div>
                </div>
            </div>

        </div>
    );
}

export default Informazioni;
