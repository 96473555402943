import React, {useState, useEffect} from 'react';
import Clessidra from '../Pagine/Clessidra';
import IconaMenu from './IconaMenu'

const STATO_RICERCA_DATI = 0;
const STATO_RICERCA_DATI_CORRETTA = 2;

function Home(props) {
    var iconaEventi = require('./eventi.png');
    var iconaQrcode = require('./qrcodegs.png');
    var iconaPrenotazioni = require('./prenotazioni.png');
    var iconaScadenze = require('./scadenze.png');
    var iconaNews = require('./news.png');
    var iconaAllenamenti = require('./allenamenti.png');
    var iconaMovimenti = require('./euro.png');
    var iconaImpostazioni = require('./impostazioni.png');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);

    var notificheColore = "#F0F0FF";
    var notificheSfondo = "#0000B0";
    var notificheTesto = "Verifica Stato Notifiche...";
    var notificheTestoAlert = props.statoVerificaNotifiche+","+props.token;
    if (props.statoVerificaNotifiche=="ok") {
        notificheColore = "#00F000";
        notificheSfondo = "#009000";
        notificheTesto = "Notifiche Attivate";
        notificheTestoAlert = "";
    }
    if (!props.token || props.token=="") {
        notificheColore = "#F00000";
        notificheSfondo = "#900000";
        notificheTesto = "Notifiche Disattivate";
        notificheTestoAlert = "Le notifiche non sono attive. Hai un browser non compatibile o non hai consentito le notifiche quando lo ha richiesto. I browser compatibili sono Safari (di serie su iPhone e iPad) o Chrome (da usare su Android). L'app deve essere lanciata da icona. Se hai fatto tutto e non funziona, prova a eliminare l'icona, torna su app.gestionesportiva.it e reinstalla la app come viene indicato";
    }

    useEffect(() => {
        setStatoPagina(STATO_RICERCA_DATI);
        scaricaDati();        
    },[]);

    useEffect(() => {
        if (props.statoVerificaNotifiche!="ok" && props.token && props.token!="") {
            // quando lo stato delle notifiche passa a "verifica in corso", riscarico i dati anche se l'avevo già fatto nella useEffect(()=>{...},[])
            // perché su alcuni telefoni ci mette del tempo a passare da "Notifiche Disattivate" a "verifica in corso"
            scaricaDati();        
        }

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then((registrations) => {
              if (registrations.length > 0) {
                console.log("Service Worker registrati:", registrations);
                registrations.forEach((registration, index) => {
                  console.log(`Service Worker ${index + 1}:`, registration);
                  console.log("Scope:", registration.scope);
                  console.log("Stato attuale:", registration.active ? "Attivo" : "Non attivo");
                });
              } else {
                console.log("Nessun Service Worker registrato.");
              }
            }).catch((error) => {
              console.error("Errore durante il recupero delle registrazioni dei Service Worker:", error);
            });
        }        
    },[props.statoVerificaNotifiche, props.token]);

    function scaricaDati() {
        var richiediVerificaNotifiche = 0;
        if (props.statoVerificaNotifiche=="") richiediVerificaNotifiche = 1;
        
        var fcm_token="";
        if (props.token) fcm_token=props.token;

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"homeAppClienti", fcm_token:fcm_token, richiediVerificaNotifiche:richiediVerificaNotifiche, emak:props.emak, sessionId: props.sessionId})
        };
    
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {          
                if(valoreDiRitorno.risultatoOperazione===1){
                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                } else {
                    console.log("Sessione scaduta, torna a login");
                    props.setPagina(props.PAGINECLIENTI.login);
                }
          },
          (error) => {console.log("Errore fetch"); props.setPagina(props.PAGINECLIENTI.login);}
        );        
    }

    if (statoPagina === STATO_RICERCA_DATI) {
        return (<Clessidra loading={true} />)
    }

    return (
        
        <div
            style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Centra verticalmente (perché la flexDirection è column) tutto il contenuto
            marginLeft: 5,
            marginRight: 5,
            }}
        >
            <div style={{
                marginBottom:"auto",
                display:"flex",
                justifyContent:"center"
            }}
            >                
            </div>

            <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.eventi)}
                    immagine={iconaEventi}
                    testo={"Eventi"}
                    evidenzia={props.identitaSelezionata.flag_nuovi_eventi>0 && props.identitaSelezionata.flag_staff==0}
                />                
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.qrcode)}
                    immagine={iconaQrcode}
                    testo={"QR Code"}
                />

            </div>

            <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.allenamenti)}
                    immagine={iconaAllenamenti}
                    testo={"Allenamenti"}
                />
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.prenotazioni)}
                    immagine={iconaPrenotazioni}
                    testo={"Prenotazioni"}
                />
            </div>

            <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.scadenze)}
                    immagine={iconaScadenze}
                    testo={"Scadenze"}
                    evidenzia={props.identitaSelezionata.flag_scadenze>0}
                />
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.news)}
                    immagine={iconaNews}
                    testo={"News"}
                    evidenzia={props.identitaSelezionata.flag_news>0}
                />
            </div>

            <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.movimenti)}
                    immagine={iconaMovimenti}
                    testo={"Pagamenti"}
                />
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.impostazioni)}
                    immagine={iconaImpostazioni}
                    testo={"Impostazioni"}
                />
            </div>
        
            <div 
                style={{
                    marginTop:"auto",
                    marginBottom:"auto",
                    marginLeft:"auto",
                    marginRight:"auto",
                    display:"flex",
                    width:"60%",
                    justifyContent:"center", 
                    fontVariant: "small-caps",
                    color:notificheColore,
                    backgroundColor:notificheSfondo,
                    borderColor:notificheColore,
                    borderStyle:"solid",
                    borderWidth:0.5,
                    cursor: "pointer"
                }}
                    onClick={() => { if (notificheTestoAlert!=="") alert(notificheTestoAlert)}}

            >
                {notificheTesto}
            </div>

        </div>

    )
}

export default Home;
