import React, {useState, useEffect, useRef} from 'react';
import stile from './stile.module.css';

function SelezionaOrario(props) {
    const backgroundColor = "#909090";
    const backgroundColorCifre = "#F0F0F0";
    const backgroundColorCifreErrato = "#802020";
    const color = "#FFFFFF";
    const colorCifre = "#404040";
    const colorCifreErrato = "#F0F0F0";
    const fontSize = 20;

    const orePossibili = ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
    const [minPossibili, setMinPossibili] = useState([]);
    const [pronto, setPronto] = useState(false);
    const [intOra, setIntOra] = useState(-1);
    const [intMin, setIntMin] = useState(-1);
    const refOra = useRef();
    const refMin = useRef();

    function formattaNumero2Decimali(n) {
        if (n>9) return n;
        return "0"+n;
    }

    useEffect(() => {
        setIntOra(parseInt(props.ora));
        setIntMin(parseInt(props.minuto));
        let n=0;
        let ar=[];
        for (let i=0; i<60; i+=props.minutiMinimiPrenotazione) {
            //console.log(String(formattaNumero2Decimali(i)))
            ar[n] = String(formattaNumero2Decimali(i));
            n++;
        }
        setMinPossibili(ar);
        setPronto(true);
    },[])

    useEffect(() => {
        //console.log(props.etichetta + ": intMin vale " + intMin)
        if (intOra>=0 && intMin>=0) props.callback(intOra,intMin);
    },[intOra,intMin])

    if (!pronto) return (<></>);

    return (
        <div style={{display: 'flex', width: 150, paddingBottom: 5, paddingLeft: 5, paddingRight: 5, flexDirection: 'column', backgroundColor: backgroundColor, borderRadius: 5}}>
            <div style={{paddingBottom: 5, textAlign: "center", fontSize: fontSize, fontWeight: "bold", color: color, backgroundColor: backgroundColor}}>
                {props.etichetta}
            </div>
            
            <div 
                style={{
                    display: 'flex', 
                    width: 130, 
                    justifyContent: "center", 
                    alignItems: 'center', 
                    backgroundColor: "#404040", 
                    paddingBottom: 2, 
                    paddingTop: 2, 
                    marginBottom: 5, 
                    marginLeft: "auto",
                    marginRight: "auto"
                }} 
                onClick={() => {
                    let m = intMin + parseInt(props.minutiMinimiPrenotazione);
                    if (m > 59) {
                        m -= 60;
                        if (intOra < 23) {
                            setIntOra(intOra + 1);
                        }
                    }
                    setIntMin(m);
                }}
            >
                <img 
                    src={require('./up2.png')} 
                    alt="Up"
                    style={{
                        width: 'auto',  // Assicura che l'immagine mantenga le proporzioni corrette
                        height: '100%', // Adatta l'altezza dell'immagine al contenitore
                        maxHeight: 30,  // Imposta un'altezza massima per l'immagine
                        objectFit: 'contain'  // Mantiene l'immagine contenuta nel suo box
                    }}
                />
            </div>
    
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{display: 'flex', width: 40, alignItems: 'center', justifyContent: 'center', marginTop: 5, marginBottom: 5, paddingLeft: 5, paddingRight: 5, paddingBottom: 10, paddingTop: 8, borderWidth: 1, borderColor: "#707070", backgroundColor: props.flagOrarioErrato ? backgroundColorCifreErrato : backgroundColorCifre}}>
                        <div style={{color: props.flagOrarioErrato ? colorCifreErrato : colorCifre, fontSize: fontSize}}>
                            {intOra}
                        </div>
                    </div>                
                </div>
                <div style={{ 
                    flex: 0.1, 
                    display: 'flex', 
                    backgroundColor: backgroundColor, 
                    justifyContent: "center", 
                    alignItems: "center",  // Aggiunto per la centratura verticale
                    height: '100%'  // Assicura che il contenitore abbia un'altezza definita
                }}>
                    <div style={{
                        display: 'flex', 
                        color: color, 
                        fontSize: 26, 
                        fontWeight: "bold", 
                        textAlign: "center", 
                        alignItems: "center",  // Assicura che il contenuto interno sia centrato
                        marginTop: '-5px' // per centrare verticalmente i "due punti"
                    }}>
                        :
                    </div>
                </div>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{display: 'flex', width: 40, alignItems: 'center',  justifyContent: 'center', marginTop: 5, marginBottom: 5, paddingLeft: 5, paddingRight: 5, paddingBottom: 10, paddingTop: 8, borderWidth: 1, borderColor: "#707070", backgroundColor: props.flagOrarioErrato ? backgroundColorCifreErrato : backgroundColorCifre}}>
                        <div style={{color: props.flagOrarioErrato ? colorCifreErrato : colorCifre, fontSize: fontSize}}>
                            {formattaNumero2Decimali(intMin)}
                        </div>
                    </div>                
                </div>
            </div>
    
            <div 
                style={{
                    display: 'flex', 
                    width: 130, 
                    justifyContent: "center", 
                    alignItems: 'center', 
                    backgroundColor: "#404040", 
                    paddingBottom: 2, 
                    paddingTop: 2, 
                    marginTop: 5, 
                    marginBottom: 5, 
                    marginLeft: "auto",
                    marginRight: "auto"
                }} 
                onClick={() => {
                    let m = intMin - parseInt(props.minutiMinimiPrenotazione);
                    if (m < 0) {
                        m += 60;
                        if (intOra > 0) {
                            setIntOra(intOra - 1);
                        }
                    }
                    setIntMin(m);
                }}
            >
                <img 
                    src={require('./down2.png')} 
                    alt="Down"
                    style={{
                        width: 'auto',  // Assicura che l'immagine mantenga le proporzioni corrette
                        height: '100%', // Adatta l'altezza dell'immagine al contenitore
                        maxHeight: 30,  // Imposta un'altezza massima per l'immagine
                        objectFit: 'contain'  // Mantiene l'immagine contenuta nel suo box
                    }}
                />
            </div>
        </div>
    );
    
}

export default SelezionaOrario;