import React, {useEffect} from 'react';
import IconaMenu from './IconaMenu';

function ImpostazioniMenu(props) {
    var iconaCambioPassword = require('./chiave.png');
    var iconaInformazioni = require('./informazioni.png');
    var iconaLogout = require('./logout.png');

    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            props.callback(props.PAGINECLIENTI.home);            
        } 
    }, [props.flagRichiestaBack])

    return (
        <div
            style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Centra verticalmente (perché la flexDirection è column) tutto il contenuto
            marginLeft: 5,
            marginRight: 5,
            }}
        >
            <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.cambioPassword)}
                    immagine={iconaCambioPassword}
                    testo={"Cambia\nPassword"}
                />
            </div>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <IconaMenu
                    callback={() => props.callback(props.PAGINECLIENTI.informazioni)}
                    immagine={iconaInformazioni}
                    testo={"Informazioni\nApp"}
                />
                <IconaMenu
                    callback={() => {
                        if (window.confirm("Confermi l'uscita dalla app?")) {
                            props.callback(props.PAGINECLIENTI.logout);
                        }
                    }}
                    immagine={iconaLogout}
                    testo={"Esci\nLogout"}
                />
            </div>

        </div>
    )
}

export default ImpostazioniMenu;
